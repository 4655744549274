const hi = {
  nav: {
    Map: "मानचित्",
    Properties: "संपत्तियां",
    Analyse: "विश्लेषण करें",
    PropGuide: "संपत्ति मार्गदर्शक",
    Shortlist: "शॉर्टलिस्ट",
    AboutUs: "हमारे बारे में",
    Utilities: "Utilities",
    Home: "Home",
    Gallery: "गैलरी",
  },
  map: {
    Map: "मानचित्",
    Satellite: "उपग्रह",
    ForSale: "बिक्री के लिए",
    ShowStormPipe: "तूफान पाइप दिखाएं",
    ShowWastePipe: "कचरा पाइप दिखाएं",
    Shortlist: "शॉर्टलिस्",
    Shortlisted: "शॉर्टलिस्ट किया गया",
    Maybe: "शायद",
    Nope: "नहीं",
  },
  detail: {
    Listed: "सूचीबद्",
    Size: "आकार",
    Bedrooms: "शयनकक्",
    Bathrooms: "स्नानघर",
    Garages: "गैराज",
    Location: "स्थान",
    OpenHome: "खुला घर",
    BasicInformation: "मूल जानकारी",
    Details: "विवरण",
    CapitalValuation: "पूँजी मूल्यांकन",
    PropertyInformation: "संपत्ति जानकारी",
    Timeline: "समयरेखा",
    SchoolZoning: "स्कूल क्षेत्रीकरण",
    ContactTheAgent: "एजेंट से संपर्क करें",
    Suburb: "उपनगर",
    NearbyProperties: "आस-पास की संपत्तियाँ",
    RecentlyViewedListings: "हाल ही में देखी गई सूचियाँ",
    All: "सभी",
    Primary: "प्राथमिक",
    Intermediate: "मध्यवर्ती",
    Secondary: "माध्यमिक",
    ShowMore: "और दिखाएं",
    ShowLess: "कम दिखाएं",
    City: "शहर",
    Suburbs: "उपनगर",
    AboutPropCentreCoNz: "PropCentre.co.nz के बारे में",
    PropertyType: "संपत्ति का प्रकार",
    Price: "मूल्",
    TypeAddress: "पता टाइप करें",
  },
  analyse: {
    PropertiesDataCentre: "संपत्तियां डेटा केंद्",
    Size: "आकार",
    Bedrooms: "शयनकक्ष",
    Bathrooms: "स्नानघर",
    Garages: "गैराज",
    BasicInformation: "मूल जानकारी",
    CapitalValuation: "पूंजी मूल्यांकन",
    PropertyInformation: "संपत्ति जानकारी",
    Timeline: "समयरेखा",
    SchoolZoning: "स्कूल क्षेत्रीकरण",
    Parking: "गैराज और पार्किंग",
  },
  shortlist: {
    ShortlistedProperties: "शॉर्टलिस्ट की गई संपत्तियां",
    All: "सभी",
    Shortlisted: "शॉर्टलिस्ट किया गया",
    Maybe: "शायद",
    Nope: "नहीं",
    Bedrooms: "शयनकक्",
    Bathrooms: "स्नानघर",
    Garages: "गैराज",
  },
  aboutus: {
    CompanyIntroduction: "कंपनी परिचय",
    cTextLineF:
      "PropCentre में आपका स्वागत है, एक अग्रणी ऑनलाइन मंच जो AI और बड़े डेटा विश्लेषण की शक्ति का उपयोग करके रियल एस्टेट उद्योग में क्रांति लाता है।",
    cTextLineS:
      "PropCentre में, हम लोगों द्वारा रियल एस्टेट खरीदने और बेचने के तरीके को पुनर्परिभाषित करने के लिए प्रतिबद्ध हैं। चाहे आप एक घर खरीदार हों जो परफेक्ट संपत्ति की तलाश में हैं या एक रियल एस्टेट प्रोफेशनल हों जो अपने कार्यप्रवाह को सुव्यवस्थित करना चाहते हैं, हमारा मंच आपकी जरूरतों को पूरा करने के लिए डिज़ाइन किया गया है।",
    Functions: "कार्",
    fTextLineTF: "1. बुद्धिमान संपत्ति खोज",
    fTextLineTFT:
      "हमारा मंच घर खरीदारों को उपलब्ध संपत्तियों की खोज करने की सहज क्षमता प्रदान करता है, प्रत्येक सूची के बारे में व्यापक जानकारी तक पहुंच प्राप्त करते हुए। आपको जरूरी सभी महत्वपूर्ण विवरणों के साथ, अपने सपनों का घर आसानी से ढूंढें।",
    fTextLineTS: "2. व्यापक संपत्ति अंतर्दृष्",
    fTextLineTST:
      "हर संपत्ति के बारे में गहन अंतर्दृष्टि खोजें, फर्श योजनाओं और सुविधाओं से लेकर पड़ोस के आंकड़ों तक। अपनी उंगलियों पर उपलब्ध सूचना के धन तक पहुंच प्राप्त करके सूचित निर्णय लें।",
    fTextLineTT: "3. रियल एस्टेट विशेषज्ञों के लिए पेशेवर उपकरण",
    fTextLineTTT:
      "रियल एस्टेट पेशेवर हमारे संपत्ति-संबंधित उपकरणों के सूट का उपयोग करके अपनी उत्पादकता को बढ़ा सकते हैं। बाजार विश्लेषण से लेकर क्लाइंट प्रबंधन तक, हमारा मंच उद्योग में उत्कृष्टता हासिल करने के लिए आपको संसाधनों से लैस करता है।",
    gnfxzj:
      "हमारा मंच AI और बड़े डेटा विश्लेषण में नवीनतम उन्नतियों का उपयोग करता है ताकि सुनिश्चित किया जा सके कि आपको उपलब्ध सबसे सटीक और अद्यतन जानकारी मिले। हम मानते हैं कि प्रौद्योगिकी और रियल एस्टेट विशेषज्ञता का संयोजन करके, हम खरीदने और बेचने की प्रक्रिया को सरल बना सकते हैं, इसे स",
    szyxfw: "डिजिटल मार्केटिंग सेवा",
    szyxfw1: "रियल एस्टेट लिस्टिंग प्रोमोशन",
    szyxfw1T:
      "हमारी ऑनलाइन रियल एस्टेट लिस्टिंग प्रमोशन सेवा के साथ प्रतिस्पर्धी रियल एस्टेट बाजार में अपनी संपत्ति की दृश्यता और वांछनीयता को बढ़ाएं। चाहे आप एक घर मालिक हों या एक रियल एस्टेट पेशेवर, हमारा मंच आपकी सूचियों को प्रभावी ढंग से प्रदर्शित करने के लिए एक व्यापक समाधान प्रदान करता है।",
    szyxfw2: "प्रदर्शन स्थान विज्ञापन",
    szyxfw2T:
      "हमारी डिस्प्ले प्लेसमेंट विज्ञापन सेवा के साथ अपने डिजिटल विज्ञापन अभियानों में सटीकता और प्रभाव की क्षमता को अनलॉक करें। विशेष रूप से व्यापार जो अपनी पहुंच का विस्तार करना चाहते हैं या मार्केटर्स जो अत्यधिक लक्षित एक्सपोजर की तलाश में हैं, वे अपनी ब्रांड उपस्थिति को बढ़ाने के लिए हमारे मंच का उपयोग कर सकते हैं।",
    MultiPlatformAd: "मल्टी-प्लेटफॉर्म इन्फ्लुएंसर विज्ञापन",
    NZAndOversea: "न्यूजीलैंड और विदेशी",
    NZContent:
      "मल्टी-प्लेटफॉर्म इन्फ्लुएंसर विज्ञापन के साथ अपने विपणन प्रयासों को अगले स्तर तक ले जाएं, जो विभिन्न सोशल मीडिया प्लेटफार्मों पर इन्फ्लुएंसर्स की शक्ति का उपयोग करने वाली एक गतिशील रणनीति है। यह सेवा इन्फ्लुएंसर्स की पहुंच और प्रामाणिकता का लाभ उठाने के लिए एक व्यापक समाधान प्रदान करती है। आप इसका उपयोग ब्रांड जागरूकता बढ़ाने, संलग्नता बढ़ाने या बिक्री बढ़ाने के लिए कर सकते हैं।",
    TechnicalD: "उद्यम के लिए तकनीकी विकास समर्थन",
    TechnicalDC: "डेटा विश्लेषण、व्यक्तिगत सिफारिश、संवाद AI और चैट बॉट्स",
    OurMission: "हमारा मिशन",
    OurMission1:
      "PropCentre में, हमारा मिशन व्यक्तियों और पेशेवरों को वे उपकरण और ज्ञान प्रदान करना है जिनकी उन्हें आत्मविश्वास के साथ रियल एस्टेट बाजार में नेविगेट करने की जरूरत है। हम रियल एस्टेट उद्योग को नई ऊँचाइयों पर पहुंचाने के लिए एक सहज, उपयोगकर्ता-अनुकूल अनुभव प्रदान करने के लिए समर्पित हैं।",
    OurMission2:
      "इस रोमांचक यात्रा में हमसे जुड़ें, जहां नवाचार रियल एस्टेट से मिलता है, और साथ में, हम संपत्ति लेन-देन के भविष्य को आकार देंगे।",
    OurMission3: "आज ही PropCentre का अन्वेषण करें और रियल एस्टेट के भविष्य का अनुभव करें।",
    ContactUs: "संपर्क करें",
  },
};
export default hi;
