<template>
  <div class="property-banner" :style="`background-image:url(${require('@/assets/no-pic.png')})`">
    <div class="no-photo">
      <span>No photo</span>
    </div>
    <div
      v-if="imgs.length > 0"
      class="property-banner--left"
      :style="`background-image:url(${resolveImageUrl(imgs[0])});`"
      @click="openPreviewBoard(0)"
    >
      <div class="property-banner__preview" @click="openPreviewBoard">
        <div class="property-banner__preview-col">
          <img class="icon" :src="require('@/assets/picture.png')" />
        </div>
        <div class="property-banner__preview-col">
          <span class="img-num">{{ imgs.length }}</span>
        </div>
      </div>
    </div>
    <div class="property-banner--right" v-if="imgs.length > 0">
      <div
        v-for="(img, index) in [imgs[1], imgs[2], imgs[3], imgs[4]]"
        :key="img ? img.id : index"
        :style="`background-image:url(${resolveImageUrl(img)});`"
        class="property-banner--grid"
        @click="openPreviewBoard(index + 1)"
      />
    </div>
    <preview-image
      ref="preview"
      v-if="imgs.length > 0"
      :imgsUrls="previewUrls"
      :visible="showPreview"
      @close="closePreview"
    />
  </div>
</template>
<script>
import { getArrayPropsDef, getEmptyStringPropsDef } from "@/common/propsConfig";
import previewImage from "@/components/previewImage";

export default {
  name: "PropertyBanner",
  components: {
    previewImage,
  },
  props: {
    imgs: getArrayPropsDef(true),
    serverDomain: getEmptyStringPropsDef(),
  },
  data() {
    return {
      showPreview: false,
      previewUrls: [],
    };
  },
  updated() {
    if (this.imgs.length && this.previewUrls.length === 0) {
      const previewUrls = this.imgs.map((img) => {
        return this.resolveImageUrl(img);
      });
      this.previewUrls = previewUrls;
    }
  },
  watch: {
    imgs(newVal){
      const previewUrls = newVal.map((img) => {
        return this.resolveImageUrl(img);
      });
      this.previewUrls = previewUrls;
    }
  },
  methods: {
    /**
     * @desc 解析图片地址
     */
    resolveImageUrl(img) {
      if (!img) {
        return "";
      }
      if (img.baseUrl.indexOf("http") != -1) {
        return img.baseUrl;
      } else {
        if (!this.serverDomain) {
          return "";
        }
        return this.serverDomain + img.baseUrl + ".crop.1660x948.jpg";
      }
    },
    /**
     * @desc
     */
    openPreviewBoard(index = 0) {
      this.showPreview = true;
      this.$refs.preview.switchCurrent(index);
    },
    /**
     * @desc
     */
    closePreview() {
      this.showPreview = false;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./propertyBanner.scss";
</style>