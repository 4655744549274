<template>
  <div>
    <el-dialog
      @close="cancel()"
      width="520px"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :show-close="true"
      center
      :visible.sync="show"
    >
      <div class="main_view">
        <div class="agent-info-view">
          <img
            v-if="agent.image"
            class="agent-head"
            :src="
              imageServerDomain + agent.image['base-url'] + '.crop.200x200.jpg'
            "
          />
          <div class="agent-name">
            {{ agent.firstName + " " + agent.lastName }}
          </div>
        </div>
        <el-tooltip
          class="item"
          effect="dark"
          content="Copied !"
          placement="top-end"
        >
          <div @click="clickCopy(agent.phoneMobile)" class="copy_view">
            <img class="phone-img" src="../../assets/popPhone.png" />
            <div class="phone-text">{{ agent.phoneMobile }}</div>
            <img class="copy-img" src="../../assets/popCopy.png" />
          </div>
        </el-tooltip>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getObjectPropsDef,
  getEmptyStringPropsDef,
} from "@/common/propsConfig.js";
export default {
  props: {
    isShow: Boolean,
    agent: getObjectPropsDef(),
    imageServerDomain: getEmptyStringPropsDef(),
  },
  watch: {
    isShow(value) {
      this.show = value;
    },
  },
  data() {
    return {
      show: false,
    };
  },
  methods: {
    cancel() {
      this.$emit("dismiss");
    },
    clickCopy(msg) {
      const save = function (e) {
        e.clipboardData.setData("text/plain", msg);
        e.preventDefault(); // 阻止默认行为
      };
      document.addEventListener("copy", save); // 添加一个copy事件
      document.execCommand("copy"); // 执行copy方法
      this.$message({ message: "复制成功", type: "success" });
    },
  },
};
</script>

<style lang="scss" scoped>
.main_view {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.agent-info-view {
  width: calc(100% - 40px);
  height: 106px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
}
.agent-head {
  width: 106px;
  height: 106px;
  border-radius: 50%;
  border: #ececec 1px solid;
}
.agent-name {
  color: #000000;
  font-size: 24px;
  text-align: left;
  margin-left: 10px;
}
.copy_view {
  height: 46px;
  border: 1px #158c79 solid;
  border-radius: 23px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.phone-text {
  color: #158c79;
  font-size: 18px;
  margin-left: 20px;
  margin-right: 20px;
}
.phone-img {
  margin-left: 20px;
  height: 18px;
  width: 18px;
}
.copy-img {
  margin-right: 20px;
  height: 18px;
  width: 18px;
}
.item {
  margin: 4px;
}
</style>