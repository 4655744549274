const districts = [
  {
    name: "Auckland City",
    num: 0,
    latitude: -36.8485,
    longitude: 174.7633,
    suburbs: [
      { name: "Freemans Bay", latitude: -36.848, longitude: 174.749, num: 0 },
      { name: "Parnell", latitude: -36.857, longitude: 174.779, num: 0 },
      { name: "Ponsonby", latitude: -36.846, longitude: 174.742, num: 0 },
      { name: "Grafton", latitude: -36.858, longitude: 174.764, num: 0 },
      { name: "Newmarket", latitude: -36.869, longitude: 174.777, num: 0 },
      { name: "Eden Terrace", latitude: -36.866, longitude: 174.758, num: 0 },
      { name: "Grey Lynn", latitude: -36.857, longitude: 174.734, num: 0 },
      { name: "Balmoral", latitude: -36.8819, longitude: 174.7464, num: 0 },
      { name: "Mount Eden", latitude: -36.881, longitude: 174.761, num: 0 },
      { name: "Kingsland", latitude: -36.871, longitude: 174.746, num: 0 },
      { name: "Sandringham", latitude: -36.894, longitude: 174.736, num: 0 },
      { name: "Mount Roskill", latitude: -36.907, longitude: 174.736, num: 0 },
      { name: "Epsom", latitude: -36.89, longitude: 174.779, num: 72 },
      { name: "Greenlane", latitude: -36.899, longitude: 174.792, num: 0 },
      { name: "Remuera", latitude: -36.874, longitude: 174.8, num: 0 },
    ],
  },
  {
    name: "North Shore City",
    num: 0,
    latitude: -36.8,
    longitude: 174.75,
    suburbs: [
      { name: "Albany", latitude: -36.7275, longitude: 174.6982, num: 0 },
      { name: "Birkenhead", latitude: -36.8133, longitude: 174.7253, num: 0 },
      { name: "Devonport", latitude: -36.8274, longitude: 174.7969, num: 0 },
      { name: "Glenfield", latitude: -36.7772, longitude: 174.7058, num: 0 },
      { name: "Takapuna", latitude: -36.787, longitude: 174.7731, num: 0 },
    ],
  },
  {
    name: "Waitakere City",
    num: 0,
    latitude: -36.8524,
    longitude: 174.5432,
    suburbs: [
      { name: "Henderson", latitude: -36.8756, longitude: 174.6278, num: 0 },
      { name: "New Lynn", latitude: -36.9101, longitude: 174.6851, num: 0 },
      { name: "Te Atatu", latitude: -36.8523, longitude: 174.6313, num: 0 },
      { name: "Titirangi", latitude: -36.9358, longitude: 174.6489, num: 0 },
      { name: "Glen Eden", latitude: -36.9264, longitude: 174.6569, num: 0 },
    ],
  },
  {
    name: "Manukau City",
    num: 0,
    latitude: -36.9928,
    longitude: 174.8847,
    suburbs: [
      { name: "Mangere", latitude: -36.968, longitude: 174.798, num: 0 },
      { name: "Otara", latitude: -36.9518, longitude: 174.8648, num: 0 },
      { name: "Papatoetoe", latitude: -36.9822, longitude: 174.8524, num: 0 },
      { name: "Manurewa", latitude: -37.0238, longitude: 174.883, num: 0 },
      { name: "Flat Bush", latitude: -36.9586, longitude: 174.9043, num: 0 },
    ],
  },
  {
    name: "Rodney",
    num: 0,
    latitude: -36.6167,
    longitude: 174.6,
    suburbs: [
      { name: "Orewa", latitude: -36.5864, longitude: 174.6931, num: 0 },
      { name: "Warkworth", latitude: -36.4007, longitude: 174.6617, num: 0 },
      { name: "Helensville", latitude: -36.6758, longitude: 174.4522, num: 0 },
      { name: "Kumeu", latitude: -36.7715, longitude: 174.5547, num: 0 },
      { name: "Whangaparaoa", latitude: -36.6245, longitude: 174.7073, num: 0 },
    ],
  },
  {
    name: "Papakura",
    num: 0,
    latitude: -37.0655,
    longitude: 174.9433,
    suburbs: [
      { name: "Takanini", latitude: -37.0513, longitude: 174.9256, num: 0 },
      { name: "Drury", latitude: -37.1022, longitude: 174.9425, num: 0 },
      { name: "Red Hill", latitude: -37.0381, longitude: 174.9007, num: 0 },
      { name: "Karaka", latitude: -37.0833, longitude: 174.9, num: 0 },
      { name: "Pahurehure", latitude: -37.0656, longitude: 174.9456, num: 0 },
    ],
  },
  {
    name: "Franklin",
    num: 0,
    latitude: -37.2,
    longitude: 174.9,
    suburbs: [
      { name: "Pukekohe", latitude: -37.2022, longitude: 174.9036, num: 0 },
      { name: "Waiuku", latitude: -37.2502, longitude: 174.7319, num: 0 },
      { name: "Tuakau", latitude: -37.2706, longitude: 174.9433, num: 0 },
      { name: "Beachlands", latitude: -36.891, longitude: 175.036, num: 0 },
      { name: "Clevedon", latitude: -37.02, longitude: 175.03, num: 0 },
    ],
  },
];

export default districts;
