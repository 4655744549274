<template>
	<div>
		<analyze-page-ad></analyze-page-ad>
		<div class="search-view">
			<div class="search-title">{{ $t('analyse.PropertiesDataCentre') }}</div>
			<div class="search-bar">
				<el-autocomplete
				ref="el_auto"
				placeholder="Search"
				size="big"
				v-model="searchKey"
				clearable class="proInput"
				prefix-icon="el-icon-search"
				:fetch-suggestions='querySearchAsync'
				@select="handleSelect"
				@clear="clearSelect"
				@keyup.enter.native="searchEvent"
				></el-autocomplete>
			</div>
		</div>
	</div>
</template>

<script>
	import analyzePageAd from '../components/analyzePageAd.vue'
	import { getHouseList } from '../api/api.js'
	export default {
		data() {
			return {
				searchKey: ''
			}
		},
		components: {
			analyzePageAd
		},
		methods: {
			querySearchAsync(queryString, cb) {
				if (queryString == '' || queryString == undefined || queryString == null) {
					cb([])
					return
				}
				var form = {}
				form.address = queryString
				form.pageIndex = 1
				form.pageSize = 10
				form.sale = 0//未售房源
				getHouseList(form).then( res=> {
					if (res.status == 200) {
						var list = []
						res.data.hoursePage.records.forEach( item=> {
							var searchItem = {}
							searchItem.value = item.fullAddress
							searchItem.addressId = item.addressId
							list.push(searchItem)
						})
						cb(list)
					}
				})
			},
			clearSelect() {
				this.$refs.el_auto.activated = true
			},
			handleSelect(val) {
				console.log('选中的房源：' + val.addressId)
				this.searchKey = val.value
				this.$router.push(`/analyse/${val.addressId}`)
				
			}
		}
	}
</script>

<style scoped>
	.search-view {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		height: 100vh;
		top: 0;
		width: 100vw;
		position: absolute;
		left: 0;
	}
	.search-title {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		font-style: normal;
		font-weight: 500;
		font-size: 42px;
		line-height: 51px;		
		color: #FFFFFF;
		text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
	}
	.search-bar {
		margin-top: 56px;
	}
	.proInput {
		width: 65vw;
		max-width: 800px;
		border-radius: 12px;
		height: 71px;
	}
</style>