/**
 * @desc 字符串属性定义
 */
export const getEmptyStringPropsDef = (require = false) => {
  return {
    require,
    type: String,
    default: "",
  };
};
/**
 * @desc 数字类型属性定义
 */
export const getNumberPropsDef = (require = false) => {
  return {
    require,
    type: Number,
    default: 0,
  };
};
/**
 * @desc 对象类型属性定义
 */
export const getObjectPropsDef = (require = false) => {
  return {
    require,
    type: Object,
    default: () => ({}),
  };
};

export const getArrayPropsDef = (require = false) => {
  return {
    require,
    type: Array,
    default: () => [],
  };
};

export const getBooleanPropsDef = (require = false, defaults = false) => {
  return {
    require,
    type: Boolean,
    default: defaults,
  };
};
