<template>
  <div class="property-brief">
    <div class="property-brief__title property-brief__row">
      <span>{{ name }}</span>
    </div>
    <div class="property-brief__desc property-brief__row">
      <span style="color: #637381; font-size: 14px">{{ subTitle }}</span>
    </div>
    <div
      v-if="listedTime"
      class="property-brief__deliver-time property-brief__row"
      style="color: #212b36; font-size: 14px"
    >
      <span>Listed: {{ listedTime }}</span>
    </div>
    <div v-if="priceDisplay" class="property-brief__money property-brief__row">
      <span style="color: #029580; font-size: 24px; font-weight: 700"
        >{{ Boolean(Number(priceDisplay)) ? "$" : ""
        }}{{ priceDisplay.toLocaleString() }}</span
      >
    </div>
    <div v-if="notsale" class="property-brief__not-for-sale">
      <span>This property is currently not on the market now</span>
    </div>
    <div class="property-brief__facilities property-brief__row">
      <div v-if="landArea > 0" class="property-brief__facilities-item">
        <div class="property-brief__facilities-icon">
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              width="40"
              height="40"
              rx="8"
              fill="#029580"
              fill-opacity="0.08"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M11 27C11 26.4477 11.4477 26 12 26C12.5523 26 13 26.4477 13 27H27C27 26.4477 27.4477 26 28 26C28.5523 26 29 26.4477 29 27V29C29 29.5523 28.5523 30 28 30C27.4477 30 27 29.5523 27 29H13C13 29.5523 12.5523 30 12 30C11.4477 30 11 29.5523 11 29V27Z"
              fill="#029580"
            />
            <path
              d="M11 24.2666V22.8519H12.9289V16.7222H11.3882C11.3327 16.7222 11.2785 16.7058 11.2322 16.6751C11.186 16.6444 11.1498 16.6007 11.1283 16.5496C11.1067 16.4984 11.1007 16.4421 11.1111 16.3875C11.1214 16.333 11.1476 16.2827 11.1863 16.243L17.2066 10.085C17.2329 10.0581 17.2643 10.0367 17.299 10.0221C17.3336 10.0075 17.3709 10 17.4085 10C17.4461 10 17.4834 10.0075 17.518 10.0221C17.5527 10.0367 17.5841 10.0581 17.6104 10.085L23.6282 16.2425C23.667 16.2822 23.6931 16.3325 23.7035 16.387C23.7138 16.4416 23.7078 16.4979 23.6863 16.5491C23.6647 16.6002 23.6286 16.6439 23.5823 16.6746C23.5361 16.7053 23.4818 16.7217 23.4263 16.7217H22.0092L22.0087 22.8514H25.6938V21.1404L23.7688 21.1463L25.165 19.4387L23.7688 19.4567L25.165 17.7667H23.959L26.156 14.9227C26.1765 14.8961 26.2023 14.8741 26.2317 14.8581C26.2611 14.842 26.2935 14.8321 26.3269 14.8291C26.3603 14.8262 26.3939 14.8301 26.4257 14.8408C26.4575 14.8514 26.4868 14.8686 26.5116 14.891L26.5413 14.9236L28.711 17.7506L27.5201 17.7667L28.9032 19.4392L27.5201 19.4567L28.9032 21.1302L27.0677 21.1385V22.8514H29V24.2661H11V24.2666ZM17.408 11.9741L13.7336 15.5935H14.3923V22.8519H20.5171V15.5935H21.0532L17.408 11.9741ZM19.7568 20.0317V21.3949H18.0506V20.0317H19.7568ZM16.9225 20.0317V21.3949H15.2563V20.0317H16.9225ZM19.7568 17.501V18.9036H18.0506V17.501H19.7568ZM16.9225 17.501V18.9036H15.2563V17.501H16.9225Z"
              fill="#029580"
            />
          </svg>
        </div>
        <div class="property-brief__facilities-num">
          <p class="property-brief__facilities-num-title">
            {{ $t("analyse.Size") }}
          </p>
          <p
            class="property-brief__facilities-num-text"
            :style="{ fontSize: Number(landArea) >= 1000 ? '12px' : '16px' }"
          >
            {{ Number(landArea).toFixed(1) }}㎡
          </p>
        </div>
      </div>
      <div v-if="floorArea > 0" class="property-brief__facilities-item">
        <div class="property-brief__facilities-icon">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_55_2607)">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M20 1C20 0.447715 19.5523 0 19 0H17C16.4477 0 16 0.447715 16 1C16 1.55228 16.4477 2 17 2V13C16.4477 13 16 13.4477 16 14C16 14.5523 16.4477 15 17 15H19C19.5523 15 20 14.5523 20 14C20 13.4477 19.5523 13 19 13V2C19.5523 2 20 1.55228 20 1ZM1 16C0.447715 16 0 16.4477 0 17V19C0 19.5523 0.447715 20 1 20C1.55228 20 2 19.5523 2 19H13C13 19.5523 13.4477 20 14 20C14.5523 20 15 19.5523 15 19V17C15 16.4477 14.5523 16 14 16C13.4477 16 13 16.4477 13 17H2C2 16.4477 1.55228 16 1 16Z"
                fill="#029580"
              />
              <g clip-path="url(#clip1_55_2607)">
                <path
                  d="M8.33333 7.5H7.5C7.27899 7.5 7.06702 7.4122 6.91074 7.25592C6.75446 7.09964 6.66667 6.88768 6.66667 6.66667C6.66667 6.44565 6.75446 6.23369 6.91074 6.07741C7.06702 5.92113 7.27899 5.83333 7.5 5.83333H8.33333V1.66667H1.66667V5.83333H4.16667C4.38768 5.83333 4.59964 5.92113 4.75592 6.07741C4.9122 6.23369 5 6.44565 5 6.66667C5 6.88768 4.9122 7.09964 4.75592 7.25592C4.59964 7.4122 4.38768 7.5 4.16667 7.5H1.66667V13.3333H8.33333V10.8333C8.33333 10.6123 8.42113 10.4004 8.57741 10.2441C8.73369 10.0878 8.94565 10 9.16667 10C9.38768 10 9.59964 10.0878 9.75592 10.2441C9.9122 10.4004 10 10.6123 10 10.8333V13.3333H13.3333V4.16667H10V5.83333H10.8333C11.0543 5.83333 11.2663 5.92113 11.4226 6.07741C11.5789 6.23369 11.6667 6.44565 11.6667 6.66667C11.6667 6.88768 11.5789 7.09964 11.4226 7.25592C11.2663 7.4122 11.0543 7.5 10.8333 7.5H8.33333ZM13.3333 15H1.66667C1.22464 15 0.800716 14.8244 0.488155 14.5118C0.175595 14.1993 0 13.7754 0 13.3333L0 1.66667C0 1.22464 0.175595 0.800716 0.488155 0.488155C0.800716 0.175595 1.22464 0 1.66667 0L8.33333 0C8.77536 0 9.19928 0.175595 9.51184 0.488155C9.82441 0.800716 10 1.22464 10 1.66667V2.5H13.3333C13.7754 2.5 14.1993 2.67559 14.5118 2.98816C14.8244 3.30072 15 3.72464 15 4.16667V13.3333C15 13.7754 14.8244 14.1993 14.5118 14.5118C14.1993 14.8244 13.7754 15 13.3333 15Z"
                  fill="#029580"
                />
              </g>
            </g>
            <defs>
              <clipPath id="clip0_55_2607">
                <rect width="20" height="20" fill="white" />
              </clipPath>
              <clipPath id="clip1_55_2607">
                <rect width="15" height="15" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
        <div class="property-brief__facilities-num">
          <p class="property-brief__facilities-num-title">
            {{ $t("analyse.floorArea") }}
          </p>
          <p
            class="property-brief__facilities-num-text"
            :style="{ fontSize: Number(floorArea) >= 1000 ? '12px' : '16px' }"
          >
            {{ Number(floorArea).toFixed(1) }}㎡
          </p>
        </div>
      </div>
      <div v-if="bedroomCount" class="property-brief__facilities-item">
        <div class="property-brief__facilities-icon">
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              width="40"
              height="40"
              rx="8"
              fill="#029580"
              fill-opacity="0.08"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M15 12.5H25C25.5523 12.5 26 12.9477 26 13.5V14.5H24.2361C23.6868 13.8863 22.8885 13.5 22 13.5H18C17.1115 13.5 16.3132 13.8863 15.7639 14.5H14V13.5C14 12.9477 14.4477 12.5 15 12.5ZM15 16.5H13.6396L12.4396 22.5H27.5604L26.3604 16.5H25C25 18.1569 23.6569 19.5 22 19.5H18C16.3431 19.5 15 18.1569 15 16.5ZM12 14.5V13.5C12 11.8431 13.3431 10.5 15 10.5H25C26.6569 10.5 28 11.8431 28 13.5V14.5L30 24.5V27.5V28.5C30 29.0523 29.5523 29.5 29 29.5C28.4477 29.5 28 29.0523 28 28.5H12C12 29.0523 11.5523 29.5 11 29.5C10.4477 29.5 10 29.0523 10 28.5V27.5V24.5L12 14.5ZM12 24.5H28V26.5H12V24.5ZM17 16.5C17 15.9477 17.4477 15.5 18 15.5H22C22.5523 15.5 23 15.9477 23 16.5C23 17.0523 22.5523 17.5 22 17.5H18C17.4477 17.5 17 17.0523 17 16.5Z"
              fill="#029580"
            />
          </svg>
        </div>
        <div class="property-brief__facilities-num">
          <p class="property-brief__facilities-num-title">
            {{ $t("analyse.Bedrooms") }}
          </p>
          <p class="property-brief__facilities-num-text">{{ bedroomCount }}</p>
        </div>
      </div>
      <div v-if="bathroomCount" class="property-brief__facilities-item">
        <div class="property-brief__facilities-icon">
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              width="40"
              height="40"
              rx="8"
              fill="#029580"
              fill-opacity="0.08"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M11.6667 19.3333V21H29C29.5523 21 30 21.4477 30 22V22.5C30 25.7656 27.9129 28.5437 24.9999 29.5733V29.6667C24.9999 30.1269 24.6268 30.5 24.1666 30.5C23.8053 30.5 23.4978 30.2701 23.3822 29.9487C23.0928 29.9826 22.7985 30 22.5 30H17.5C17.2015 30 16.9071 29.9826 16.6177 29.9487C16.5022 30.2701 16.1946 30.5 15.8333 30.5C15.3731 30.5 15 30.1269 15 29.6667V29.5732C12.2369 28.5966 10.2169 26.0468 10.0164 23C10.0055 22.8347 10 22.668 10 22.5V21.3333V21V19.3333V14.6667C10 12.3655 11.8655 10.5 14.1667 10.5C16.0885 10.5 17.7065 11.8011 18.1877 13.5706C18.2219 13.6964 18.2504 13.8247 18.2728 13.9551C18.4165 14.0017 18.5545 14.061 18.6854 14.1317C18.7956 14.1911 18.9008 14.2586 19.0003 14.3333L19.0137 14.3435C19.0664 14.3836 19.1174 14.4258 19.1667 14.4699C19.4802 14.7505 19.7225 15.1091 19.8631 15.5151C20.0136 15.95 19.6269 16.3333 19.1667 16.3333H15.8334C15.3731 16.3333 14.9864 15.95 15.137 15.5151C15.2776 15.1091 15.5199 14.7505 15.8334 14.4699C15.8827 14.4258 15.9337 14.3836 15.9864 14.3435L15.9998 14.3333C16.0866 14.2681 16.1779 14.2084 16.273 14.1547C16.3711 14.0993 16.4733 14.0504 16.5791 14.0084C16.53 13.8281 16.4612 13.6558 16.3752 13.4942C16.1292 13.0317 15.7422 12.6557 15.2715 12.4234C14.9383 12.259 14.5633 12.1667 14.1667 12.1667C12.786 12.1667 11.6667 13.286 11.6667 14.6667V19.3333ZM27.9776 23C27.7981 24.9921 26.5563 26.6779 24.8231 27.4867C24.6706 27.2919 24.4332 27.1667 24.1666 27.1667C23.7276 27.1667 23.3678 27.5062 23.3356 27.9369C23.0631 27.9785 22.7841 28 22.5 28H17.5C17.2159 28 16.9368 27.9785 16.6643 27.9369C16.6321 27.5061 16.2723 27.1667 15.8333 27.1667C15.5667 27.1667 15.3293 27.2919 15.1768 27.4867C13.4436 26.6779 12.2019 24.9921 12.0224 23H27.9776Z"
              fill="#029580"
            />
          </svg>
        </div>
        <div class="property-brief__facilities-num">
          <p class="property-brief__facilities-num-title">
            {{ $t("analyse.Bathrooms") }}
          </p>
          <p class="property-brief__facilities-num-text">{{ bathroomCount }}</p>
        </div>
      </div>
      <div v-if="parkingCoveredCount" class="property-brief__facilities-item">
        <div class="property-brief__facilities-icon">
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              width="40"
              height="40"
              rx="8"
              fill="#029580"
              fill-opacity="0.08"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M20.4116 12.114C20.1583 11.962 19.8417 11.962 19.5884 12.114L12.8254 16.1718C12.3133 16.4791 12 17.0324 12 17.6296V26.0009C12 26.9397 12.7611 27.7009 13.7 27.7009H14.5V21.1C14.5 19.4431 15.8431 18.1 17.5 18.1H22.5C24.1569 18.1 25.5 19.4431 25.5 21.1V27.7009H26.3C27.2389 27.7009 28 26.9397 28 26.0009V17.6296C28 17.0324 27.6867 16.4791 27.1746 16.1718L20.4116 12.114ZM18.5594 10.399C19.4461 9.86699 20.5539 9.86699 21.4406 10.399L28.2036 14.4568C29.3181 15.1255 30 16.3299 30 17.6296V26.0009C30 28.0443 28.3435 29.7009 26.3 29.7009H25.5C24.4971 29.7009 23.6666 28.9627 23.5222 28H16.4778C16.3334 28.9627 15.5029 29.7009 14.5 29.7009H13.7C11.6565 29.7009 10 28.0443 10 26.0009V17.6296C10 16.3299 10.6819 15.1255 11.7964 14.4568L18.5594 10.399ZM16.5 26H23.5V24H16.5V26ZM23.5 21.1V22H16.5V21.1C16.5 20.5477 16.9477 20.1 17.5 20.1H22.5C23.0523 20.1 23.5 20.5477 23.5 21.1Z"
              fill="#029580"
            />
          </svg>
        </div>
        <div class="property-brief__facilities-num">
          <p class="property-brief__facilities-num-title">
            {{ $t("analyse.Garages") }}
          </p>
          <p class="property-brief__facilities-num-text">
            {{ parkingCoveredCount }}
          </p>
        </div>
      </div>
      <div v-if="subType" class="property-brief__facilities-item">
        <div class="property-brief__facilities-icon">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18.1663 18.0609H2.01377C1.83253 18.0605 1.6588 17.9884 1.53065 17.8602C1.40249 17.7321 1.33035 17.5583 1.33002 17.3771C1.33002 17.0002 1.63689 16.6934 2.01377 16.6934H18.1663C18.3476 16.6934 18.5215 16.7654 18.6498 16.8936C18.778 17.0219 18.85 17.1958 18.85 17.3771C18.85 17.5585 18.778 17.7324 18.6498 17.8606C18.5215 17.9888 18.3476 18.0609 18.1663 18.0609Z"
              fill="#029580"
            />
            <path
              d="M10.2969 18.0604H3.99439C3.30127 18.0604 2.73627 17.4685 2.73627 16.7422V5.61537C2.73627 5.00974 3.11877 4.49224 3.67377 4.33974L10.0156 2.14412C10.195 2.10085 10.3819 2.09914 10.562 2.13911C10.7422 2.17908 10.9108 2.25967 11.055 2.37474C11.3731 2.62662 11.5569 3.01162 11.5569 3.42912V16.7416C11.555 17.4704 10.9906 18.0597 10.2969 18.0597V18.0604ZM4.10377 16.6929H10.1875V3.52849L4.10377 5.63787V16.6929Z"
              fill="#029580"
            />
            <path
              d="M16.3963 18.0611H11.3319C10.7013 18.0611 10.1875 17.5105 10.1875 16.8324V7.07423C10.1875 6.67986 10.365 6.30861 10.66 6.07798C10.7907 5.97464 10.9434 5.90287 11.1064 5.86829C11.2693 5.8337 11.438 5.83723 11.5994 5.87861L11.6325 5.88611L16.7031 7.76298C17.1975 7.91173 17.5369 8.38986 17.5369 8.94673V16.8317C17.5406 17.5092 17.0269 18.0605 16.3963 18.0605V18.0611ZM11.555 16.6936H16.1744V9.02548L11.555 7.31673V16.6936ZM8.68939 9.47298H5.54502C5.36378 9.47265 5.19005 9.40051 5.0619 9.27235C4.93374 9.14419 4.8616 8.97047 4.86127 8.78923C4.86127 8.41236 5.16814 8.10548 5.54502 8.10548H8.68939C9.06627 8.10548 9.37314 8.41236 9.37314 8.78923C9.37314 9.16611 9.06627 9.47298 8.68939 9.47298Z"
              fill="#029580"
            />
            <path
              d="M14.4375 12.4827H13.3888C13.2075 12.4824 13.0338 12.4103 12.9056 12.2821C12.7775 12.1539 12.7053 11.9802 12.705 11.799C12.705 11.4221 13.0119 11.1152 13.3888 11.1152H14.4375C14.8144 11.1152 15.1213 11.4221 15.1213 11.799C15.1213 12.1777 14.8163 12.4827 14.4375 12.4827ZM8.68939 12.4827H5.54502C5.36378 12.4824 5.19005 12.4103 5.0619 12.2821C4.93374 12.1539 4.8616 11.9802 4.86127 11.799C4.86127 11.4221 5.16814 11.1152 5.54502 11.1152H8.68939C8.77918 11.1152 8.8681 11.1329 8.95105 11.1673C9.03401 11.2016 9.10938 11.252 9.17288 11.3155C9.23637 11.379 9.28673 11.4544 9.32109 11.5373C9.35546 11.6203 9.37314 11.7092 9.37314 11.799C9.37314 11.8888 9.35546 11.9777 9.32109 12.0606C9.28673 12.1436 9.23637 12.219 9.17288 12.2825C9.10938 12.346 9.03401 12.3963 8.95105 12.4307C8.8681 12.465 8.77918 12.4827 8.68939 12.4827Z"
              fill="#029580"
            />
          </svg>
        </div>
        <div class="property-brief__facilities-num">
          <p class="property-brief__facilities-num-title">
            {{ $t("analyse.subType") }}
          </p>
          <p class="property-brief__facilities-num-text">{{ subType }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { datetimeMapping } from "@/common/datetime";
import {
  getBooleanPropsDef,
  getEmptyStringPropsDef,
  getNumberPropsDef,
} from "@/common/propsConfig";
import { dateTimeParser } from "@/utils/tools";

export default {
  name: "PropertyBrief",
  props: {
    id: getEmptyStringPropsDef(),
    country: getEmptyStringPropsDef(),
    parkingCoveredCount: getNumberPropsDef(),
    parkingOtherCount: getNumberPropsDef(),
    /** 名称 */
    name: getEmptyStringPropsDef(),
    /** 房产名称 */
    buildingName: getEmptyStringPropsDef(),
    /** 副标题 */
    subTitle: getEmptyStringPropsDef(),
    /** 房产价格 */
    price: {
      capitalValue: getEmptyStringPropsDef(),
      improvementsValue: getEmptyStringPropsDef(),
      landValue: getEmptyStringPropsDef(),
    },
    /** 价格编码 */
    priceCode: getNumberPropsDef(),
    /** 显示价格 */
    priceDisplay: getEmptyStringPropsDef(),
    /** 房产 ID */
    propertyShortId: getEmptyStringPropsDef(),
    /** 发布时间 */
    publishedDate: getNumberPropsDef(),
    /** 浴室数量  */
    bathroomCount: getNumberPropsDef(),
    /**  */
    bathroomEnsuiteCount: getNumberPropsDef(),
    /**  */
    bathroomFullCount: getNumberPropsDef(),
    /** 卧室数量 */
    bedroomCount: getNumberPropsDef(),
    /** 房屋图片 */
    photos: {
      type: Array,
      required: false,
    },
    landArea: getNumberPropsDef(),
    floorArea: getNumberPropsDef(),
    subType: getEmptyStringPropsDef(),
    notsale: getBooleanPropsDef(),
  },
  data() {
    return {};
  },
  computed: {
    listedTime() {
      if (this.$props.publishedDate) {
        const { year, month, dat } = dateTimeParser(this.$props.publishedDate);
        return `${dat} ${datetimeMapping[month]} ${year}`;
      }
      return "";
    },
  },
  methods: {
    // getListedTime() {
    //   if (this.$props.publishedDate) {
    //     const { year, month, dat } = dateTimeParser(this.$props.publishedDate);
    //     return `${dat} ${datetimeMapping[month]} ${year}`;
    //   }
    //   return "";
    // },
  },
};
</script>
<style lang="scss" scoped>
@import "./propertyBrief.scss";
</style>