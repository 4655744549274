
<template>
  <div class="analysis-detail-page">
    <!-- 房产信息，分为左右两部分，右半边是地图 -->
    <div class="analysis-detail-page-left">
      <div class="analysis-card">
        <property-brief
          :name="house.fullAddress"
          :priceDisplay="house.priceDisplay"
          :subTitle="house.header"
          :publishedDate="house.publishedDate"
          :bathroomCount="house.properties ? house.properties.bathrooms : 0"
          :bedroomCount="house.properties ? house.properties.bedrooms : 0"
          :parkingCoveredCount="
            house.properties ? house.properties.carparks : 0
          "
          :landArea="parseInt(house.properties ? house.properties.landArea : 0)"
        />
      </div>
      <div v-if="house.properties" class="analysis-card">
        <div class="analysis-title">{{ $t('analyse.BasicInformation') }}</div>
        <basic-information
          :ownerShip="house.properties.titlesOwners[0].owners"
          :legalDescription="house.properties.legalDescription"
          :landTitle="`${house.properties.titleType} ${house.properties.landArea}`"
          :zoning="house.properties.unitaryPlan"
          :certificateOfTitle="house.properties.titlesOwners[0].titleNo"
        />
      </div>
      <div class="analysis-card">
        <div class="analysis-title">{{ $t('analyse.CapitalValuation') }}</div>
        <capital-value :total="house.priceDisplay" :capital="capital" />
      </div>
      <div v-if="house.properties" class="analysis-card">
        <div class="analysis-title">{{ $t('analyse.PropertyInformation') }}</div>
        <property-information
          :yearBuilt="house.properties.decadeOfConstruction"
          :construction="house.properties.construction"
          :propertyView="house.properties.viewType"
          :pool="house.properties.deck"
          :rooms="house.properties.bedrooms + house.properties.bathrooms"
          :area="house.properties.floorArea"
        />
      </div>
      <div class="analysis-card">
        <div class="analysis-title">{{ $t('analyse.Timeline') }}</div>
        <div class="analysis-card-timeline-wrapper">
          <timeline :times="times" />
        </div>
      </div>
    </div>
    <div class="map-container" id="analysis_map"></div>
	<div class="analysis-detail-page-right">
		<div style="bottom: 176px;" class="map-types">
		  <div
		    v-for="mapTypeItem in mapTypes"
		    :key="mapTypeItem.desc"
		    class="map-type"
		    @click="swicthMapType(mapTypeItem.mapType)"
		  >
		    <div v-if="mapTypeItem.mapType === mapType" class="map-type-icon" />
		    <img
		      v-else
		      class="map-type-icon"
		      style="background: #ffffff"
		      :src="mapTypeItem.icon"
		      alt=""
		    />
		    <span>{{ mapTypeItem.desc }}</span>
		  </div>
		</div>
		<div style="height: 76px;bottom: 80px;" class="map-pipes">
		  <div
		    v-for="pipe in pipes"
		    :key="pipe.desc"
		    class="map-pipe"
		    @click="swicthLayer(pipe, pipe[mapType])"
		  >
		    <img
		      v-if="pipe.icon"
		      class="map-pipe-icon"
		      style="background: #ffffff"
		      :src="
		        pipe.layerType.includes(layerType) ? pipe.iconSelected : pipe.icon
		      "
		      alt=""
		    />
		    <div v-else class="map-pipe-icon" />
		    <span>{{ pipe.desc }}</span>
		  </div>
		</div>
	</div>
  </div>
</template>

<script>
import mapboxgl from "!mapbox-gl";
import { getHouseDetailNotForSale } from "@/api/property";
import { API_SUCCESS_CODE } from "@/common/apiCode";
import { mapToken } from "@/common/map";
import BasicInformation from "./basicInformation/basicInformation.vue";
import propertyBrief from "./brief/propertyBrief.vue";
import capitalValue from "./capitalValue/capitalValue.vue";
import PropertyInformation from "./propertyInformation/propertyInformation.vue";
import Timeline from "./timeline/timeline.vue";
let mapRef
const layerTypes = {
  def: "mapbox://styles/mapbox/streets-v12",
  stall: "mapbox://styles/dreamcatchernick/clipv2wfc009e01r1crygdvnt",
  wat: "mapbox://styles/dreamcatchernick/cliptw3fh000b01pzdfq9405k",
  sto: "mapbox://styles/dreamcatchernick/cliptxlrl000n01pw2hbidrl2",
  all: "mapbox://styles/dreamcatchernick/clcvrhi3y000514qost5v9r77",
  staWat: "mapbox://styles/dreamcatchernick/clipu0aj5009d01r1hsmgdwdu",
  staSto: "mapbox://styles/dreamcatchernick/cliptyolf003r01q46qsa456i",
  stellWithPipe: "mapbox://styles/dreamcatchernick/cli60udyd00pe01pgeok20sm0",
  emp: "mapbox://styles/mapbox/streets-v12",
  district: "mapbox://styles/dreamcatchernick/clmbn6fdm018101pjfoplc58u",
};

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export default {
  components: {
    BasicInformation,
    propertyBrief,
    capitalValue,
    PropertyInformation,
    Timeline,
  },
  data() {
    return {
      lng: "",
      lat: "",
	  mapType: "map",
	  layerType: "def",
      times: [],
      showAgreementConfirm: false,
      currentPropertyId: 0,
      capital: {
        landValue: "",
        improvementsValue: "",
      },
      house: {
        addressId: 0,
        wkt: "",
        changeId: 0,
        addressType: "0",
        unitValue: "",
        addressNumber: 0,
        addressNumberSuffix: "",
        waterRouteName: "",
        waterName: "",
        suburbLocality: "0",
        townCity: "0",
        fullAddressNumber: "0",
        fullRoadName: "0",
        fullAddress: "",
        roadSectionId: 0,
        gd2000Xcoord: 0,
        gd2000Ycoord: 0,
        waterRouteNameAscii: "",
        waterNameAscii: "",
        suburbLocalityAscii: "",
        townCityAscii: "",
        fullRoadNameAscii: "",
        fullAddressAscii: "",
        shapeX: 0,
        shapeY: 0,
        properties: {
          addressId: 0,
          titleNo: "",
          titleType: "",
          landArea: "",
          floorArea: "",
          unitaryPlan: "",
          bedrooms: null,
          bathrooms: null,
          carparks: 0,
          decadeOfConstruction: "",
          contour: "",
          construction: "",
          condition: "",
          viewType: "",
          deck: "",
          council: "",
          legalDescription: "",
          estateDescription: "",
          street: "",
          suburb: "",
          district: "",
          city: "",
          updateTime: null,
          mortgagesLeasesEasements: [
            {
              id: 0,
              titleNo: "",
              landDistrict: "",
              memorialText: "",
              current: "",
              instrumentNumber: "",
              instrumentLodgedDatetime: 0,
              instrumentType: "",
              encumbrancees: "",
            },
          ],
          titlesOwners: [
            {
              id: 0,
              titleNo: "",
              status: "",
              type: "",
              landDistrict: "",
              issueDate: null,
              guaranteeStatus: "",
              estateDescription: "",
              spatialExtentsShared: "",
            },
          ],
        },
        propertyRate: {
          accountKey: 0,
          addressId: 0,
          titleNo: null,
          capitalRate: "",
          valueOfImprovements: "",
          landValue: "",
          propertyRate: "",
          rateYear: "",
        },
        collect: false,
      },
    };
  },
  activated() {
    this.currentPropertyId = this.$route.params.id;
    this.getHouseDetail();
  },
  computed: {
	  mapTypes() {
	    return [
	      {
	        desc: this.$t("map.Map"),
	        icon: require("@/assets/nor-selected.png"),
	        iconSelected: require("@/assets/shoutlisted.png"),
	        mapType: "map",
	      },
	      {
	        desc: this.$t("map.Satellite"),
	        icon: require("@/assets/nor-selected.png"),
	        iconSelected: require("@/assets/shoutlisted.png"),
	        mapType: "sta",
	      },
	    ];
	  },
	  pipes() {
	    return [
	      {
	        desc: this.$t("map.ShowStormPipe"),
	        icon: require("@/assets/nor-selected.png"),
	        iconSelected: require("@/assets/shoutlisted.png"),
	        layerType: ["sto", "staSto"],
	        sta: "staSto",
	        map: "sto",
	      },
	      {
	        desc: this.$t("map.ShowWastePipe"),
	        icon: require("@/assets/nor-selected.png"),
	        iconSelected: require("@/assets/shoutlisted.png"),
	        layerType: ["wat", "staWat"],
	        sta: "staWat",
	        map: "wat",
	      },
	    ];
	  },
  },
  methods: {
    initMap() {
      mapboxgl.accessToken = mapToken;
      const map = new mapboxgl.Map({
        container: "analysis_map", // container ID
        style: layerTypes["def"], // style URL
        center: [this.lng, this.lat], // starting position [lng, lat]
        zoom: 18, // starting zoom
      });
	  mapRef = map;
      new mapboxgl.Marker({ anchor: "center" })
        .setLngLat([this.lng, this.lat])
        .addTo(map);
    },
	/**
	 * @desc 绘制图层
	 */
	swicthLayer(pipe, layerType) {
	  console.log(pipe)
	  this.pipe = pipe;
	  if (this.layerType === layerType) {
	    this.layerType = "def";
	  } else {
	    this.layerType = layerType;
	  }
	  mapRef.setStyle(layerTypes[this.layerType]);
	},
	/**
	 * @desc 切换地图类型
	 */
	swicthMapType(mapType) {
	  if (this.mapType === mapType) {
	    this.mapType = "map";
	  } else {
	    this.mapType = mapType;
	  }
	  this.layerType = this.pipe?.[mapType] || (this.mapType == "map" ? "def" : "stall");
	  mapRef.setStyle(layerTypes[this.layerType]);
	},
    async getHouseDetail() {
      try {
        // 637595082ab156f3cbd6373e
        const results = await getHouseDetailNotForSale(this.$route.params.id);
        console.log(results, "-data-results");
        if (results.status === API_SUCCESS_CODE && results.data) {
          this.house = results.data; //transferDataToOnSaled(results.data);
          this.capital = {
            landValue: this.house.propertyRate.landValue,
            improvementsValue: this.house.propertyRate.valueOfImprovements,
            propertyRate: this.house.propertyRate.propertyRate,
            rateYear: this.house.propertyRate.rateYear,
          };
          this.lat = String(this.house.gd2000Ycoord);
          this.lng = String(this.house.gd2000Xcoord);
          this.initMap();
          const times = [];
          results.data.properties?.mortgagesLeasesEasements?.forEach((item) => {
            const d = new Date(item.instrumentLodgedDatetime);
            const month = d.getMonth();
            const date = d.getDate();
            const year = d.getFullYear();
            times.push({
              month: months[month] + " " + date,
              year: year,
              money: item["instrumentType"],
              status: item.memorialText,
            });
          });
          console.log(times, "times");
          this.times = times;
        } else {
          // todo error
        }
      } catch (error) {
        // to exception
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss">
@import "~@/theme/styles/index.scss";

.analysis-detail-page {
  position: relative;
  @include flex;
  height: 100%;
  text-align: left;
  .map-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }
  &-left {
    position: relative;
    width: 512px;
    height: 90%;
    background: #ffffff;
    border-radius: 12px;
    overflow: scroll;
    margin: 15px;
    z-index: 10;
    box-sizing: border-box;
    .analysis-card {
      @include flex(column);
      @include flex-adjust(flex-start, flex-start);
      margin: 24px;
      background-color: #ffffff;
      padding: 24px 0;
      border-bottom: 1px solid #919eab33;
      h2: {
        margin-bottom: 5px;
      }
      &-timeline-wrapper {
        padding: 20px 10px;
      }
      .analysis-title {
        font-size: 18px;
        color: #212b36;
        font-weight: 700;
        font-family: Public Sans;
        margin-bottom: 10px;
      }
    }
  }
  .analysis-card:last-of-type {
    border-bottom: none;
  }
  &-right {
    flex: 1.7;
	z-index: 100;
	margin-left: auto;
	margin-top: auto;
  }
  .map-types {
    @include flex(column);
    @include flex-adjust(space-between, flex-start);
    position: absolute;
    right: 24px;
    bottom: 320px;
    width: 173px;
    height: 76px;
    padding: 8px 0;
    background: #FFFFFF;
    border-radius: 10px;
    z-index: 1;
    box-sizing: border-box;
  
    .map-type {
      flex: 1;
      @include flex;
      @include flex-adjust(flex-start, center);
      font-family: Avenir, Helvetica, Arial, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      color: #333333;
  
      &-icon {
        width: 12px;
        height: 12px;
        margin: 0 8px;
        border: 0;
        border-radius: 50%;
        background-color: #158C79;
      }
    }
  
    .map-type:hover {
      cursor: pointer;
    }
  }
  
  .map-pipes {
    @include flex(column);
    @include flex-adjust(space-between, flex-start);
    position: absolute;
    right: 24px;
    bottom: 36px;
    width: 173px;
    height: 108px;
    padding: 13px 0;
    background: #FFFFFF;
    border-radius: 10px;
    z-index: 1;
    box-sizing: border-box;
  
    .map-pipe {
      flex: 1;
      @include flex;
      @include flex-adjust(flex-start, center);
      font-family: Avenir, Helvetica, Arial, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      color: #333333;
  
      &-icon {
        width: 12px;
        height: 12px;
        margin: 0 8px;
        border: 0;
        border-radius: 50%;
        background-color: #158C79;
      }
    }
  
    .map-pipe:hover {
      cursor: pointer;
    }
  }
}
</style>