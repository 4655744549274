<template>
  <div class="nav-view-ss">
    <div :style="{ width: isWidth ? '416px' : '320px' }" class="search-view-ff">
      <img
        style="margin: 15px; width: 16px; height: 16px"
        src="../assets/search_icon.png"
      />
      <input
        class="input-s-ff"
        @input="searchAddress"
        v-model="filterForm.address"
        :placeholder="$t('detail.TypeAddress')"
      />
    </div>
    <div class="search-menu-ff">
      <el-cascader
        @change="regionChangeEvent"
        :placeholder="$t('detail.Location')"
        :options="locationOptions"
        :props="locationProps"
        collapse-tags
        clearable
        class="location-cascaser-s-ff"
      ></el-cascader>
      <div class="line-s"></div>
      <el-popover
        placement="bottom"
        popper-class="popover"
        :visible-arrow="false"
        width="448"
        v-model="propertTypeVisible"
      >
        <div class="pop-content">
          <div class="pop-title">
            {{ $t("detail.PropertyType") }}
          </div>
          <el-radio-group v-model="propertTypeValue">
            <el-radio
              style="margin: 10px"
              v-for="(item, index) in typeOptions"
              :label="item.value"
              :key="index"
              >{{ item.value }}</el-radio
            >
          </el-radio-group>
          <div class="option-bottom-view">
            <div @click="clearType()" class="clear-btn">Clear</div>
            <div class="right-view-ff">
              <el-button
                class="custom-btn-confirm"
                @click="confirmType()"
                style="margin-left: 12px"
                type="primary"
                >Confirm</el-button
              >
              <el-button
                class="custom-btn-cancel"
                @click="propertTypeVisible = false"
                >Cancel</el-button
              >
            </div>
          </div>
        </div>
        <div slot="reference" class="property-type">
          <div
            :class="filterForm.listingSubType == '' ? 'value-nor' : 'value-sel'"
          >
            {{
              filterForm.listingSubType != ""
                ? filterForm.listingSubType
                : $t("detail.PropertyType")
            }}
          </div>
          <i
            style="margin-left: 2px; color: #919eab"
            class="el-icon-arrow-down"
          ></i>
        </div>
      </el-popover>
      <div class="line-s"></div>
      <el-popover
        popper-class="popover"
        :visible-arrow="false"
        placement="bottom"
        width="448"
        v-model="priceVisible"
      >
        <div class="pop-content">
          <div class="pop-title">
            {{ $t("detail.Price") }}
          </div>
          <div class="block">
            <el-slider
              :show-tooltip="false"
              v-model="priceRangeValue"
              range
              :step="500000"
              :show-stops="true"
              :max="10000000"
            >
            </el-slider>
            <div
              style="
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
              "
            >
              <div class="price-s-ff">
                ${{ priceRangeValue[0].toLocaleString("en-US") }}
              </div>
              <div class="price-s-ff">
                ${{
                  priceRangeValue[1].toLocaleString("en-US") +
                  (priceRangeValue[1] == 10000000 ? "+" : "")
                }}
              </div>
            </div>
          </div>
          <div class="option-bottom-view">
            <div @click="clearPrice()" class="clear-btn">Clear</div>
            <div class="right-view-ff">
              <el-button
                class="custom-btn-confirm"
                @click="confirmPrice()"
                style="margin-left: 12px"
                type="primary"
                >Confirm</el-button
              >
              <el-button class="custom-btn-cancel" @click="priceVisible = false"
                >Cancel</el-button
              >
            </div>
          </div>
        </div>
        <div slot="reference" class="property-type">
          <div
            :class="
              filterForm.minCapitalValue == '' &&
              filterForm.maxCapitalValue == ''
                ? 'value-nor'
                : 'value-sel'
            "
          >
            {{ $t("detail.Price") }}
          </div>
          <i
            style="margin-left: 2px; color: #919eab"
            class="el-icon-arrow-down"
          ></i>
        </div>
      </el-popover>
      <div class="line-s"></div>
      <el-popover
        popper-class="popover"
        :visible-arrow="false"
        placement="bottom"
        width="448"
        v-model="bedroomVisiable"
      >
        <div class="pop-content">
          <div class="pop-title">
            {{ $t("analyse.Bedrooms") }}
          </div>
          <el-checkbox-group v-model="bedrooms">
            <el-checkbox
              v-for="(item, index) in sixOptions"
              :key="index"
              :label="item.value"
              ><span v-if="item.value == 6">6+</span></el-checkbox
            >
          </el-checkbox-group>
          <div class="option-bottom-view">
            <div @click="clearBedroom()" class="clear-btn">Clear</div>
            <div class="right-view-ff">
              <el-button
                class="custom-btn-confirm"
                @click="confirmBedroom()"
                style="margin-left: 12px"
                type="primary"
                >Confirm</el-button
              >
              <el-button
                class="custom-btn-cancel"
                @click="bedroomVisiable = false"
                >Cancel</el-button
              >
            </div>
          </div>
        </div>
        <div slot="reference" class="property-type">
          <div
            :class="
              filterForm.bedroomCountList == '' ? 'value-nor' : 'value-sel'
            "
          >
            {{ $t("analyse.Bedrooms") }}
          </div>
          <i
            style="margin-left: 2px; color: #919eab"
            class="el-icon-arrow-down"
          ></i>
        </div>
      </el-popover>
      <div class="line-s"></div>
      <el-popover
        popper-class="popover"
        :visible-arrow="false"
        placement="bottom"
        width="448"
        v-model="bathroomVisiable"
      >
        <div class="pop-content">
          <div class="pop-title">
            {{ $t("analyse.Bathrooms") }}
          </div>
          <el-checkbox-group v-model="bathrooms">
            <el-checkbox
              v-for="(item, index) in sixOptions"
              :key="index"
              :label="item.value"
              ><span v-if="item.value == 6">6+</span></el-checkbox
            >
          </el-checkbox-group>
          <div class="option-bottom-view">
            <div @click="clearBathroom()" class="clear-btn">Clear</div>
            <div class="right-view-ff">
              <el-button
                class="custom-btn-confirm"
                @click="confirmBathroom()"
                style="margin-left: 12px"
                type="primary"
                >Confirm</el-button
              >
              <el-button
                class="custom-btn-cancel"
                @click="bathroomVisiable = false"
                >Cancel</el-button
              >
            </div>
          </div>
        </div>
        <div slot="reference" class="property-type">
          <div
            :class="
              filterForm.bathroomCountList == '' ? 'value-nor' : 'value-sel'
            "
          >
            {{ $t("analyse.Bathrooms") }}
          </div>
          <i
            style="margin-left: 2px; color: #919eab"
            class="el-icon-arrow-down"
          ></i>
        </div>
      </el-popover>
      <div class="line-s"></div>
      <el-popover
        popper-class="popover"
        :visible-arrow="false"
        placement="bottom"
        width="448"
        v-model="parkVisiable"
      >
        <div class="pop-content">
          <div class="pop-title">
            {{ $t("analyse.Parking") }}
          </div>
          <el-checkbox-group style="margin-top: 20px" v-model="parks">
            <el-checkbox
              v-for="(item, index) in threeOptions"
              :key="index"
              :label="item.value"
              ><span v-if="item.value == 3">3+</span></el-checkbox
            >
          </el-checkbox-group>
          <div class="option-bottom-view">
            <div @click="clearPark()" class="clear-btn">Clear</div>
            <div class="right-view-ff">
              <el-button
                class="custom-btn-confirm"
                @click="confirmPark()"
                style="margin-left: 12px"
                type="primary"
                >Confirm</el-button
              >
              <el-button class="custom-btn-cancel" @click="parkVisiable = false"
                >Cancel</el-button
              >
            </div>
          </div>
        </div>
        <div slot="reference" class="property-type">
          <div
            :class="
              filterForm.parkingCoveredCountList == ''
                ? 'value-nor'
                : 'value-sel'
            "
          >
            {{ $t("analyse.Parking") }}
          </div>
          <i
            style="margin-left: 2px; color: #919eab"
            class="el-icon-arrow-down"
          ></i>
        </div>
      </el-popover>
    </div>
  </div>
</template>

<script>
import relationOptions from "@/common/relationData.js";
export default {
  props: {
    isWidth: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      locationProps: {
        value: "value",
        label: "name",
        children: "submenu",
        multiple: true,
      },
      locationOptions: [],
      filterForm: {
        sale: "1",
        listingSubType: "",
        suburbs: "",
        bedroomCountList: "",
        bathroomCountList: "",
        parkingCoveredCountList: "",
        address: "",
        minCapitalValue: "",
        maxCapitalValue: "",
      },
      typeOptions: [
        {
          label: "House",
          value: "House",
        },
        {
          label: "Unit",
          value: "Unit",
        },
        {
          label: "Townhouse",
          value: "Townhouse",
        },
        {
          label: "Apartment",
          value: "Apartment",
        },
        {
          label: "Section",
          value: "Section",
        },
        {
          label: "Lifestyle",
          value: "Lifestyle",
        },
        {
          label: "Lifestyle Section",
          value: "Lifestyle Section",
        },
      ],
      propertTypeVisible: false,
      propertTypeValue: "",
      priceVisible: false,
      priceRangeValue: [0, 10000000],
      bedroomVisiable: false,
      bedrooms: [],
      bathroomVisiable: false,
      bathrooms: [],
      parkVisiable: false,
      parks: [],
      sixOptions: [
        {
          label: "1",
          value: "1",
        },
        {
          label: "2",
          value: "2",
        },
        {
          label: "3",
          value: "3",
        },
        {
          label: "4",
          value: "4",
        },
        {
          label: "5",
          value: "5",
        },
        {
          label: "6",
          value: "6",
        },
      ],
      threeOptions: [
        {
          label: "1",
          value: "1",
        },
        {
          label: "2",
          value: "2",
        },
        {
          label: "3",
          value: "3",
        },
      ],
    };
  },
  mounted() {
    relationOptions.forEach((item) => {
      this.locationOptions.push(item);
    });
  },
  methods: {
    removeEmptyProperties(obj) {
      for (var propName in obj) {
        if (
          obj[propName] === null ||
          obj[propName] === undefined ||
          obj[propName] === ""
        ) {
          delete obj[propName];
        }
      }
    },
    clearPark() {
      this.filterForm.parkingCoveredCountList = "";
      this.parks = [];
      this.parkVisiable = false;
      this.searchEvent();
    },
    confirmPark() {
      this.filterForm.parkingCoveredCountList = this.parks.join(",");
      this.parkVisiable = false;
      this.searchEvent();
    },
    clearBathroom() {
      this.filterForm.bathroomCountList = "";
      this.bathrooms = [];
      this.bathroomVisiable = false;
      this.searchEvent();
    },
    confirmBathroom() {
      this.filterForm.bathroomCountList = this.bathrooms.join(",");
      this.bathroomVisiable = false;
      this.searchEvent();
    },
    clearBedroom() {
      this.filterForm.bedroomCountList = "";
      this.bedrooms = [];
      this.bedroomVisiable = false;
      this.searchEvent();
    },
    confirmBedroom() {
      this.filterForm.bedroomCountList = this.bedrooms.join(",");
      this.bedroomVisiable = false;
      this.searchEvent();
    },
    clearPrice() {
      this.priceRangeValue = [0, 10000000];
      this.priceVisible = false;
      this.filterForm.minCapitalValue = "";
      this.filterForm.maxCapitalValue = "";
      this.searchEvent();
    },
    confirmPrice() {
      if (this.priceRangeValue[0] != 0) {
        this.filterForm.minCapitalValue = this.priceRangeValue[0];
      }
      if (this.priceRangeValue[1] != 10000000) {
        this.filterForm.minCapitalValue = this.priceRangeValue[1];
      }
      this.priceVisible = false;
      this.searchEvent();
    },
    confirmType() {
      this.propertTypeVisible = false;
      this.filterForm.listingSubType = this.propertTypeValue;
      this.searchEvent();
    },
    clearType() {
      this.propertTypeVisible = false;
      this.propertTypeValue = "";
      this.filterForm.listingSubType = "";
      this.searchEvent();
    },
    regionChangeEvent(val) {
      let subArray = val;
      let suburbs = [];
      subArray.forEach((item) => {
        if (item.length == 3) {
          suburbs.push(item[2]);
        }
      });
      this.filterForm.suburbs = suburbs.join("|");
      this.searchEvent();
    },
    searchAddress() {
      this.searchEvent();
    },
    searchEvent() {
      var form = { ...this.filterForm };
      this.removeEmptyProperties(form);
      this.$emit("search", form);
    },
  },
};
</script>

<style lang="scss">
.nav-view-ss {
  display: flex;
  flex-direction: row;
  align-items: center;
  top: 80px;
  left: 24px;
  position: absolute;
  z-index: 999;
}
.search-view-ff {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 230px;
  height: 56px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
.input-s-ff {
  background-color: transparent;
  border: 0;
  outline: none;
  width: calc(100% - 60px);
  height: 30px;
  text-align: left;
  font-size: 14px;
}
.search-menu-ff {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 56px;
  border-radius: 8px;
  background: #fff;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: 24px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
.location-cascaser-s-ff {
  height: 40px;
  width: 120px;
  font-size: 14px;
  padding-right: 10px;
}
.el-cascader .el-input__inner {
  border: none !important;
  box-shadow: none !important;
}
.property-type {
  padding-right: 15px;
  padding-left: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 56px;
}
.line-s {
  background: rgba(145, 158, 171, 0.2);
  height: 40px;
  width: 1px;
}
.value-sel {
  color: #029580;
  font-family: Public Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  height: 40px;
  white-space: nowrap;
}
.value-nor {
  color: #637381;
  font-family: Public Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  height: 40px;
  white-space: nowrap;
}
.pop-content {
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
}
.pop-title {
  color: #212b36;
  font-family: Public Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}
.option-bottom-view {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
}
.clear-btn {
  color: #637381;
  font-family: Public Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.right-view-ff {
  margin-left: auto;
  display: flex;
  flex-direction: row-reverse;
}
.price-s-ff {
  color: #212b36;
  font-family: Public Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}
.popover {
  box-shadow: 0px 20px 40px -4px rgba(145, 158, 171, 0.24),
    0px 0px 2px 0px rgba(145, 158, 171, 0.24) !important;
  background: #fff !important;
  border: none !important;
  border-radius: 16px !important;
}
.custom-btn-confirm {
  background: #212b36 !important;
  border-radius: 8px !important;
  color: #ffffff !important;
  font-size: 14px !important;
  border: none !important;
}
.custom-btn-cancel {
  border-radius: 8px !important;
  border: 1px solid var(--components-button-outlined, rgba(145, 158, 171, 0.24)) !important;
  border-radius: 8px !important;
  color: #212b36 !important;
  font-size: 14px !important;
}
</style>