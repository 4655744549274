<template>
	<div class="utilitiesDetailView">
		<div class="gpt-view">
			<div class="type-title">{{ type }}</div>
			<div class="gpt-content">
				<div class="prop-guide-wrapper">
				  <div class="prop-guide-chat-box">
				    <div
				      v-for="bubble in chatBubbles"
				      :key="bubble.id"
				      :class="
				        bubble.self ? 'chat-bubble-wrapper left' : 'chat-bubble-wrapper right'
				      "
				    >
				      <img
				        v-if="!bubble.self"
				        class="ai-thumbnail"
				        :src="require('@/assets/propGuide-head.png')"
				        alt="1"
				      />
				      <div
				        v-if="bubble.type === 'message'"
				        :class="
				          bubble.self
				            ? 'prop-guide-chat-bubble-self'
				            : 'prop-guide-chat-bubble-ai'
				        "
				      >
				        <!-- <span>{{ bubble.content }}</span> -->
				        <div v-if="Array.isArray(bubble.content)" class="chat-text">
				          <p
				            style="margin-bottom: 10px"
				            v-for="(row, index) in bubble.content"
				            :key="index"
				            v-html="row"
				          ></p>
				        </div>
				        <div v-else class="chat-text">
				          <p v-html="bubble.content"></p>
				        </div>
				        <div class="chat-house-list">
				          <div
				            v-for="(house, index) in bubble.houseList"
				            :key="house.id"
				            class="chat-house"
				          >
				            <div style="width: 72px">
				              <img
				                class="chat-house-thumbnail"
				                :src="
				                  imageServerDomain +
				                  house.photos?.[0]?.baseUrl +
				                  '.crop.140x178.jpg'
				                "
				              />
				            </div>
				
				            <div class="chat-house-info" @click="toDetail(house.id)">
				              <div class="chat-house-title">
				                <span
				                  >[{{ index + 1 }}]{{ house.address.displayAddress }}</span
				                >
				              </div>
				              <div class="chat-house-data">
				                <div class="chat-house-data-item">
				                  <img
				                    class="chat-house-icon"
				                    :src="require('@/assets/mianji.png')"
				                  />
				                  <span style="font-size: 11px">{{ house.landArea }}</span>
				                </div>
				                <div class="chat-house-data-item">
				                  <img
				                    class="chat-house-icon"
				                    :src="require('@/assets/woshi.png')"
				                  />
				                  <span style="font-size: 11px">{{
				                    house.bedroomCount
				                  }}</span>
				                </div>
				                <div class="chat-house-data-item">
				                  <img
				                    class="chat-house-icon"
				                    :src="require('@/assets/yushi.png')"
				                  />
				                  <span style="font-size: 11px">{{
				                    house.bathroomFullCount
				                  }}</span>
				                </div>
				              </div>
				            </div>
				          </div>
				        </div>
				      </div>
				      <div
				        v-if="bubble.type === 'loading'"
				        :class="
				          bubble.self
				            ? 'prop-guide-chat-bubble-self'
				            : 'prop-guide-chat-bubble-ai'
				        "
				      >
				        <div class="guide-loader">
				          <div class="loader"></div>
				        </div>
				      </div>
				    </div>
				  </div>
				  <div class="prop-guide-chat-input">
				    <div class="prop-guide-textarea">
				      <textarea
				        class="prop-guide-text-input"
				        v-model="inputValue"
				        placeholder="Type a question"
				        @keypress.enter="handleClickSendButton"
				      />
				      <div class="prop-send-button">
				        <img
				          alt="x"
				          :style="{
				            cursor: inputValue?.length > 0 ? 'pointer' : 'not-allowed',
				          }"
				          :src="
				            inputValue?.length > 0
				              ? require('../../assets/prop_send.png')
				              : require('../../assets/prop_send_nor.png')
				          "
				          @click="handleClickSendButton"
				        />
				      </div>
				    </div>
				  </div>
				</div>
			</div>
		</div>
		<div class="recommend-view">
			<div style="color: #000000;margin-top: 93px;font-size: 30px;font-weight: 700;">
				Recommended financial institution
			</div>
			<img style="width: 411px;height: 238px;margin-top: 67px;" src="../../assets/utilities/cmcMarketIcon.png"/>
			<div style="margin-top: 30px;color: #4AA4C7;font-size: 26px;">Redefine your trading strategy.</div>
			<el-button style="margin-top: 52px;width: 151px;height: 37px;" type="primary">Learn more</el-button>
		</div>
	</div>
</template>

<script>
	import { getGptsAiResponse } from "@/api/api";
	function generateUUid() {
	  // 生成唯一id
	  let d = new Date().getTime();
	  let uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
	    /[xy]/g,
	    function (c) {
	      let r = (d + Math.random() * 16) % 16 | 0;
	      d = Math.floor(d / 16);
	      return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
	    }
	  );
	  return uuid;
	}
	const welcomeMessage = "Hello, I'm PropGuide, your personal property assistant. You can ask me about the comparision of utilities here."
	export default {
		data() {
			return {
				type: '',
				searchKey: '',
				inputValue: "",
				chatBubbles: [],
				imageServerDomain:
				  "https://oss-propercentre.oss-ap-southeast-1.aliyuncs.com",
			}
		},
		activated() {
			window.scrollTo(0, 0);
			this.type = this.$route.query.type
			this.searchKey = this.$route.query.searchKey
		},
		mounted() {
		  setTimeout(() => {
		    this.initChatMessage();
		  }, 1500);
		},
		methods: {
		  initChatMessage() {
		    this.chatBubbles.push({
		      id: generateUUid(),
		      type: "message",
		      content: welcomeMessage,
		      self: false,
		    });
		  },
		  toDetail(propertyId) {
		    this.$router.push(`/property/${propertyId}`);
		  },
		  async getAiResponse(message) {
		    this.chatBubbles.push({
		      id: generateUUid(),
		      type: "loading",
		      self: false,
		    });
		    const results = await getGptsAiResponse({ message });
		    this.chatBubbles.pop();
			console.log(results)
		    if (results.status === 200) {
		      var regex = /(\[[0123456789]+\])/g;
		      const contents = results.data.split("\n");
		      const uuid = generateUUid();
		      const formatContents = contents?.map((element) => {
		        element = element.replace(
		          regex,
		          `<span class="ai-link-${uuid}" style="color:#158C79;cursor:pointer;font-weight:800">$1</span>`
		        );
		        return element;
		      });
		      const houseList = results.data.houseList?.hourses;
		      this.chatBubbles.push({
		        id: uuid,
		        type: "message",
		        content: formatContents.filter((item) => item.length > 0),
		        houseList,
		        self: false,
		      });
		      setTimeout(() => {
		        const links = document.querySelectorAll(`.ai-link-${uuid}`);
		        links.forEach((link) => {
		          link.addEventListener("click", () => {
		            this.toDetail(
		              houseList[link.innerText.replace("[", "").replace("]", "") - 1]
		                ?.id
		            );
		          });
		        });
		      }, 100);
		    }
		  },
		  handleClickSendButton() {
		    if (this.inputValue.trim().length === 0) {
		      this.inputValue = "";
		      return;
		    }
		    if (this.inputValue.length > 0) {
		      this.chatBubbles.push({
		        id: generateUUid(),
		        type: "message",
		        content: this.inputValue,
		        self: true,
		      });
		      this.propQuestions = [];
		
		      this.getAiResponse(this.inputValue.replace(/\n/g, ""));
		      this.inputValue = "";
		    }
		  },
		},
	}
</script>

<style scoped lang="scss">
	@import './utilitiesDetailView.scss';
	.utilitiesDetailView {
		display: flex;
		flex-direction: column;
		margin-top: 56px;
		width: 1440px;
		margin: 0 auto;
	}
	.gpt-view {
		background: #E1EDEB;
		width: 100%;
		display: flex;
		flex-direction: column;
		height: 686px;
	}
	.type-title {
		text-align: left;
		color: #000000;
		font-size: 24px;
		font-weight: 600;
		margin-top: 26px;
		margin-bottom: 19px;
		margin-left: calc(50% - 400px);
	}
	.gpt-content {
		width: 800px;
		height: 527px;
		border-radius: 16px;
		background: #FFF;
		margin-left: calc(50% - 400px);
		padding: 30px;
		margin-bottom: 30px;
	}
	.recommend-view {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 716px;
		background-color: #FFF;
	}
</style>