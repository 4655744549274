<template>
  <div class="detail-bottom-wrapper">
    <div>
      <h3>PropCentre.co.nz</h3>
    </div>
    <div class="content-wrapper">
      <div class="bottom-content-title">
        <div class="detail-bottom-left__title">
          <span>City</span>
        </div>
        <div class="detail-bottom-middle__title">
          <span>Suburbs</span>
        </div>
        <div class="detail-bottom-right__title">
          <span>About PropCenter.co.nz</span>
        </div>
      </div>
      <div class="bottom-content">
        <div class="detail-bottom-left">
          <div
            class="detail-bottom-city-item"
            v-for="city in cities"
            :key="city"
            @click="toPropertySearch(city, 1)"
          >
            <span>{{ city }}</span>
          </div>
        </div>
        <div class="detail-bottom-middle">
          <div
            class="detail-bottom-middle-suburb"
            v-for="(suburb, index) in suburbs"
            :key="index"
          >
            <div
              class="detail-bottom-middle-suburb-item"
              v-for="name in suburb"
              :key="name"
              @click="toPropertySearch(name, 2)"
            >
              <span>{{ name }}</span>
            </div>
          </div>
        </div>
        <div class="detail-bottom-right">
          <div class="detail-bottom-right-item">
            <span>About us</span>
          </div>
          <div class="detail-bottom-right-item">
            <span>Privacy Policy</span>
          </div>
          <div class="detail-bottom-right-item">
            <span>Terms</span>
          </div>
          <div class="detail-bottom-right-item">
            <span>Contact us</span>
          </div>
        </div>
      </div>
    </div>
    <div class="detail-bottom-bottom">
      <span>©️ 2023 PropCentre Ltd</span>
    </div>
  </div>
</template>

<script>
import cityData from "@/common/relationData.js";

export default {
  name: "detailBottom",
  data() {
    return {
      cityData: cityData,
      cities: [],
      suburbs: [],
    };
  },
  mounted() {
    console.log(this.cityData, "city data.");
    this.cities = this.cityData[0].submenu.map((item) => {
      return item.name;
    });
    const suburbs = this.cityData[0].submenu.map((item) => {
      return item.submenu
        .map((subitem) => {
          return subitem.name;
        })
        .splice(0, 7);
    });
    this.suburbs = suburbs.splice(0, 4);
    console.log(this.suburbs, "suburbs");
  },
  methods: {
    toPropertySearch(name, t) {
      this.$router.push({
        path: "/propertView",
        query: {
          name: name.trim(),
          t,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.detail-bottom-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 60px 80px;
  box-sizing: border-box;
  background: #919eab14;
  padding-bottom: 120px;
  .content-wrapper {
    width: 100%;
  }
  .bottom-content-title {
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #5d5b5f;
    .detail-bottom-left__title {
      flex: 1;
      display: flex;
      justify-content: flex-start;
    }
    .detail-bottom-middle__title {
      flex: 4;
      display: flex;
      justify-content: flex-start;
    }
    .detail-bottom-right__title {
      flex: 1;
      display: flex;
      justify-content: flex-start;
    }
  }
  .bottom-content {
    display: flex;
    margin-top: 20px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #5d5b5f;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    .detail-bottom-left {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      .detail-bottom-city-item {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 40px;
        cursor: pointer;
      }
    }
    .detail-bottom-middle {
      flex: 4;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      .detail-bottom-middle-suburb {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        .detail-bottom-middle-suburb-item {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          height: 40px;
          cursor: pointer;
        }
      }
    }
    .detail-bottom-right {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      .detail-bottom-right-item {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 40px;
      }
    }
  }
  .detail-bottom-bottom {
    margin-top: 20px;
  }
}
</style>