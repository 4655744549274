var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nav-menu"},[_c('img',{staticClass:"logoImg",attrs:{"src":require("../assets/homelogo.png")}}),_c('div',{class:_vm.index == 7 ? 'menuItemSel' : 'menuItem',on:{"click":function($event){return _vm.changeMenuEvent(7)}}},[_c('img',{staticStyle:{"width":"15px","height":"16px"},attrs:{"src":_vm.index == 7
            ? require('../assets/navBar/ic-home-sel.png')
            : require('../assets/navBar/ic-home-nor.png')}}),_c('p',{class:_vm.index == 7 ? 'menu-text-sel' : 'menu-text-nor'},[_vm._v(" "+_vm._s(_vm.$t("nav.Home"))+" ")])]),_c('div',{class:_vm.index == 9 ? 'menuItemSel' : 'menuItem',on:{"click":function($event){return _vm.changeMenuEvent(9)}}},[_c('img',{staticStyle:{"width":"15px","height":"16px"},attrs:{"src":_vm.index == 9
            ? require('../assets/navBar/ic-home-sel.png')
            : require('../assets/navBar/ic-home-nor.png')}}),_c('p',{class:_vm.index == 9 ? 'menu-text-sel' : 'menu-text-nor'},[_vm._v(" "+_vm._s(_vm.$t("nav.Gallery"))+" ")])]),_c('div',{class:_vm.index == 3 ? 'menuItemSel' : 'menuItem',on:{"click":function($event){return _vm.changeMenuEvent(3)}}},[_c('img',{staticStyle:{"width":"15px","height":"16px"},attrs:{"src":_vm.index == 3
            ? require('../assets/navBar/ic-map-sel.png')
            : require('../assets/navBar/ic-map-nor.png')}}),_c('p',{class:_vm.index == 3 ? 'menu-text-sel' : 'menu-text-nor'},[_vm._v(" "+_vm._s(_vm.$t("nav.Map"))+" ")])]),_c('div',{class:_vm.index == 0 ? 'menuItemSel' : 'menuItem',on:{"click":function($event){return _vm.changeMenuEvent(0)}}},[_c('img',{staticStyle:{"width":"15px","height":"16px"},attrs:{"src":_vm.index == 0
            ? require('../assets/navBar/ic-properties-sel.png')
            : require('../assets/navBar/ic-properties-nor.png')}}),_c('p',{class:_vm.index == 0 ? 'menu-text-sel' : 'menu-text-nor'},[_vm._v(" "+_vm._s(_vm.$t("nav.Properties"))+" ")])]),(_vm.env === 'development')?_c('div',{class:_vm.index == 4 ? 'menuItemSel' : 'menuItem',on:{"click":function($event){return _vm.changeMenuEvent(4)}}},[_c('img',{staticStyle:{"width":"15px","height":"16px"},attrs:{"src":_vm.index == 4
            ? require('../assets/navBar/ic-analyse-sel.png')
            : require('../assets/navBar/ic-analyse-nor.png')}}),_c('p',{class:_vm.index == 4 ? 'menu-text-sel' : 'menu-text-nor'},[_vm._v(" "+_vm._s(_vm.$t("nav.Analyse"))+" ")])]):_vm._e(),(_vm.env === 'development')?_c('div',{class:_vm.index == 5 ? 'menuItemSel' : 'menuItem',on:{"click":function($event){return _vm.changeMenuEvent(5)}}},[_c('img',{staticStyle:{"width":"15px","height":"16px"},attrs:{"src":_vm.index == 5
            ? require('../assets/navBar/ic-propguide-sel.png')
            : require('../assets/navBar/ic-propguide-nor.png')}}),_c('p',{class:_vm.index == 5 ? 'menu-text-sel' : 'menu-text-nor'},[_vm._v(" "+_vm._s(_vm.$t("nav.PropGuide"))+" ")]),_vm._m(0)]):_vm._e(),(_vm.env === 'development')?_c('div',{class:_vm.index == 6 ? 'menuItemSel' : 'menuItem',on:{"click":function($event){return _vm.changeMenuEvent(6)}}},[_c('img',{staticStyle:{"width":"18px","height":"18px"},attrs:{"src":_vm.index == 6
            ? require('../assets/navBar/ic-utilities-sel.png')
            : require('../assets/navBar/ic-utilities-nor.png')}}),_c('p',{class:_vm.index == 6 ? 'menu-text-sel' : 'menu-text-nor'},[_vm._v(" "+_vm._s(_vm.$t("nav.Utilities"))+" ")])]):_vm._e(),_c('div',{class:_vm.index == 1 ? 'menuItemSel' : 'menuItem',on:{"click":function($event){return _vm.changeMenuEvent(1)}}},[_c('img',{staticStyle:{"width":"18px","height":"18px"},attrs:{"src":_vm.index == 1
            ? require('../assets/navBar/ic-shortlist-sel.png')
            : require('../assets/navBar/ic-shortlist-nor.png')}}),_c('p',{class:_vm.index == 1 ? 'menu-text-sel' : 'menu-text-nor'},[_vm._v(" "+_vm._s(_vm.$t("nav.Shortlist"))+" ")])]),(_vm.env === 'development')?_c('div',{class:_vm.index == 2 ? 'menuItemSel' : 'menuItem',on:{"click":function($event){return _vm.changeMenuEvent(2)}}},[_c('img',{staticStyle:{"width":"18px","height":"18px"},attrs:{"src":_vm.index == 2
            ? require('../assets/navBar/ic-aboutus-sel.png')
            : require('../assets/navBar/ic-aboutus-nor.png')}}),_c('p',{class:_vm.index == 2 ? 'menu-text-sel' : 'menu-text-nor'},[_vm._v(" "+_vm._s(_vm.$t("nav.AboutUs"))+" ")])]):_vm._e(),_c('div',{staticStyle:{"display":"flex","flex-direction":"row-reverse","align-items":"center","margin-left":"auto"}},[(_vm.isLogin == false)?_c('div',{staticClass:"login-btn",on:{"click":_vm.goToSign}},[_vm._v(" Sign Up ")]):_vm._e(),(_vm.isLogin)?_c('el-dropdown',{attrs:{"trigger":"click"}},[(_vm.isLogin)?_c('div',{staticStyle:{"display":"flex","flex-direction":"row-reverse","align-items":"center"}},[_c('img',{staticClass:"right_img",attrs:{"src":require("../assets/down_icon.png")}}),_c('div',{staticClass:"name"},[_vm._v(_vm._s(_vm.name))])]):_vm._e(),(_vm.isLogin)?_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',{nativeOn:{"click":function($event){return _vm.clickLogout.apply(null, arguments)}}},[_vm._v("退出登录")])],1):_vm._e()],1):_vm._e(),_c('el-dropdown',{attrs:{"trigger":"click"}},[_c('img',{staticClass:"langIcon",attrs:{"src":require("../assets/navBar/language.png")}}),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',{class:_vm.lan == 'en' ? 'langSel' : '',nativeOn:{"click":function($event){return _vm.changeType('en')}}},[_vm._v("English")]),_c('el-dropdown-item',{class:_vm.lan == 'zh' ? 'langSel' : '',nativeOn:{"click":function($event){return _vm.changeType('zh')}}},[_vm._v("中文")])],1)],1)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"beta-icon"},[_c('span',[_vm._v("Beta")])])
}]

export { render, staticRenderFns }