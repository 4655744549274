var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"utilitiesDetailView"},[_c('div',{staticClass:"gpt-view"},[_c('div',{staticClass:"type-title"},[_vm._v(_vm._s(_vm.type))]),_c('div',{staticClass:"gpt-content"},[_c('div',{staticClass:"prop-guide-wrapper"},[_c('div',{staticClass:"prop-guide-chat-box"},_vm._l((_vm.chatBubbles),function(bubble){return _c('div',{key:bubble.id,class:bubble.self ? 'chat-bubble-wrapper left' : 'chat-bubble-wrapper right'},[(!bubble.self)?_c('img',{staticClass:"ai-thumbnail",attrs:{"src":require('@/assets/propGuide-head.png'),"alt":"1"}}):_vm._e(),(bubble.type === 'message')?_c('div',{class:bubble.self
			            ? 'prop-guide-chat-bubble-self'
			            : 'prop-guide-chat-bubble-ai'},[(Array.isArray(bubble.content))?_c('div',{staticClass:"chat-text"},_vm._l((bubble.content),function(row,index){return _c('p',{key:index,staticStyle:{"margin-bottom":"10px"},domProps:{"innerHTML":_vm._s(row)}})}),0):_c('div',{staticClass:"chat-text"},[_c('p',{domProps:{"innerHTML":_vm._s(bubble.content)}})]),_c('div',{staticClass:"chat-house-list"},_vm._l((bubble.houseList),function(house,index){return _c('div',{key:house.id,staticClass:"chat-house"},[_c('div',{staticStyle:{"width":"72px"}},[_c('img',{staticClass:"chat-house-thumbnail",attrs:{"src":_vm.imageServerDomain +
			                  house.photos?.[0]?.baseUrl +
			                  '.crop.140x178.jpg'}})]),_c('div',{staticClass:"chat-house-info",on:{"click":function($event){return _vm.toDetail(house.id)}}},[_c('div',{staticClass:"chat-house-title"},[_c('span',[_vm._v("["+_vm._s(index + 1)+"]"+_vm._s(house.address.displayAddress))])]),_c('div',{staticClass:"chat-house-data"},[_c('div',{staticClass:"chat-house-data-item"},[_c('img',{staticClass:"chat-house-icon",attrs:{"src":require('@/assets/mianji.png')}}),_c('span',{staticStyle:{"font-size":"11px"}},[_vm._v(_vm._s(house.landArea))])]),_c('div',{staticClass:"chat-house-data-item"},[_c('img',{staticClass:"chat-house-icon",attrs:{"src":require('@/assets/woshi.png')}}),_c('span',{staticStyle:{"font-size":"11px"}},[_vm._v(_vm._s(house.bedroomCount))])]),_c('div',{staticClass:"chat-house-data-item"},[_c('img',{staticClass:"chat-house-icon",attrs:{"src":require('@/assets/yushi.png')}}),_c('span',{staticStyle:{"font-size":"11px"}},[_vm._v(_vm._s(house.bathroomFullCount))])])])])])}),0)]):_vm._e(),(bubble.type === 'loading')?_c('div',{class:bubble.self
			            ? 'prop-guide-chat-bubble-self'
			            : 'prop-guide-chat-bubble-ai'},[_vm._m(0,true)]):_vm._e()])}),0),_c('div',{staticClass:"prop-guide-chat-input"},[_c('div',{staticClass:"prop-guide-textarea"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputValue),expression:"inputValue"}],staticClass:"prop-guide-text-input",attrs:{"placeholder":"Type a question"},domProps:{"value":(_vm.inputValue)},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.handleClickSendButton.apply(null, arguments)},"input":function($event){if($event.target.composing)return;_vm.inputValue=$event.target.value}}}),_vm._v(" "),_c('div',{staticClass:"prop-send-button"},[_c('img',{style:({
			            cursor: _vm.inputValue?.length > 0 ? 'pointer' : 'not-allowed',
			          }),attrs:{"alt":"x","src":_vm.inputValue?.length > 0
			              ? require('../../assets/prop_send.png')
			              : require('../../assets/prop_send_nor.png')},on:{"click":_vm.handleClickSendButton}})])])])])])]),_c('div',{staticClass:"recommend-view"},[_c('div',{staticStyle:{"color":"#000000","margin-top":"93px","font-size":"30px","font-weight":"700"}},[_vm._v(" Recommended financial institution ")]),_c('img',{staticStyle:{"width":"411px","height":"238px","margin-top":"67px"},attrs:{"src":require("../../assets/utilities/cmcMarketIcon.png")}}),_c('div',{staticStyle:{"margin-top":"30px","color":"#4AA4C7","font-size":"26px"}},[_vm._v("Redefine your trading strategy.")]),_c('el-button',{staticStyle:{"margin-top":"52px","width":"151px","height":"37px"},attrs:{"type":"primary"}},[_vm._v("Learn more")])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"guide-loader"},[_c('div',{staticClass:"loader"})])
}]

export { render, staticRenderFns }