import axios from "axios";
import store from "../store/index.js";
import router from "../router/index.js";
const baseURL = "https://www.propcentre.co.nz";//https://www.propcentre.co.nz http://47.116.191.218
const service = axios.create({
  baseURL: baseURL, // http://localhost:21021/
  withCredentials: true, // send cookies when cross-domain requests
  timeout: 20000, // request timeout
  //`xsrfCookieName`是要用作 xsrf 令牌的值的cookie的名称
  xsrfCookieName: "XSRF-TOKEN", // default
  // `xsrfHeaderName`是携带xsrf令牌值的http头的名称
  xsrfHeaderName: "X-XSRF-TOKEN", // default
});

// request interceptor
service.interceptors.request.use(
  (config) => {
    let token = getToken();
    if (token != undefined && token != "") {
      config.headers["nz-token"] = token;
    }
    config.headers["Accept-Language"] = localStorage.getItem('locale') || 'en';
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  (response) => {
    // console.log(response)
    const res = response.data;
    if (response.status !== 200) {
      this.$message(res.errMsg || "Error");
      return Promise.reject(new Error(res.errMsg || "Error"));
    } else {
      return response;
    }
  },
  (error) => {
    console.log(error);
    if (error.response.status == 403) {
      store.dispatch("user/logout");
      router.replace("/signUp");
    }
    return Promise.reject(error);
  }
);
function getToken() {
  if (localStorage.getItem("token")) {
    return localStorage.getItem("token");
  } else {
    return "";
  }
}
window.request = service;
export default service;
