<template>
  <div class="open-home">
    <div v-for="time in lists" :key="time.id" class="open-home__row">
      <!-- <img style="width:16px;height:16px" :src="require('@/assets/calendar.png')" /> -->
      <span class="open-home__row-piece">{{ time.weekDay }}</span>
      <span class="open-home__row-piece"
        >{{ time.date }}th {{ time.month }}</span
      >
      <span class="open-home__row-piece"
        >{{ time.startHours }}:00pm-{{ time.endHours }}:30pm</span
      >
    </div>
  </div>
</template>
<script>
import { getArrayPropsDef } from "@/common/propsConfig";
import { dateTimeParser } from "@/utils/tools";
import { weekDays, datetimeMapping } from "@/common/datetime";
export default {
  name: "openHome",
  props: {
    openHomes: getArrayPropsDef(),
  },
  data() {
    return {
      lists: [],
    };
  },
  mounted() {
    this.makeList();
  },
  methods: {
    makeList() {
      this.lists = this.openHomes.map((openHome) => {
        const datetimeComponentStart = dateTimeParser(openHome.start);
        const datetimeComponentEnd = dateTimeParser(openHome.end);
        console.log(datetimeComponentStart, "datetimeComponentStart");
        return {
          id: openHome.start,
          weekDay: weekDays[datetimeComponentStart.day],
          date: datetimeComponentStart.dat,
          month: datetimeMapping[datetimeComponentStart.month],
          startHours: datetimeComponentStart.hours,
          startMinute: datetimeComponentStart.minute,
          endHours: datetimeComponentEnd.hours,
          endMinute: datetimeComponentEnd.minute,
        };
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./openHome.scss";
</style>