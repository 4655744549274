export default [
  // {
  // 	"name": "All Region",
  // 	"value": "all",
  // 	"submenu": [{
  // 		"name": "All Region",
  // 		"value": "all",
  // 		"submenu": [{
  // 			"name": "All Region",
  // 			"value": "all",
  // 		}]
  // 	}]
  // },
  {
    name: "Auckland",
    value: "Auckland",
    submenu: [
      {
        name: "Auckland City",
        value: "Auckland City",
        submenu: [
          {
            name: "Arch Hill",
            value: "Arch Hill",
          },
          {
            name: "Auckland CBD",
            value: "Auckland CBD",
          },
          {
            name: "Avondale",
            value: "Avondale",
          },
          {
            name: "Blockhouse Bay",
            value: "Blockhouse Bay",
          },
          {
            name: "Balmoral",
            value: "Balmoral",
          },
          {
            name: "Blackpool",
            value: "Blackpool",
          },
          {
            name: "Eden Terrace",
            value: "Eden Terrace",
          },
          {
            name: "Eden Valley",
            value: "Eden Valley",
          },
          {
            name: "Ellerslie",
            value: "Ellerslie",
          },
          {
            name: "Epsom",
            value: "Epsom",
          },
          {
            name: "Freemans Bay",
            value: "Freemans Bay",
          },
          {
            name: "Glendowie",
            value: "Glendowie",
          },
          {
            name: "Glen Innes",
            value: "Glen Innes",
          },
          {
            name: "Grafton",
            value: "Grafton",
          },
          {
            name: "Greenlane",
            value: "Greenlane",
          },
          {
            name: "Greenwoods Corner",
            value: "Greenwoods Corner",
          },
          {
            name: "Grey Lynn",
            value: "Grey Lynn",
          },
          {
            name: "Herne Bay",
            value: "Herne Bay",
          },
          {
            name: "Hillsborough",
            value: "Hillsborough",
          },
          {
            name: "Kingsland",
            value: "Kingsland",
          },
          {
            name: "Kohimarama",
            value: "Kohimarama",
          },
          {
            name: "Lynfield",
            value: "Lynfield",
          },
          {
            name: "Meadowbank",
            value: "Meadowbank",
          },
          {
            name: "Mission Bay",
            value: "Mission Bay",
          },
          {
            name: "Morningside",
            value: "Morningside",
          },
          {
            name: "Mount Albert",
            value: "Mount Albert",
          },
          {
            name: "Mount Eden",
            value: "Mount Eden",
          },
          {
            name: "Mount Roskill",
            value: "Mount Roskill",
          },
          {
            name: "Mount Wellington",
            value: "Mount Wellington",
          },
          {
            name: "Newmarket",
            value: "Newmarket",
          },
          {
            name: "Newton",
            value: "Newton",
          },
          {
            name: "New Windsor",
            value: "New Windsor",
          },
          {
            name: "Onehunga",
            value: "Onehunga",
          },
          {
            name: "Oneroa",
            value: "Oneroa",
          },
          {
            name: "Onetangi",
            value: "Onetangi",
          },
          {
            name: "One Tree Hill",
            value: "One Tree Hill",
          },
          {
            name: "Orakei",
            value: "Orakei",
          },
          {
            name: "Oranga",
            value: "Oranga",
          },
          {
            name: "Ostend",
            value: "Ostend",
          },
          {
            name: "Otahuhu",
            value: "Otahuhu",
          },
          {
            name: "Owairaka",
            value: "Owairaka",
          },
          {
            name: "Palm Beach",
            value: "Palm Beach",
          },
          {
            name: "Panmure",
            value: "Panmure",
          },
          {
            name: "Parnell",
            value: "Parnell",
          },
          {
            name: "Penrose",
            value: "Penrose",
          },
          {
            name: "Point England",
            value: "Point England",
          },
          {
            name: "Point Chevalier",
            value: "Point Chevalier",
          },
          {
            name: "Ponsonby",
            value: "Ponsonby",
          },
          {
            name: "Remuera",
            value: "Remuera",
          },
          {
            name: "Royal Oak",
            value: "Royal Oak",
          },
          {
            name: "Saint Heliers",
            value: "Saint Heliers",
          },
          {
            name: "St Johns",
            value: "St Johns",
          },
          {
            name: "Saint Marys Bay",
            value: "Saint Marys Bay",
          },
          {
            name: "Sandringham",
            value: "Sandringham",
          },
          {
            name: "Stonefields",
            value: "Stonefields",
          },
          {
            name: "Surfdale",
            value: "Surfdale",
          },
          {
            name: "Tamaki",
            value: "Tamaki",
          },
          {
            name: "Te Papapa",
            value: "Te Papapa",
          },
          {
            name: "Three Kings",
            value: "Three Kings",
          },
          {
            name: "Waikowhai",
            value: "Waikowhai",
          },
          {
            name: "Wai o Taiki Bay",
            value: "Wai o Taiki Bay",
          },
          {
            name: "Waterview",
            value: "Waterview",
          },
          {
            name: "Western Springs",
            value: "Western Springs",
          },
          {
            name: "Westfield",
            value: "Westfield",
          },
          {
            name: "Westmere",
            value: "Westmere",
          },
        ],
      },
      {
        name: "North Shore City",
        value: "North Shore City",
        submenu: [
          {
            name: "Albany",
            value: "Albany",
          },
          {
            name: "Bayswater",
            value: "Bayswater",
          },
          {
            name: "Bayview",
            value: "Bayview",
          },
          {
            name: "Beach Haven",
            value: "Beach Haven",
          },
          {
            name: "Belmont",
            value: "Belmont",
          },
          {
            name: "Birkdale",
            value: "Birkdale",
          },
          {
            name: "Birkenhead",
            value: "Birkenhead",
          },
          {
            name: "Browns Bay",
            value: "Browns Bay",
          },
          {
            name: "Campbells Bay",
            value: "Campbells Bay",
          },
          {
            name: "Castor Bay",
            value: "Castor Bay",
          },
          {
            name: "Chatswood",
            value: "Chatswood",
          },
          {
            name: "Cheltenham",
            value: "Cheltenham",
          },
          {
            name: "Crown Hill",
            value: "Crown Hill",
          },
          {
            name: "Devonport",
            value: "Devonport",
          },
          {
            name: "Fairview Heights",
            value: "Fairview Heights",
          },
          {
            name: "Forrest Hill",
            value: "Forrest Hill",
          },
          {
            name: "Glenfield",
            value: "Glenfield",
          },
          {
            name: "Greenhithe",
            value: "Greenhithe",
          },
          {
            name: "Hauraki",
            value: "Hauraki",
          },
          {
            name: "Highbury",
            value: "Highbury",
          },
          {
            name: "Hillcrest",
            value: "Hillcrest",
          },
          {
            name: "Long Bay",
            value: "Long Bay",
          },
          {
            name: "Mairangi Bay",
            value: "Mairangi Bay",
          },
          {
            name: "Marlborough",
            value: "Marlborough",
          },
          {
            name: "Milford",
            value: "Milford",
          },
          {
            name: "Murrays Bay",
            value: "Murrays Bay",
          },
          {
            name: "Narrow Neck",
            value: "Narrow Neck",
          },
          {
            name: "Northcote",
            value: "Northcote",
          },
          {
            name: "Northcote Point",
            value: "Northcote Point",
          },
          {
            name: "North Harbour",
            value: "North Harbour",
          },
          {
            name: "Northcross",
            value: "Northcross",
          },
          {
            name: "Okura",
            value: "Okura",
          },
          {
            name: "Oteha",
            value: "Oteha",
          },
          {
            name: "Paremoremo",
            value: "Paremoremo",
          },
          {
            name: "Pinehill",
            value: "Pinehill",
          },
          {
            name: "Rosedale",
            value: "Rosedale",
          },
          {
            name: "Rothesay Bay",
            value: "Rothesay Bay",
          },
          {
            name: "Schnapper Rock",
            value: "Schnapper Rock",
          },
          {
            name: "Stanley Bay",
            value: "Stanley Bay",
          },
          {
            name: "Stanley Point",
            value: "Stanley Point",
          },
          {
            name: "Sunnynook",
            value: "Sunnynook",
          },
          {
            name: "Takapuna",
            value: "Takapuna",
          },
          {
            name: "Torbay",
            value: "Torbay",
          },
          {
            name: "Totara Vale",
            value: "Totara Vale",
          },
          {
            name: "Unsworth Heights",
            value: "Unsworth Heights",
          },
          {
            name: "Waiake",
            value: "Waiake",
          },
          {
            name: "Wairau Valley",
            value: "Wairau Valley",
          },
          {
            name: "Westlake",
            value: "Westlake",
          },
          {
            name: "Windsor Park",
            value: "Windsor Park",
          },
        ],
      },
      {
        name: "Manukau City",
        value: "Manukau City",
        submenu: [
          {
            name: "Airport Oaks",
            value: "Airport Oaks",
          },
          {
            name: "Beachlands",
            value: "Beachlands",
          },
          {
            name: "Botany Downs",
            value: "Botany Downs",
          },
          {
            name: "Brookby",
            value: "Brookby",
          },
          {
            name: "Bucklands Beach",
            value: "Bucklands Beach",
          },
          {
            name: "Burswood",
            value: "Burswood",
          },
          {
            name: "Chapel Downs",
            value: "Chapel Downs",
          },
          {
            name: "Clendon Park",
            value: "Clendon Park",
          },
          {
            name: "Clevedon",
            value: "Clevedon",
          },
          {
            name: "Clover Park",
            value: "Clover Park",
          },
          {
            name: "Cockle Bay",
            value: "Cockle Bay",
          },
          {
            name: "Dannemora",
            value: "Dannemora",
          },
          {
            name: "East Tamaki",
            value: "East Tamaki",
          },
          {
            name: "East Tamaki Heights",
            value: "East Tamaki Heights",
          },
          {
            name: "Eastern Beach",
            value: "Eastern Beach",
          },
          {
            name: "Farm Cove",
            value: "Farm Cove",
          },
          {
            name: "Favona",
            value: "Favona",
          },
          {
            name: "Flat Bush",
            value: "Flat Bush",
          },
          {
            name: "Golflands",
            value: "Golflands",
          },
          {
            name: "Goodwood Heights",
            value: "Goodwood Heights",
          },
          {
            name: "Greenmeadows",
            value: "Greenmeadows",
          },
          {
            name: "Half Moon Bay",
            value: "Half Moon Bay",
          },
          {
            name: "Highland Park",
            value: "Highland Park",
          },
          {
            name: "Hillpark",
            value: "Hillpark",
          },
          {
            name: "Howick",
            value: "Howick",
          },
          {
            name: "Huntington Park",
            value: "Huntington Park",
          },
          {
            name: "Kawakawa Bay",
            value: "Kawakawa Bay",
          },
          {
            name: "Mahia Park",
            value: "Mahia Park",
          },
          {
            name: "Mangere",
            value: "Mangere",
          },
          {
            name: "Mangere Bridge",
            value: "Mangere Bridge",
          },
          {
            name: "Mangere East",
            value: "Mangere East",
          },
          {
            name: "Manukau",
            value: "Manukau",
          },
          {
            name: "Manukau Heights",
            value: "Manukau Heights",
          },
          {
            name: "Manurewa",
            value: "Manurewa",
          },
          {
            name: "Maraetai",
            value: "Maraetai",
          },
          {
            name: "Meadowlands",
            value: "Meadowlands",
          },
          {
            name: "Mellons Bay",
            value: "Mellons Bay",
          },
          {
            name: "Middlemore",
            value: "Middlemore",
          },
          {
            name: "Murphys Heights",
            value: "Murphys Heights",
          },
          {
            name: "Northpark",
            value: "Northpark",
          },
          {
            name: "Orere Point",
            value: "Orere Point",
          },
          {
            name: "Ormiston",
            value: "Ormiston",
          },
          {
            name: "Otara",
            value: "Otara",
          },
          {
            name: "Pakuranga",
            value: "Pakuranga",
          },
          {
            name: "Pakuranga Heights",
            value: "Pakuranga Heights",
          },
          {
            name: "Papatoetoe",
            value: "Papatoetoe",
          },
          {
            name: "Randwick Park",
            value: "Randwick Park",
          },
          {
            name: "Settlers Cove",
            value: "Settlers Cove",
          },
          {
            name: "Shamrock Park",
            value: "Shamrock Park",
          },
          {
            name: "Shelly Park",
            value: "Shelly Park",
          },
          {
            name: "Silkwood Heights",
            value: "Silkwood Heights",
          },
          {
            name: "Somerville",
            value: "Somerville",
          },
          {
            name: "Sunnyhills",
            value: "Sunnyhills",
          },
          {
            name: "The Gardens",
            value: "The Gardens",
          },
          {
            name: "Totara Heights",
            value: "Totara Heights",
          },
          {
            name: "Tuscany Estate",
            value: "Tuscany Estate",
          },
          {
            name: "Waimahia Landing",
            value: "Waimahia Landing",
          },
          {
            name: "Wattle Cove",
            value: "Wattle Cove",
          },
          {
            name: "Wattle Downs",
            value: "Wattle Downs",
          },
          {
            name: "Weymouth",
            value: "Weymouth",
          },
          {
            name: "Whitford",
            value: "Whitford",
          },
          {
            name: "Wiri",
            value: "Wiri",
          },
        ],
      },
      {
        name: "Papakura District",
        value: "Papakura District",
        submenu: [
          {
            name: "Alfriston",
            value: "Alfriston",
          },
          {
            name: "Ardmore",
            value: "Ardmore",
          },
          {
            name: "Conifer Grove",
            value: "Conifer Grove",
          },
          {
            name: "Drury",
            value: "Drury",
          },
          {
            name: "Longford Park",
            value: "Longford Park",
          },
          {
            name: "Red Hill",
            value: "Red Hill",
          },
          {
            name: "Rosehill",
            value: "Rosehill",
          },
          {
            name: "Manurewa East",
            value: "Manurewa East",
          },
          {
            name: "Opaheke",
            value: "Opaheke",
          },
          {
            name: "Pahurehure",
            value: "Pahurehure",
          },
          {
            name: "Papakura",
            value: "Papakura",
          },
          {
            name: "Runciman",
            value: "Runciman",
          },
          {
            name: "Red Hill",
            value: "Red Hill",
          },
          {
            name: "Rosehill",
            value: "Rosehill",
          },
          {
            name: "Takanini",
            value: "Takanini",
          },
        ],
      },
      {
        name: "Franklin District",
        value: "Franklin District",
        submenu: [
          {
            name: "Awhitu",
            value: "Awhitu",
          },
          {
            name: "Ararimu",
            value: "Ararimu",
          },
          {
            name: "Bombay",
            value: "Bombay",
          },
          {
            name: "Buckland",
            value: "Buckland",
          },
          {
            name: "Clarks Beach",
            value: "Clarks Beach",
          },
          {
            name: "Hunua",
            value: "Hunua",
          },
          {
            name: "Karaka",
            value: "Karaka",
          },
          {
            name: "Karaka Harbourside",
            value: "Karaka Harbourside",
          },
          {
            name: "Kingseat",
            value: "Kingseat",
          },
          {
            name: "Manukau Heads",
            value: "Manukau Heads",
          },
          {
            name: "Paerata",
            value: "Paerata",
          },
          {
            name: "Paparimu",
            value: "Paparimu",
          },
          {
            name: "Patumahoe",
            value: "Patumahoe",
          },
          {
            name: "Pollok",
            value: "Pollok",
          },
          {
            name: "Pukekohe",
            value: "Pukekohe",
          },
          {
            name: "Waiau Pa",
            value: "Waiau Pa",
          },
          {
            name: "Waiuku",
            value: "Waiuku",
          },
        ],
      },
      {
        name: "Waitakere City",
        value: "Waitakere City",
        submenu: [
          {
            name: "Anawhata",
            value: "Anawhata",
          },
          {
            name: "Cornwallis",
            value: "Cornwallis",
          },
          {
            name: "Bethells Beach",
            value: "Bethells Beach",
          },
          {
            name: "Glen Eden",
            value: "Glen Eden",
          },
          {
            name: "Glendene",
            value: "Glendene",
          },
          {
            name: "Green Bay",
            value: "Green Bay",
          },
          {
            name: "Henderson",
            value: "Henderson",
          },
          {
            name: "Henderson Valley",
            value: "Henderson Valley",
          },
          {
            name: "Herald Island",
            value: "Herald Island",
          },
          {
            name: "Hobsonville",
            value: "Hobsonville",
          },
          {
            name: "Huia",
            value: "Huia",
          },
          {
            name: "Karekare",
            value: "Karekare",
          },
          {
            name: "Kaurilands",
            value: "Kaurilands",
          },
          {
            name: "Kelston",
            value: "Kelston",
          },
          {
            name: "Konini",
            value: "Konini",
          },
          {
            name: "Laingholm",
            value: "Laingholm",
          },
          {
            name: "Lincoln",
            value: "Lincoln",
          },
          {
            name: "McLaren Park",
            value: "McLaren Park",
          },
          {
            name: "Massey",
            value: "Massey",
          },
          {
            name: "New Lynn",
            value: "New Lynn",
          },
          {
            name: "Oratia",
            value: "Oratia",
          },
          {
            name: "Parau",
            value: "Parau",
          },
          {
            name: "Piha",
            value: "Piha",
          },
          {
            name: "Ranui",
            value: "Ranui",
          },
          {
            name: "Royal Heights",
            value: "Royal Heights",
          },
          {
            name: "Sunnyvale",
            value: "Sunnyvale",
          },
          {
            name: "Swanson",
            value: "Swanson",
          },
          {
            name: "Te Atatu",
            value: "Te Atatu",
          },
          {
            name: "Te Atatu Peninsula",
            value: "Te Atatu Peninsula",
          },
          {
            name: "Te Atatu South",
            value: "Te Atatu South",
          },
          {
            name: "Titirangi",
            value: "Titirangi",
          },
          {
            name: "Waiatarua",
            value: "Waiatarua",
          },
          {
            name: "Westgate",
            value: "Westgate",
          },
          {
            name: "West Harbour",
            value: "West Harbour",
          },
          {
            name: "Western Heights",
            value: "Western Heights",
          },
          {
            name: "Whenuapai",
            value: "Whenuapai",
          },
        ],
      },
      {
        name: "Rodney District",
        value: "Rodney District",
        submenu: [
          {
            name: "Algies Bay",
            value: "Algies Bay",
          },
          {
            name: "Arkles Bay",
            value: "Arkles Bay",
          },
          {
            name: "Army Bay",
            value: "Army Bay",
          },
          {
            name: "Big Omaha",
            value: "Big Omaha",
          },
          {
            name: "Dairy Flat",
            value: "Dairy Flat",
          },
          {
            name: "Dome Forest",
            value: "Dome Forest",
          },
          {
            name: "Dome Valley",
            value: "Dome Valley",
          },
          {
            name: "Glorit",
            value: "Glorit",
          },
          {
            name: "Gulf Harbour",
            value: "Gulf Harbour",
          },
          {
            name: "Hatfields Beach",
            value: "Hatfields Beach",
          },
          {
            name: "Helensville",
            value: "Helensville",
          },
          {
            name: "Huapai",
            value: "Huapai",
          },
          {
            name: "Kaipara Flats",
            value: "Kaipara Flats",
          },
          {
            name: "Kaukapakapa",
            value: "Kaukapakapa",
          },
          {
            name: "Kumeu",
            value: "Kumeu",
          },
          {
            name: "Leigh",
            value: "Leigh",
          },
          {
            name: "Mahurangi East",
            value: "Mahurangi East",
          },
          {
            name: "Mahurangi West",
            value: "Mahurangi West",
          },
          {
            name: "Mahurangi West",
            value: "Mahurangi West",
          },
          {
            name: "Mangakura",
            value: "Mangakura",
          },
          {
            name: "Manly",
            value: "Manly",
          },
          {
            name: "Makarau",
            value: "Makarau",
          },
          {
            name: "Matakana",
            value: "Matakana",
          },
          {
            name: "Matakatia",
            value: "Matakatia",
          },
          {
            name: "Millwater",
            value: "Millwater",
          },
          {
            name: "Muriwai",
            value: "Muriwai",
          },
          {
            name: "Omaha",
            value: "Omaha",
          },
          {
            name: "Orewa",
            value: "Orewa",
          },
          {
            name: "Parakai",
            value: "Parakai",
          },
          {
            name: "Pakiri",
            value: "Pakiri",
          },
          {
            name: "Point Wells",
            value: "Point Wells",
          },
          {
            name: "Port Albert",
            value: "Port Albert",
          },
          {
            name: "Puhoi",
            value: "Puhoi",
          },
          {
            name: "Red Beach",
            value: "Red Beach",
          },
          {
            name: "Redvale",
            value: "Redvale",
          },
          {
            name: "Riverhead",
            value: "Riverhead",
          },
          {
            name: "Sandspit",
            value: "Sandspit",
          },
          {
            name: "Shelly Beach",
            value: "Shelly Beach",
          },
          {
            name: "Silverdale",
            value: "Silverdale",
          },
          {
            name: "Snells Beach",
            value: "Snells Beach",
          },
          {
            name: "South Head",
            value: "South Head",
          },
          {
            name: "Stanmore Bay",
            value: "Stanmore Bay",
          },
          {
            name: "Tapora",
            value: "Tapora",
          },
          {
            name: "Tauhoa",
            value: "Tauhoa",
          },
          {
            name: "Taupaki",
            value: "Taupaki",
          },
          {
            name: "Tawharanui Peninsula",
            value: "Tawharanui Peninsula",
          },
          {
            name: "Te Arai",
            value: "Te Arai",
          },
          {
            name: "Te Hana",
            value: "Te Hana",
          },
          {
            name: "Ti Point",
            value: "Ti Point",
          },
          {
            name: "Tindalls Beach",
            value: "Tindalls Beach",
          },
          {
            name: "Tomarata",
            value: "Tomarata",
          },
          {
            name: "Wade Heads",
            value: "Wade Heads",
          },
          {
            name: "Waimauku",
            value: "Waimauku",
          },
          {
            name: "Wainui",
            value: "Wainui",
          },
          {
            name: "Waitoki",
            value: "Waitoki",
          },
          {
            name: "Waiwera",
            value: "Waiwera",
          },
          {
            name: "Warkworth",
            value: "Warkworth",
          },
          {
            name: "Whangaparaoa",
            value: "Whangaparaoa",
          },
          {
            name: "Whangaripo",
            value: "Whangaripo",
          },
          {
            name: "Whangateau",
            value: "Whangateau",
          },
          {
            name: "Wharehine",
            value: "Wharehine",
          },
          {
            name: "Wellsford",
            value: "Wellsford",
          },
          {
            name: "Woodhill Forest",
            value: "Woodhill Forest",
          },
        ],
      },
    ],
  },
];
