import service from "../utils/request";
import { getTouchId } from "../utils/auth.js";
// 查询经销商基础信息
export function getHouseDetail (id) {
  return service({
    url: `/api/v1/nz/hourse/detail/${id}`,
    method: "get"
  });
}

// 房屋详情页面信息
export function getHouseDetailPage (id) {
  var touchId = getTouchId()
  return service({
    url: `/api/v1/nz/hourse/detail/${id}?touchId=${touchId}`,
    method: "get"
  });
}

// 查询经销商基础信息
export function getHouseDetailNotForSale (id) {
  return service({
    url: `api/v1/nz/street-address/new/detail/${id}`,
    method: "get"
  });
}

// 根据经纬度查询房屋信息
export function getHouseListByLatLng(params) {
  return service({
    url: `/api/v1/nz/hourse/list-point`,
    method: 'get',
    params
  })
}

// 根据经纬度查询附近房屋信息
export function getNearbyHouseListByLatLng(params) {
  return service({
    url: `/api/v1/nz/hourse/nearby/list`,
    method: 'get',
    params
  })
}

// 根据touchId查询最近浏览房屋
export function getRecentlyViewedList(params) {
  return service({
    url: `api/v1/nz/user/recently-view/list`,
    method: 'get',
    params
  })
}