const cn = {
  nav: {
    Map: "地图选房",
    Properties: "房源列表",
    Analyse: "详情分析",
    PropGuide: "房产助理",
    Shortlist: "我的精选",
    AboutUs: "关于我们",
    Utilities: "Utilities",
    Home: "首页",
    Gallery: "画廊",
  },
  map: {
    Map: "平面地图",
    Satellite: "卫星地图",
    ForSale: "销售中",
    ShowStormPipe: "显示雨水管",
    ShowWastePipe: "显示污水管",
    Shortlist: "入选",
    Shortlisted: "已入选",
    Maybe: "待定",
    Nope: "排除",
  },
  detail: {
    Listed: "上架时间",
    Size: "面积",
    Bedrooms: "卧室",
    Bathrooms: "浴室",
    Garages: "车位",
    Location: "位置",
    OpenHome: "开放日",
    BasicInformation: "基本信息",
    Details: "详情描述",
    CapitalValuation: "资产估值",
    PropertyInformation: "房产信息",
    Timeline: "时间线",
    SchoolZoning: "学区",
    ContactTheAgent: "联系经纪人",
    Suburb: "社区",
    NearbyProperties: "附近的房产",
    RecentlyViewedListings: "最近浏览的房产",
    All: "全部",
    Primary: "小学",
    Intermediate: "初中",
    Secondary: "高中",
    ShowMore: "显示更多",
    ShowLess: "显示更少",
    City: "城市",
    Suburbs: "社区",
    AboutPropCentreCoNz: "关于PropCentre",
    PropertyType: "房产类型",
    Price: "价格",
    TypeAddress: "输入地址",
  },
  analyse: {
    PropertiesDataCentre: "房产数据中心",
    subType: "房屋类型",
    Size: "面积",
    Bedrooms: "卧室",
    Bathrooms: "浴室",
    Garages: "车位",
    BasicInformation: "基本信息",
    CapitalValuation: "资产估值",
    PropertyInformation: "房产信息",
    Timeline: "时间线",
    SchoolZoning: "学区",
    Parking: "停车场",
    floorArea: "室内面积",
    listingSubType: "房屋类型"
  },
  shortlist: {
    ShortlistedProperties: "入选的房产",
    All: "全部",
    Shortlisted: "已入选",
    Maybe: "待定",
    Nope: "排除",
    Bedrooms: "卧室",
    Bathrooms: "浴室",
    Garages: "车位",
  },
  aboutus: {
    CompanyIntroduction: "公司介绍",
    cTextLineF: "欢迎来到PropCentre，这是一个尖端的在线平台，它利用人工智能和大数据分析的力量，革新房地产行业。",
    cTextLineS:
      "在PropCentre，我们致力于改变人们购买和出售房地产的方式。无论您是正在寻找完美房产的购房者，还是希望简化工作流程的房地产专业人士，我们的平台都旨在满足您的需求。",
    Functions: "功能",
    fTextLineTF: "1. 智能房产搜索",
    fTextLineTFT:
      "我们的平台赋予购房者轻松搜索可购买房产的能力，获取每个房源的全面信息。轻松找到您的梦想之家，并掌握您所需的所有基本细节。",
    fTextLineTS: "2. 广泛的房产洞察",
    fTextLineTST: "了解每个房产的深入洞察，从平面图和设施到邻里统计数据。通过触手可及的丰富信息做出明智的决策。",
    fTextLineTT: "3. 房地产专家的专业工具",
    fTextLineTTT:
      "房地产专业人士可以使用我们的一系列与房产相关的工具来提升他们的生产力。从市场分析到客户管理，我们的平台为您提供在行业中出类拔萃所需的资源。",
    gnfxzj:
      "我们的平台利用最新的人工智能和大数据分析进展，确保您接收到最准确、最新的信息。我们相信，通过将技术和房地产专业知识结合起来，我们可以简化购买和销售过程，使其对所有参与者来说更加高效和透明。",
    szyxfw: "数字营销服务",
    szyxfw1: "房地产清单推广",
    szyxfw1T:
      "通过我们的在线房地产清单推广服务，在竞争激烈的房地产市场中提升您的房产的可见度和吸引力。无论您是房主还是房地产专业人士，我们的平台都提供了一个全面的解决方案，有效展示您的房源。",
    szyxfw2: "展示位置广告",
    szyxfw2T:
      "利用我们的展示位置广告服务，解锁您数字广告活动中的精准度和影响力潜力。特别适用于寻求扩大影响范围的企业或寻求高度针对性曝光的营销人员，可以使用我们的平台为放大品牌影响力提供全面的解决方案。",
    MultiPlatformAd: "多平台影响者广告",
    NZAndOversea: "新西兰及海外",
    NZContent:
      "通过多平台影响者广告，利用各种社交媒体平台上影响者的力量，将您的营销工作提升到一个新的水平。这项服务提供了一个全面的解决方案，以利用影响者的广泛影响力和真实性。您可以使用它来增强品牌知名度、推动参与度或提升销售。",
    TechnicalD: "企业技术发展支持",
    TechnicalDC: "数据分析、个性化推荐会话、AI和聊天机器人",
    OurMission: "我们的使命",
    OurMission1:
      "在PropCentre，我们的使命是赋予个人和专业人士他们在自信地驾驭房地产市场所需的工具和知识。我们致力于提供一个无缝、用户友好的体验，将房地产行业提升到新的高度。",
    OurMission2: "加入我们这个激动人心的旅程，创新与房地产相遇，在这里，我们将共同塑造房产交易的未来。",
    OurMission3: "今天就探索PropCentre，体验房地产的未来。",
    ContactUs: "联系我们",
  },
};
export default cn;
