<template>
  <div class="agent-card-wrapper">
    <div
      class="agent-office-back"
      :style="{ backgroundColor: officeBrandColor }"
    >
      <img
        class="agent-office-image"
        alt="agent card background"
        :src="officeImage"
      />
    </div>
    <div class="agent-card-portrait">
      <img class="agent-head" alt="" :src="agentHead" />
    </div>
    <div class="agent-info">
      <h2>{{ agentName }}</h2>
      <p>{{ officeName }}</p>
    </div>
    <div class="agent-btns">
      <img
        src="../assets/viewprofile.png"
        class="agent-profile"
        alt=""
        @click="goToAgent"
      />
      <div class="agent-entity">
        <img
          @click="showSendEmail"
          class="agent-equlity"
          src="../assets/emailEnquiry.png"
          alt=""
        />
        <div class="agent-phone">
          <img
            src="../assets/I_phone.png"
            class="agent-phone-icon"
            alt=""
            @click="showCopyPhone"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getEmptyStringPropsDef } from "@/common/propsConfig";
export default {
  props: {
    agentName: getEmptyStringPropsDef(),
    officeName: getEmptyStringPropsDef(),
    agentHead: getEmptyStringPropsDef(),
    officeImage: getEmptyStringPropsDef(),
    officeBrandColor: getEmptyStringPropsDef(),
    id: getEmptyStringPropsDef(),
  },
  data() {
    return {};
  },
  methods: {
    goToAgent() {
      this.$router.push({ path: `/agent/${this.id}` });
    },
    showCopyPhone() {
      this.$emit("show-phone-view");
    },
    showSendEmail() {
      this.$emit("show-email-view");
    },
  },
};
</script>

<style scoped lang="scss">
.agent-card-wrapper {
  position: relative;
  width: 300px;
  height: 380px;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  padding: 20px;
  overflow: hidden;

  .agent-office-back {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 74px;
    z-index: -1;
    .agent-office-image {
      position: absolute;
      width: 142px;
      height: 42px;
      left: auto;
      right: 15px;
      top: 16px;
      z-index: 2;
    }
  }

  .agent-card-portrait {
    text-align: left;
    height: 120px;
    .agent-head {
      width: 108px;
      height: 108px;
      border-radius: 50%;
      background-color: #f0f0f0;
    }
  }
  .agent-info {
    text-align: left;
    padding: 15px 0;
  }
  .agent-btns {
    .agent-profile {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 45px;
      font-weight: 800;
      box-sizing: border-box;
      cursor: pointer;
    }
    .agent-entity {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      .agent-equlity {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 45px;
        border-radius: 45px;
        background: #158c79;
        color: #ffffff;
        cursor: pointer;
      }
      .agent-phone {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 45px;
        height: 45px;
        border-radius: 45px;
        box-sizing: border-box;
        border: 2px solid #158c79;
        background: #158c7933;
        .agent-phone-icon {
          width: 28px;
          height: 28px;
          cursor: pointer;
        }
      }
    }
  }
}

.agent-card-wrapper:hover {
  // 慢慢放大
  transform: scale(1.02);
}
</style>