<template>
  <div id="app">
    <nav-menu v-if="!noTop"></nav-menu>
    <keep-alive>
      <router-view class="contain-view" />
    </keep-alive>
  </div>
</template>
<script>
import navMenu from "./components/navMenu.vue";
import { setTouchId } from "./utils/auth.js";
export default {
  data() {
    return {
      noTop: false,
    };
  },
  components: {
    navMenu,
  },
  mounted() {
    document
      .querySelector("body")
      .setAttribute("style", "background-color: #F6FAFB;");
    // 查询query参数
    let query = this.$route.query;
    if (query && query.noTop) {
      this.noTop = true;
    }
  },
  methods: {},
  created() {
    setTouchId();
  },
  beforeCreate() {
    let imgs = [
      require("./assets/ad1.jpg"),
      require("./assets/ad2.jpg"),
      require("./assets/ad3.jpg"),
      require("./assets/ad4.jpg"),
      require("./assets/ad5.jpg"),
      require("./assets/ad6.jpg"),
    ];
    for (let img of imgs) {
      let image = new Image();
      image.onload = () => {};
      image.src = img;
    }
  },
};
</script>
<style>
@font-face {
  font-family: "Public Sans";
  src: url("./assets/fonts/PublicSans-Regular-14.otf");
}

* {
  margin: 0;
  padding: 0;
  border: 0;
  font-family: Public Sans;
  scrollbar-width: thin;
}

::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  width: 100%;
  color: #2c3e50;
  margin: 0px auto;
}

.contain-view {
  padding-top: 56px;
}
</style>
