<template>
  <div class="property-information">
    <div class="property-information__row">
      <div class="property-information__row-title">
        <span>Area</span>
      </div>
      <div class="property-information__row-content">
        <span>{{ parseInt(area) }}m²</span>
      </div>
    </div>
    <div v-if="rooms" class="property-information__row">
      <div class="property-information__row-title">
        <span>Rooms</span>
      </div>
      <div class="property-information__row-content">
        <span>{{ rooms }}</span>
      </div>
    </div>
    <div v-if="yearBuilt" class="property-information__row">
      <div class="property-information__row-title">
        <span>Year built</span>
      </div>
      <div class="property-information__row-content">
        <span>{{ yearBuilt }}</span>
      </div>
    </div>
    <div v-if="cladding" class="property-information__row">
      <div class="property-information__row-title">
        <span>Cladding</span>
      </div>
      <div class="property-information__row-content">
        <span>{{ cladding }}</span>
      </div>
    </div>
    <div v-if="construction" class="property-information__row">
      <div class="property-information__row-title">
        <span>Roof </span>
      </div>
      <div class="property-information__row-content">
        <span>{{ construction }}</span>
      </div>
    </div>
    <div v-if="parking" class="property-information__row">
      <div class="property-information__row-title">
        <span>Parking</span>
      </div>
      <div class="property-information__row-content">
        <span>{{ parking }}</span>
      </div>
    </div>
    <div v-if="propertyView" class="property-information__row">
      <div class="property-information__row-title">
        <span>Property View</span>
      </div>
      <div class="property-information__row-content">
        <span>{{ propertyView }}</span>
      </div>
    </div>
    <div v-if="pool" class="property-information__row">
      <div class="property-information__row-title">
        <span>Pool</span>
      </div>
      <div class="property-information__row-content">
        <span>{{ pool }}</span>
      </div>
    </div>
    <div v-if="motowayAccess" class="property-information__row">
      <div class="property-information__row-title">
        <span>Motoway access</span>
      </div>
      <div class="property-information__row-content">
        <span>{{ motowayAccess }}</span>
      </div>
    </div>
    <div v-if="cityDistance" class="property-information__row">
      <div class="property-information__row-title">
        <span>City Distance</span>
      </div>
      <div class="property-information__row-content">
        <span>{{ cityDistance }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import {
  getEmptyStringPropsDef,
  getNumberPropsDef,
} from "@/common/propsConfig";
export default {
  props: {
    area: getEmptyStringPropsDef(),
    rooms: getNumberPropsDef(),
    yearBuilt: getEmptyStringPropsDef(),
    cladding: getEmptyStringPropsDef(),
    construction: getEmptyStringPropsDef(),
    parking: getEmptyStringPropsDef(),
    propertyView: getEmptyStringPropsDef(),
    pool: getEmptyStringPropsDef(),
    motowayAccess: getEmptyStringPropsDef(),
    cityDistance: getEmptyStringPropsDef(),
  },
  data() {
    return {};
  },
  created() {
    console.log(this.area, this.rooms);
  },
};
</script>
<style lang="scss" scoped>
@import "./propertyInformation.scss";
</style>