<template>
	<div class="utilitesView">
		<div class="energyView">
			<div class="energyContent">
				<div class="titleT">
					Energy Comparison
				</div>
				<div class="titleSubT">
					Compare energy prices to check <br>
					whether you could save.
				</div>
				<div class="contentMenu">
					<div class="contentMenuItem">
						<img src="../../assets/utilities/Electricity.png" />
						<div class="text">
							Electricity
						</div>
					</div>
					<div class="contentMenuItem">
						<img src="../../assets/utilities/Gas.png" />
						<div class="text">
							Gas
						</div>
					</div>
					<div class="contentMenuItem">
						<img src="../../assets/utilities/Broadband.png" />
						<div class="text">
							Broadband
						</div>
					</div>
					<div class="contentMenuItem">
						<img src="../../assets/utilities/Mobile.png" />
						<div class="text">
							Mobile
						</div>
					</div>
					<div class="contentMenuItem">
						<img src="../../assets/utilities/TvPackages.png" />
						<div class="text">
							TV Packages
						</div>
					</div>
				</div>
				<div @click="showFreeType('Energy Comparison')" class="tryTreeBtn">
					Try Free
				</div>
			</div>
			<img class="energyImage" src="../../assets/utilities/energyIcon.png" />
		</div>
		<div style="background: #2ABDA8;" class="energyView">
			<img style="margin-left: 100px;margin-right: 0px;" class="energyImage" src="../../assets/utilities/finance.png" />
			<div class="energyContent">
				<div class="titleT">
					Finance Comparison
				</div>
				<div class="titleSubT">
					Help you find the right financial <br>
					products or services, saving time, <br>
					hassle and money.
				</div>
				<div class="contentMenu">
					<div class="contentMenuItem">
						<img src="../../assets/utilities/Loan.png" />
						<div class="text">
							Loan&Finance
						</div>
					</div>
					<div class="contentMenuItem">
						<img src="../../assets/utilities/Insurance.png" />
						<div class="text">
							Insurance
						</div>
					</div>
					<div class="contentMenuItem">
						<img src="../../assets/utilities/Inversting.png" />
						<div class="text">
							Investing
						</div>
					</div>
					<div class="contentMenuItem">
						<img src="../../assets/utilities/creditCard.png" />
						<div class="text">
							Credit Cards
						</div>
					</div>
				</div>
				<div @click="showFreeType('Finance Comparison')" style="background-color: #FFFFFF;color: #02927E;" class="tryTreeBtn">
					Try Free
				</div>
			</div>
		</div>
		<div class="energyView">
			<div class="energyContent">
				<div class="titleT">
					Householder Service Comparison
				</div>
				<div class="titleSubT">
					Give you comprehensive, objective<br>
					and free information to help you<br>
					make the best decision.
				</div>
				<div class="contentMenu">
					<div class="contentMenuItem">
						<img src="../../assets/utilities/Agents.png" />
						<div class="text">
							Agents
						</div>
					</div>
					<div class="contentMenuItem">
						<img src="../../assets/utilities/Lawler.png" />
						<div class="text">
							Lawler
						</div>
					</div>
					<div class="contentMenuItem">
						<img src="../../assets/utilities/propertyM.png" />
						<div class="text">
							Property<br>
							Management
						</div>
					</div>
					<div class="contentMenuItem">
						<img src="../../assets/utilities/accountants.png" />
						<div class="text">
							Accountants
						</div>
					</div>
					<div class="contentMenuItem">
						<img src="../../assets/utilities/otherservice.png" />
						<div class="text">
							Other service
						</div>
					</div>
				</div>
				<div @click="showFreeType('Householder Service Comparison')" class="tryTreeBtn">
					Try Free
				</div>
			</div>
			<img class="energyImage" src="../../assets/utilities/householder.png" />
		</div>
		<el-dialog
		:visible.sync="show"
		width="62.5%"
		center
		>
		<div class="popContent">
			<div style="text-align: left;color: #000000;font-size: 36px;">
				Please fill in your address
			</div>
			<div style="text-align: left;margin-top: 27px;color: #000000;font-size: 24px;">
				Each area has its unique service providers, and we're here to match<br>
				you with the perfect one. Let's find the best fit for you!
			</div>
			<div style="text-align: left;margin-top: 19px;color: #000000;font-size: 12px;">
				The information provided will only be used for check service purposes.
			</div>
			<div class="search-bar">
				<el-autocomplete
				ref="el_auto"
				placeholder="Search"
				size="big"
				v-model="searchKey"
				clearable class="proInput"
				prefix-icon="el-icon-search"
				:fetch-suggestions='querySearchAsync'
				@select="handleSelect"
				@clear="clearSelect"
				@keyup.enter.native="searchEvent"
				></el-autocomplete>
			</div>
			<div class="option-view-s">
				<el-button @click="show = false" style="font-weight: 600;color: #158C79;border-color: #158C79;width: 200px;margin-right: 145px;">
					Not Now
				</el-button>
				<el-button @click="startEvent()" style="width: 200px;font-weight: 600;" type="primary">
					Start
				</el-button>
			</div>
		</div>
		</el-dialog>
	</div>
</template>

<script>
	import { getHouseList } from '../../api/api.js'
	export default {
		data() {
			return {
				show: false,
				searchKey: '',
				type: ''
			}
		},
		methods: {
			showFreeType(type) {
				this.type = type
				this.show = true
			},
			startEvent() {
				this.show = false
				console.log(this.type)
				this.$router.push(`/utilitiesDetailView?type=${this.type}&searchKey=${this.searchKey}`)
			},
			querySearchAsync(queryString, cb) {
				if (queryString == '' || queryString == undefined || queryString == null) {
					cb([])
					return
				}
				var form = {}
				form.address = queryString
				form.pageIndex = 1
				form.pageSize = 10
				form.sale = 0//未售房源
				getHouseList(form).then( res=> {
					if (res.status == 200) {
						var list = []
						res.data.hoursePage.records.forEach( item=> {
							var searchItem = {}
							searchItem.value = item.fullAddress
							searchItem.addressId = item.addressId
							list.push(searchItem)
						})
						cb(list)
					}
				})
			},
			clearSelect() {
				this.$refs.el_auto.activated = true
			},
			handleSelect(val) {
				this.searchKey = val.value
			}
		}
	}
</script>

<style scoped lang="scss">
	.utilitesView {
		display: flex;
		flex-direction: column;
		margin-top: 56px;
		width: 1440px;
		margin: 0 auto;
	}
	.energyView {
		display: flex;
		flex-direction: row;
		background: #E1EDEB;
		height: 620px;
		width: 100%;
		align-items: center;
	}
	.energyImage {
		width: 450px;
		height: 450px;
		margin-left: auto;
		margin-right: 100px;
		border-radius: 90px;
	}
	.energyContent {
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin-left: 74px;
	}
	.titleT {
		color: #000;
		font-family: Inter;
		font-size: 30px;
		font-style: normal;
		font-weight: bold;
		line-height: 22px;
		text-align: left;
	}
	.titleSubT {
		color: #000;
		font-family: Rammetto One;
		font-size: 36px;
		font-style: normal;
		font-weight: 700;
		line-height: 50px;
		margin-top: 56px;
		text-align: left;
	}
	.contentMenu {
		display: flex;
		flex-direction: row;
		margin-top: 40px;
		justify-content: space-around;
	}
	.contentMenuItem {
		display: flex;
		flex-direction: column;
		align-items: center;
		img {
			width: 80px;
			height: 80px;
		}
		.text {
			color: #000;
			text-align: center;
			font-family: Inter;
			font-size: 18px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			margin-top: 8px;
		}
	}
	.tryTreeBtn {
		border-radius: 12px;
		background: #02927E;
		width: 200px;
		height: 60px;
		margin-top: 50px;
		color: #FFF;
		text-align: center;
		font-size: 24px;
		font-weight: 600;
		line-height: 60px;
		cursor: pointer;
	}
	.popContent {
		display: flex;
		flex-direction: column;
		width: 100%;
	}
	.search-bar {
		margin-top: 50px;
		width: 100%;
	}
	.proInput {
		width: 100%;
		border-radius: 8px;
		height: 60px;
	}
	.option-view-s {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		margin-top: 30px;
	}
</style>