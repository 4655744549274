<template>
  <div class="gallery-wrapper">
    <div class="gallery-bg-wrapper">
      <img class="gallery-bg" :src="require('../../assets/gallery-bg.jpg')" />
    </div>
    <div class="gallery-content-wrapper">
      <div class="gallery-top">
        <div class="gallery-top-module">
          <img
            class="gallery-top-logo"
            :src="require('../../assets/gallery-logo.png')"
          />
        </div>
        <div class="gallery-top-module" style="justify-content: flex-start">
          <p class="gallery-introduction">
            Property images need to be more than just beautiful, memorable, and
            creative. They have a very specific job: generate leads. Take a look
            through some of our photography projects that have done just that
            for a number of different clients.
          </p>
        </div>
      </div>
      <div class="gallery-video">
        <video
          class="gallery-video-content"
          controls
          src="https://oss-propercentre.oss-ap-southeast-1.aliyuncs.com/PropCentre%2B%E5%85%AC%E5%8F%B8%E4%BB%8B%E7%BB%8D%2B%E6%9C%89%E5%AD%97%E5%B9%95-%E5%B0%8F.mp4"
        ></video>
      </div>
      <div class="gallery-tabs">
        <div
          :class="
            galleryType === 'PHOTOGRAPHY'
              ? 'gallery-tab-item-active'
              : `gallery-tab-item`
          "
          @click="switchTab('PHOTOGRAPHY')"
        >
          <span>Photography</span>
        </div>
        <div
          :class="
            galleryType === 'VR'
              ? 'gallery-tab-item-active'
              : `gallery-tab-item`
          "
          @click="switchTab('VR')"
        >
          <span>VR</span>
        </div>
        <div
          :class="
            galleryType === 'VIDEO'
              ? 'gallery-tab-item-active'
              : `gallery-tab-item`
          "
          @click="switchTab('VIDEO')"
        >
          <span>Video</span>
        </div>
        <div
          :class="
            galleryType === 'FLOOR_PLAN'
              ? 'gallery-tab-item-active'
              : `gallery-tab-item`
          "
          @click="switchTab('FLOOR_PLAN')"
        >
          <span>Floor Plan</span>
        </div>
        <div
          :class="
            galleryType === 'DRONE_VISION'
              ? 'gallery-tab-item-active'
              : `gallery-tab-item`
          "
          @click="switchTab('DRONE_VISION')"
        >
          <span>Drone Vision</span>
        </div>
      </div>
      <div v-if="galleryType !== 'FLOOR_PLAN'" class="gallery-content-list">
        <div
          v-for="(photo, index) in photos"
          :key="photo.houseId"
          class="gallery-content-list-photo"
          :style="`background-image: url(${photo.url})`"
          @mouseover="showIntro(index)"
          @mouseleave="showIntro(-1)"
          @click="
            showDetail(
              photo.houseId,
              photo.address.region,
              photo.publishedDate,
              photo.vrVrl
            )
          "
        >
          <div v-if="introIndex === index" class="photo-intro">
            <div class="photo-district">
              <span>{{ photo.address.region }}</span>
            </div>
            <!-- <div class="photo-publish-time">
              <span>{{ formatTime(photo.publishedDate) }}</span>
            </div> -->
          </div>
        </div>
      </div>
      <div v-if="galleryType === 'FLOOR_PLAN'" class="gallery-content-list">
        <img
          v-for="(photo, index) in photos"
          :key="photo.houseId"
          :src="photo.url"
          class="gallery-content-list-photo-img"
          @mouseover="showIntro(index)"
          @mouseleave="showIntro(-1)"
        />
        <div v-if="introIndex === index" class="photo-intro">
          <div class="photo-district">
            <span>{{ photo.address.region }}</span>
          </div>
        </div>
      </div>
    </div>
    <div v-if="detailId" class="gallery-detail-list" @click="hideDetail">
      <div class="gallery-detail-list-content">
        <div class="gallery-detail-title">
          <img
            class="left-icon"
            :src="require('../../assets/left_icon.png')"
            @click="hideDetail"
          />
          <div class="gallery-detail-title-text">
            <span>{{ detailTitle }}</span>
          </div>
          <!-- <div class="gallery-detail-time-text">
            <span>{{ formatTime(detailTime) }}</span>
          </div> -->
        </div>
        <div class="gallery-detail-content">
          <div
            v-for="photo in detailPhotos"
            :key="photo"
            class="gallery-detail-photo"
            :style="`background-image: url(${photo})`"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getGalleryDetail, getGalleryList } from "@/api/api";

export default {
  name: "galleryView",
  data() {
    return {
      detailId: "",
      galleryType: "PHOTOGRAPHY",
      pageIndex: 1,
      pageSize: 50,
      photos: [],
      introIndex: -1,
      detailTitle: "",
      detailTime: "",
      detailPhotos: [],
    };
  },
  async mounted() {
    this.getGalleryList();
  },
  methods: {
    getGalleryList() {
      getGalleryList(this.galleryType, this.pageIndex, this.pageSize).then(
        (response) => {
          this.photos = response.data;
        }
      );
    },
    getGalleryDetail(id) {
      getGalleryDetail(id).then((response) => {
        this.detailPhotos = response.data;
      });
    },
    switchTab(t) {
      this.galleryType = t;
      this.getGalleryList();
    },
    showIntro(index) {
      this.introIndex = index;
    },
    formatTime(time) {
      return new Date(time).toLocaleDateString();
    },
    showDetail(id, title, time, vrUrl) {
      if (vrUrl?.trim()) {
        window.open(vrUrl);
        return;
      }
      this.detailId = id;
      this.detailTitle = title;
      this.detailTime = time;
      this.getGalleryDetail(id);
    },
    hideDetail() {
      this.detailId = "";
      this.detailTitle = "";
      this.detailTime = "";
      this.detailPhotos = [];
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./galleryView.scss";
</style>