const emailKey = 'email'
const tokenKey = 'token'
const touchId = 'touchId'
import Fingerprint2 from 'fingerprintjs2'
export function getTouchId() {
	return localStorage.getItem(touchId)
}

export function setTouchId() {
	Fingerprint2.get(function(components) {
		const values = components.map(function(component,index) {
			if (index === 0) { //把微信浏览器里UA的wifi或4G等网络替换成空,不然切换网络会ID不一样
			return component.value.replace(/\bNetType\/\w+\b/, '')
			}
			return component.value
		})
		const murmur = Fingerprint2.x64hash128(values.join(''), 31);
		localStorage.setItem(touchId,murmur)
	})
}

export function getEmail() {
  return localStorage.getItem(emailKey)
}

export function setEmail(email) {
  return localStorage.setItem(emailKey,email)
}

export function getToken() {
  return localStorage.getItem(tokenKey)
}

export function setToken(token) {
  return localStorage.setItem(tokenKey,token)
}

export function removeAllData() {
	localStorage.removeItem(tokenKey)
	localStorage.removeItem(emailKey)
}
