<template>
	<div class="sign-up-view">
		<div v-if="isLogin" class="login-view">
			<div class="title">Welcome Back</div>
			<div style="margin-top: 60px;" class="title_sub">Email</div>
			<el-input class="sign-input" placeholder="Enter Email" v-model="loginEmail"></el-input>
<!-- 			<div style="margin-top: 14px;" class="title_sub">Code</div>
			<div class="sign-send-view">
				<el-input class="sign-code-input" placeholder="Enter Code" v-model="loginCode"></el-input>
				<el-button type="primary" class="send-code-btn" @click="btnSend()">{{sendCodeBtnText}}</el-button>
			</div> -->
			<div style="margin-top: 14px;" class="title_sub">Password</div>
			<el-input show-password class="sign-input" placeholder="Enter Password" v-model="loginPassword"></el-input>
			<div @click="loginEvent" class="sign-up-btn">Log in with Email</div>
			<div style="margin-top: 45px;text-align: left;font-size: 14px;color: #636363;">
				By continuing, I agree to PropCentre’s <span style="color: #3F907F;" @click="showTerms = true">Terms & Conditions</span> and to us using your<br>
				information as per our <span @click="showPrivacy = true" style="color: #3F907F;">Privacy Policy</span>(incl. collection statement).
			</div>
		</div>
		<div v-else class="login-view">
			<div class="title">
				Create your PropCentre profile and let us help<br>
				you find the perfect property match
			</div>
			<div style="margin-top: 60px;" class="title_sub">Email</div>
			<el-input class="sign-input" placeholder="Enter Email" v-model="registerEmail"></el-input>
			<div style="margin-top: 14px;" class="title_sub">Code</div>
			<div class="sign-send-view">
				<el-input class="sign-code-input" placeholder="Enter Code" v-model="registerCode"></el-input>
				<el-button type="primary" class="send-code-btn" @click="btnSend()">{{sendCodeBtnText}}</el-button>
			</div>
			<div style="margin-top: 14px;" class="title_sub">Password</div>
			<el-input show-password class="sign-input" placeholder="Enter Password" v-model="registerPassword"></el-input>
			<div @click="registerEvent" class="sign-up-btn">Continue</div>
			<div style="margin-top: 45px;text-align: left;font-size: 14px;color: #636363;">
				By continuing, I agree to PropCentre’s <span style="color: #3F907F;" @click="showTerms = true">Terms & Conditions</span> and to us using your<br>
				information as per our <span @click="showPrivacy = true" style="color: #3F907F;">Privacy Policy</span>(incl. collection statement).
			</div>
		</div>
		<div class="sign-up-bottom">
			<div @click="goToBack" class="cancel-btn">Cancel</div>
			<div @click="isLogin = !isLogin" class="change-btn">{{ bottomMessage() }}<span style="color: #3F907F;">{{ isLogin ? 'Sign up' : 'Log In' }}</span></div>
		</div>
		<privacy-policy :isShow="showPrivacy" @dissmiss='showPrivacyEvent'></privacy-policy>
		<terms-conditions :isShow="showTerms" @dissmiss='showTermsEvent'></terms-conditions>
	</div>
</template>

<script>
	import { login, register, getCode } from "../api/api.js"
	import privacyPolicy from "../components/privacyPolicy.vue"
	import termsConditions from "../components/termsConditions.vue"
	export default {
		data() {
			return {
				isLogin: true,
				loginEmail: '',
				loginPassword: '',
				registerEmail: '',
				registerPassword: '',
				showPrivacy: false,
				showTerms: false,
				registerCode: '',
				// loginCode: '',
				sendCodeBtnText: 'Get Code',
				disabled: false,
			}
		},
		components: {
			privacyPolicy,
			termsConditions
		},
		activated() {
			this.scrollToTop()
		},
		methods: {
			doLoop (seconds) {
				seconds = seconds || 60
				this.sendCodeBtnText = seconds + 's'
				let countdown = setInterval(() => {
					if (seconds > 0) {
						this.sendCodeBtnText = seconds + 's'
						--seconds
					} else {
						this.sendCodeBtnText = 'Get Code'
						this.disabled = false
						clearInterval(countdown)
					}
				}, 1000)
			},
			btnSend() {
				if (this.disabled === true) {
					return
				}
				if (this.isLogin) {
					if (this.loginEmail == '' || this.loginEmail == null || this.loginEmail == undefined) {
						this.$message.error('Please Enter Email！')
						return
					}
				}else {
					if (this.registerEmail == '' || this.registerEmail == null || this.registerEmail == undefined) {
						this.$message.error('Please Enter Email！')
						return
					}
				}
				this.disabled = true
				this.sendCodeBtnText = 'Waiting...'
				var data = {}
				data.email = this.loginEmail || this.registerEmail
				getCode(data).then( res=> {
					console.log(res)
					if (res.status == 200) {
						setTimeout(() => {
							this.doLoop(60)
						})
					}
				})
			},
			showPrivacyEvent() {
				this.showPrivacy = false
			},
			showTermsEvent() {
				this.showTerms = false
			},
			scrollToTop() {
				window.scrollTo(0, 0)
			},
			goToBack() {
				this.$router.go(-1)
			},
			bottomMessage() {
				return this.isLogin ? "Don't have an account yet? " : "Aleady have an accound? "
			},
			loginEvent() {
				if (this.loginEmail == '' || this.loginEmail == null || this.loginEmail == undefined) {
					this.$message.error('Please Enter Email')
					return
				}
				if (this.isEmail(this.loginEmail) == false) {
					this.$message.error('Please enter the correct email address!')
					return
				}
				// if (this.loginCode == '' || this.loginCode == null || this.loginCode == undefined) {
				// 	this.$message.error('Please Enter Code')
				// 	return
				// }
				if (this.loginPassword == '' || this.loginPassword == null || this.loginPassword == undefined) {
					this.$message.error('Please Enter Password')
					return
				}
				var data = {}
				data.email = this.loginEmail
				data.password = this.loginPassword
				// data.code = this.loginCode
				login(data).then( res=> {
					if (res.status == 200) {
						if (res.data.token) {
							this.$message({
								message: 'Login succeeded!',
								type: 'success'
							})
							this.$store.dispatch('user/setIsLogin',true)
							this.$store.dispatch('user/setToken',res.data.token)
							this.$store.dispatch('user/setEmail',this.loginEmail)
							this.$router.replace('/')
						}else {
							this.$message.error('Account not registered!')
						}
					}
				})
			},
			isEmail(email) {
				var emailReg = /^\w{3,}(\.\w+)*@[A-z0-9]+(\.[A-z]{2,5}){1,2}$/
				return emailReg.test(email)
			},
			registerEvent() {
				if (this.registerEmail == '' || this.registerEmail == null || this.registerEmail == undefined) {
					this.$message.error('Please Enter Email')
					return
				}
				if (this.isEmail(this.registerEmail) == false) {
					this.$message.error('Please enter the correct email address!')
					return
				}
				if (this.registerCode == '' || this.registerCode == null || this.registerCode == undefined) {
					this.$message.error('Please Enter Code')
					return
				}
				if (this.registerPassword == '' || this.registerPassword == null || this.registerPassword == undefined) {
					this.$message.error('Please Enter Password')
					return
				}
				var data = {}
				data.email = this.registerEmail
				data.password = this.registerPassword
				data.code = this.registerCode
				register(data).then( res=> {
					if (res.status == 200) {
						this.loginEmail = this.registerEmail
						this.loginPassword = this.registerPassword
						this.loginCode = ''
						this.registerCode = ''
						this.sendCodeBtnText = 'Get Code'
						this.disabled = false
						this.isLogin = true
						this.$message({
							message: 'Register succeeded!',
							type: 'success'
						})
					}
				})
			}
		}
	}
</script>

<style scoped lang="scss">
	.sign-up-view {
		display: flex;
		flex-direction: column;
		background-color: #fff;
		width: 100%;
		align-items: center;
		height: calc(100vh - 80px);
	}
	.login-view {
		display: flex;
		flex-direction: column;
		width: 100%;
		align-items: center;
		margin-top: 10px;
		background-color: #fff;
	}
	.sign-up-bottom {
		height: 56px;
		width: 100%;
		background: #FFFFFF;
		box-shadow: 0px 0px 2px 0px rgba(94, 93, 93, 0.25);
		position: fixed;
		top: auto;
		bottom: 0px;
		z-index: 998;
		display: flex;
		flex-direction: row;
		align-items: center;
	}
	.title {
		color: #636363;
		font-size: 28px;
		font-weight: 700;
		text-align: center;
		margin-top: 20px;
		line-height: 36px;
	}
	.title_sub {
		width: 514px;
		text-align: left;
		padding: 14px 6px;
	}
	.sign-input {
		width: 514px;
	}
	.sign-send-view {
		width: 514px;
		display: flex;
		flex-direction: row;
		align-items: center;
	}
	.sign-code-input {
		width: 400px;
	}
	.send-code-btn {
		width: 100px;
		margin-left: 14px;
		height: 50px;
	}
	/deep/.el-input__inner {
		height: 50px;
	}
	.sign-up-btn {
		margin-top: 50px;
		width: 514px;
		background-color: #54BCA9;
		height: 50px;
		border-radius: 5px;
		color: #fff;
		text-align: center;
		line-height: 50px;
	}
	.cancel-btn {
		width: 50%;
		height: 80px;
		line-height: 80px;
		text-align: center;
		color: #7A7A7A;
		font-size: 18px;
		font-weight: 700;
	}
	.change-btn {
		width: 50%;
		height: 80px;
		line-height: 80px;
		text-align: center;
		color: #7A7A7A;
		font-size: 18px;
		font-weight: 700;
	}
</style>