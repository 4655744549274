<template>
  <div class="home">
	<div id="contact-us-home-view" class="contact-us-home-view">
		<div class="contact-us-home-view-flag"></div>
		<div class="contact-us-left-view">
			<div class="big-title">{{ $t('aboutus.CompanyIntroduction') }}</div>
			<div class="sub-des">
				{{ $t('aboutus.cTextLineF') }}<br/>
				<br/>
				{{ $t('aboutus.cTextLineS') }}
			</div>
		</div>
		<img class="contact-us-right-img" src="../assets/ad_house.png"/>
	</div>
	<div id="property-view" class="property-view">
		<img class="property-img-left" src="../assets/about_b.png"/>
		<img class="property-img-right" src="../assets/about_r.png"/>
		<div class="property-info">
			<div style="margin-left: 125px;" class="big-s-title">
				{{ $t('aboutus.Functions') }}
			</div>
			<div style="margin-left: 125px;margin-top: 24px;" class="small-title">
				{{ $t('aboutus.fTextLineTF') }}
			</div>
			<div style="margin-left: 125px;margin-top: 8px;width: calc(65% - 10px);" class="sub-des">
				{{ $t('aboutus.fTextLineTFT') }}
			</div>
			<div style="margin-left: 176px;margin-top: 40px;" class="small-title">
				{{ $t('aboutus.fTextLineTS') }}
			</div>
			<div style="margin-left: 176px;margin-top: 8px;width: calc(65% - 41px);" class="sub-des">
				{{ $t('aboutus.fTextLineTST') }}
			</div>
			<div style="margin-left: 249px;margin-top: 40px;" class="small-title">
				{{ $t('aboutus.fTextLineTT') }}
			</div>
			<div style="margin-left: 249px;margin-top: 8px;width: calc(65% - 124px);" class="sub-des">
				{{ $t('aboutus.fTextLineTTT') }}<br/>
				{{ $t('aboutus.gnfxzj') }}
			</div>
		</div>
	</div>
	<div id="propert-listing-service" class="propert-listing-service">
		<div class="p-head-back"></div>
		<div class="p-middle-view">
			<div class="big-s-title">
				{{ $t('aboutus.szyxfw') }}
			</div>
			<div style="margin-top: 80px;" class="small-title">
				{{ $t('aboutus.szyxfw1') }}
			</div>
			<div style="margin-top: 8px;" class="sub-des">
				{{ $t('aboutus.szyxfw1T') }}
			</div>
			<div style="margin-top: 80px;" class="small-title">
				{{ $t('aboutus.szyxfw2') }}
			</div>
			<div style="margin-top: 8px;" class="sub-des">
				{{ $t('aboutus.szyxfw2T') }}
			</div>
			<div style="margin-top: 190px;" class="big-s-title">
				{{ $t('aboutus.MultiPlatformAd') }}
			</div>
			<div style="margin-top: 80px;" class="small-title">
				{{ $t('aboutus.NZAndOversea') }}
			</div>
			<div style="margin-top: 8px;" class="sub-des">
				{{ $t('aboutus.NZContent') }}
			</div>
			<div style="margin-top: 80px;" class="small-title">
				{{ $t('aboutus.TechnicalD') }}
			</div>
			<div style="margin-top: 8px;" class="sub-des">
				{{ $t('aboutus.TechnicalDC') }}
			</div>
			<div style="margin-top: 190px;" class="big-s-title">
				{{ $t('aboutus.OurMission') }}
			</div>
			<div style="margin-top: 80px;" class="sub-des">
				{{ $t('aboutus.OurMission1') }}<br/>
				{{ $t('aboutus.OurMission2') }}<br/>
				{{ $t('aboutus.OurMission3') }}
			</div>
		</div>
		<div @click="show = true" style="cursor: pointer;margin-top: 144px;width: 180px;height:45px;background: #01947E;border-radius: 8px;font-weight: 700;font-size: 18px;line-height: 45px;color: #FFFFFF;">{{ $t('aboutus.ContactUs') }}</div>
	</div>
	<el-dialog
	:visible.sync="show"
	width="40%"
	center>
	<span style="color: #333333;font-size: 20px;font-weight: 600;">&ensp;&ensp;&ensp;&ensp;Email:&ensp;&ensp;</span>
	<span style="color: #333333;font-size: 20px;">info@propcentre.co.nz</span>
	<br><br>
	<span style="color: #333333;font-size: 20px;font-weight: 600;">TelePhone:&ensp;&ensp;</span>
	<span style="color: #333333;font-size: 20px;">09 600 6550</span>
	</el-dialog>
  </div>
</template>

<script>
export default {
	name: 'HomeView',
	data() {
		return {
			show: false
		}
	},
	activated() {
		this.scrollToTop()
	},
	methods: {
		scrollToTop() {
			window.scrollTo(0, 0)
		},
	}
}
</script>

<style scoped>
	.home {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-bottom: 195px;
		background-color: #FFFFFF;
		margin: 0px auto;
	}
	.nav-view {
		display: flex;
		flex-direction: row;
		align-items: center;
		height: 107px;
		width: 100%;
		position: fixed;
		background-color: #fff;
		z-index: 999;
	}
	.nav-logo {
		width: 151px;
		height: 33px;
		margin-left: 130px;
	}
	.nav-active {
		color: #01947E;
		font-size: 20px;
		font-weight: 400;
		line-height: 24px;
		margin-left: 34px;
	}
	.nav-normal {
		color: #949494;
		font-size: 20px;
		font-weight: 400;
		line-height: 24px;
		margin-left: 34px;
	}
	.contact-us-view-normal {
		margin-left: auto;
		margin-right: 0px;
		height: 107px;
		width: 520px;
		background-color: #01947E;
		display: flex;
		flex-direction: row-reverse;
		align-items: center;
	}
	.contact-us-p-normal {
		color: #fff;
		margin-right: 130px;
		font-size: 20px;
		font-weight: 700;
		line-height: 24px;
	}
	.contact-us-view-native {
		margin-left: auto;
		margin-right: 0px;
		height: 107px;
		width: 520px;
		background-color: #fff;
		display: flex;
		flex-direction: row-reverse;
		align-items: center;
	}
	.contact-us-p-native {
		color: #01947E;
		margin-right: 130px;
		font-size: 20px;
		font-weight: 700;
		line-height: 24px;
	}
	.contact-us-home-view {
		height: 812px;
		width: 100%;
		display: flex;
		flex-direction: row;
		position: relative;
		background-color: #fff;
		margin-top: 0px;
		z-index: 1;
		align-items: center;
		justify-content: center;
	}
	.contact-us-left-view {
		display: flex;
		flex-direction: column;
		width: 650px;
		align-items: center;
		justify-content: center;
	}
	.contact-us-right-img {
		width: 496px;
		height: 482px;
		margin-left: 42px;
	}
	.big-title {
		color: #01947E;
		font-family: Inter;
		font-size: 50px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
	}
	.sub-des {
		color: #949494;
		font-family: Inter;
		font-size: 20px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		margin-top: 60px;
		text-align: left;
	}
	.contact-us-home-view-flag {
		position: absolute;
		z-index: -1;
		background-color: #01947E;
		width: 415px;
		height: 812px;
		float: right;
		right: 0;
		top: 0;
	}
	.left_info {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 110px;
		margin-left: 130px;
	}
	.left_title {
		font-weight: 700;
		font-size: 60px;
		line-height: 73px;
		color: #01947E;
		text-align: left;
	}
	.left_sub_title {
		margin-top: 30px;
		margin-left: 8px;
		font-weight: 400;
		font-size: 30px;
		line-height: 36px;
		color: #949494;
		text-align: left;
	}
	.contact-us-btn {
		background-color: #01947E;
		text-align: center;
		font-weight: 700;
		font-size: 26px;
		line-height: 80px;
		color: #FFFFFF;
		height: 80px;
		width: 358px;
		margin-top: 54px;
		cursor: pointer;
	}
	.ad-img {
		margin-top: 73px;
		margin-left: 34px;
		height: 629px;
		width: 647px;
	}
	.property-view {
		height: 942px;
		width: 100%;
		display: flex;
		flex-direction: row;
		position: relative;
		background-color: #fff;
		margin-top: 0px;
		z-index: 1;
	}
	.property-img-left {
		position: absolute;
		top: auto;
		bottom: 0;
		left: 0px;
		width: 206px;
		height: 587px;
		z-index: -1;
	}
	.property-img-right {
		position: absolute;
		float: right;
		top: 0px;
		right: 0px;
		width: 415px;
		height: 942px;
		z-index: -1;
	}
	.property-right-img {
		margin-top: 253px;
		margin-left: 108px;
		width: 260px;
		height: 484px;
	}
	.property-info {
		display: flex;
		flex-direction: column;
		margin-top: 62px;
	}
	.propert-listing-service {
		width: 100%;
		background-color: #fff;
		display: flex;
		flex-direction: column;
		align-items: center;
		z-index: 1;
	}
	.p-head-back {
		height: 140px;
		width: 100%;
		background: url('../assets/headImageBack.png');
	}
	.propert-listing-service-back {
		position: absolute;
		z-index: -1;
		top: 0px;
		left: 0px;
		width: 1440px;
		height: 183px;
		background: linear-gradient(180deg, rgba(230, 244, 242, 0) 29.95%, #E6F4F2 100%);
		transform: matrix(1, 0, 0, -1, 0, 0);
	}
	.section-nav {
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-top: 158px;
		justify-content: center;
	}
	.property-listing-content {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}
	.display-adv-view {
		height: 766px;
		width: 100%;
		display: flex;
		flex-direction: column;
		background-color: #fff;
	}
	.display-adv-view-content {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}
	.display-img-icon {
		width: 555px;
		height: 312px;
		margin-top: 176px;
		margin-left: 106px;
	}
	.business-partners-view {
		height: 783px;
		width: 100%;
		display: flex;
		flex-direction: column;
		background-color: #fff;
	}
	.gap_view {
		height: 124px;
		width: 1440px;
		background: linear-gradient(180deg, rgba(230, 244, 242, 0) 29.95%, #E6F4F2 100%);
	}
	.why_prop_view {
		display: flex;
		flex-direction: column;
		align-items: center;
		background-color: #fff;
		height: 700px;
		z-index: 1;
		position: relative;
		width: 100%;
	}
	.why_prop_view_back {
		position: absolute;
		z-index: -1;
		background: linear-gradient(180deg, #E6F4F2 0%, rgba(230, 244, 242, 0) 100%);
		height: 682px;
		width: 100%;
	}
	.bottom-view {
		display: flex;
		flex-direction: column;
		height: 1330px;
		width: 100%;
		background-color: #fff;
	}
	.bottom-row-view {
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-left: 178px;
		float: left;
	}
	.big-s-title {
		color: #01947E;
		font-family: Inter;
		font-size: 40px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
		text-align: left;
	}
	.small-title {
		color: #01947E;
		font-family: Inter;
		font-size: 30px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
		text-align: left;
	}
	.p-middle-view {
		width: calc(100% - 412px);
		display: flex;
		flex-direction: column;
	}
</style>
