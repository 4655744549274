<template>
  <div class="contact-agent-view">
    <div class="agent-info-view">
      <img v-if="agent.image"
        class="agent-head"
        :src="headUrl(agent.image['base-url'])"
      />
      <div class="agent-name-phone">
        <div class="agent-name">
          {{ agent.firstName + " " + agent.lastName }}
        </div>
        <div class="agent-phone-view">
          <!-- <div class="agent-phone-title">Phone number：</div> -->
          <img src="../assets/phone-black.png" class="agent-icon" />
          <div class="agent-phone">{{ agent.phoneMobile }}</div>
        </div>
      </div>
      <div v-if="agent.officeMap"
        @click="goToOffice(agent.officeMap.id)"
        class="logo-view"
        :style="{
          backgroundColor:
            agent.officeBrandHexCode || agent.officeMap?.brandHexCode,
        }"
      >
        <img v-if="agent.officeMap && agent.officeMap.images && agent.officeMap.images.length > 0"
          class="logo-img"
          :src="officeUrl(agent.officeImage || agent.officeMap.images[0]['base-url'])"
        />
      </div>
    </div>
    <div class="send-email-view">
      <div class="send-email-left-view">
        <div class="section-title">
          <div class="section-small-title">
            First Name<span style="color: #637381">*</span>
          </div>
        </div>
        <el-input
          v-model="form.firstName"
          size="large"
          class="section-input"
          style="height: 50px; border-radius: 8px"
        ></el-input>
        <div class="section-title">
          <div class="section-small-title">
            Email<span style="color: #637381">*</span>
          </div>
        </div>
        <el-input
          v-model="form.email"
          size="large"
          class="section-input"
          style="height: 50px; border-radius: 8px"
        ></el-input>
        <div class="section-title">
          <div class="section-small-title">Phone</div>
        </div>
        <el-input
          v-model="form.phoneNum"
          size="large"
          class="section-input"
          style="height: 50px; border-radius: 8px"
        ></el-input>
      </div>
      <div class="send-email-right-view">
        <div class="section-title">
          <div class="section-small-title">Message</div>
        </div>
        <el-input
          v-model="form.message"
          placeholder="I'm interested in 15 Woven Place, Drury (ref. 850817) and I would like more information please."
          :autosize="{ minRows: 6, maxRows: 6 }"
          type="textarea"
          class="section-input"
        ></el-input>
        <div style="height: 16px"></div>
        <el-button
          :loading="sending"
          @click="sendEmail"
          size="large"
          :style="{
            width: '100%',
            height: '50px',
            borderRadius: '8px',
            backgroundColor: '#212B36',
            color: '#FFF',
            fontWeight: 600,
          }"
          :disabled="!(form.email && form.firstName)"
          >Submit Now</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import {
  getEmptyStringPropsDef,
  getObjectPropsDef,
} from "@/common/propsConfig.js";
import { mapGetters } from "vuex";
import { contactAgent } from "../api/api.js";
export default {
  props: {
    agent: getObjectPropsDef(),
    imageServerDomain: getEmptyStringPropsDef(),
  },
  computed: {
    ...mapGetters("user", {
      email: "email",
    }), 
  },
  watch: {
    email: {
      handler: function () {
        if (this.email != "" && this.email != null && this.email != undefined) {
          this.refreshName();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.refreshName();
  },
  data() {
    return {
      form: {
        email: "",
        firstName: "",
        message: "",
        phoneNum: "",
      },
      sending: false,
    };
  },
  methods: {
	headUrl: function (baseUrl) {
	  if (baseUrl) {
	    if (baseUrl.indexOf("http") != -1) {
	      return baseUrl + "?x-oss-process=image/resize,m_fill,h_200,w_200";
	    } else {
	      return (
	        this.imageServerDomain +
	        baseUrl +
	        ".crop.200x200.jpg"
	      ); //750*750
	    }
	  } else {
	    return "";
	  }
	},
	officeUrl: function (baseUrl) {
		if (baseUrl) {
		  if (baseUrl.indexOf("http") != -1) {
		    return baseUrl;
		  } else {
		    return (
		      this.imageServerDomain +
		      baseUrl +
		      ".crop.400x110.jpg"
		    ); //750*750
		  }
		} else {
		  return "";
		}
	},
    goToOffice(id) {
      this.$router.push(`/office/${id}`);
    },
    refreshName() {
      if (this.email != "" && this.email != null && this.email != undefined) {
        this.form.firstName = this.email.substring(0, this.email.indexOf("@"));
      }
    },
    isEmail(email) {
      var emailReg = /^\w{3,}(\.\w+)*@[A-z0-9]+(\.[A-z]{2,5}){1,2}$/;
      return emailReg.test(email);
    },
    sendEmail() {
	  console.log(this.agent)
      if (this.isEmail(this.form.email) == false) {
        this.$message.error("Please enter the correct email address!");
        return;
      }
      this.sending = true;
      contactAgent(this.form).then((res) => {
        if (res.status == 200) {
          this.$message({
            message:
              "We’ve sent your enquiry to the agent. The agent will be in touch soon.",
            type: "success",
          });
        }
        this.sending = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.contact-agent-view {
  width: 1260px;
  border-radius: 8px;
  border: #e7e7e7 1px solid;
  margin-top: 20px;
  padding-bottom: 5px;
}
.agent-info-view {
  margin-top: 20px;
  margin-left: 20px;
  width: calc(100% - 40px);
  height: 106px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.agent-head {
  width: 106px;
  height: 106px;
  border-radius: 50%;
  border: #ececec 1px solid;
}
.agent-name-phone {
  display: flex;
  flex-direction: column;
  margin-left: 15px;
}
.agent-name {
  color: #000000;
  font-size: 18px;
  font-weight: 800;
  text-align: left;
}
.agent-phone-view {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 15px;
}
.agent-phone-title {
  color: #000000;
  font-size: 18px;
  text-align: left;
}
.agent-icon {
  width: 20px;
  height: 20px;
}
.agent-phone {
  color: #212b36;
  font-size: 18px;
  text-align: left;
  margin-left: 15px;
}
.logo-view {
  width: 223px;
  height: 77px;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
  margin-left: auto;
  margin-right: 0px;
  cursor: pointer;
  z-index: 9;
}
.logo-img {
  width: 200px;
  height: auto;
}
.send-email-view {
  margin-top: 20px;
  margin-left: 20px;
  width: calc(100% - 40px);
  display: flex;
  flex-direction: row;
}
.send-email-left-view {
  display: flex;
  flex-direction: column;
  width: calc(50% - 40px);
}
.send-email-right-view {
  width: 50%;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: 0px;
}

.section-title {
  width: 100%;
  position: relative;
  color: #000000;
  font-size: 18px;
  text-align: left;
  height: 15px;
  z-index: 1;
}
.section-small-title {
  position: absolute;
  top: 10px;
  left: 5px;
  z-index: 10;
  padding: 3px;
  background-color: #ffffff;
  color: #637381;
  font-size: 12px;
}
.section-input {
  width: 100%;
  margin-top: 5px;
  margin-bottom: 15px;
}
</style>

<style lang="scss">
.send-email-left-view {
  .el-input__inner {
    height: 50px;
    border-radius: 8px;
  }
}
</style>