<template>
  <div class="map-zoom">
    <div class="map-container" id="pro_map"></div>
    <div class="map-types">
      <div
        v-for="mapTypeItem in mapTypes"
        :key="mapTypeItem.desc"
        class="map-type"
        @click="swicthMapType(mapTypeItem.mapType)"
      >
        <div v-if="mapTypeItem.mapType === mapType" class="map-type-icon" />
        <img
          v-else
          class="map-type-icon"
          style="background: #ffffff"
          :src="mapTypeItem.icon"
          alt=""
        />
        <span>{{ mapTypeItem.desc }}</span>
      </div>
    </div>
    <div class="map-legends">
      <div
        v-for="legend in legends"
        :key="legend.desc"
        class="map-legend"
        @click="switchShowtype(legend)"
      >
        <img
          v-if="legend.icon && legend.isShow"
          class="map-legend-icon"
          style="background: #ffffff"
          :src="legend.icon"
          alt=""
        />
        <div
          v-else
          :class="
            legend.isShow ? 'map-legend-icon' : 'map-legend-icon-outlined'
          "
        />
        <span>{{ legend.desc }}</span>
      </div>
    </div>
    <!-- <div class="map-pipes">
      <div
        v-for="item in pipes"
        :key="item.desc"
        class="map-pipe"
        @click="switchLayer(item.name)"
      >
        <img
          v-if="item.icon"
          class="map-pipe-icon"
          style="background: #ffffff"
          :src="item.name === pipe ? item.iconSelected : item.icon"
          alt=""
        />
        <div v-else class="map-pipe-icon" />
        <span>{{ item.desc }}</span>
      </div>
    </div> -->
  </div>
</template>
<script>
import mapboxgl from "!mapbox-gl";
import { mapToken } from "@/common/map";
import {
  getArrayPropsDef,
  getEmptyStringPropsDef,
  getNumberPropsDef,
  getObjectPropsDef,
} from "@/common/propsConfig";
import bus from "@/utils/bus";

let mapRef,
  zoom = 10,
  currentSelected,
  currentMarkers = [];

const collectTypes = {
  SHOUTLISTED: "property-marker-shoutlisted",
  MAYBE: "property-marker-maybe",
  NOPE: "property-marker-nope",
  NONE: "property-marker",
};

const layerTypes = {
  def: "mapbox://styles/mapbox/streets-v12",
  sta: "mapbox://styles/dreamcatchernick/clipv2wfc009e01r1crygdvnt",
  wat: "mapbox://styles/dreamcatchernick/cliptw3fh000b01pzdfq9405k",
  sto: "mapbox://styles/dreamcatchernick/cliptxlrl000n01pw2hbidrl2",
  all: "mapbox://styles/dreamcatchernick/clcvrhi3y000514qost5v9r77",
  staWat: "mapbox://styles/dreamcatchernick/clipu0aj5009d01r1hsmgdwdu",
  staSto: "mapbox://styles/dreamcatchernick/cliptyolf003r01q46qsa456i",
  stellWithPipe: "mapbox://styles/dreamcatchernick/cli60udyd00pe01pgeok20sm0",
  emp: "mapbox://styles/mapbox/streets-v12",
  district: "mapbox://styles/dreamcatchernick/clmbn6fdm018101pjfoplc58u",
};

const layers = {
  def: {
    nor: "mapbox://styles/mapbox/streets-v12",
    wat: "mapbox://styles/dreamcatchernick/cliptw3fh000b01pzdfq9405k",
    sto: "mapbox://styles/dreamcatchernick/cliptxlrl000n01pw2hbidrl2",
  },
  sta: {
    nor: "mapbox://styles/dreamcatchernick/clipv2wfc009e01r1crygdvnt",
    wat: "mapbox://styles/dreamcatchernick/clipu0aj5009d01r1hsmgdwdu",
    sto: "mapbox://styles/dreamcatchernick/cliptyolf003r01q46qsa456i",
  },
};

const makeDistrict = (lat, lng, name, num, callback) => {
  const el = document.createElement("div");
  const disName = document.createElement("span");
  disName.style.fontWeight = "800";
  const houseNum = document.createElement("span");
  disName.innerText = name;
  houseNum.innerText = Number(num) === 0 ? "---" : num + "k";
  el.appendChild(disName);
  el.appendChild(houseNum);
  el.className = "district-marker";
  el.lat = lat;
  el.lng = lng;
  el.addEventListener("click", () => {
    callback(el);
  });
  return el;
};

const makeSuburbs = (lat, lng, name, num, callback) => {
  const el = document.createElement("div");
  const disName = document.createElement("span");
  disName.style.fontWeight = "800";
  const houseNum = document.createElement("span");
  disName.innerText = name;
  houseNum.innerText = Number(num) === 0 ? "---" : num + "k";
  el.appendChild(disName);
  el.appendChild(houseNum);
  el.className = "suburbs-marker";
  el.lat = lat;
  el.lng = lng;
  el.addEventListener("click", () => {
    callback(el);
  });
  return el;
};

const makeMarker = (lat, lng, collectType, callback) => {
  collectType = collectType || "NONE";
  const el = document.createElement("div");
  el.className = collectTypes[collectType];
  el.lat = lat;
  el.lng = lng;
  el.addEventListener("click", () => {
    callback(el);
  });
  return el;
};

const hideHouseMarker = () => {
  document.querySelectorAll(".property-marker").forEach((el) => {
    el.style.display = "none";
  });
};

const showHouseMarker = () => {
  document.querySelectorAll(".property-marker").forEach((el) => {
    el.style.display = "";
  });
};

const clearDistricts = () => {
  document.querySelectorAll(".district-marker").forEach((el) => {
    el.remove();
  });
};

const clearSuburbs = () => {
  document.querySelectorAll(".suburbs-marker").forEach((el) => {
    el.remove();
  });
};

const getMarkerDetail = async (el, house, imageServerDomain) => {
  let imgIndex = 0;
  const photos = house.photos;
  const bottomEl = document.createElement("div");
  bottomEl.style.width = "100%";
  bottomEl.style.height = "30px";
  bottomEl.style.position = "absolute";
  bottomEl.style.bottom = "0";
  bottomEl.style.left = "0";
  bottomEl.style.display = "flex";
  bottomEl.style.alignItems = "flex-start";
  bottomEl.style.justifyContent = "space-between";
  const priceEl = document.createElement("span");
  priceEl.style.color = "#fff";
  priceEl.style.fontSize = "16px";
  priceEl.style.fontWeight = "800";
  priceEl.style.padding = "0 10px";
  priceEl.innerHTML = `${house.priceDisplay}`;
  bottomEl.appendChild(priceEl);
  const info = document.createElement("div");
  info.style.display = "flex";
  info.style.alignItems = "center";
  info.style.padding = "0 10px";

  const bedEl = document.createElement("span");
  bedEl.style.color = "#fff";
  bedEl.style.fontSize = "10px";
  bedEl.style.fontWeight = "400";
  bedEl.style.padding = "0 10px 0 3px";
  bedEl.innerHTML = `${house.bedroomCount}`;
  const bathEl = document.createElement("span");
  bathEl.style.color = "#fff";
  bathEl.style.fontSize = "10px";
  bathEl.style.fontWeight = "400";
  bathEl.style.padding = "0 10px 0 3px";
  bathEl.innerHTML = `${house.bathroomCount}`;
  const square = document.createElement("span");
  square.style.color = "#fff";
  square.style.fontSize = "10px";
  square.style.fontWeight = "400";
  square.style.padding = "0 10px 0 3px";
  square.innerHTML = `${house.floorArea}`;

  const imgBed = document.createElement("img");
  imgBed.src = require("@/assets/woshi-wt.png");
  imgBed.style.width = "12px";
  imgBed.style.height = "12px";
  const imgBath = document.createElement("img");
  imgBath.src = require("@/assets/yushi-wt.png");
  imgBath.style.width = "12px";
  imgBath.style.height = "12px";
  const imgSquare = document.createElement("img");
  imgSquare.src = require("@/assets/area-wt.png");
  imgSquare.style.width = "12px";
  imgSquare.style.height = "12px";

  info.appendChild(imgSquare);
  info.appendChild(square);
  info.appendChild(imgBed);
  info.appendChild(bedEl);
  info.appendChild(imgBath);
  info.appendChild(bathEl);
  bottomEl.appendChild(info);
  const titleEl = document.createElement("div");
  titleEl.style.width = "100%";
  titleEl.style.height = "30px";
  titleEl.style.position = "absolute";
  titleEl.style.bottom = "30px";
  titleEl.style.left = "0";
  titleEl.style.display = "flex";
  titleEl.style.alignItems = "center";
  titleEl.style.justifyContent = "flex-start";
  // 超出一行省略号
  titleEl.style.whiteSpace = "nowrap";
  titleEl.style.overflow = "hidden";
  titleEl.style.textOverflow = "ellipsis";
  titleEl.innerHTML = `<span style="color:#fff;font-size:16px;font-weight:400;padding:0 10px">${house.address?.displayAddress}</span>`;
  const backBlack = document.createElement("div");
  backBlack.style.width = "100%";
  backBlack.style.height = "60px";
  backBlack.style.position = "absolute";
  backBlack.style.bottom = "0";
  backBlack.style.left = "0";
  backBlack.style.background = "linear-gradient(180deg,rgba(0,0,0,0),#000)";
  backBlack.style.borderRadius = "10px";
  const arrowLeft = document.createElement("img");
  arrowLeft.src = require("@/assets/arrow-left.png");
  arrowLeft.style.width = "30px";
  arrowLeft.style.height = "30px";
  arrowLeft.style.position = "absolute";
  arrowLeft.style.bottom = "75px";
  arrowLeft.style.left = "10px";
  arrowLeft.style.cursor = "pointer";
  const arrowRight = document.createElement("img");
  arrowRight.src = require("@/assets/arrow-right.png");
  arrowRight.style.width = "30px";
  arrowRight.style.height = "30px";
  arrowRight.style.position = "absolute";
  arrowRight.style.bottom = "75px";
  arrowRight.style.right = "10px";
  arrowRight.style.cursor = "pointer";
  el.appendChild(arrowLeft);
  el.appendChild(arrowRight);
  el.appendChild(backBlack);
  el.appendChild(bottomEl);
  el.appendChild(titleEl);
  if (photos) {
    const imgUrl = photos[imgIndex]?.baseUrl;
    const backgroundImageUrl = `url(${imageServerDomain}/${imgUrl}.crop.580x400.jpg`;
    el.style.backgroundColor = "#ffffff99";
    el.style.backgroundImage = backgroundImageUrl;
    el.style.backgroundSize = "100% auto";
    el.style.backgroundRepeat = "no-repeat";
    el.style.backgroundPosition = "center center";
    el.addEventListener("click", (event) => {
      event.stopPropagation();
      window.location.href = `/#/property/${house.id}`;
    });
    arrowRight.addEventListener("click", (event) => {
      event.stopPropagation();
      imgIndex++;
      if (imgIndex >= photos.length) {
        imgIndex = 0;
      }
      const imgUrl = photos[imgIndex]?.baseUrl;
      el.style.backgroundImage = `url(${imageServerDomain}/${imgUrl}.crop.580x400.jpg`;
      el.style.backgroundSize = "100% auto";
      el.style.backgroundRepeat = "no-repeat";
      el.style.backgroundPosition = "center center";
    });
    arrowLeft.addEventListener("click", (event) => {
      event.stopPropagation();
      imgIndex--;
      if (imgIndex < 0) {
        imgIndex = photos.length - 1;
      }
      const imgUrl = photos[imgIndex]?.baseUrl;
      el.style.backgroundImage = `url(${imageServerDomain}/${imgUrl}.crop.580x400.jpg`;
      el.style.backgroundSize = "100% auto";
      el.style.backgroundRepeat = "no-repeat";
      el.style.backgroundPosition = "center center";
    });
  }
};

const makeHouseHolder = (house, imageServerDomain) => {
  // const wrapper = document.createElement("div");
  const el = document.createElement("div");
  el.style.width = "52px";
  el.style.height = "58.12px";
  el.lat = house.latitude;
  el.lng = house.longitude;
  // el.src = require("@/assets/houseLocate.png");
  const imgUrl = require("@/assets/houseLocate.png");
  el.style.backgroundImage = `url(${imgUrl})`;
  el.style.backgroundSize = "100% 100%";
  el.style.backgroundRepeat = "no-repeat";
  el.style.position = "relative";
  const brand = document.createElement("div");
  brand.style.width = "360px";
  brand.style.height = "180px";
  brand.style.position = "absolute";
  brand.style.top = "-200px";
  brand.style.left = "-154px";
  brand.style.borderRadius = "10px";
  el.appendChild(brand);
  getMarkerDetail(brand, house, imageServerDomain);
  return el;
};

const pointKeys = new Set();

export default {
  name: "proBigMap",
  props: {
    locationList: getArrayPropsDef(),
    houseList: getArrayPropsDef(),
    initLat: getNumberPropsDef(),
    initLng: getNumberPropsDef(),
    selectedPoint: getObjectPropsDef(),
    zoom: getNumberPropsDef(),
    coordinates: getArrayPropsDef(),
    imageServerDomain: getEmptyStringPropsDef(),
    districts: getArrayPropsDef(),
  },
  data() {
    return {
      pipe: "nor",
      selectIndex: 0,
      locations: [],
      layerType: "def",
      mapType: "def",
      showShoutedList: true,
      showMaybe: true,
      showNope: true,
      showDefaultShow: true,
    };
  },
  computed: {
    mapTypes() {
      return [
        {
          desc: this.$t("map.Map"),
          icon: require("@/assets/nor-selected.png"),
          iconSelected: require("@/assets/shoutlisted.png"),
          mapType: "def",
        },
        {
          desc: this.$t("map.Satellite"),
          icon: require("@/assets/nor-selected.png"),
          iconSelected: require("@/assets/shoutlisted.png"),
          mapType: "sta",
        },
      ];
    },
    legends() {
      return [
        {
          className: ".property-marker-shoutlisted",
          desc: this.$t("map.Shortlisted"),
          icon: require("@/assets/shoutlisted.png"),
          isShow: true,
        },
        {
          className: ".property-marker-maybe",
          desc: this.$t("map.Maybe"),
          icon: require("@/assets/maybe.png"),
          isShow: true,
        },
        {
          className: ".property-marker-nope",
          desc: this.$t("map.Nope"),
          icon: require("@/assets/Nope-or.png"),
          isShow: true,
        },
        {
          className: ".property-marke",
          desc: this.$t("map.ForSale"),
          icon: "",
          isShow: true,
        },
      ];
    },
    pipes() {
      return [
        {
          name: "sto",
          desc: this.$t("map.ShowStormPipe"),
          icon: require("@/assets/nor-selected.png"),
          iconSelected: require("@/assets/shoutlisted.png"),
          layerType: ["sto", "staSto"],
          sta: "staSto",
          map: "sto",
        },
        {
          name: "wat",
          desc: this.$t("map.ShowWastePipe"),
          icon: require("@/assets/nor-selected.png"),
          iconSelected: require("@/assets/shoutlisted.png"),
          layerType: ["wat", "staWat"],
          sta: "staWat",
          map: "wat",
        },
      ];
    },
  },
  watch: {
    districts() {
      this.drawDistrict();
    },
    selectedPoint(newVal) {
      this.selectPoint(newVal);
      mapRef.setCenter([newVal.longitude, newVal.latitude]);
    },
    initLat() {
      // this.initMap();
    },
    locationList(newVal) {
      // this.clearOldPoints();
      if (newVal.length > 0) {
        this.drawPoints(newVal);
        // this.selectPoint(newVal[0]);
      }
    },
  },
  mounted() {
    this.initMap();
    this.drawDistrict();
    bus.$on("collect", ({ id, collectType }) => {
      const marker = currentMarkers.find(
        (marker) => marker._element.propertyId === id
      );
      marker &&
        (marker._element.className = `mapboxgl-marker mapboxgl-marker-anchor-center ${collectTypes[collectType]}`);
    });
    bus.$on("uncollect", ({ id }) => {
      const marker = currentMarkers.find(
        (marker) => marker._element.propertyId === id
      );
      marker && (marker._element.className = collectTypes["NONE"]);
    });
  },
  methods: {
    initDistrict() {},
    /**
     * @desc 初始化地图
     */
    initMap() {
      /**
       * 初始化地图
       */
      mapboxgl.accessToken = mapToken;
      const map = new mapboxgl.Map({
        container: "pro_map", // container ID
        style: layerTypes["def"], // style URL
        center: [this.initLng, this.initLat], // starting position [lng, lat]
        zoom: this.zoom || zoom, // starting zoom
      });
      mapRef = map;
      window.map = map;
      mapRef.on("load", () => {
        this.drawPolygon();
      });
      /**
       * 监听地图事件
       */
      mapRef.on("dragend", () => {
        const center = map.getCenter();
        // currentSelected?.remove();
        this.$emit("moveend", center);
      });
      /**
       * 监听地图缩放
       */
      mapRef.on("zoomend", () => {
        const zoom = map.getZoom();
        this.$emit("zoomend", zoom);
        console.log(zoom, "zoom");
        if (zoom <= 12) {
          clearSuburbs();
          this.drawDistrict();
          currentSelected?.remove();
          return;
        }
        if (zoom <= 14) {
          clearDistricts();
          hideHouseMarker();
          this.drawSuburb();
          currentSelected?.remove();
          return;
        }
        clearSuburbs();
        showHouseMarker();
      });
    },
    /**
     * @desc 清理之前的标记
     */
    clearOldPoints() {
      currentMarkers.forEach((marker) => {
        marker.remove();
      });
      currentMarkers.length = 0;
    },
    /**
     * @desc 点击选中房源
     */
    selectPoint(location) {
      document.querySelectorAll(".img-selected").forEach((el) => {
        el.remove();
      });
      const { latitude, longitude } = location;
      const houseHolder = makeHouseHolder(location, this.imageServerDomain);
      setTimeout(() => {
        currentSelected = new mapboxgl.Marker({
          element: houseHolder,
          draggable: false,
        })
          .setLngLat([longitude, latitude])
          .addTo(mapRef);
        currentSelected.setOffset([0, -26]);
        currentSelected._element.style.zIndex = 1000;
        currentSelected._element.className += " img-selected";
        this.$emit("choose-property", location);
      }, 0);
    },
    /**
     * @desc 切换地图类型
     */
    swicthMapType(mapType) {
      this.mapType = mapType || "def";
      const layerType = layers[this.mapType][this.pipe];
      mapRef.setStyle(layerType);
      this.drawPolygon();
    },
    /**
     * @desc 绘制图层
     */
    switchLayer(pipe = "") {
      this.pipe = pipe || "nor";
      const layerType = layers[this.mapType][this.pipe];
      mapRef.setStyle(layerType);
      this.drawPolygon();
    },
    drawSuburb() {
      const suburbs = this.districts.reduce((prev, next) => {
        return [...prev, ...next.suburbs];
      }, []);
      suburbs.forEach((suburb) => {
        const { latitude, longitude, averagePrice, name } = suburb;
        const displayPrice =
          "CV Med. $" + Math.ceil((averagePrice || 0) / 1000);
        console.log(
          displayPrice,
          averagePrice,
          Math.ceil(averagePrice || 0 / 1000),
          "suburb " + name
        );
        const el = makeSuburbs(latitude, longitude, name, displayPrice, () => {
          clearSuburbs();
          mapRef.setCenter([longitude, latitude]);
          mapRef.setZoom(14.5);
          const center = mapRef.getCenter();
          this.$emit("moveend", center);
        });
        setTimeout(() => {
          const marker = new mapboxgl.Marker(el)
            .setLngLat([longitude, latitude])
            .addTo(mapRef);
          currentMarkers.push(marker);
        }, 0);
      });
    },
    drawDistrict() {
      this.districts.forEach((district) => {
        const { latitude, longitude, name, averagePrice } = district;
        console.log(district, "dis");
        const displayPrice =
          "CV Med. $" + Math.ceil((averagePrice || 0) / 1000);
        const el = makeDistrict(latitude, longitude, name, displayPrice, () => {
          clearDistricts();
          mapRef.setCenter([longitude, latitude]);
          mapRef.setZoom(12.5);
          this.drawSuburb();
        });
        setTimeout(() => {
          const marker = new mapboxgl.Marker(el)
            .setLngLat([longitude, latitude])
            .addTo(mapRef);
          currentMarkers.push(marker);
        }, 0);
      });
    },
    /**
     * @desc 绘制房源
     */
    drawPoints(locationList) {
      // 增量绘制
      locationList.forEach((location) => {
        const { latitude, longitude, collectType, id } = location;
        if (pointKeys.has(id)) {
          return;
        } else {
          pointKeys.add(id);
        }
        new Promise((resolve) => {
          const el = makeMarker(latitude, longitude, collectType, () => {
            this.selectPoint(location);
          });
          if (this.zoom <= 14) {
            el.style.display = "none";
          }
          el.propertyId = id;
          resolve(
            setTimeout(() => {
              const marker = new mapboxgl.Marker(el)
                .setLngLat([longitude, latitude])
                .addTo(mapRef);
              currentMarkers.push(marker);
            }, 0)
          );
        });
      });
      this.locations = locationList;
    },
    /**
     * @desc 切换图例
     */
    switchShowtype(lengend) {
      lengend.isShow = !lengend.isShow;
      this.legends = [...this.legends];
      const domList = document.querySelectorAll(lengend.className);
      if (lengend.isShow) {
        domList.forEach((dom) => (dom.style.display = ""));
      } else {
        domList.forEach((dom) => (dom.style.display = "none"));
      }
    },
    removePolygon() {
      try {
        mapRef.removeLayer("polygon-source");
        mapRef.removeLayer("outline");
        mapRef.removeSource("polygon-source");
      } catch (error) {
        console.log(error);
      }
    },
    /**
     * 绘制多边形
     */
    drawPolygon() {
      if (!this.coordinates.length) {
        return;
      }
      // this.removePolygon();
      const sourceId = `polygon-source_${Date.now()}`;
      mapRef.addSource(sourceId, {
        type: "geojson",
        data: {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: [this.coordinates],
          },
        },
      });
      const polygonId = `polygon_${Date.now()}`;
      mapRef.addLayer({
        id: polygonId,
        type: "fill",
        source: sourceId,
        layout: {},
        paint: {
          "fill-color": "#3F907F",
          "fill-opacity": 0.4,
        },
      });
      const ountlineId = `outline_${Date.now()}`;
      mapRef.addLayer({
        id: ountlineId,
        type: "line",
        source: sourceId,
        layout: {},
        paint: {
          "line-color": "#3F907F",
          "line-width": 2,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./map.scss";
</style>
<style lang="scss">
.property-marker,
.property-marker-nope,
.property-marker-maybe,
.property-marker-shoutlisted {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-position: center center;
  background-size: 100% 100%;
}
.district-marker {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  padding: 5px;
  border-radius: 50%;
  background: #54bca9;
  color: #ffffff;
}

.suburbs-marker {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90px;
  height: 90px;
  padding: 5px;
  border-radius: 50%;
  background: #54bca9;
  color: #ffffff;
  font-size: 13px;
  font-weight: 400;
}
.district-marker:hover,
.suburbs-marker:hover {
  cursor: pointer;
}
.property-marker {
  border: 1px solid #ffffff;
  box-sizing: border-box;
  background: #54bca9;
}
.property-marker-nope {
  background-color: #ffffff;
  background-image: url("~@/assets/Nope-or.png");
}
.property-marker-maybe {
  background-color: #ffffff;
  background-image: url("~@/assets/maybe.png");
}
.property-marker-shoutlisted {
  background-color: #ffffff;
  background-image: url("~@/assets/shoutlisted.png");
}
.property-marker:hover {
  width: 21px;
  height: 21px;
  cursor: pointer;
}
</style>