<template>
  <div class="property-detail">
    <div class="property-detail__content">
      <!-- Banner -->
      <property-banner :imgs="house.photos" :serverDomain="imageServerDomain" />
      <!-- Infomation -->
      <div class="property-detail__info">
        <div class="property-detail__info-agent-sticky">
          <div
            v-for="agent in agents"
            :key="agent.id"
            style="margin-bottom: 24px"
            @handleAgentClick="handleAgentClick(agent.id)"
          >
            <property-agent
              :id="agent.id"
              :email="agent.email"
              :firstName="agent.firstName"
              :hourseNum="0"
              :introduction="''"
              :lastName="agent.lastName"
              :name="agent.name"
              :officeBrandHexCode="''"
              :officeName="agent.officeName"
              :phoneMobile="agent.phoneMobile"
              :type="agent.type"
              :image="agent.image['base-url']"
              :imageServerDomain="imageServerDomain"
            />
          </div>
        </div>
        <!-- House Infomation -->
        <div class="property-detail__info--house">
          <!-- Title & Price -->
          <div class="property-detail__row">
            <property-brief
              :name="house.fullAddress"
              :priceDisplay="house.priceDisplay"
              :subTitle="house.header"
              :publishedDate="house.publishedDate"
              :bathroomCount="house.properties.bathrooms"
              :bedroomCount="house.properties.bedrooms"
              :parkingCoveredCount="house.properties.carparks"
              :landArea="parseInt(house.properties.landArea)"
              :notsale="true"
            />
          </div>
          <!-- Location -->
          <div v-if="house.wkt" class="property-detail__row">
            <div class="property-detail__title">
              <span>Location</span>
            </div>
            <property-location
              :address="house.fullAddress"
              :lat="String(house.gd2000Ycoord)"
              :lng="String(house.gd2000Xcoord)"
            />
          </div>
          <!-- Open Home -->
          <div
            v-if="house.openHomes && house.openHomes.length > 0"
            class="property-detail__row"
          >
            <div class="property-detail__title">
              <span>Open Home</span>
            </div>
            <open-home
              v-if="house.openHomes.length > 0"
              :open-homes="house.openHomes"
            />
          </div>
          <!-- Basic information -->
          <div class="property-detail__row">
            <div class="property-detail__title">
              <span>Basic information</span>
            </div>
            <basic-information
              :ownerShip="house.properties.titlesOwners[0].owners"
              :legalDescription="house.properties.legalDescription"
              :landTitle="`${house.properties.titleType} ${house.properties.landArea}`"
              :zoning="house.properties.unitaryPlan"
              :certificateOfTitle="house.properties.titlesOwners[0].titleNo"
            />
          </div>
          <!-- Detail -->
          <div v-if="house.descriptio" class="property-detail__row">
            <div class="property-detail__title">
              <span>Detail</span>
            </div>
            <div class="property-detail__desc" v-html="house.description" />
          </div>
          <!-- Capital Valuation -->
          <div v-if="house.propertyRate" class="property-detail__row">
            <div class="property-detail__title">
              <span>Capital Valuation</span>
            </div>
            <div>
              <capital-value :total="house.priceDisplay" :capital="capital" />
            </div>
          </div>
          <!-- Property Information -->
          <div class="property-detail__row">
            <div class="property-detail__title">
              <span>Property Information</span>
            </div>
            <property-information
              :yearBuilt="house.properties.decadeOfConstruction"
              :construction="house.properties.construction"
              :propertyView="house.properties.viewType"
              :pool="house.properties.deck"
              :rooms="house.properties.bedrooms + house.properties.bathrooms"
              :area="house.properties.floorArea"
            />
          </div>
          <!-- Underground Services & Flood -->
          <div v-show="false" class="property-detail__row">
            <div class="property-detail__title">
              <span>Underground Services & Flood</span>
            </div>
          </div>
          <!-- Timeline -->

          <!-- <div class="property-detail__row">
            <div class="property-detail__title">
              <span>Timeline</span>
            </div>
          </div> -->

          <!-- School -->
          <div
            v-if="house.schools && house.schools.length"
            class="property-detail__row"
          >
            <div class="property-detail__title">
              <span>School</span>
            </div>
            <div>
              <property-school :schools="house.schools" />
            </div>
          </div>
        </div>
        <!-- House Agent -->
        <div class="property-detail__info--agent">
          <!-- <div
            v-for="agent in agents"
            :key="agent.id"
            style="margin-bottom: 24px"
          >
            <property-agent
              :id="agent.id"
              :email="agent.email"
              :firstName="agent.firstName"
              :hourseNum="0"
              :introduction="''"
              :lastName="agent.lastName"
              :name="agent.name"
              :officeBrandHexCode="''"
              :officeName="agent.officeName"
              :phoneMobile="agent.phoneMobile"
              :type="agent.type"
              :image="agent.image['base-url']"
              :imageServerDomain="imageServerDomain"
            />
          </div> -->
        </div>
        <!--  -->
        <div v-if="!house.collectType" class="property-detail__user-favorite">
          <div class="favorite-btn nope" @click="collect('NOPE')">
            <span>✖︎ Nope</span>
          </div>
          <div class="favorite-btn maybe" @click="collect('MAYBE')">
            <span>Maybe</span>
          </div>
          <div class="favorite-btn shoutlist" @click="collect('SHOUTLISTED')">
            <span>✔︎ Shortlist</span>
          </div>
        </div>
        <div v-else class="property-detail__user-favorite">
          <div
            v-if="house.collectType === 'SHOUTLISTED'"
            class="favorite-btn-choosen shoutlist"
            @click="uncollect()"
          >
            <span>✔︎ Shortlisted</span>
          </div>
          <div
            v-if="house.collectType === 'MAYBE'"
            class="favorite-btn-choosen maybe"
            @click="uncollect()"
          >
            <span>Maybe</span>
          </div>
          <div
            v-if="house.collectType === 'NOPE'"
            class="favorite-btn-choosen nope"
            @click="uncollect()"
          >
            <span>✖︎ Nope</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import propertyAgent from "./agent/propertyAgent";
import propertyBanner from "./banner/propertyBanner.vue";
import propertyBrief from "./brief/propertyBrief.vue";
import propertyLocation from "./location/propertyLocation.vue";
import openHome from "./openHome/OpenHome.vue";
import basicInformation from "./basicInformation/basicInformation";
import propertyInformation from "./propertyInformation/propertyInformation.vue";
import propertySchool from "./school/school";
import capitalValue from "./capitalValue/capitalValue.vue";
import { getHouseDetailNotForSale } from "@/api/property";
import { API_SUCCESS_CODE } from "@/common/apiCode";
import { collect, uncollect } from "@/api/api.js";
import { mapGetters } from "vuex";

let currentPropertyId = "";

export default {
  name: "PropertyDetailNotForSaleView",
  /**
   * @desc 自定义组件
   */
  components: {
    capitalValue,
    propertyAgent,
    propertyBanner,
    propertyBrief,
    propertyLocation,
    basicInformation,
    openHome,
    propertyInformation,
    propertySchool,
  },
  computed: {
    ...mapGetters("user", {
      isLogin: "isLogin",
    }),
  },
  /**
   * @desc 页面数据
   */
  data() {
    return {
      imageServerDomain: "",
      agents: [],
      capital: {
        landValue: "",
        improvementsValue: "",
      },
      house: {
        isStick: false,
        stickRight: (window.innerWidth - 1275) / 2,
        addressId: 0,
        wkt: "",
        changeId: 0,
        addressType: "0",
        unitValue: "",
        addressNumber: 0,
        addressNumberSuffix: "",
        waterRouteName: "",
        waterName: "",
        suburbLocality: "0",
        townCity: "0",
        fullAddressNumber: "0",
        fullRoadName: "0",
        fullAddress: "",
        roadSectionId: 0,
        gd2000Xcoord: 0,
        gd2000Ycoord: 0,
        waterRouteNameAscii: "",
        waterNameAscii: "",
        suburbLocalityAscii: "",
        townCityAscii: "",
        fullRoadNameAscii: "",
        fullAddressAscii: "",
        shapeX: 0,
        shapeY: 0,
        properties: {
          addressId: 0,
          titleNo: "",
          titleType: "",
          landArea: "",
          floorArea: "",
          unitaryPlan: "",
          bedrooms: null,
          bathrooms: null,
          carparks: 0,
          decadeOfConstruction: "",
          contour: "",
          construction: "",
          condition: "",
          viewType: "",
          deck: "",
          council: "",
          legalDescription: "",
          estateDescription: "",
          street: "",
          suburb: "",
          district: "",
          city: "",
          updateTime: null,
          mortgagesLeasesEasements: [
            {
              id: 0,
              titleNo: "",
              landDistrict: "",
              memorialText: "",
              current: "",
              instrumentNumber: "",
              instrumentLodgedDatetime: 0,
              instrumentType: "",
              encumbrancees: "",
            },
          ],
          titlesOwners: [
            {
              id: 0,
              titleNo: "",
              status: "",
              type: "",
              landDistrict: "",
              issueDate: null,
              guaranteeStatus: "",
              estateDescription: "",
              spatialExtentsShared: "",
            },
          ],
        },
        propertyRate: {
          accountKey: 0,
          addressId: 0,
          titleNo: null,
          capitalRate: "",
          valueOfImprovements: "",
          landValue: "",
          propertyRate: "",
          rateYear: "",
        },
        collect: false,
      },
    };
  },
  /**
   * @desc Mounted
   */
  mounted() {
    currentPropertyId = this.$route.params.id;
    this.getHouseDetail();
    window.addEventListener("scroll", () => {
      console.log(window.scrollY);
      if (window.scrollY > 463) {
        this.isStick = true;
      } else {
        this.isStick = false;
      }
    });
  },
  /**
   * @desc
   */
  updated() {
    if (currentPropertyId !== this.$route.params.id) {
      this.getHouseDetail();
      currentPropertyId = this.$route.params.id;
    }
  },
  activated() {
    this.scrollToTop();
    if (currentPropertyId !== this.$route.params.id) {
      this.getHouseDetail();
    }
  },
  /**
   * @desc 页面方法
   */
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    /**
     * @desc 查询
     */
    async getHouseDetail() {
      try {
        // 637595082ab156f3cbd6373e
        const results = await getHouseDetailNotForSale(this.$route.params.id);
        if (results.status === API_SUCCESS_CODE && results.data) {
          this.house = results.data; //transferDataToOnSaled(results.data);
          this.capital = {
            landValue: this.house.propertyRate.landValue,
            improvementsValue: this.house.propertyRate.valueOfImprovements,
            propertyRate: this.house.propertyRate.propertyRate,
            rateYear: this.house.propertyRate.rateYear,
          };
        } else {
          // todo error
        }
      } catch (error) {
        // to exception
        console.log(error);
      }
    },
    async collect(collectType) {
      if (this.isLogin == false) {
        this.$router.push({ path: "/signUp" });
        return;
      }
      collect({
        houseId: this.house.id,
        collectType,
      }).then((res) => {
        if (res.status == 200) {
          this.house.collectType = collectType;
        }
      });
    },
    async uncollect() {
      uncollect({ houseId: this.house.id }).then((res) => {
        if (res.status == 200) {
          this.house.collectType = null;
        }
      });
    },
    handleAgentClick(id) {
      this.$router.push({ path: `/agent/${id}` });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./propertyDetailView.scss";
</style>