<template>
  <div class="prop-guide-wrapper">
    <div class="prop-guide-chat-box">
      <div style="height: 72px"></div>
      <div
        v-for="bubble in chatBubbles"
        :key="bubble.id"
        :class="
          bubble.self ? 'chat-bubble-wrapper left' : 'chat-bubble-wrapper right'
        "
      >
        <img
          v-if="!bubble.self"
          class="ai-thumbnail"
          :src="require('@/assets/propGuide-head.png')"
          alt="1"
        />
        <div
          v-if="bubble.type === 'message'"
          :class="
            bubble.self
              ? 'prop-guide-chat-bubble-self'
              : 'prop-guide-chat-bubble-ai'
          "
        >
          <!-- <span>{{ bubble.content }}</span> -->
          <div v-if="Array.isArray(bubble.content)" class="chat-text">
            <p
              style="margin-bottom: 10px"
              v-for="(row, index) in bubble.content"
              :key="index"
              v-html="row"
            ></p>
          </div>
          <div v-else class="chat-text">
            <p v-html="bubble.content"></p>
          </div>
          <div class="chat-house-list">
            <div
              v-for="(house, index) in bubble.houseList"
              :key="house.id"
              class="chat-house"
            >
              <div style="width: 72px">
                <img
                  class="chat-house-thumbnail"
                  :src="
                    imageServerDomain +
                    house.photos?.[0]?.baseUrl +
                    '.crop.140x178.jpg'
                  "
                />
              </div>

              <div class="chat-house-info" @click="toDetail(house.id)">
                <div class="chat-house-title">
                  <span
                    >[{{ index + 1 }}]{{ house.address.displayAddress }}</span
                  >
                </div>
                <div class="chat-house-data">
                  <div class="chat-house-data-item">
                    <img
                      class="chat-house-icon"
                      :src="require('@/assets/mianji.png')"
                    />
                    <span style="font-size: 11px">{{ house.landArea }}</span>
                  </div>
                  <div class="chat-house-data-item">
                    <img
                      class="chat-house-icon"
                      :src="require('@/assets/woshi.png')"
                    />
                    <span style="font-size: 11px">{{
                      house.bedroomCount
                    }}</span>
                  </div>
                  <div class="chat-house-data-item">
                    <img
                      class="chat-house-icon"
                      :src="require('@/assets/yushi.png')"
                    />
                    <span style="font-size: 11px">{{
                      house.bathroomFullCount
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="bubble.type === 'loading'"
          :class="
            bubble.self
              ? 'prop-guide-chat-bubble-self'
              : 'prop-guide-chat-bubble-ai'
          "
        >
          <div class="guide-loader">
            <div class="loader"></div>
          </div>
        </div>
      </div>
      <div class="question-list">
        <div
          v-for="q in propQuestions"
          :key="q.id"
          class="question"
          @click="chooseQuestion(q)"
        >
          <span>{{ q.question }}</span>
        </div>
      </div>
    </div>
    <div class="prop-guide-chat-input">
      <div class="prop-guide-textarea">
        <textarea
          class="prop-guide-text-input"
          v-model="inputValue"
          placeholder="Type a question"
          @keypress.enter="handleClickSendButton"
        />
        <div class="prop-send-button">
          <img
            alt="x"
            :style="{
              cursor: inputValue?.length > 0 ? 'pointer' : 'not-allowed',
            }"
            :src="
              inputValue?.length > 0
                ? require('../../assets/prop_send.png')
                : require('../../assets/prop_send_nor.png')
            "
            @click="handleClickSendButton"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getAiQuestions, getAiResponse } from "@/api/api";
function generateUUid() {
  // 生成唯一id
  let d = new Date().getTime();
  let uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
    /[xy]/g,
    function (c) {
      let r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
    }
  );
  return uuid;
}

const welcomeMessage =
  "Hello, I'm PropGuide, your personal property assistant. Please tell me the location, budget, number of rooms, school zoning requirements, or other preferences, and I will recommend properties for you.<br/>(In the future, I will have the capability to conduct more detailed analyses for you, recommend good agents, and analyze regional price changes.)";

export default {
  data() {
    return {
      inputValue: "",
      chatBubbles: [],
      propQuestions: [],
      imageServerDomain:
        "https://oss-propercentre.oss-ap-southeast-1.aliyuncs.com",
    };
  },
  mounted() {
    setTimeout(() => {
      this.initChatMessage();
      this.getQuestions();
    }, 1500);
  },
  methods: {
    async getQuestions() {
      const results = await getAiQuestions();
      this.propQuestions = results.data;
    },
    chooseQuestion(q) {
      this.inputValue = q.question;
      this.propQuestions = [];
    },
    initChatMessage() {
      this.chatBubbles.push({
        id: generateUUid(),
        type: "message",
        content: welcomeMessage,
        self: false,
      });
    },
    toDetail(propertyId) {
      this.$router.push(`/property/${propertyId}`);
    },
    async getAiResponse(message) {
      this.chatBubbles.push({
        id: generateUUid(),
        type: "loading",
        self: false,
      });
      const results = await getAiResponse({ message });
      this.chatBubbles.pop();
      if (results.status === 200) {
        var regex = /(\[[0123456789]+\])/g;
        const contents = results.data.res.split("\n");
        const uuid = generateUUid();
        const formatContents = contents?.map((element) => {
          element = element.replace(
            regex,
            `<span class="ai-link-${uuid}" style="color:#158C79;cursor:pointer;font-weight:800">$1</span>`
          );
          return element;
        });
        const houseList = results.data.houseList?.hourses;
        this.chatBubbles.push({
          id: uuid,
          type: "message",
          content: formatContents.filter((item) => item.length > 0),
          houseList,
          self: false,
        });
        setTimeout(() => {
          const links = document.querySelectorAll(`.ai-link-${uuid}`);
          links.forEach((link) => {
            link.addEventListener("click", () => {
              this.toDetail(
                houseList[link.innerText.replace("[", "").replace("]", "") - 1]
                  ?.id
              );
            });
          });
        }, 100);
      }
    },
    handleClickSendButton() {
      if (this.inputValue.trim().length === 0) {
        this.inputValue = "";
        return;
      }
      if (this.inputValue.length > 0) {
        this.chatBubbles.push({
          id: generateUUid(),
          type: "message",
          content: this.inputValue,
          self: true,
        });
        this.propQuestions = [];

        this.getAiResponse(this.inputValue);
        this.inputValue = "";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./propGuide.scss";
</style>