<template>
  <div class="ourLists">
    <div class="ourLists_Title">Our Listings</div>
    <div style="display: flex; flex-direction: column; width: 1300px">
      <div class="ourLists_List">
        <div
          @click="goToDetail(item)"
          :class="
            index % 4 == 3 ? 'ourLists_Cell' : 'ourLists_Cell ourLists_GapRight'
          "
          v-for="(item, index) in dataList"
          :key="index"
        >
          <div style="display: flex; flex-direction: column; height: 100%">
            <div class="img-content">
              <img
                class="house-cell-img"
                :src="
                  imgUrl(item) == ''
                    ? require('../../assets/unsale_back.png')
                    : imgUrl(item)
                "
              />
            </div>
            <div
              v-if="
                item.collectType == '' ||
                item.collectType == null ||
                item.collectType == 'MAYBE'
              "
              class="option-view"
            >
              <img
                @click.stop="collectEvent(item, 'SHOUTLISTED', index)"
                class="option-img"
                src="../../assets/collect_yes_new.png"
              />
              <img
                @click.stop="collectEvent(item, 'NOPE', index)"
                class="option-img"
                src="../../assets/collect_no_new.png"
              />
            </div>
            <div v-if="item.collectType == 'SHOUTLISTED'" class="option-view">
              <img
                @click.stop="uncollectEvent(item, index)"
                class="option-img"
                src="../../assets/collect_sel_new.png"
              />
            </div>
            <div v-if="item.collectType == 'NOPE'" class="option-view">
              <img
                @click.stop="uncollectEvent(item, index)"
                class="option-img"
                src="../../assets/collect_unsel_new.png"
              />
            </div>
            <div class="bottom-cell-view">
              <p class="price-title">{{ item.priceDisplay }}</p>
              <p class="address-title">{{ item.address.displayAddress }}</p>
              <div class="cell-bottom-shuxing-view">
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                  "
                  v-if="item.landArea > 0"
                >
                  <img
                    class="cell_info_bottom_img"
                    src="../../assets/mianji.png"
                  />
                  <p class="cell_info_bottom_label">
                    {{
                      item.landArea +
                      (item.landAreaUnit == "SQM" ? "㎡" : item.landAreaUnit)
                    }}
                  </p>
                </div>
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                  "
                  v-if="item.bedroomCount > 0"
                >
                  <img
                    class="cell_info_bottom_img"
                    src="../../assets/woshi.png"
                  />
                  <p class="cell_info_bottom_label">{{ item.bedroomCount }}</p>
                </div>
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                  "
                  v-if="item.bathroomCount > 0"
                >
                  <img
                    class="cell_info_bottom_img"
                    src="../../assets/yushi.png"
                  />
                  <p class="cell_info_bottom_label">{{ item.bathroomCount }}</p>
                </div>
              </div>
            </div>
            <div v-if="item.photos.length > 0" class="photo-nums-view">
              <img
                style="margin-left: 8px; height: 11px; width: 11px"
                src="../../assets/photo_icon.png"
              />
              <p
                style="
                  color: #158c79;
                  font-size: 12px;
                  font-weight: 700;
                  margin-left: 5px;
                "
              >
                {{ item.photos.length }}
              </p>
            </div>
            <div v-if="item.show == true" class="agent-view">
              <div
                style="
                  z-index: 2;
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                "
                v-for="(agentItem, agentIndex) in item.agents"
                :key="agentIndex"
              >
                <img
                  :class="
                    agentIndex == 0 ? 'cell_head_img' : 'cell_head_img_second'
                  "
                  :src="agentItem.userHeadImageUrl"
                />
              </div>
              <div
                :style="{ backgroundColor: item.agentMap.officeBrandHexCode }"
                class="cell_office_image_s"
              >
                <img
                  mode="heightFix"
                  style="height: 14px; width: 85px"
                  :src="item.agentMap.officeImage"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <el-pagination
        class="ourLists_pagination"
        layout="prev, pager, next"
        :total="total"
        :page-size="8"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { collect, getHourseByOfficeId, uncollect } from "../../api/api.js";
export default {
  data() {
    return {
      officeId: "",
      dataList: [],
      total: 0,
      currentPage: 1,
      imageServerDomain: "",
      agentMap: {},
    };
  },
  computed: {
    ...mapGetters("user", {
      isLogin: "isLogin",
    }),
  },
  props: {
    id: String,
  },
  watch: {
    id(value) {
      this.officeId = value;
      this.getList();
    },
  },
  methods: {
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getList();
    },
    imgUrl(item) {
      if (item.photos != null) {
        if (item.source === "OWNER") {
          return item.photos[0].baseUrl;
        } else {
          if (item.photos.length > 0) {
            if (item.photos[0].baseUrl) {
              if (item.photos[0].baseUrl.indexOf("http") != -1) {
                return item.photos[0].baseUrl;
              } else {
                return (
                  this.imageServerDomain +
                  item.photos[0].baseUrl +
                  ".crop.980x650.jpg"
                );
              }
            }
          } else {
            return "";
          }
        }
      }
      return "";
    },
    collectEvent(item, collectType, index) {
      if (this.isLogin == false) {
        this.$router.push({ path: "/signUp" });
        return;
      }
      var form = {};
      form.hourseId = item.id;
      form.collectType = collectType;
      form.source = "ON_SALE";
      collect(form).then((res) => {
        if (res.status == 200) {
          item.collectType = collectType;
          this.$set(this.dataList, index, item);
        }
      });
    },
    uncollectEvent(item, index) {
      var form = {};
      form.hourseId = item.id;
      form.source = "ON_SALE";
      uncollect(form).then((res) => {
        if (res.status == 200) {
          item.collectType = "";
          this.$set(this.dataList, index, item);
        }
      });
    },
    goToDetail(item) {
      let routeData = this.$router.resolve(`/property/${item.id}`);
      window.open(routeData.href, "_blank");
    },
    getList() {
      var form = {};
      form.officeId = this.officeId;
      form.pageIndex = this.currentPage;
      form.pageSize = 8;
      getHourseByOfficeId(form).then((res) => {
        if (res.status == 200) {
          this.total = res.data.hoursePage.total;
          this.dataList.length = 0;
          this.imageServerDomain = res.data.imageServerDomain;
          this.agentMap = res.data.agentMap;
          res.data.hoursePage.records.forEach((item) => {
            var newItem = {};
            newItem = item;
            if (item.agents) {
              if (item.agents != null && item.agents.length > 0) {
                newItem.agentMap = {};
                if (this.agentMap[item.agents[0].id]) {
                  newItem.agentMap.officeBrandHexCode =
                    this.agentMap[item.agents[0].id].officeBrandHexCode;
                } else {
                  newItem.agentMap.officeBrandHexCode = "#000000";
                }
                if (
                  this.agentMap[item.agents[0].id] &&
                  this.agentMap[item.agents[0].id].image
                ) {
                  if (
                    this.agentMap[item.agents[0].id].image["base-url"].indexOf(
                      "http"
                    ) != -1
                  ) {
                    newItem.agentMap.userHeadImageUrl =
                      this.agentMap[item.agents[0].id].image["base-url"];
                  } else {
                    newItem.agentMap.userHeadImageUrl =
                      this.imageServerDomain +
                      this.agentMap[item.agents[0].id].image["base-url"] +
                      ".crop.128x128.jpg";
                  }
                  newItem.agentMap.name =
                    this.agentMap[item.agents[0].id].firstName +
                    " " +
                    this.agentMap[item.agents[0].id].lastName;
                  newItem.agentMap.officeImage =
                    this.imageServerDomain +
                    this.agentMap[item.agents[0].id].officeImage +
                    ".crop.350x70.jpg";
                } else {
                  newItem.agentMap.userHeadImageUrl = require("../../assets/headDefault.png");
                }
                newItem.agents.forEach((subItem) => {
                  if (this.agentMap[subItem.id]) {
                    if (this.agentMap[subItem.id].image == null) {
                      subItem.userHeadImageUrl = require("../../assets/headDefault.png");
                    } else {
                      if (
                        this.agentMap[subItem.id].image["base-url"].indexOf(
                          "http"
                        ) != -1
                      ) {
                        subItem.userHeadImageUrl =
                          this.agentMap[subItem.id].image["base-url"];
                      } else {
                        subItem.userHeadImageUrl =
                          this.imageServerDomain +
                          this.agentMap[subItem.id].image["base-url"] +
                          ".crop.200x200.jpg";
                      }
                    }
                    if (this.agentMap[subItem.id]) {
                      subItem.officeBrandHexCode =
                        this.agentMap[subItem.id].officeBrandHexCode;
                    } else {
                      subItem.officeBrandHexCode = "#000000";
                      console.log(this.agentMap[subItem.id].officeBrandHexCode);
                    }
                    subItem.officeImage =
                      this.imageServerDomain +
                      this.agentMap[subItem.id].officeImage +
                      ".crop.350x70.jpg";
                    subItem.phoneMobile = this.agentMap[subItem.id].phoneMobile;
                    subItem.introduction =
                      this.agentMap[subItem.id].introduction;
                    subItem.name =
                      this.agentMap[subItem.id].firstName +
                      " " +
                      this.agentMap[subItem.id].lastName;
                    subItem.email = this.agentMap[subItem.id].email;
                  }
                });
              } else {
                newItem.sale = false;
              }
            }
            this.dataList.push(newItem);
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./ourLists.scss";
</style>