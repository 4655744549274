<template>
  <div class="agent-info">
    <div class="agent-info__desc">
      <div class="agent-info__desc-thumbnail">
        <img
          class="agent-head"
          :src="headUrl(image)"
        />
      </div>
      <div>
        <div class="agent-info__desc-name">
          <span>{{ name ? name : `${firstName} ${lastName}` }}</span>
        </div>
        <div class="agent-info__desc-office">
          <span>{{ officeName }}</span>
        </div>
      </div>
    </div>
    <div class="agent-info__contact">
      <div class="contact-button">Contact</div>
    </div>
  </div>
</template>
<script>
import {
  getEmptyStringPropsDef,
  getNumberPropsDef,
} from "@/common/propsConfig";

export default {
  name: "PropertyAgent",
  props: {
    id: getEmptyStringPropsDef(),
    index: getNumberPropsDef(),
    email: getEmptyStringPropsDef(),
    firstName: getEmptyStringPropsDef(),
    hourseNum: getNumberPropsDef(),
    introduction: getEmptyStringPropsDef(),
    lastName: getEmptyStringPropsDef(),
    name: getEmptyStringPropsDef(),
    officeName: getEmptyStringPropsDef(),
    officeBrandHexCode: getEmptyStringPropsDef(),
    phoneMobile: getEmptyStringPropsDef(),
    type: getEmptyStringPropsDef(),
    image: getEmptyStringPropsDef(),
    imageServerDomain: getEmptyStringPropsDef(),
  },
  /**
   * @desc 页面数据
   */
  data() {
    return {};
  },
  /**
   * @desc 页面方法
   */
  methods: {
	headUrl: function (baseUrl) {
	  if (baseUrl) {
	    if (baseUrl.indexOf("http") != -1) {
	      return baseUrl + "?x-oss-process=image/resize,m_fill,h_200,w_200";
	    } else {
	      return (
	        this.imageServerDomain +
	        baseUrl +
	        ".crop.200x200.jpg"
	      ); //750*750
	    }
	  } else {
	    return "";
	  }
	},
  },
};
</script>
<style lang="scss" scoped>
@import "./propertyAgent.scss";
</style>