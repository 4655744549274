<template>
  <div class="procenter-map">
    <div class="procenter-map__polyfill">
      <p-map
        @choose-property="onTapProperty"
        @moveend="onMoveEnd"
        @zoomend="onZoomEnd"
        :districts="districts"
        :houseList="houseList"
        :initLat="lat"
        :initLng="lng"
        :locationList="locationList"
        :selectedPoint="selectedPoint"
        :imageServerDomain="imageServerDomain"
        :zoom="zoom"
      />
    </div>
    <div
      v-if="zoom > 14 && locationList.length > 0"
      class="procenter-map__search-anwser"
    >
      <p>
        {{ locationList.length }} properties found,
        {{ locationList.length > 100 ? 100 : locationList.length }} displayed.
      </p>
    </div>
    <div
      class="procenter-map__widgets"
      :style="{ bottom: `${showList ? '24px' : ''}` }"
    >
      <div class="procenter-map__widgets-header" @click="handleShowList">
        <span>View properties within the visible area</span>
        <span :style="{ transform: `rotate(${showList ? '0deg' : '180deg'})` }"
          >⌵</span
        >
      </div>
      <div class="procenter-map__widgets-list-wrapper">
        <div v-if="showList" class="procenter-map__widgets-list">
          <div
            v-for="item in houseDetailList"
            :key="item.id"
            class="procenter-map__widgets-list-item"
          >
            <map-recommend
              :imageServerDomain="imageServerDomain"
              :propertyId="item.id"
              :coverUrl="
                imageServerDomain +
                  item.photos?.[0]?.baseUrl +
                  '.crop.580x400.jpg' || require('@/assets/unsale_back.png')
              "
              :photoNum="item.photos?.length"
              :title="item?.address?.displayAddress"
              :desc="item.header"
              :collectType="item.collectType"
              :landArea="item.landArea"
              :landAreaUnit="item.landAreaUnit"
              @switchIndex="switchRecommend"
              @collect="onCollect"
              @uncollect="onUnCollect"
              @tapcard="toDetail"
            />
          </div>
        </div>
      </div>
    </div>
    <div>
      <!--      <map-search-bar
        @saveFilter="handleFilter"
        :total="matchesTotals"
        :matches="matches"
      ></map-search-bar> -->
    </div>
    <filter-nav @search="handleFilter"></filter-nav>
  </div>
</template>
<script>
import { collect, uncollect } from "@/api/api.js";
import { getHouseListByLatLng } from "@/api/property";
import districts from "@/common/district";
import filterNav from "@/components/filterNav.vue";
import mapRecommend from "@/components/mapRecommend.vue";
import bus from "@/utils/bus";
import { mapGetters } from "vuex";
import { getDistrict, getSuburbs } from "../../api/api";
import pMap from "./widgets/map/map.vue";

const initLat = -36.85,
  initLng = 174.75,
  defaultDistance = 1500;

// let timerInterval;

const houseInfoMap = new Map();

export default {
  name: "proMap",
  components: { pMap, mapRecommend, filterNav },
  data() {
    return {
      zoom: 10,
      showList: false,
      houseDetailList: [],
      lat: initLat,
      lng: initLng,
      locationList: [],
      distance: defaultDistance,
      ready: false,
      houseList: [],
      agentMap: {},
      imageServerDomain:
        "https://oss-propercentre.oss-ap-southeast-1.aliyuncs.com",
      currentIndex: 0,
      selectedId: "",
      selectedPoint: {},
      selectedProperty: {},
      loadingDetail: false,
      selectedDisplayData: {
        coverUrl: "",
        photoNum: 0,
        title: "",
        desc: "",
        size: 0,
        bedroomCount: 0,
        bathroomCount: 0,
        collectType: "",
        landArea: 0,
        landAreaUnit: "",
        propertyId: "",
        priceDisplay: "",
      },
      districts: districts,
      filterOptions: {},
      matchesTotals: 0,
      matches: 0,
      // isMatching: false,
      nextIndex: 0,
      districtData: [],
    };
  },
  computed: {
    ...mapGetters("user", {
      isLogin: "isLogin",
    }),
  },
  mounted() {
    this.initData(this.lat, this.lng, this.distance);
  },
  methods: {
    handleShowList() {
      this.showList = !this.showList;
    },
    /**
     * @desc
     */
    handleFilter(val) {
      this.locationList = [];
      // this.isMatching = true;
      this.handleFilterRequest(val);
    },
    showSelectEvent(val) {
      this.showSelected(val);
    },
    async handleFilterRequest(val) {
      this.filterOptions = val;
      const locations = await this.getHousesByLatLng({
        // latitude: this.lat,
        // longitude: this.lng,
        // distance: this.distance,
        ...val,
      });
      this.locationList = locations;
      // this.locationList.length > 0 &&
      //   (this.selectedPoint = this.locationList[0]);
      if (this.locationList.length == 0) {
        // this.isMatching = false;
        this.filterOptions = {};
        this.initData(this.lat, this.lng, this.distance);
        this.$message("No Data!");
      }
    },
    async getDistrict() {
      const results = await getDistrict();
      const distData = districts.map((dis) => {
        const dist = results.data.find((dist) => dist.district === dis.name);
        if (dist) {
          dis = { ...dis, ...dist };
        }
        return dis;
      });
      this.districts = distData;
    },
    async getSuburbData() {
      const distData = await Promise.all(
        this.districts.map(async (dis) => {
          const { data: suburbs } = await getSuburbs(dis.name);
          dis.suburbs.forEach((suburb, index) => {
            for (let i = 0; i < suburbs.length; i++) {
              if (suburbs[i].suburb === suburb.name) {
                dis.suburbs[index] = { ...suburb, ...suburbs[i] };
                break;
              }
            }
          });
          return dis;
        })
      );
      this.districts = distData;
    },
    async initData(latitude, longitude, distance) {
      await this.getDistrict();
      await this.getSuburbData();
      await this.getHousesByLatLng({
        latitude,
        longitude,
        distance,
      });
    },
    /** 数据请求 */

    // showSelected(index) {
    //   // const location = this.locationList[index];
    //   // this.selectedPoint = location;
    // },

    /**
     * @desc 查询被选中的房屋
     */
    async getSelectedProperty(id) {
      this.selectedProperty = houseInfoMap.get(id);
      const hourse = this.selectedProperty;
      const selectedDisplayData = {
        coverUrl: hourse.photos[0]?.baseUrl
          ? this.imageServerDomain +
            hourse.photos[0]?.baseUrl +
            ".crop.580x400.jpg"
          : require("@/assets/unsale_back.png"),
        photoNum: hourse.photos[0]?.length || 0,
        title: hourse.address.displayAddress,
        desc: hourse.header,
        size: hourse.size,
        bedroomCount: hourse.bedroomCount,
        bathroomCount: hourse.bathroomCount,
        collectType: hourse.collectType,
        landArea: hourse.landArea,
        landAreaUnit: hourse.landAreaUnit,
        propertyId: hourse.id,
        priceDisplay: hourse.priceDisplay,
      };
      this.selectedDisplayData = selectedDisplayData;
    },
    /**
     * @desc get houses by lat，lng，distance
     */
    async getHousesByLatLng(params) {
      const results = await getHouseListByLatLng(params);
      this.houseDetailList = results?.data || [];
      results.data?.forEach((item) => {
        if (houseInfoMap.has(item.id)) {
          return;
        }
        houseInfoMap.set(item.id, item);
      });
      const locationList = results.data; // Array.from(houseInfoMap.values());
      return (this.locationList = locationList);
    },

    /** 用户操作 */

    /**
     * @desc 切换推荐内容
     */
    switchRecommend(index) {
      this.currentIndex = index;
      console.log(index, "switch recommend");
    },

    /** 监听事件 */

    onZoomEnd(zoom) {
      this.zoom = zoom;
      if (zoom <= 12) {
        this.showList = false;
        return;
      }
      if (zoom <= 14) {
        this.showList = false;
        return;
      }
      this.showList = true;
    },

    /**
     * @desc 监听地图拖拽结束
     */
    onMoveEnd({ lat, lng }) {
      if (this.isMatching) {
        return;
      }
      this.lat = lat;
      this.lng = lng;
      // this.locaitonList = [];
      this.getHousesByLatLng({
        latitude: lat,
        longitude: lng,
        distance: this.distance,
        ...this.filterOptions,
      });
      // this.this = locations[0]
    },
    /**
     * @desc 获取点击的房产信息
     */
    onTapProperty({ id }) {
      if (this.isMatching) {
        this.locationList.forEach((item, index) => {
          if (item.id == id) {
            this.nextIndex = index;
          }
        });
      } else {
        this.getSelectedProperty(id);
      }
    },

    /**
     * @desc 用户点击收集房源
     */
    onCollect({ id, collectType }) {
      if (this.isLogin == false) {
        this.$router.push({ path: "/signUp" });
        return;
      }
      this.selectedDisplayData.collectType = collectType;
      bus.$emit("collect", { id, collectType });
      this.houseDetailList.find((item) => item.id === id).collectType =
        collectType;
      collect({ hourseId: id, collectType })
        .then(() => {
          setTimeout(() => {
            const idx = this.locationList.findIndex(
              (location) => location.id === id
            );
            console.log(idx);
            const next = this.locationList.find((location, index) => {
              return index > idx && !location.collect;
            });
            if (next) {
              console.log(next);
              this.onTapProperty({ id: next.id });
              this.selectedPoint = next;
            }
          }, 500);
        })
        .catch((error) => {
          this.$emit("collect-error", {
            id: this.propertyId,
            collectType,
            error,
          });
        });
    },
    /**
     * @desc 用户点击取消收集
     */
    onUnCollect({ id }) {
      this.selectedDisplayData.collectType = null;
      bus.$emit("uncollect", { id });
      uncollect({ hourseId: id }).then((res) => {
        console.log(res);
      });
    },
    /**
     * @desc 用户点击卡片
     */
    toDetail(propertyId) {
      this.$router.push(`/property/${propertyId}`);
    },
    closeMatchEvent() {
      this.isMatching = false;
      this.filterOptions = {};
      this.initData(this.lat, this.lng, this.distance);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./mapView.scss";
</style>