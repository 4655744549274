import {
  setEmail,
  getEmail,
  setToken,
  getToken,
  removeAllData
} from '../../utils/auth.js'
import { resetRouter } from '../../router/index.js'

const getInitState = () => {
  return {
    isLogin: getToken() !== null ? true :false,
    email: getEmail(),
    token: getToken()
  }
}

const state = getInitState()

const getters = {
  isLogin (state) {
    return state.isLogin
  },
  email (state) {
    return state.email
  },
  token (state){
    return state.token
  }
}
const actions = {
  setIsLogin ({ commit }, data) {
    commit('setIsLogin', data)
  },
  setToken ({ commit }, data) {
    commit('setToken', data)
    commit('setIsLogin', true)
    setToken(data)
  },
  setEmail ({ commit }, data) {
    commit('setEmail', data)
    setEmail(data)
  },
  logout ({ commit }) {
	commit('setIsLogin', false)
    removeAllData()
    resetRouter()
    commit('RESET_STATE')
  },
  logoutData ({ commit }) {
    removeAllData()
    commit('RESET_STATE')
  }
}
const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getInitState())
  },
  setIsLogin (state, data) {
    state.isLogin = data
  },
  setToken (state, data) {
    state.token = data
  },
  setEmail (state, data) {
    state.email = data
  }
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
