<template>
	<div>
		<el-dialog @close="cancel()" width="1200px" :modal-append-to-body='false' :close-on-click-modal="false" :show-close="true" center :visible.sync="show">
			<div class="contact-agent-view">
				<div class="agent-info-view">
					<img v-if="agent.image" class="agent-head" :src="imageServerDomain + agent.image['base-url'] + '.crop.200x200.jpg'"  />
					<div class="agent-name ">{{ agent.firstName + ' ' + agent.lastName }}</div>
				</div>
				<div class="send-email-view">
					<div class="send-email-left-view">
						<div class="section-title">First Name<span style="color: #FF1717;">*</span></div>
						<el-input v-model="form.firstName" class="section-input"></el-input>
						<div class="section-title">Email<span style="color: #FF1717;">*</span></div>
						<el-input v-model="form.email" class="section-input"></el-input>
						<div class="section-title">Phone</div>
						<el-input v-model="form.phoneNum" class="section-input"></el-input>
					</div>
					<div class="send-email-right-view">
						<div class="section-title">Message</div>
						<el-input v-model="form.message" placeholder="I'm interested in 15 Woven Place, Drury (ref. 850817) and I would like more information please." :autosize="{ minRows: 6, maxRows: 6}" type="textarea" class="section-input"></el-input>
						<el-button :loading='sending' @click="sendEmail" type="primary" style="width: 100%;" :disabled='!(form.email && form.firstName)'>Send Email</el-button>
					</div>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		getObjectPropsDef,
		getEmptyStringPropsDef
	} from "@/common/propsConfig.js";
	import { contactAgent } from "../../api/api.js"
	import { mapGetters } from 'vuex'
	export default {
		props: {
			isShow: Boolean,
			agent: getObjectPropsDef(),
			imageServerDomain: getEmptyStringPropsDef()
		},
		computed: {
			...mapGetters('user', {
				email: 'email'
			})
		},
		watch: {
			isShow(value) {
				this.show = value
			},
			email: {
				handler: function() {
				if (this.email != '' && this.email != null && this.email != undefined) {
					this.refreshName()
				}
			},
			deep: true,
			}
		},
		data() {
			return {
				show: false,
				form: {
					email: '',
					firstName: '',
					message: '',
					phoneNum: ''
				},
				sending: false
			}
		},
		methods: {
			cancel() {
				this.$emit('dismiss')
			},
			refreshName() {
				if (this.email != '' && this.email != null && this.email != undefined) {
					this.form.firstName = this.email.substring(0,this.email.indexOf('@'))
				}
			},
			isEmail(email) {
				var emailReg = /^\w{3,}(\.\w+)*@[A-z0-9]+(\.[A-z]{2,5}){1,2}$/
				return emailReg.test(email)
			},
			sendEmail() {
				if (this.isEmail(this.form.email) == false) {
					this.$message.error('Please enter the correct email address!')
					return
				}
				this.sending = true
				contactAgent(this.form).then( res=> {
					if (res.status == 200) {
						this.$message({
							message: 'We’ve sent your enquiry to the agent. The agent will be in touch soon.',
							type: 'success'
						})
						this.$emit('dismiss')
					}
					this.sending = false
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.contact-agent-view {
		width: 100%;
		margin-top: 20px;
		padding-bottom: 5px;
	}
	.agent-info-view {
		margin-top: 20px;
		margin-left: 20px;
		width: calc(100% - 40px);
		height: 106px;
		display: flex;
		flex-direction: row;
		align-items: center;
	}
	.agent-head {
		width: 106px;
		height: 106px;
		border-radius: 50%;
		border: #ECECEC 1px solid;
	}
	.agent-name-phone {
		display: flex;
		flex-direction: column;
		margin-left: 15px;
	}
	.agent-name {
		color: #000000;
		font-size: 24px;
		text-align: left;
		margin-left: 10px;
	}
	.agent-phone-view {
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-top: 8px;
	}
	.agent-phone-title {
		color: #000000;
		font-size: 18px;
		text-align: left;
	}
	.agent-icon {
		width: 20px;
		height: 20px;
	}
	.agent-phone {
		color: #787878;
		font-size: 18px;
		text-align: left;
	}
	.logo-view {
		width: 223px;
		height: 77px;
		border-radius: 8px;
		align-items: center;
		justify-content: center;
		display: flex;
		flex-direction: row;
		margin-left: auto;
		margin-right: 0px;
	}
	.logo-img {
		width: 200px;
		height: 55px;
	}
	.send-email-view {
		margin-top: 20px;
		margin-left: 20px;
		width: calc(100% - 40px);
		display: flex;
		flex-direction: row;
	}
	.send-email-left-view {
		display: flex;
		flex-direction: column;
		width: calc(50% - 40px);
	}
	.send-email-right-view {
		width: 50%;
		display: flex;
		flex-direction: column;
		margin-left: auto;
		margin-right: 0px;
	}
	.section-title {
		color: #000000;
		font-size: 18px;
		text-align: left;
	}
	.section-input {
		width: 100%;
		margin-top: 5px;
		margin-bottom: 15px;
	}
</style>