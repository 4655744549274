<template>
  <div
    style="
      display: flex;
      flex-direction: column;
      background-color: #fff;
      align-items: center;
    "
  >
    <div
      style="
        z-index: 998;
        position: fixed;
        background-color: #fff;
        width: 1152px;
        height: 104px;
      "
    >
      <filter-nav
        :isWidth="true"
        style="left: 50%; top: 24px; transform: translateX(-50%)"
        @search="searchEvent"
      ></filter-nav>
    </div>
    <div style="height: 80px"></div>
    <div class="right-view">
      <div v-if="adlist.length > 0" class="ad-main">
        <swiper :options="adlist.length > 1 ? swiperOption : {}" ref="mySwiper">
          <swiper-slide v-for="(item, index) in adlist" :key="index">
            <div @click="goToAdDetail(index)" class="ad-imgs-main">
              <template v-if="item.photos.length >= 5">
                <div class="ad-imgs-main__left">
                  <img
                    style="width: 100%; height: 100%; z-index: -1"
                    :src="imgIndexUrl(item, 0, 1006, 516)"
                  />
                  <div
                    style="top: 242px; right: 24px"
                    v-if="item.photos.length > 0"
                    class="photo-nums-view"
                  >
                    <img
                      style="margin-left: 8px; height: 11px; width: 11px"
                      src="../assets/imgCount.png"
                    />
                    <p
                      style="
                        color: #fff;
                        font-size: 12px;
                        font-weight: 700;
                        margin-left: 5px;
                      "
                    >
                      {{ item.photos.length }}
                    </p>
                  </div>
                </div>
                <div class="ad-imgs-main__right">
                  <img
                    class="right_small_img"
                    v-for="i in 4"
                    :key="i"
                    :src="imgIndexUrl(item, i + 1, 1000, 564)"
                  />
                </div>
              </template>
              <template v-else-if="item.photos.length > 0">
                <img
                  :src="imgIndexUrl(item, 0, 2020, 576)"
                  style="width: 100%; height: 100%"
                />
              </template>
            </div>
            <div @click="goToAdDetail(index)" class="ad-content-main">
              <p class="cell-title-label">{{ item.address.displayAddress }}</p>
              <p class="cell-info-label">{{ item.header }}</p>
              <p
                class="cell-info-label"
                style="color: #158c79; font-size: 14px; font-weight: 700"
              >
                {{ item.priceDisplay }}
              </p>
              <div
                style="margin-left: 0px; margin-top: 14px"
                class="cell-bottom-shuxing-view"
              >
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                  "
                  v-if="item.landArea > 0"
                >
                  <img
                    class="cell_info_bottom_img"
                    src="../assets/mianji.png"
                  />
                  <p class="cell_info_bottom_label">
                    {{
                      Number(item.landArea).toFixed(1) +
                      (item.landAreaUnit == "SQM" ? "㎡" : item.landAreaUnit)
                    }}
                  </p>
                </div>
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                  "
                  v-if="item.bedroomCount > 0"
                >
                  <img class="cell_info_bottom_img" src="../assets/woshi.png" />
                  <p class="cell_info_bottom_label">
                    {{ item.bedroomCount }}
                  </p>
                </div>
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                  "
                  v-if="item.bathroomCount > 0"
                >
                  <img class="cell_info_bottom_img" src="../assets/yushi.png" />
                  <p class="cell_info_bottom_label">
                    {{ item.bathroomCount }}
                  </p>
                </div>
              </div>
              <div class="ad-agent-view">
                <img
                  class="ad_head_img"
                  :src="item.agentMap.userHeadImageUrl"
                />
                <div
                  :style="{ backgroundColor: item.agentMap.officeBrandHexCode }"
                  class="cell_office_image_b"
                >
                  <img
                    mode="heightFix"
                    style="
                      margin-left: auto;
                      height: 28px;
                      width: 120px;
                      margin-right: 12px;
                    "
                    :src="item.agentMap.officeImage"
                  />
                </div>
              </div>
            </div>
          </swiper-slide>
          <div
            v-if="adlist.length > 1"
            class="swiper-pagination"
            slot="pagination"
          ></div>
        </swiper>
      </div>
      <div v-loading="loading" class="list-main">
        <el-empty
          v-if="loading == false && list.length == 0"
          description="No Data"
        ></el-empty>
        <div class="house-list">
          <div
            @click="goToDetail(item)"
            v-for="(item, index) in list"
            :key="index"
            class="house-cell"
          >
            <div
              style="display: flex; flex-direction: column; height: 100%"
              v-if="item.sale == true"
            >
              <div class="img-content">
                <img
                  class="house-cell-img"
                  :src="
                    imgUrl(item) == ''
                      ? require('../assets/unsale_back.png')
                      : imgUrl(item)
                  "
                />
              </div>
              <p class="cell-title-label">{{ item.address.displayAddress }}</p>
              <p class="cell-info-label">{{ item.header }}</p>
              <div class="cell-price-bottom-view">
                <p style="color: #158c79; font-size: 14px; font-weight: 700">
                  {{ item.priceDisplay }}
                </p>
                <div class="cell-bottom-shuxing-view">
                  <div
                    style="
                      display: flex;
                      flex-direction: row;
                      align-items: center;
                    "
                    v-if="item.landArea > 0"
                  >
                    <img
                      class="cell_info_bottom_img"
                      src="../assets/mianji.png"
                    />
                    <p class="cell_info_bottom_label">
                      {{
                        item.landArea +
                        (item.landAreaUnit == "SQM" ? "㎡" : item.landAreaUnit || "㎡")
                      }}
                    </p>
                  </div>
                  <div
                    style="
                      display: flex;
                      flex-direction: row;
                      align-items: center;
                    "
                    v-if="item.bedroomCount > 0"
                  >
                    <img
                      class="cell_info_bottom_img"
                      src="../assets/woshi.png"
                    />
                    <p class="cell_info_bottom_label">
                      {{ item.bedroomCount }}
                    </p>
                  </div>
                  <div
                    style="
                      display: flex;
                      flex-direction: row;
                      align-items: center;
                    "
                    v-if="item.bathroomCount > 0"
                  >
                    <img
                      class="cell_info_bottom_img"
                      src="../assets/yushi.png"
                    />
                    <p class="cell_info_bottom_label">
                      {{ item.bathroomCount }}
                    </p>
                  </div>
                </div>
              </div>
              <div v-if="item.photos && item.photos.length > 0" class="photo-nums-view">
                <img
                  style="margin-left: 8px; height: 11px; width: 11px"
                  src="../assets/imgCount.png"
                />
                <p
                  style="
                    color: #fff;
                    font-size: 12px;
                    font-weight: 700;
                    margin-left: 5px;
                  "
                >
                  {{ item.photos.length }}
                </p>
              </div>
              <div
                @click.stop="goToOffice(item.agentMap.officeId)"
                v-if="item.show == true"
                class="agent-view"
              >
                <div
                  style="
                    z-index: 2;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                  "
                  v-for="(agentItem, agentIndex) in item.agents"
                  :key="agentIndex"
                >
                  <img
                    :class="
                      agentIndex == 0 ? 'cell_head_img' : 'cell_head_img_second'
                    "
                    :src="agentItem.userHeadImageUrl"
                  />
                </div>
                <div
                  :style="{ backgroundColor: item.agentMap.officeBrandHexCode }"
                  class="cell_office_image_s"
                >
                  <img
                    mode="heightFix"
                    style="height: 14px; width: 85px"
                    :src="item.agentMap.officeImage"
                  />
                </div>
              </div>
              <div
                v-if="item.collectType == '' || item.collectType == null"
                class="bottom-cell-view"
              >
                <div
                  @click.stop="collectEvent(item, 'NOPE', index)"
                  class="nope_btn"
                >
                  <div class="nope_img"></div>
                  <p class="nope_title">Nope</p>
                </div>
                <div
                  @click.stop="collectEvent(item, 'MAYBE', index)"
                  class="maybe-btn"
                >
                  <div
                    style="color: #212b36; font-size: 12px; font-weight: 700"
                  >
                    Maybe
                  </div>
                </div>
                <div
                  @click.stop="collectEvent(item, 'SHOUTLISTED', index)"
                  class="shortlist_btn"
                >
                  <div class="shortlist_img"></div>
                  <p class="shortlist_title">Shortlist</p>
                </div>
              </div>
              <div
                v-if="item.collectType == 'SHOUTLISTED'"
                style="justify-content: center"
                class="bottom-cell-view"
              >
                <div
                  @click.stop="uncollectEvent(item, index)"
                  class="shortlist_btn"
                >
                  <div class="shortlist_img"></div>
                  <p class="shortlist_title">Shortlist</p>
                </div>
              </div>
              <div
                v-if="item.collectType == 'MAYBE'"
                style="justify-content: center"
                class="bottom-cell-view"
              >
                <div
                  @click.stop="uncollectEvent(item, index)"
                  class="maybe-btn"
                >
                  <div
                    style="color: #212b36; font-size: 12px; font-weight: 700"
                  >
                    Maybe
                  </div>
                </div>
              </div>
              <div
                v-if="item.collectType == 'NOPE'"
                style="justify-content: center"
                class="bottom-cell-view"
              >
                <div @click.stop="uncollectEvent(item, index)" class="nope_btn">
                  <div class="nope_img"></div>
                  <p class="nope_title">Nope</p>
                </div>
              </div>
            </div>
            <div
              style="display: flex; flex-direction: column; height: 100%"
              v-if="item.sale == false"
            >
              <div class="img-content">
                <img
                  class="house-cell-img"
                  :src="
                    imgUrl(item) == ''
                      ? require('../assets/unsale_back.png')
                      : imgUrl(item)
                  "
                />
              </div>
              <p class="cell-title-label">{{ item.fullAddress }}</p>
              <p v-if="item.properties" class="cell-info-label">
                {{ item.properties.legalDescription }}
              </p>
              <div v-if="item.properties" class="cell-price-bottom-view">
                <p style="color: #158c79; font-size: 14px; font-weight: 700">
                  Not Listed
                </p>
                <div class="cell-bottom-shuxing-view">
                  <div
                    style="
                      display: flex;
                      flex-direction: row;
                      align-items: center;
                    "
                    v-if="item.properties.bedrooms > 0"
                  >
                    <img
                      class="cell_info_bottom_img"
                      src="../assets/woshi.png"
                    />
                    <p class="cell_info_bottom_label">
                      {{ item.properties.bedrooms }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="photo-nums-view">
                <img
                  style="margin-left: 8px; height: 11px; width: 11px"
                  src="../assets/imgCount.png"
                />
                <p
                  style="
                    color: #fff;
                    font-size: 12px;
                    font-weight: 700;
                    margin-left: 5px;
                  "
                >
                  No photo
                </p>
              </div>
              <div
                v-if="item.collectType == '' || item.collectType == null"
                class="bottom-cell-view"
              >
                <div
                  @click.stop="collectEvent(item, 'NOPE', index)"
                  class="nope_btn"
                >
                  <div class="nope_img"></div>
                  <p class="nope_title">Nope</p>
                </div>
                <div
                  @click.stop="collectEvent(item, 'MAYBE', index)"
                  class="maybe-btn"
                >
                  <div
                    style="color: #212b36; font-size: 12px; font-weight: 700"
                  >
                    Maybe
                  </div>
                </div>
                <div
                  @click.stop="collectEvent(item, 'SHOUTLISTED', index)"
                  class="shortlist_btn"
                >
                  <div class="shortlist_img"></div>
                  <p class="shortlist_title">Shortlist</p>
                </div>
              </div>
              <div
                v-if="item.collectType == 'SHOUTLISTED'"
                style="justify-content: center"
                class="bottom-cell-view"
              >
                <div
                  @click.stop="uncollectEvent(item, index)"
                  class="shortlist_btn"
                >
                  <div class="shortlist_img"></div>
                  <p class="shortlist_title">Shortlist</p>
                </div>
              </div>
              <div
                v-if="item.collectType == 'MAYBE'"
                style="justify-content: center"
                class="bottom-cell-view"
              >
                <div
                  @click.stop="uncollectEvent(item, index)"
                  class="maybe-btn"
                >
                  <div
                    style="color: #212b36; font-size: 12px; font-weight: 700"
                  >
                    Maybe
                  </div>
                </div>
              </div>
              <div
                v-if="item.collectType == 'NOPE'"
                style="justify-content: center"
                class="bottom-cell-view"
              >
                <div @click.stop="uncollectEvent(item, index)" class="nope_btn">
                  <div class="nope_img"></div>
                  <p class="nope_title">Nope</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "swiper/dist/css/swiper.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import { mapGetters } from "vuex";
import { collect, getHouseList, uncollect } from "../api/api.js";
import { compactObj, isEmpty } from "../utils/tools.js";
// import filterMenu from "../components/filterMenu.vue"
import filterNav from "@/components/filterNav.vue";
export default {
  data() {
    return {
      swiperOption: {
        loop: true,
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true, //允许分页点击跳转
        },
      },
      list: [],
      imageServerDomain: "",
      agentMap: {},
      loading: false,
      loadingMore: false,
      page: 1,
      hasMore: false,
      district: "", //区
      suburb: "", //街道
      listingSubType: "",
      adlist: [],
      adImageServerDomain: "",
      adAgentMap: {},
      form: {},
      total: 0,
    };
  },
  computed: {
    ...mapGetters("user", {
      isLogin: "isLogin",
    }),
  },
  components: {
    swiper,
    swiperSlide,
    filterNav,
  },
  destroyed() {
    window.removeEventListener("scroll", this.scroll, true);
  },
  created() {
    window.addEventListener("scroll", this.scroll, true);
  },
  mounted() {
    this.requestMain(true);
    this.requestAd();
  },
  activated() {
    if (this.$route.query.name) {
      if (this.$route.query.t == 1) {
        this.form.district = this.$route.query.name;
      } else if (this.$route.query.t == 2) {
        this.form.suburb = this.$route.query.name;
      }
      this.requestMain(true);
    }
    window.scrollTo(0, 0);
    console.log(this.$route.query);
  },
  methods: {
    goToOffice(id) {
      this.$router.push(`/office/${id}`);
    },
    searchEvent(form) {
      this.form = form;
      this.total = 0;
      this.requestMain(true);
    },
    clearEvent(form) {
      this.form = form;
      this.requestMain(true);
    },
    goToAdDetail(index) {
      console.log(index);
      this.$router.push(`/property/${this.adlist[index].id}`);
    },
    requestAd() {
      var data = {};
      data.top = 1;
      getHouseList(data).then((res) => {
        if (res.status == 200) {
          this.adImageServerDomain = res.data.imageServerDomain;
          this.adAgentMap = res.data.agentMap;
          this.adlist.length = 0;
          res.data.hoursePage.records.forEach((item) => {
            var newItem = {};
            newItem = item;
            newItem.agentMap = {};
            if (this.agentMap[item.agents[0].id]) {
              newItem.agentMap.officeBrandHexCode =
                this.agentMap[item.agents[0].id].officeBrandHexCode;
            } else {
              newItem.agentMap.officeBrandHexCode = "#000000";
            }
            if (this.adAgentMap[item.agents[0].id].image == null) {
              newItem.agentMap.userHeadImageUrl =
                "../../static/headDefault.png";
            } else {
              newItem.agentMap.userHeadImageUrl =
                this.adImageServerDomain +
                this.adAgentMap[item.agents[0].id].image["base-url"] +
                ".crop.128x128.jpg";
            }
            newItem.agentMap.name =
              this.adAgentMap[item.agents[0].id].firstName +
              " " +
              this.adAgentMap[item.agents[0].id].lastName;
            newItem.agentMap.officeImage =
              this.adImageServerDomain +
              this.adAgentMap[item.agents[0].id].officeImage +
              ".crop.350x70.jpg";
            this.adlist.push(newItem);
          });
        }
      });
    },
    onSlideChangeStart(currentPage) {
      console.log("onSlideChangeStart", currentPage);
    },
    onSlideChangeEnd(currentPage) {
      console.log("onSlideChangeEnd", currentPage);
    },
    regionChangeEvent(val) {
      console.log(val);
      this.form.district = "";
      this.form.suburb = "";
      var form = {};
      if (val[0] != "all") {
        if (val[1] != "all") {
          form.district = val[1];
        }
        if (val[2] != "all") {
          form.suburb = val[2];
        }
      }
      this.form = { ...this.form, ...form };
      this.requestMain(true);
      console.log(val);
    },
    collectEvent(item, collectType, index) {
      if (this.isLogin == false) {
        this.$router.push({ path: "/signUp" });
        return;
      }
      var form = {};
      form.hourseId = item.id;
      form.collectType = collectType;
      if (item.sale == true) {
        form.source = "ON_SALE";
      } else {
        form.source = "NOT_SALE";
      }
      collect(form).then((res) => {
        if (res.status == 200) {
          item.collectType = collectType;
          this.$set(this.list, index, item);
        }
      });
    },
    uncollectEvent(item, index) {
      var form = {};
      form.hourseId = item.id;
      if (item.sale == true) {
        form.source = "ON_SALE";
      } else {
        form.source = "NOT_SALE";
      }
      uncollect(form).then((res) => {
        if (res.status == 200) {
          item.collectType = "";
          this.$set(this.list, index, item);
        }
      });
    },
    goToDetail(item) {
      if (item.sale == true) {
        this.$router.push(`/property/${item.id}`);
      } else {
        this.$router.push(`/propertyNoListed/${item.addressId}`);
        //未售房源id = addressId
        console.log(item.addressId);
      }
    },
    scroll() {
      var scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      var windowHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
      var scrollHeight = document.body.scrollHeight;
      if (scrollTop + windowHeight + 1 >= scrollHeight) {
        if (
          this.hasMore &&
          this.loading == false &&
          this.loadingMore == false
        ) {
          this.requestMain(false);
        }
      }
    },
    imgIndexUrl(item, index, w, h) {
      if (item.photos) {
        if (item.source === "OWNER" && item.photos.length > index) {
          return item.photos[index].baseUrl;
        } else {
          if (item.photos.length > index) {
            if (item.photos[index].baseUrl) {
              if (item.photos[index].baseUrl.indexOf("http") != -1) {
                return item.photos[index].baseUrl;
              } else {
                return (
                  this.adImageServerDomain +
                  item.photos[index].baseUrl + `.crop.1660x948.jpg?x-oss-process=image/resize,m_fill,h_${h},w_${w}`
                );
              }
            }
          } else {
            return "";
          }
        }
      }
      return "";
    },
    imgUrl(item) {
      if (item.photos) {
        if (item.source === "OWNER" && item.photos.length > 0) {
          return item.photos[0].baseUrl;
        } else {
          if (item.photos.length > 0) {
            if (item.photos[0].baseUrl) {
              if (item.photos[0].baseUrl.indexOf("http") != -1) {
                return item.photos[0].baseUrl;
              } else {
                return (
                  this.imageServerDomain +
                  item.photos[0].baseUrl +
                  ".crop.1660x948.jpg"
                );
              }
            }
          } else {
            return "";
          }
        }
      }
      return "";
    },
    requestMain(isFresh) {
      var form = {};
      if (isFresh == true) {
        this.page = 1;
        this.loading = true;
      } else {
        this.loadingMore = true;
      }
      form.pageIndex = this.page;
      form.pageSize = 12;
      form = { ...form, ...this.form };
      if (
        form.address == "" ||
        form.address == null ||
        form.address == undefined
      ) {
        form.top = 0;
      }
      compactObj(form, isEmpty);
      getHouseList(form).then((res) => {
        this.loadingMore = false;
        this.loading = false;
        if (res.status == 200) {
          this.imageServerDomain = res.data.imageServerDomain;
          this.agentMap = res.data.agentMap;
          if (isFresh == true) {
            this.list.length = 0;
          }
          this.total = res.data.hoursePage.total;
          res.data.hoursePage.records.forEach((item) => {
            var newItem = {};
            newItem = item;
            if (item.agents) {
              if (item.agents != null && item.agents.length > 0) {
                newItem.agentMap = {};
                if (this.agentMap[item.agents[0].id]) {
                  newItem.agentMap.officeId =
                    this.agentMap[item.agents[0].id].officeId;
                  newItem.agentMap.officeBrandHexCode =
                    this.agentMap[item.agents[0].id].officeBrandHexCode;
                } else {
                  newItem.agentMap.officeBrandHexCode = "#000000";
                }
                if (this.agentMap[item.agents[0].id]) {
                  if (this.agentMap[item.agents[0].id].image == null) {
                    newItem.agentMap.userHeadImageUrl = require("../assets/headDefault.png");
                  } else {
                    if (
                      this.agentMap[item.agents[0].id].image[
                        "base-url"
                      ].indexOf("http") != -1
                    ) {
                      newItem.agentMap.userHeadImageUrl =
                        this.agentMap[item.agents[0].id].image["base-url"];
                    } else {
                      newItem.agentMap.userHeadImageUrl =
                        this.imageServerDomain +
                        this.agentMap[item.agents[0].id].image["base-url"] +
                        ".crop.128x128.jpg";
                    }
                  }
                }
                if (this.agentMap[item.agents[0].id]) {
                  newItem.agentMap.name =
                    this.agentMap[item.agents[0].id].firstName +
                    " " +
                    this.agentMap[item.agents[0].id].lastName;
                  newItem.agentMap.officeImage =
                    this.imageServerDomain +
                    this.agentMap[item.agents[0].id].officeImage +
                    ".crop.350x70.jpg";
                }
                newItem.agents.forEach((subItem) => {
                  if (this.agentMap[subItem.id]) {
                    if (this.agentMap[subItem.id].image) {
                      if (
                        this.agentMap[subItem.id].image["base-url"].indexOf(
                          "http"
                        ) != -1
                      ) {
                        subItem.userHeadImageUrl =
                          this.agentMap[subItem.id].image["base-url"];
                      } else {
                        subItem.userHeadImageUrl =
                          this.imageServerDomain +
                          this.agentMap[subItem.id].image["base-url"] +
                          ".crop.200x200.jpg";
                      }
                    } else {
                      subItem.userHeadImageUrl = require("../assets/headDefault.png");
                    }
                    subItem.officeBrandHexCode =
                      this.agentMap[subItem.id].officeBrandHexCode;
                    subItem.officeImage =
                      this.imageServerDomain +
                      this.agentMap[subItem.id].officeImage +
                      ".crop.350x70.jpg";
                    subItem.phoneMobile = this.agentMap[subItem.id].phoneMobile;
                    subItem.introduction =
                      this.agentMap[subItem.id].introduction;
                    subItem.name =
                      this.agentMap[subItem.id].firstName +
                      " " +
                      this.agentMap[subItem.id].lastName;
                    subItem.email = this.agentMap[subItem.id].email;
                  }
                });
              }
            }
            this.list.push(newItem);
          });
          if (res.data.hoursePage.records.length < 12) {
            this.hasMore = false;
          } else {
            this.hasMore = true;
            this.page++;
          }
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
/deep/ .swiper-pagination-bullet {
  background-color: #d9d9d9;
}
/deep/ .swiper-pagination-bullet-active {
  background-color: #959595;
}
.right-view {
  width: 1360px;
  display: flex;
  flex-direction: column;
}
.filter-menu {
  margin-left: 4px;
  width: 1056px;
  // width: calc(100% - 384px);
  height: 75px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: fixed;
  z-index: 998;
  background-color: #fff;
}
.ad-main {
  margin-top: 25px;
  margin-left: 25px;
  min-width: 1010px;
  height: 419px;
  width: calc(100% - 50px);
}
.ad-main .swiper-container {
  position: relative;
  width: 100%;
  height: 418px;
  border: 1px solid #dfdce2;
  border-radius: 9px;
}
.ad-main .swiper-container .swiper-slide {
  width: 100%;
  height: 418px;
  color: #000;
  background: #ffffff;
  display: flex;
  flex-direction: column;
}
.ad-imgs-main {
  height: 288px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  &__left {
    width: calc(50% - 2px);
    height: 100%;
    position: relative;
  }
  &__right {
    width: calc(50% - 2px);
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    justify-content: space-between;
  }
}
.right_small_img {
  width: calc(50% - 2px);
  height: calc(50% - 2px);
}
.ad-content-main {
  height: 130px;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}
.list-main {
  min-width: 1360px;
  width: 100%;
  margin-left: 0px;
}
.house-list {
  margin-top: 0rpx;
  margin-bottom: 25px;
  margin-left: 104px;
  width: 1152px;
  // width: calc(100% - 50px);
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: space-between;
}
.house-cell {
  margin-top: 25px;
  height: 368px;
  position: relative;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  width: 372px;
  border-radius: 16px;
  box-shadow: 0px 12px 24px -4px rgba(145, 158, 171, 0.12),
    0px 0px 2px 0px rgba(145, 158, 171, 0.2);
}
.house-cell:hover {
  box-shadow: 0px 0px 5px 2px rgba(74, 74, 74, 0.25);
}
.bottom-cell-view {
  margin-top: auto;
  margin-bottom: 15px;
  height: 36x;
  margin-left: 20px;
  width: calc(100% - 40px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.bottom-btn-view {
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.img-content {
  margin-top: 8px;
  margin-left: 8px;
  width: calc(100% - 16px);
  height: 196px;
  border-radius: 8px;
  overflow: hidden;
}
.house-cell-img {
  width: 100%;
  height: 204px;
  border-radius: 8px;
}
.cell-title-label {
  margin-top: 20px;
  color: #212b36;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  width: calc(100% - 40px);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  margin-left: 20px;
  text-align: left;
}
.cell-info-label {
  margin-top: 8px;
  font-size: 12px;
  color: #919eab;
  line-height: 15px;
  width: calc(100% - 40px);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  margin-left: 20px;
  text-align: left;
}
.cell-price-bottom-view {
  height: 20px;
  margin-top: 8px;
  width: calc(100% - 40px);
  margin-left: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.cell-bottom-shuxing-view {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
  margin-right: 0px;
}
.cell_info_bottom_label {
  font-size: 10px;
  line-height: 12px;
  color: #000000;
  font-weight: 700;
  margin-left: 5px;
}
.cell_info_bottom_img {
  width: 15px;
  height: 15px;
  margin-left: 15px;
}
.photo-nums-view {
  height: 22px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 8px;
  background: var(--grey-800, #212b36);
  position: absolute;
  z-index: 100;
  top: 174px;
  left: auto;
  right: 20px;
  padding-right: 8px;
}
.agent-view {
  position: absolute;
  left: 17px;
  top: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.ad-agent-view {
  position: absolute;
  left: auto;
  right: 24px;
  top: 45px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.ad_head_img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #eaeaea;
  z-index: 2;
}
.cell_head_img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #eaeaea;
}
.cell_head_img_second {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #eaeaea;
  margin-left: -10px;
}
.cell_office_image_s {
  border-radius: 4px;
  height: 22px;
  margin-left: -10px;
  width: 85px;
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.cell_office_image_b {
  border-radius: 4px;
  height: 44px;
  margin-left: -20px;
  width: 164px;
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.house-cell:hover .house-cell-img {
  transform: scale(1.2, 1.2);
  -ms-transform: scale(1.2, 1.2); /* IE 9 */
  -moz-transform: scale(1.2, 1.2); /* Firefox */
  -o-transform: scale(1.2, 1.2); /* Opera */
  -webkit-transform: scale(1.2, 1.2); /* Safari 和 Chrome */
}
.nope_btn {
  border-radius: 8px;
  background: rgba(255, 171, 0, 0.08);
  width: 105px;
  height: 36px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.house-cell:hover .nope_btn {
  background-color: #ff9933;
}
.nope_img {
  width: 12px;
  height: 12px;
  background: url("../assets/nopesel.png");
  background-size: cover;
}
.house-cell:hover .nope_img {
  width: 12px;
  height: 12px;
  background: url("../assets/nope.png");
  background-size: cover;
}
.nope_title {
  color: #ff9933;
  font-size: 14px;
  font-weight: 700;
  margin-left: 6px;
}
.house-cell:hover .nope_title {
  color: #ffffff;
  font-size: 12px;
  font-weight: 700;
  margin-left: 6px;
}

.shortlist_btn {
  border-radius: 8px;
  background: rgba(2, 149, 128, 0.08);
  width: 105px;
  height: 36px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.house-cell:hover .shortlist_btn {
  background-color: #158c79;
}
.shortlist_img {
  width: 12px;
  height: 12px;
  background: url("../assets/gouxuansel.png");
  background-size: cover;
}
.house-cell:hover .shortlist_img {
  width: 12px;
  height: 12px;
  background: url("../assets/gouxuan.png");
  background-size: cover;
}
.shortlist_title {
  color: #158c79;
  font-size: 12px;
  font-weight: 700;
  margin-left: 6px;
}
.house-cell:hover .shortlist_title {
  color: #ffffff;
  font-size: 12px;
  font-weight: 700;
  margin-left: 6px;
}
.maybe-btn {
  width: 105px;
  height: 36px;
  border-radius: 8px;
  background: rgba(145, 158, 171, 0.08);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
</style>