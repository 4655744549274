<template>
  <div class="basic-information">
    <div class="basic-information__row">
      <div class="basic-information__row-title">Ownership</div>
      <div class="basic-information__row-content">
        <span>{{
          wholeName == 1 ? ownerShip : ownerShip.substring(0, 3) + "******"
        }}</span>
        <img
          v-if="wholeName == 1"
          class="fyi"
          :src="require('@/assets/fyi.png')"
          @click="showContactUs"
        />
        <span v-if="wholeName == 0">&nbsp;( </span>
        <img
          v-if="wholeName == 0"
          :src="require('@/assets/scope.png')"
          alt="detail"
        />
        <span
          v-if="wholeName == 0"
          style="color: #158c79; cursor: pointer"
          @click="showConfirm"
        >
          Detail
        </span>
        <span v-if="wholeName == 0">)</span>
        <div
          v-if="showAgreeConfirm"
          class="basic-information__row-confirm"
          :style="{
            top: agreementY + 'px',
            left: agreemenX + 'px',
          }"
        >
          <div class="confirm-agreement">
            <img
              class="agreement-check"
              :src="
                !isConfirm
                  ? require('@/assets/check-blank.png')
                  : require('@/assets/check-right.png')
              "
              alt="1"
              @click="checkConfirm"
            />
            <span style="margin-left: 5px">I agree with </span>
            <span
              style="color: #158c79; cursor: pointer"
              @click="showAgreeDetail"
              >&nbsp;homeowner info terms.</span
            >
          </div>
          <div class="confirm-button-wrapper">
            <div class="confirm-button" @click="showOwnerShip">
              <span>Show ownership</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="basic-information__row">
      <div class="basic-information__row-title">Land Title</div>
      <div class="basic-information__row-content" v-html="landTitle"></div>
    </div>
    <div class="basic-information__row">
      <div class="basic-information__row-title">Cetificate of Title</div>
      <div class="basic-information__row-content">{{ certificateOfTitle }}</div>
    </div>
    <div class="basic-information__row">
      <div class="basic-information__row-title">Legal Description</div>
      <div class="basic-information__row-content">{{ legalDescription }}</div>
    </div>
    <div class="basic-information__row">
      <div class="basic-information__row-title">Zoning</div>
      <div class="basic-information__row-content">{{ zoning }}</div>
    </div>
    <!-- todo: make it a component -->
    <div v-if="showTerms" class="dialog-mask">
      <div class="terms-dialog">
        <div class="terms-content">
          <div class="terms-head">
            <div class="terms-left"></div>
            <div class="terms-center">
              <span>Homeowner information terms</span>
            </div>
            <div class="terms-right"></div>
          </div>
          <div class="terms-inner-content">
            <p>
              All homeowner data displayed by PropCentre is obtained from Toitū
              Te Whenua (LINZ)’s public platform. This homeowner data (i) is
              displayed for informational purposes only, to assist PropCentre
              users by providing all public information about a property in one
              centralised platform, and (ii) is collected and dealt with in
              accordance with the Creative Commons Attribution 3.0 New Zealand
              Licence (CC BY 3.0 NZ) and the LINZ Licence for Personal Data,
              available here
              https://www.linz.govt.nz/products-services/data/licensing-and-using-data/linz-licence-personal-data,
              along with our Ts and Cs and our Privacy Policy. By proceeding,
              you acknowledge and agree to the terms of the CC BY 3.0 NZ and
              LINZ Licence for Personal Data and our Ts and Cs and privacy
              policy. If you have any questions or require any information to be
              removed, please contact us at info@propcentre.co.nz.
            </p>
          </div>
        </div>
        <div class="terms-bottom">
          <div class="terms-confirm" @click="hideTerms">
            <span>Confirm</span>
          </div>
        </div>
      </div>
    </div>
    <div v-if="contactUs" class="dialog-mask">
      <div class="terms-dialog" style="height: 300px">
        <div class="terms-content">
          <div class="terms-head">
            <div class="terms-left"></div>
            <div class="terms-center">
              <span>Contact Us</span>
            </div>
            <div class="terms-right"></div>
          </div>
          <div class="terms-inner-content">
            <p>
              If you have any questions or come across any errors, please
              contact us via the following email address: info@propcentre.co.nz.
            </p>
          </div>
        </div>
        <div class="terms-bottom">
          <div class="terms-confirm" @click="hideContact">
            <span>Confirm</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getEmptyStringPropsDef } from "@/common/propsConfig";
export default {
  props: {
    ownerShip: getEmptyStringPropsDef(),
    landTitle: getEmptyStringPropsDef(),
    certificateOfTitle: getEmptyStringPropsDef(),
    legalDescription: getEmptyStringPropsDef(),
    zoning: getEmptyStringPropsDef(),
  },
  data() {
    return {
      contactUs: false,
      showAgreeConfirm: false,
      agreemenX: 0,
      agreementY: 0,
      isConfirm: false,
      agreeDetail: false,
      wholeName: 0,
      showTerms: false,
    };
  },
  mounted() {
    this.wholeName = window.localStorage.getItem("wholeName") || 0;
  },
  methods: {
    showConfirm(evt) {
      this.showAgreeConfirm = true;
      this.agreemenX = evt.clientX;
      this.agreementY = evt.clientY;
    },
    checkConfirm() {
      this.isConfirm = !this.isConfirm;
    },
    showAgreeDetail() {
      this.agreeDetail = true;
      this.showTerms = true;
    },
    hideTerms() {
      this.showTerms = false;
    },
    showOwnerShip() {
      if (!this.isConfirm) {
        this.$message({
          type: "warning",
          message: "Please agree with the terms.",
        });
        return;
      }
      this.showAgreeConfirm = false;
      this.wholeName = 1;
      window.localStorage.setItem("wholeName", 1);
    },
    showContactUs() {
      this.contactUs = true;
    },
    hideContact() {
      this.contactUs = false;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./basicInformation.scss";
</style>