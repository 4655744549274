<template>
  <div class="agent-house-card">
    <div
      class="agent-house-card-picture"
      :style="`background-image: url(${this.imageUrl})`"
    />
    <div class="agent-house-card-info">
      <h3 style="color: #158c79; text-align: left">{{ this.price }}</h3>
      <p class="agent-house-card-desc">{{ this.address }}</p>
      <div class="agent-house-data">
        <div class="agent-house-data-item">
          <img
            class="agent-house-data-item-icon"
            :src="require('@/assets/area.png')"
          />
          <span>{{ this.area }} ㎡</span>
        </div>
        <div class="agent-house-data-item">
          <img
            class="agent-house-data-item-icon"
            :src="require('@/assets/woshi.png')"
          />
          <span>{{ this.bedroom }}</span>
        </div>
        <div class="agent-house-data-item">
          <img
            class="agent-house-data-item-icon"
            :src="require('@/assets/yushi.png')"
          />
          <span>{{ this.bathroom }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "./agent.scss";

export default {
  props: {
    address: {
      type: String,
      default: "",
    },
    price: {
      type: String,
      default: "",
    },
    area: {
      type: Number,
      default: 0,
    },
    bedroom: {
      type: Number,
      default: 0,
    },
    bathroom: {
      type: Number,
      default: 0,
    },
    imageUrl: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      msg: "Welcome to Your Vue.js App",
    };
  },
  methods: {
    getAgent() {
      this.$store.dispatch("agent/getAgent");
    },
  },
};
</script>