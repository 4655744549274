const en = {
  nav: {
    Map: "Map",
    Properties: "Properties",
    Analyse: "Analyse",
    PropGuide: "PropGuide",
    Shortlist: "Shortlist",
    AboutUs: "About us",
    Utilities: "Utilities",
    Home: "Home",
    Gallery: "Gallery",
  },
  map: {
    Map: "Map",
    Satellite: "Satellite",
    ForSale: "For Sale",
    ShowStormPipe: "Show storm pipe",
    ShowWastePipe: "Show waste pipe",
    Shortlist: "Shortlist",
    Shortlisted: "Shortlisted",
    Maybe: "Maybe",
    Nope: "Nope",
  },
  detail: {
    Listed: "Listed",
    Size: "Land Area",
    Bedrooms: "Bedrooms",
    Bathrooms: "Bathrooms",
    Garages: "Garages",
    Location: "Location",
    OpenHome: "Open home",
    BasicInformation: "Basic information",
    Details: "Details",
    CapitalValuation: "Capital Valuation",
    PropertyInformation: "Property Information",
    Timeline: "Timeline",
    SchoolZoning: "School Zoning",
    ContactTheAgent: "Contact the Agent",
    Suburb: "Suburb",
    NearbyProperties: "Nearby Properties",
    RecentlyViewedListings: "Recently Viewed Listings",
    All: "All",
    Primary: "Primary",
    Intermediate: "Intermediate",
    Secondary: "Secondary",
    ShowMore: "Show more",
    ShowLess: "Show less",
    City: "City",
    Suburbs: "Suburbs",
    AboutPropCentreCoNz: "About PropCentre.co.nz",
    PropertyType: "Property type",
    Price: "Price",
    TypeAddress: "Type an address",
  },
  analyse: {
    PropertiesDataCentre: "Properties Data Centre",
    Size: "Land Area",
    Bedrooms: "Bedrooms",
    Bathrooms: "Bathrooms",
    Garages: "Garages",
    floorArea: "Floor Area",
    subType: "Type",
    BasicInformation: "Basic information",
    CapitalValuation: "Capital Valuation",
    PropertyInformation: "Property Information",
    Timeline: "Timeline",
    SchoolZoning: "School Zoning",
    Parking: "Garage&Parking",
    listingSubType: "Type"
  },
  shortlist: {
    ShortlistedProperties: "Shortlisted Properties",
    All: "All",
    Shortlisted: "Shortlisted",
    Maybe: "Maybe",
    Nope: "Nope",
    Bedrooms: "Bedrooms",
    Bathrooms: "Bathrooms",
    Garages: "Garages",
  },
  aboutus: {
    CompanyIntroduction: "Company Introduction",
    cTextLineF:
      "Welcome to PropCentre，a cutting-edge online platform that leverages the power of AI and big data analytics to revolutionize the real estate industry.",
    cTextLineS:
      "At PropCentre, we are committed to reshaping the way people buy and sell real estate. Whether you are a homebuyer searching for the perfect property or a real estate professional looking to streamline your workflow, our platform is designed to meet your needs.",
    Functions: "Functions",
    fTextLineTF: "1. Intelligent Property Search",
    fTextLineTFT:
      "Our platform empowers homebuyers with the ability to effortlessly search for available properties, gaining access to comprehensive information about each listing. Find your dream home with ease, armed with all the essential details you need.",
    fTextLineTS: "2. Extensive Property Insights",
    fTextLineTST:
      "Discover in-depth insights about every property, from floor plans and amenities to neighborhood statistics. Make informed decisions by accessing a wealth of information right at your fingertips.",
    fTextLineTT: "3.Professional Tools for Real Estate Experts",
    fTextLineTTT:
      "Real estate professionals can supercharge their productivity using our suite of property-related tools. From market analysis to client management, our platform equips you with the resources to excel in the industry.",
    gnfxzj:
      "Our platform harnesses the latest advancements in AI and big data analytics to ensure that you receive the most accurate and up-to-date information available. We believe that by merging technology and real estate expertise, we can simplify the buying and selling process, making it more efficient and transparent for everyone involved.",
    szyxfw: "Digital Marketing Service",
    szyxfw1: "Real estate listing Promoting",
    szyxfw1T:
      "Elevate your property is visibility and desirability in the competitive real estate market with our Online Real Estate Listing Promotion service. Whether you are a homeowner or a real estate professional, our platform offers a comprehensive solution to showcase your listings effectively.",
    szyxfw2: "Display placement advertising",
    szyxfw2T:
      "Unlock the potential of precision and impact in your digital advertising campaigns with our Display Placement Advertising service. Especially for Businesses seeking to expand their reach or marketers aiming for highly targeted exposure can utilize our platform for a comprehensive solution to amplify their brand presence.",
    MultiPlatformAd: "Multi-Platform Influencer Advertising",
    NZAndOversea: "NZ and oversea",
    NZContent:
      "Take your marketing efforts to the next level with Multi-Platform Influencer Advertising, a dynamic strategy that leverages the power of influencers across various social media platforms. This service offers a comprehensive solution to harness the reach and authenticity of influencers. You can use it to enhance brand awareness, drive engagement, or boost sales.",
    TechnicalD: "Technical development support for enterprise",
    TechnicalDC: "Data Analysis Personalized recommendation Conversation AI&Chat bots",
    OurMission: "Our Mission",
    OurMission1:
      "At PropCentre, our mission is to empower individuals and professionals alike with the tools and knowledge they need to navigate the real estate market with confidence. We are dedicated to providing a seamless, user-friendly experience that elevates the real estate industry to new heights.",
    OurMission2:
      "Join us on this exciting journey, where innovation meets real estate, and together, we will shape the future of property transactions.",
    OurMission3: "Explore PropCentre today and experience the future of real estate.",
    ContactUs: "Contact Us",
  },
};
export default en;
