var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"gallery-wrapper"},[_c('div',{staticClass:"gallery-bg-wrapper"},[_c('img',{staticClass:"gallery-bg",attrs:{"src":require('../../assets/gallery-bg.jpg')}})]),_c('div',{staticClass:"gallery-content-wrapper"},[_c('div',{staticClass:"gallery-top"},[_c('div',{staticClass:"gallery-top-module"},[_c('img',{staticClass:"gallery-top-logo",attrs:{"src":require('../../assets/gallery-logo.png')}})]),_vm._m(0)]),_vm._m(1),_c('div',{staticClass:"gallery-tabs"},[_c('div',{class:_vm.galleryType === 'PHOTOGRAPHY'
            ? 'gallery-tab-item-active'
            : `gallery-tab-item`,on:{"click":function($event){return _vm.switchTab('PHOTOGRAPHY')}}},[_c('span',[_vm._v("Photography")])]),_c('div',{class:_vm.galleryType === 'VR'
            ? 'gallery-tab-item-active'
            : `gallery-tab-item`,on:{"click":function($event){return _vm.switchTab('VR')}}},[_c('span',[_vm._v("VR")])]),_c('div',{class:_vm.galleryType === 'VIDEO'
            ? 'gallery-tab-item-active'
            : `gallery-tab-item`,on:{"click":function($event){return _vm.switchTab('VIDEO')}}},[_c('span',[_vm._v("Video")])]),_c('div',{class:_vm.galleryType === 'FLOOR_PLAN'
            ? 'gallery-tab-item-active'
            : `gallery-tab-item`,on:{"click":function($event){return _vm.switchTab('FLOOR_PLAN')}}},[_c('span',[_vm._v("Floor Plan")])]),_c('div',{class:_vm.galleryType === 'DRONE_VISION'
            ? 'gallery-tab-item-active'
            : `gallery-tab-item`,on:{"click":function($event){return _vm.switchTab('DRONE_VISION')}}},[_c('span',[_vm._v("Drone Vision")])])]),(_vm.galleryType !== 'FLOOR_PLAN')?_c('div',{staticClass:"gallery-content-list"},_vm._l((_vm.photos),function(photo,index){return _c('div',{key:photo.houseId,staticClass:"gallery-content-list-photo",style:(`background-image: url(${photo.url})`),on:{"mouseover":function($event){return _vm.showIntro(index)},"mouseleave":function($event){return _vm.showIntro(-1)},"click":function($event){return _vm.showDetail(
            photo.houseId,
            photo.address.region,
            photo.publishedDate,
            photo.vrVrl
          )}}},[(_vm.introIndex === index)?_c('div',{staticClass:"photo-intro"},[_c('div',{staticClass:"photo-district"},[_c('span',[_vm._v(_vm._s(photo.address.region))])])]):_vm._e()])}),0):_vm._e(),(_vm.galleryType === 'FLOOR_PLAN')?_c('div',{staticClass:"gallery-content-list"},[_vm._l((_vm.photos),function(photo,index){return _c('img',{key:photo.houseId,staticClass:"gallery-content-list-photo-img",attrs:{"src":photo.url},on:{"mouseover":function($event){return _vm.showIntro(index)},"mouseleave":function($event){return _vm.showIntro(-1)}}})}),(_vm.introIndex === _vm.index)?_c('div',{staticClass:"photo-intro"},[_c('div',{staticClass:"photo-district"},[_c('span',[_vm._v(_vm._s(_vm.photo.address.region))])])]):_vm._e()],2):_vm._e()]),(_vm.detailId)?_c('div',{staticClass:"gallery-detail-list",on:{"click":_vm.hideDetail}},[_c('div',{staticClass:"gallery-detail-list-content"},[_c('div',{staticClass:"gallery-detail-title"},[_c('img',{staticClass:"left-icon",attrs:{"src":require('../../assets/left_icon.png')},on:{"click":_vm.hideDetail}}),_c('div',{staticClass:"gallery-detail-title-text"},[_c('span',[_vm._v(_vm._s(_vm.detailTitle))])])]),_c('div',{staticClass:"gallery-detail-content"},_vm._l((_vm.detailPhotos),function(photo){return _c('div',{key:photo,staticClass:"gallery-detail-photo",style:(`background-image: url(${photo})`)})}),0)])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"gallery-top-module",staticStyle:{"justify-content":"flex-start"}},[_c('p',{staticClass:"gallery-introduction"},[_vm._v(" Property images need to be more than just beautiful, memorable, and creative. They have a very specific job: generate leads. Take a look through some of our photography projects that have done just that for a number of different clients. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"gallery-video"},[_c('video',{staticClass:"gallery-video-content",attrs:{"controls":"","src":"https://oss-propercentre.oss-ap-southeast-1.aliyuncs.com/PropCentre%2B%E5%85%AC%E5%8F%B8%E4%BB%8B%E7%BB%8D%2B%E6%9C%89%E5%AD%97%E5%B9%95-%E5%B0%8F.mp4"}})])
}]

export { render, staticRenderFns }