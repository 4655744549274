/**
 * @desc 时间解析函数
 * @param {*} timeStr 
 * @returns 
 */
export const dateTimeParser = (timeStr) => {
  const d = new Date(timeStr);
  const year = d.getFullYear();
  const month = d.getMonth() + 1;
  const dat = d.getDate();
  const hours = d.getHours();
  const minute = d.getMinutes();
  const second = d.getSeconds();
  const day = d.getDay();
  return { year, month, dat, hours, minute, second, day }
}

// /**
//  * @desc resoveImageUrl
//  * @param {*} item 
//  * @returns 
//  */
// export const resoveImageUrl = (item) => {
//   if (item.photos !=  null) {
//     if (item.source === 'OWNER') {
//       return item.photos[0].baseUrl
//     }else {
//       if (item.photos.length > 0) {
//         if (item.photos[0].baseUrl.indexOf('http') != -1) {
//           return item.photos[0].baseUrl
//         }else {
//           return this.imageServerDomain + item.photos[0].baseUrl + ''
//         }
//       }else {
//         return ''
//       }
//     }
//   }
//   return ''
// }

/**
 * 去除对象中所有符合条件的对象
 * @param {Object} obj 来源对象
 * @param {Function} fn 函数验证每个字段
 */
export const compactObj = (obj, fn)=> {
  for (var i in obj) {
    if (typeof obj[i] === 'object') {
      compactObj(obj[i], fn)
    }
    if (fn(obj[i])) {
      delete obj[i]
    }
  }
}
 
// 删除空对象 删除'', null, undefined
export const isEmpty = (foo)=> {
    if (typeof foo === 'object') {
      for (var i in foo) {
        return false
      }
      return true
    } else {
      return foo === '' || foo === null || foo === undefined
    }
}