<template>
  <div class="property-location">
    <!-- <div class="property-location__address">
      <div class="property-location__address-icon">
        <svg
          width="14"
          height="17"
          viewBox="0 0 14 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.6625 16.0437L7 15.5938L6.6625 16.0437L7 16.2969L7.3375 16.0437L7 15.5938L7.3375 16.0437H7.33806L7.33919 16.0421L7.34313 16.0392L7.35719 16.0286L7.40781 15.9892C7.69543 15.7626 7.9766 15.5279 8.251 15.2855C8.95531 14.6643 9.61857 13.998 10.2366 13.2909C11.6626 11.6523 13.1875 9.33875 13.1875 6.875C13.1875 3.45781 10.4172 0.6875 7 0.6875C3.58281 0.6875 0.8125 3.45781 0.8125 6.875C0.8125 9.33875 2.338 11.6523 3.76281 13.2909C4.56992 14.2141 5.45377 15.0672 6.40488 15.8413C6.48347 15.9046 6.56279 15.967 6.64281 16.0286L6.65631 16.0398L6.66081 16.0421L6.66194 16.0437H6.6625ZM1.9375 6.875C1.9375 5.53234 2.47087 4.24467 3.42027 3.29527C4.36967 2.34587 5.65734 1.8125 7 1.8125C8.34266 1.8125 9.63032 2.34587 10.5797 3.29527C11.5291 4.24467 12.0625 5.53234 12.0625 6.875C12.0625 8.91125 10.7755 10.9571 9.38781 12.5529C8.65582 13.39 7.85727 14.1666 7 14.8749C6.14273 14.1666 5.34418 13.39 4.61219 12.5529C3.2245 10.9571 1.9375 8.91125 1.9375 6.875Z"
            fill="#158C79"
          />
        </svg>
      </div>
      <span>{{ address }}</span>
    </div> -->
    <div
      class="property-location__map"
      id="map"
      :style="{ height: `${height}px` }"
    />
  </div>
</template>
<script>
// import * as L from "leaflet";
import { getEmptyStringPropsDef } from "@/common/propsConfig";
import { mapToken } from "@/common/map";
import mapboxgl from "!mapbox-gl";
export default {
  props: {
    address: getEmptyStringPropsDef(),
    lat: getEmptyStringPropsDef(),
    lng: getEmptyStringPropsDef(),
  },
  data() {
    return {
      height: 0,
    };
  },
  mounted() {
    const width = document.querySelector("#map").offsetWidth;
    this.height = width * 0.73;
    mapboxgl.accessToken = mapToken;
    setTimeout(() => {
      const map = new mapboxgl.Map({
        container: "map", // container ID
        style: "mapbox://styles/mapbox/streets-v12", // style URL
        center: [this.lng, this.lat], // starting position [lng, lat]
        zoom: 16, // starting zoom
      });
      new mapboxgl.Marker({ anchor: "center" })
        .setLngLat([this.lng, this.lat])
        .addTo(map);
    }, 100);
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
@import "./propertyLocation.scss";
@import "/node_modules/mapbox-gl/dist/mapbox-gl.css";
</style>
<style lang="scss">
.mapboxgl-ctrl-bottom-right {
  display: none !important;
}
</style>