var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"basic-information"},[_c('div',{staticClass:"basic-information__row"},[_c('div',{staticClass:"basic-information__row-title"},[_vm._v("Ownership")]),_c('div',{staticClass:"basic-information__row-content"},[_c('span',[_vm._v(_vm._s(_vm.wholeName == 1 ? _vm.ownerShip : _vm.ownerShip.substring(0, 3) + "******"))]),(_vm.wholeName == 1)?_c('img',{staticClass:"fyi",attrs:{"src":require('@/assets/fyi.png')},on:{"click":_vm.showContactUs}}):_vm._e(),(_vm.wholeName == 0)?_c('span',[_vm._v(" ( ")]):_vm._e(),(_vm.wholeName == 0)?_c('img',{attrs:{"src":require('@/assets/scope.png'),"alt":"detail"}}):_vm._e(),(_vm.wholeName == 0)?_c('span',{staticStyle:{"color":"#158c79","cursor":"pointer"},on:{"click":_vm.showConfirm}},[_vm._v(" Detail ")]):_vm._e(),(_vm.wholeName == 0)?_c('span',[_vm._v(")")]):_vm._e(),(_vm.showAgreeConfirm)?_c('div',{staticClass:"basic-information__row-confirm",style:({
          top: _vm.agreementY + 'px',
          left: _vm.agreemenX + 'px',
        })},[_c('div',{staticClass:"confirm-agreement"},[_c('img',{staticClass:"agreement-check",attrs:{"src":!_vm.isConfirm
                ? require('@/assets/check-blank.png')
                : require('@/assets/check-right.png'),"alt":"1"},on:{"click":_vm.checkConfirm}}),_c('span',{staticStyle:{"margin-left":"5px"}},[_vm._v("I agree with ")]),_c('span',{staticStyle:{"color":"#158c79","cursor":"pointer"},on:{"click":_vm.showAgreeDetail}},[_vm._v(" homeowner info terms.")])]),_c('div',{staticClass:"confirm-button-wrapper"},[_c('div',{staticClass:"confirm-button",on:{"click":_vm.showOwnerShip}},[_c('span',[_vm._v("Show ownership")])])])]):_vm._e()])]),_c('div',{staticClass:"basic-information__row"},[_c('div',{staticClass:"basic-information__row-title"},[_vm._v("Land Title")]),_c('div',{staticClass:"basic-information__row-content",domProps:{"innerHTML":_vm._s(_vm.landTitle)}})]),_c('div',{staticClass:"basic-information__row"},[_c('div',{staticClass:"basic-information__row-title"},[_vm._v("Cetificate of Title")]),_c('div',{staticClass:"basic-information__row-content"},[_vm._v(_vm._s(_vm.certificateOfTitle))])]),_c('div',{staticClass:"basic-information__row"},[_c('div',{staticClass:"basic-information__row-title"},[_vm._v("Legal Description")]),_c('div',{staticClass:"basic-information__row-content"},[_vm._v(_vm._s(_vm.legalDescription))])]),_c('div',{staticClass:"basic-information__row"},[_c('div',{staticClass:"basic-information__row-title"},[_vm._v("Zoning")]),_c('div',{staticClass:"basic-information__row-content"},[_vm._v(_vm._s(_vm.zoning))])]),(_vm.showTerms)?_c('div',{staticClass:"dialog-mask"},[_c('div',{staticClass:"terms-dialog"},[_vm._m(0),_c('div',{staticClass:"terms-bottom"},[_c('div',{staticClass:"terms-confirm",on:{"click":_vm.hideTerms}},[_c('span',[_vm._v("Confirm")])])])])]):_vm._e(),(_vm.contactUs)?_c('div',{staticClass:"dialog-mask"},[_c('div',{staticClass:"terms-dialog",staticStyle:{"height":"300px"}},[_vm._m(1),_c('div',{staticClass:"terms-bottom"},[_c('div',{staticClass:"terms-confirm",on:{"click":_vm.hideContact}},[_c('span',[_vm._v("Confirm")])])])])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"terms-content"},[_c('div',{staticClass:"terms-head"},[_c('div',{staticClass:"terms-left"}),_c('div',{staticClass:"terms-center"},[_c('span',[_vm._v("Homeowner information terms")])]),_c('div',{staticClass:"terms-right"})]),_c('div',{staticClass:"terms-inner-content"},[_c('p',[_vm._v(" All homeowner data displayed by PropCentre is obtained from Toitū Te Whenua (LINZ)’s public platform. This homeowner data (i) is displayed for informational purposes only, to assist PropCentre users by providing all public information about a property in one centralised platform, and (ii) is collected and dealt with in accordance with the Creative Commons Attribution 3.0 New Zealand Licence (CC BY 3.0 NZ) and the LINZ Licence for Personal Data, available here https://www.linz.govt.nz/products-services/data/licensing-and-using-data/linz-licence-personal-data, along with our Ts and Cs and our Privacy Policy. By proceeding, you acknowledge and agree to the terms of the CC BY 3.0 NZ and LINZ Licence for Personal Data and our Ts and Cs and privacy policy. If you have any questions or require any information to be removed, please contact us at info@propcentre.co.nz. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"terms-content"},[_c('div',{staticClass:"terms-head"},[_c('div',{staticClass:"terms-left"}),_c('div',{staticClass:"terms-center"},[_c('span',[_vm._v("Contact Us")])]),_c('div',{staticClass:"terms-right"})]),_c('div',{staticClass:"terms-inner-content"},[_c('p',[_vm._v(" If you have any questions or come across any errors, please contact us via the following email address: info@propcentre.co.nz. ")])])])
}]

export { render, staticRenderFns }