<template>
  <div class="timeline-wrapper">
    <div class="timeline-inner">
      <div class="timeline-row" v-for="(time, index) in showTimes" :key="index">
        <div class="timeline-time">
          <span class="timeline-month">{{ time.month }}</span>
          <span class="timeline-year">{{ time.year }}</span>
        </div>
        <div class="timeline-icon">
          <div class="timeline-icon-wrapper">
            <div
              class="timeline-icon-inner"
              :style="{ backgroundColor: index === 0 ? '#158C79' : '' }"
            ></div>
          </div>
        </div>
        <div class="timeline-money">
          <div class="timeline-money-number">
            <span>{{ time.money }}</span>
          </div>
          <div class="timeline-money-status">
            <span>{{ time.status }}</span>
          </div>
        </div>
      </div>
    </div>
    <div v-if="times.length > 6" class="timeline-more" @click="showMore">
      <span>{{ !isShowMore ? "Show more" : "Hide" }}</span>
      <span style="margin-bottom: 3px">{{ !isShowMore ? "⌄" : "⌃" }}</span>
    </div>
  </div>
</template>

<script>
const timelineIcons = {
  "Departmental Dealing": require("../../../assets/timeline-money.png"),
  Transfer: require("../../../assets/timeline-transfer.png"),
  Mortgage: require("../../../assets/timeline-money-bag.png"),
  Lease: require("../../../assets/timeline-key.png"),
  "Order for New Certificate of Title": require("../../../assets/timeline-cert.png"),
  Others: require("../../../assets/timeline-house.png"),
};

export default {
  name: "time-line",
  props: {
    times: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    showTimes() {
      if (!this.isShowMore) {
        return this.times.slice(0, 6);
      } else {
        return this.times;
      }
    },
  },
  data() {
    return {
      timelineIcons: timelineIcons,
      isShowMore: false,
    };
  },
  methods: {
    showMore() {
      this.isShowMore = !this.isShowMore;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./timeline.scss";
</style>