<template>
  <div class="carousel">
    <transition-group name="fade" tag="div" class="carousel-images" appear>
      <div v-for="(image, index) in images" :key="image.src" v-show="index === currentIndex">
        <div class="carousel-image" :style="{ backgroundImage: `url(${image.src})`, transform: image.transform, transition: image.transition }"></div>
      </div>
    </transition-group>
  </div>
</template>

<script>
export default {
  data() {
    return {
      images: [
        {
          src: require('../assets/ad1.jpg'),
          transform: 'scale(1)',
          transition: 'transform 5s linear',
        },
        {
          src: require('../assets/ad2.jpg'),
          transform: 'scale(1)',
          transition: 'transform 5s linear',
        },
        {
          src: require('../assets/ad3.jpg'),
          transform: 'scale(1)',
          transition: 'transform 5s linear',
        },
		{
			src: require('../assets/ad4.jpg'),
			transform: 'scale(1)',
			transition: 'transform 5s linear',
		},
		{
			src: require('../assets/ad5.jpg'),
			transform: 'scale(1)',
			transition: 'transform 5s linear',
		},
		{
			src: require('../assets/ad6.jpg'),
			transform: 'scale(1)',
			transition: 'transform 5s linear',
		},
      ],
      currentIndex: 0,
    };
  },
  methods: {
    nextImage() {
		this.currentIndex++;
		if (this.currentIndex >= this.images.length) {
			this.currentIndex = 0;
		}
		this.images[this.currentIndex].transform = 'scale(1)';
		setTimeout(() => {
			this.images[this.currentIndex].transform = 'scale(1.5)';
		}, 1000);
    },
    autoPlay() {
		this.images[this.currentIndex].transform = 'scale(1)';
		setTimeout(() => {
			this.images[this.currentIndex].transform = 'scale(1.5)';
		}, 2000);
		setInterval(() => {
			this.nextImage();
		}, 7000);
    },
  },
  created() {
    this.autoPlay();
  },
};
</script>

<style scoped>
.carousel {
  position: absolute;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  top: 0;
  left: 0;
}

.carousel-images {
  position: absolute;
  width: 100%;
  height: 100%;
}

.carousel-images div {
  position: absolute;
  width: 100%;
  height: 100%;
}

.carousel-image {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.8s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
