<template>
	<div>
		<el-dialog @close="cancel()" width="800px" :modal-append-to-body='false' :close-on-click-modal="true" :show-close="false" title="WEBSITE TERMS AND CONDITIONS" center :visible.sync="showDialog">
		<div style="height: 450px;text-align: left;font-size: 14px;color: #313131;overflow: auto;white-space: pre-line;">
			<h1>
				1.Introduction
			</h1>
			<p>
				Welcome to the PropCentre website terms and conditions (collectively, the “Ts & Cs”). These Ts & Cs relate to and can be found on <a style="color: #158C79;" href="http://propcentre.co.nz">propcentre.co.nz</a>. 
				In these Ts & Cs: 
				<br/><br/>. “we”, “us”, “our” refer to NZ Data Service Limited. 
				<br/><br/>. The terms “you” and “your” refer to any person visiting the Website.
				<br/><br/>. “CGA” means the Consumer Guarantees Act 1993.
			</p>
			<h1>
				2.Acceptance of Ts & Cs
			</h1>
			<p>
				By accessing the Website, you agree that:
				<br/><br/>(a) you have read and understand these Ts & Cs; 
				<br/><br/>(b) by continuing to use our Website, you accepted the Ts & Cs and agree to be bound by them;
				<br/><br/>(c) the Ts & Cs will govern your use of, and access to, our Website and the services provided;
				<br/><br/>(d) if you don’t comply with these Ts & Cs, we may stop you from using the Website. 
			</p>
			<h1>
				3.Privacy
			</h1>
			<p>
				Personal information that you provide to use via our Website, will be handled in accordance with our Privacy Policy, available (here)
			</p>
			<h1>
				4.Your promises to us
			</h1>
			<p>
				You promise us that:
				<br/><br/>(a) you are 18 years of age or older;
				<br/><br/>(b) you are legally capable of entering into binding contracts;
				<br/><br/>(c) you won’t use the Website for a purpose that is unlawful or prohibited by these Ts & Cs;
				<br/><br/>(d) all details you provide to us are true, accurate, current, and complete;
				<br/><br/>(e) you are the person referred to in the information provided. 
			</p>
			<h1>
				5.Content on the Website
			</h1>
			<p>
				All the content on the Website is owned by us or used with the permission of the people who own it and is protected by New Zealand and international property laws. 
				The trade marks related to the Website are owned by us. All other intellectual property (including brand names and trade marks) that appear on the Website belong to other businesses. You are not allowed to use this intellectual property and doing so may infringe the owners’ rights. 
				Additionally, the Website contains statistical,location and other data from third party sources,include data sourced from:
				<br/><br/>(a) Local councils (including Auckland Council);
				<br/><br/>(b) The LINZ Data Service licensed for reuse under CC BY 4.0.
			</p>
			<h1>
				6.Sharing your personal information
			</h1>
			<p>
				Website Information and Your Reliance on It
				<br/><br/>The Website is provided to you free of charge for your own personal and non-commercial use, to assist you with property decisions and information gathering.  Information is collated by us in good faith but is sourced from third parties, including local councils, real estate agents and other data providers, has not been verified by us, and is based on historical data and may not reflect the location today or in the future.
				<br/><br/>Although we aim to ensure the property listings, advertisements and other information contained on our Website is accurate and up to date, we make no warranty as to the accuracy, legality, completeness or usefulness of the information. The Website information is provided on an ‘as is’ and ‘available’ basis. In no way is the information on our Website intended to be taken as a recommendation that you choose a particular property or agent. It is your responsibility to evaluate the quality of the information that we provide. 
				<br/><br/>We make no representations about, and accept no responsibility for, the suitability or quality of any property, nor of any value estimates or similar, that is displayed on the Website.  We are not a property agency.  Any queries you might have about a property should be directed towards a real estate agent, not us.  We will not get involved in any communications between you and agents and we do not participate in any part of any property transactions.
				<br/><br/>In circumstances where you elect to commit to a purchase, you are responsible for checking, confirming and satisfying yourself as to the accuracy of the information displayed on the Website, and instructing any necessary surveyors, engineers, technicians or lawyers before committing to any purchase.
				<br/><br/>Use of Website
				<br/><br/>Some aspects of our Website might be delivered by other technical providers. This isn’t always within our control and while we’ll do our best, we cannot promise that you’ll always have access to our Website, or its content will be delivered uninterrupted and without mistakes, or that the Websites will not have viruses or other harmful properties. 
				<br/><br/>We’re not liable for anything that happens following your use of our Websites and it’s your responsibility to put in place your own security measures to make sure any content you get through our Websites can’t harm your own computer systems.
				<br/><br/>Linked Websites
				<br/><br/>NZ Data Service have no responsibility regarding any material you obtain from any other Website, even if you access it via our Website. The links that we provide, we do so as a convenience only. 
				<br/><br/>Warranties
				<br/><br/>Subject to the remainder of these Ts & Cs (including clause 7 below, which affirms your rights under the CGA), to the fullest extent permitted by law, other than as set out in these Ts & Cs, we make no representation or warranty (express or implied) in respect of the condition, quality or fitness for any purpose of:
				<br/><br/>(a) any service;
				<br/><br/>(b) our Website (including any content therein). It is your responsibility to satisfy yourself as to the condition, quality and fitness for purpose of any property displayed on our Website and the Website, and you acknowledge that (subject to clause 7 below);
				<br/><br/>(c) we make no other representation or warranty in relation to our Website;
				<br/><br/>(d) we exclude all representations, warranties, conditions or obligations which might be imposed or implied, whether by statute, common law, equity or otherwise;
				<br/><br/>(e) without limiting the generality of the above in any way, we do not represent or warrant that our Website is free from any computer viruses or other defects, or that your access to our Website will be continuous or uninterrupted;
				<br/><br/>(f) we do not provide any guarantees in relation to the products or services supplied to you by any third party (including in relation to third party real estate agents).
			</p>
			<h1>
				7.Limitation of liability
			</h1>
			<p>
				<br/><br/>Should you not agree with any part of these Ts & Cs, or have any dispute or claim against us, your sole and exclusive remedy will be to discontinue using our Website. 
				<br/><br/>Subject to the CGA, we will be under no liability whatsoever to you for any indirect and/or consequential loss and/or expense (including loss of profit) suffered by you arising out of a breach by us of these Ts & Cs. 
				<br/><br/>These Ts & Cs are to be read subject to our obligations to you to guarantee the quality and fitness for purpose of our services under the CGA. Nothing in these Ts & Cs overrides or restricts those obligations. However, we emphasize that we do not provide any such statutory guarantees in relation to products or services supplied to you by any other party. 
			</p>
			<h1>
				8.Where you breach
			</h1>
			<p>
				You agree to compensate us as a result of:
				<br/><br/>(a) any actions you take that disrupt access to and/or the functioning of our Website; 
				<br/><br/>(b) any breach by you of your obligations under these Ts & Cs.
			</p>
			<h1>
				9.Electronic messages
			</h1>
			<p>
				You agree by registering with us via the Website that you expressly consent to your inclusion in our direct marketing database and accept that you may, as a result, receive regular electronic communications and promotional communications from us. You have the right to ask us at any time to stop sending online communications and promotional offers to you.
			</p>
			<h1>
				10.Changes to these Ts & Cs
			</h1>
			<p>
				We may from time to time change these Ts & Cs, our policies, or the content of our Website, without notice. Such a change is effective immediately after posting to our Website. If you use our Website after we make such a change, then you are agreeing to such change. If you do not agree to such a change, then you must immediately stop using our Website.
				<br/><br/>You must:
				<br/><br/>(a) read the Ts & Cs before using the Website;
				<br/><br/>(b) refer back to these Ts & Cs often.
			</p>
			<h1>
				11.General 
			</h1>
			<p>
				You agree that:
				(a) if any part of these Ts & Cs is found to be invalid, void, or unenforceable it will be considered to be separate from the rest of these Ts & Cs, which will still apply. 
				(b) our failure or delay to act or enforce any part of these Ts & Cs shouldn’t be seen by you as us waiving any rights.
				(c) The Ts & Cs are a contract between you and us about your use of our Website. No one can enforce any parts of these Ts & Cs for you. 
				(d) New Zealand law governs these Ts & Cs and only the New Zealand courts can resolve any disputes between us in relation to them.
				(e) If you have any questions about our Website or these Ts & Cs, please contact us on [insert].
				(f) We may assign our rights under these Ts & Cs without seeking your prior consent.
			</p>
			<h1>
				12.Cancellation by us
			</h1>
			<p>
				Without prejudice to other remedies we have, if at any time you are in breach of any obligation under these Ts & Cs we may suspend your access to our Website. We will not be liable to you for any loss or damage you suffer because we have exercised our rights under this clause.
			</p>
		</div>
		<div style="flex-direction: row;display: flex;padding-top: 30px;padding-bottom: 10px;justify-content: center;">
			<el-button size="medium" @click="cancel" style="width:150px;">OK</el-button>
		</div>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		props: {
			isShow: Boolean
		},
		computed: {
			showDialog: {
				get() {
					return this.isShow
				},
				set(val) {
					this.$emit('dissmiss',val)
				}
			}
		},
		methods: {
			cancel () {
				this.$emit('dissmiss',false)
			}
		}
	}
</script>

<style>
</style>