const jp = {
  nav: {
    Map: "地図",
    Properties: "不動産",
    Analyse: "分析",
    PropGuide: "プロガイド",
    Shortlist: "選ばれた",
    AboutUs: "私たちについて",
    Utilities: "Utilities",
    Home: "Home",
    Gallery: "画廊",
  },
  map: {
    Map: "平面地図",
    Satellite: "衛星地図",
    ForSale: "販売用",
    ShowStormPipe: "雨水管を表示",
    ShowWastePipe: "排水管を表示",
    Shortlist: "選ばれた",
    Shortlisted: "選ばれている",
    Maybe: "たぶん",
    Nope: "いいえ",
  },
  detail: {
    Listed: "掲載済み",
    Size: "サイズ",
    Bedrooms: "寝室",
    Bathrooms: "浴室",
    Garages: "ガレージ",
    Location: "場所",
    OpenHome: "オープンハウス",
    BasicInformation: "基本情報",
    Details: "詳細",
    CapitalValuation: "資本評価",
    PropertyInformation: "不動産情報",
    Timeline: "タイムライン",
    SchoolZoning: "学区",
    ContactTheAgent: "代理人に連絡する",
    Suburb: "区域",
    NearbyProperties: "近隣の不動産",
    RecentlyViewedListings: "最近閲覧したリスト",
    All: "全部",
    Primary: "小学",
    Intermediate: "中学",
    Secondary: "高校",
    ShowMore: "もっと見る",
    ShowLess: "表示を減らす",
    City: "都市",
    Suburbs: "区域",
    AboutPropCentreCoNz: "PropCentre.co.nzについて",
    PropertyType: "不動産の種類",
    Price: "価格",
    TypeAddress: "住所を入力",
  },
  analyse: {
    PropertiesDataCentre: "不動産データセンター",
    Size: "サイズ",
    Bedrooms: "寝室",
    Bathrooms: "浴室",
    Garages: "ガレージ",
    BasicInformation: "基本情報",
    CapitalValuation: "資本評価",
    PropertyInformation: "不動産情報",
    Timeline: "タイムライン",
    SchoolZoning: "学区",
    Parking: "ガレージ",
  },
  shortlist: {
    ShortlistedProperties: "選ばれた不動産",
    All: "全部",
    Shortlisted: "選ばれている",
    Maybe: "たぶん",
    Nope: "いいえ",
    Bedrooms: "寝室",
    Bathrooms: "浴室",
    Garages: "ガレージ",
  },
  aboutus: {
    CompanyIntroduction: "会社紹介",
    cTextLineF:
      "プロップセンターへようこそ。プロップセンターは、AIとビッグデータ分析の力を活用して不動産業界を革命化する最先端のオンラインプラットフォームです。",
    cTextLineS:
      "プロップセンターでは、不動産の購入と売却方法を再構築することに取り組んでいます。完璧な物件を探している住宅購入者であるか、業務プロセスを効率化したい不動産専門家であるかに関わらず、当社のプラットフォームはあなたのニーズに合わせて設計されています。",
    Functions: "機能",
    fTextLineTF: "1. 知的な不動産検索",
    fTextLineTFT:
      "当社のプラットフォームは、利用可能な物件を簡単に検索し、各リストに関する包括的な情報にアクセスできるようにすることで、住宅購入者に力を与えます。すべての重要な詳細情報を備えて、簡単に理想の家を見つけてください。",
    fTextLineTS: "2. 幅広い物件の洞察",
    fTextLineTST:
      "床のプランや設備から地域の統計情報まで、すべての物件に関する詳細な情報を発見します。情報が手の届くところで豊富にアクセスすることで、情報を元にした意思決定を行います。",
    fTextLineTT: "3. 不動産専門家向けのプロフェッショナルツール",
    fTextLineTTT:
      "不動産専門家は、当社の物件関連ツールのスイートを使用して生産性を向上させることができます。市場分析からクライアント管理まで、当社のプラットフォームは業界で優れたリソースを提供し、成功に向けて装備しています。",
    gnfxzj:
      "当社のプラットフォームは、AIとビッグデータ分析の最新の進歩を活用して、利用可能な最も正確で最新の情報を提供します。技術と不動産の専門知識を結びつけることで、購入と売却プロセスを簡素化し、関係者全員にとって効率的かつ透明にすると信じています。",
    szyxfw: "デジタルマーケティングサービス",
    szyxfw1: "不動産リストのプロモーション",
    szyxfw1T:
      "オンライン不動産リストプロモーションサービスを提供し、競争の激しい不動産市場で物件の可視性と魅力を向上させます。ホームオーナーであるか、不動産専門家であるかに関係なく、当社のプラットフォームは効果的にリストを紹介する包括的なソリューションを提供します。",
    szyxfw2: "ディスプレイ配置広告",
    szyxfw2T:
      "当社のディスプレイ配置広告サービスを使用して、デジタル広告キャンペーンでの精度と影響の可能性を引き出します。特にリーチを拡大しようとする企業や高度にターゲットを絞った露出を目指すマーケターにとって、当社のプラットフォームはブランドプレゼンスを増幅する包括的なソリューションとして活用できます。",
    MultiPlatformAd: "マルチプラットフォームインフルエンサー広告",
    NZAndOversea: "NZおよび海外",
    NZContent:
      "マルチプラットフォームインフルエンサー広告は、さまざまなソーシャルメディアプラットフォームでインフルエンサーの力を活用するダイナミックな戦略で、マーケティング活動を次のレベルに引き上げます。このサービスは、インフルエンサーのリーチと信頼性を活用する包括的なソリューションを提供します。ブランド認知度を向上させたり、エンゲージメントを促進したり、売上を増やすために活用できます。",
    TechnicalD: "企業向けの技術開発サポート",
    TechnicalDC: "データ分析、個別のおすすめ、会話AI＆チャットボット",
    OurMission: "私たちの使命",
    OurMission1:
      "プロップセンターでは、個人とプロフェッショナルの双方に、自信を持って不動産市場を歩むために必要なツールと知識を提供することが使命です。私たちは、不動産業界を新たな高みに引き上げるシームレスでユーザーフレンドリーなエクスペリエンスを提供することに専念しています。",
    OurMission2: "革新が不動産と出会うこの興奮する旅に参加し、一緒に不動産取引の未来を築きましょう。",
    OurMission3: "プロップセンターを今日探索し、不動産の未来を体験してみてください。",
    ContactUs: "お問い合わせ",
  },
};
export default jp;
