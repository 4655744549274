var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"suburb-wrapper"},[_c('div',{staticClass:"suburb-map"},[(_vm.lat)?_c('p-map',{attrs:{"initLat":_vm.lat,"initLng":_vm.lng,"zoom":13,"coordinates":_vm.coordinates,"locationList":_vm.locationList},on:{"choose-property":_vm.onTapProperty}}):_vm._e(),_c('div',{staticClass:"suburb-house-detail"},[_c('map-recommend',{attrs:{"loading":_vm.loadingDetail,"houseList":_vm.houseList,"imageServerDomain":_vm.imageServerDomain,"propertyId":_vm.selectedDisplayData.propertyId,"coverUrl":_vm.selectedDisplayData.coverUrl,"photoNum":_vm.selectedDisplayData.photoNum,"title":_vm.selectedDisplayData.title,"desc":_vm.selectedDisplayData.desc,"size":_vm.selectedDisplayData.size,"bedroomCount":_vm.selectedDisplayData.bedroomCount,"bathroomCount":_vm.selectedDisplayData.bathroomCount,"collectType":_vm.selectedDisplayData.collectType,"landArea":_vm.selectedDisplayData.landArea,"landAreaUnit":_vm.selectedDisplayData.landAreaUnit},on:{"collect":_vm.onCollect,"uncollect":_vm.onUnCollect,"tapcard":_vm.toDetail}})],1)],1),_c('div',{staticStyle:{"height":"40px"}}),(_vm.agents?.length > 0)?_c('div',{staticClass:"suburb-agents"},[_c('h2',[_vm._v("Agents")]),_c('div',{staticClass:"suburb-agents-list"},_vm._l((_vm.agents),function(agent){return _c('div',{key:agent.id,staticClass:"suburb-agent-item"},[_c('agent-card',{attrs:{"agentName":`${agent.firstName} ${agent.lastName}`,"officeName":agent.officeName,"agentHead":_vm.imageServerDomain +
            agent.image?.['base-url'] +
            '.crop.128x128.jpg',"officeImage":_vm.imageServerDomain + agent.officeImage + '.crop.284x84.jpg',"officeBrandColor":agent.officeBrandHexCode,"id":agent.id},on:{"show-phone-view":function($event){return _vm.switchShowPhoneView(agent)},"show-email-view":function($event){return _vm.switchShowSendEmail(agent)}}})],1)}),0),_c('div',{staticClass:"suburb-agents-page"},[_c('div',{staticClass:"suburb-agents-page-item",on:{"click":function($event){return _vm.handleClickAgentPage(_vm.pageIndexAgent > 1 ? _vm.pageIndexAgent - 1 : 1)}}},[_vm._v(" < ")]),_vm._l((_vm.pageAgents),function(num,index){return _c('div',{key:num,staticClass:"suburb-agents-page-item",style:({
          width: index > 9 ? '25px' : '',
          fontWeight: index + 1 === _vm.pageIndexAgent ? 'bold' : '',
          color: index + 1 === _vm.pageIndexAgent ? '#158c79' : '',
        }),on:{"click":function($event){return _vm.handleClickAgentPage(index + 1)}}},[_vm._v(" "+_vm._s(index + 1)+" ")])}),_c('div',{staticClass:"suburb-agents-page-item",on:{"click":function($event){return _vm.handleClickAgentPage(
            _vm.pageIndexAgent < _vm.pageAgents ? _vm.pageIndexAgent + 1 : _vm.pageAgents
          )}}},[_vm._v(" > ")])],2)]):_vm._e(),_c('div',{staticClass:"suburb-houses"},[_c('h2',[_vm._v("Properties For Sales")]),_c('div',{staticClass:"suburb-properties-list"},_vm._l((_vm.houseList),function(house){return _c('div',{key:house.id,staticClass:"suburb-property-item",on:{"click":function($event){return _vm.toPropertyDetail(house.id)}}},[_c('AgentHouseCard',{attrs:{"price":house.priceDisplay || '',"address":house.address.fullAddress,"area":house.floorArea,"bedroom":house.bedroomCount,"bathroom":house.bathroomCount,"image-url":_vm.imageServerDomain +
            house.photos[0]['baseUrl'] +
            house.photos[0]['large']}})],1)}),0),_c('div',{staticClass:"suburb-house-page"},[_c('div',{staticClass:"suburb-house-page-item",on:{"click":function($event){return _vm.handleClickHousePage(_vm.pageIndexHouse > 1 ? _vm.pageIndexHouse - 1 : 1)}}},[_vm._v(" < ")]),_vm._l((_vm.pageHouses),function(num,index){return _c('div',{key:num,staticClass:"suburb-house-page-item",style:({
          width: index > 9 ? '25px' : '',
          fontWeight: index + 1 === _vm.pageIndexHouse ? 'bold' : '',
          color: index + 1 === _vm.pageIndexHouse ? '#158c79' : '',
        }),on:{"click":function($event){return _vm.handleClickHousePage(index + 1)}}},[_vm._v(" "+_vm._s(index + 1)+" ")])}),_c('div',{staticClass:"suburb-house-page-item",on:{"click":function($event){return _vm.handleClickHousePage(
            _vm.pageIndexHouse < _vm.pageHouses ? _vm.pageIndexHouse + 1 : _vm.pageHouses
          )}}},[_vm._v(" > ")])],2)]),_c('copy-phone-view',{attrs:{"isShow":_vm.showCopyPhone,"agent":_vm.currnetAgent,"imageServerDomain":_vm.imageServerDomain},on:{"dismiss":_vm.dissCopyPhoneView}}),_c('send-email-view',{attrs:{"isShow":_vm.showSendEmail,"agent":_vm.currnetAgent,"imageServerDomain":_vm.imageServerDomain},on:{"dismiss":_vm.dissSendEmailView}}),_c('detail-bottom')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }