import PropertyMapView from "@/views/map/MapView.vue";
import PropertyDetailNotForSaleView from "@/views/propertyDetail/PropertyDetailNotForSaleView";
import PropertyDetailView from "@/views/propertyDetail/PropertyDetailView";
import SuburbView from "@/views/suburb/SuburbView";
import utilitiesDetailView from "@/views/utilities/utilitiesDetailView.vue";
import utilitiesView from "@/views/utilities/utilitiesView.vue";
import Vue from "vue";
import VueRouter from "vue-router";
import AgentView from "../views/agent/AgentView.vue";
import AnalyseView from "../views/AnalyseView.vue";
import GalleryView from "../views/gallery/GalleryView.vue";
import HomeNewView from "../views/HomeNewView.vue";
import HomeView from "../views/HomeView.vue";
import IntermediaryDetail from "../views/intermediaryDetail/intermediaryDetail.vue";
import PropertView from "../views/PropertView.vue";
import AnalyseDetailView from "../views/propertyDetail/AnalyseDetailView.vue";
import PropGuideView from "../views/propGuide/PropGuideView.vue";
import ShoutlistView from "../views/ShoutlistView.vue";
import SignUpView from "../views/SignUpView.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "HomeNewView",
    component: HomeNewView,
  },
  {
    path: "/gallery",
    name: "GalleryView",
    component: GalleryView,
  },
  {
    path: "/porpertyMap",
    name: "porpertyMap",
    component: PropertyMapView,
  },
  {
    path: "/propertView",
    name: "propertView",
    component: PropertView,
  },
  {
    path: "/about",
    name: "about",
    component: HomeView,
  },
  {
    path: "/agent/:id",
    name: "agent",
    component: AgentView,
  },
  {
    path: "/signUp",
    name: "signUp",
    component: SignUpView,
  },
  {
    path: "/shortlist",
    name: "shortlist",
    component: ShoutlistView,
  },
  {
    path: "/propGuide",
    name: "propGuide",
    component: PropGuideView,
  },
  {
    path: "/property/:id",
    name: "propertyDetailView",
    component: PropertyDetailView,
  },
  {
    path: "/propertyNoListed/:id",
    name: "porpertyMap",
    component: PropertyDetailNotForSaleView,
  },
  {
    path: "/suburb/:suburbName",
    name: "suburbView",
    component: SuburbView,
  },
  {
    path: "/analyse",
    name: "analyse",
    component: AnalyseView,
  },
  {
    path: "/analyse/:id",
    name: "analyseDetail",
    component: AnalyseDetailView,
  },
  {
    path: "/office/:id",
    name: "intermediaryDetail",
    component: IntermediaryDetail,
  },
  {
    path: "/utilities",
    name: "utilitiesView",
    component: utilitiesView,
  },
  {
    path: "/utilitiesDetailView",
    name: "utilitiesDetailView",
    component: utilitiesDetailView,
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  console.log(to);
  let isMobile = navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  );
  // https://propcentre.ai http://47.116.191.218
  if (isMobile) {
    if (to.name == "propertyDetailView" && to.params.id) {
      window.location.href = `https://propcentre.ai/h5/detail/${to.params.id}`;
    } else {
      window.location.href = "https://propcentre.ai/h5";
    }
  }
  next();
});
export function resetRouter() {
  const newRouter = router;
  router.matcher = newRouter.matcher; // reset router
}
export default router;
