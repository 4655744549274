<template>
  <div v-if="visible" class="preview-image__container">
    <div class="preview-image__main">
      <div class="preview-image__main-switch">
        <div v-show="currentIndex != 0" class="preview-prev" @click="siwtchPrev">
          <img
            style="width: 20px; height: 36px"
            :src="require('@/assets/arrow.png')"
          />
        </div>
      </div>
      <img
        class="preview-image__main-content"
        :src="current || imgsUrls[0]"
        @load="imageLoaded"
      />
      <div class="preview-image__main-switch">
        <div v-show="currentIndex != imgsUrls.length - 1" class="preview-next" @click="siwtchNext">
          <img
            style="width: 20px; height: 36px; transform: rotate(180deg)"
            :src="require('@/assets/arrow.png')"
          />
        </div>
      </div>
      <div @click="close">
        <img class="preview-close" :src="require('@/assets/wrong.png')" />
      </div>
    </div>
    <div class="preview-image-scroll">
      <div
        class="preview-image-scroll__container"
        :style="`width:${imgsUrls.length * 220}}px`"
      >
        <div
          v-for="(imgUrl, i) in imgsUrls"
          :key="imgUrl"
          class="preview-image-scroll-item"
          :style="`background-image:url(${imgUrl})`"
          @click="switchCurrent(i)"
        />
      </div>
    </div>
  </div>
</template>
<script>
import {
  getArrayPropsDef,
  getBooleanPropsDef,
  getNumberPropsDef,
} from "@/common/propsConfig";
export default {
  props: {
    imgsUrls: getArrayPropsDef(),
    visible: getBooleanPropsDef(true),
    index: getNumberPropsDef(),
  },
  data() {
    return {
      currentIndex: 0,
      current: "",
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    switchCurrent(idx) {
      this.current = this.imgsUrls[idx];
      this.currentIndex = idx;
    },
    siwtchPrev() {
	  console.log(this.imgsUrls)
      if (this.currentIndex === 0) {
        return;
      }
      const prevIndex = this.currentIndex - 1;
      this.current = this.imgsUrls[(this.currentIndex = prevIndex)];
    },
    siwtchNext() {
      if (this.currentIndex === this.imgsUrls.length - 1) {
        return;
      }
      const nextIndex = this.currentIndex + 1;
      this.current = this.imgsUrls[(this.currentIndex = nextIndex)];
    },
    setIndex(index) {
      this.currentIndex = index;
      this.current = this.imgsUrls[index];
    },
    imageLoaded(res) {
      console.log(res.target.src, "image loaded");
      var img = new Image();
      img.src = res.target.src;
      console.log(img.width);
      res.target.width = img.width;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@/theme/styles/index.scss";
.preview-image__container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  @include flex(column);
  background-color: rgba($color: #000000, $alpha: 0.85);
}
.preview-image__main {
  flex: 1;
  position: relative;
  @include flex;
  width: 100%;
  padding: 50px 0;
  &-content {
    margin: auto;
    height: 568px;
	width: 994px;
    background-size: 100% 100%;
    // background-color: #222222;
    // background-position: center center;
    // background-repeat: no-repeat;
  }
  &-switch {
    flex: 1;
    @include flex;
    @include flex-adjust(center, center);
    height: 100%;
    .preview-next,
    .preview-prev {
      @include flex;
      @include flex-adjust(center, center);
      width: 72px;
      height: 72px;
      background: #1a1a1a;
      border-radius: 50%;
      transform: scale(0.7);
      cursor: pointer;
    }
  }
}
.preview-image-scroll {
  @include flex;
  @include flex-adjust(flex-start, center);
  height: 280px;
  overflow: scroll;
  margin: 0 auto;
  &-item {
    width: 157px;
    height: 106px;
    background-size: auto 100%;
    background-color: #222222;
    background-position: center center;
    background-repeat: no-repeat;
    border-radius: 5px;
    margin: 0 30px;
    cursor: pointer;
  }
  &__container {
    @include flex;
  }
}
.preview-close {
  position: absolute;
  top: 30px;
  right: 50px;
  width: 42px;
  height: 42px;
  padding: 10px;
  border-radius: 24px;
  box-sizing: border-box;
  background-color: #1a1a1a;
  cursor: pointer;
}
</style>