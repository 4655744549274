<template>
  <div class="property-nearby__schools">
    <div class="property-nearby__schools-tabs">
      <div
        v-for="tab in tabs"
        :key="tab.id"
        class="property-nearby__schools-tabs-item"
        @click="switchTab(tab.id)"
        :style="
          currentIndex === tab.id
            ? `border-bottom: 3px solid #212B36`
            : 'border-bottom: 3px solid transparent'
        "
      >
        <span>{{ tab.name }}</span>
        <span class="item-tab-num">{{ tab.num }}</span>
      </div>
    </div>
    <div class="property-nearby__schools-tab-title">
      <div class="title-name" style="flex: 1.5">
        <span>Name</span>
      </div>
      <div class="title-name">
        <span>Type</span>
      </div>
      <div class="title-name">
        <span>Zoning</span>
      </div>
    </div>
    <div
      v-for="school in computedSchools"
      :key="school.shortId"
      class="property-nearby__schools-row"
    >
      <div class="property-nearby__schools-row-info" style="flex: 1.5">
        <span>{{ school.organizationName }}</span>
        <span style="color: #212b36">{{ school.geoRadius }}</span>
      </div>
      <div class="property-nearby__schools-row-info">
        <span>{{ school.attributes?.["coed-status"] }}</span>
        <span>{{ school.attributes?.["authority"] }}</span>
      </div>
      <div class="property-nearby__schools-row-info">
        <span>{{ school.inZone ? "Zoned" : "Unzoned" }}</span>
      </div>
    </div>
    <div
      v-if="this.formatSchools[this.currentIndex]?.length > 6"
      class="property-nearby__schools-more"
      @click="showMore"
    >
      <span>{{ !isShowMore ? "Show more" : "Hide" }}</span>
      <span v-if="!isShowMore" style="margin-bottom: 5px">&nbsp;⌄</span>
      <span v-if="isShowMore" style="margin-top: 5px">&nbsp;⌃</span>
    </div>
  </div>
</template>
<script>
import { getArrayPropsDef } from "@/common/propsConfig";
export default {
  name: "propertySchool",
  props: {
    schools: getArrayPropsDef(),
  },
  computed: {
    computedSchools() {
      if (!this.isShowMore) {
        return this.formatSchools[this.currentIndex]?.slice(0, 6) || [];
      } else {
        return this.formatSchools[this.currentIndex] || [];
      }
    },
  },
  data() {
    return {
      isShowMore: false,
      currentIndex: "All",
      // formatSchools: [],
      tabs: [],
      formatSchools: {},
    };
  },
  mounted() {
    const formatSchools = {
      All: [],
      Primary: [],
      Intermediate: [],
      Secondary: [],
    };
    formatSchools.All = this.schools;
    this.schools.forEach((item) => {
      const schoolType = item.attributes["organization-type"];
      if (!formatSchools[schoolType]) {
        formatSchools[schoolType] = [];
      }
      formatSchools[schoolType].push(item);
    });
    const tabs = [...this.tabs];
    Object.keys(formatSchools).forEach((key) => {
      tabs.push({
        name: key,
        id: key,
        num: formatSchools[key].length,
      });
    });
    this.tabs = tabs;
    this.formatSchools = formatSchools;
  },
  methods: {
    switchTab(id) {
      this.currentIndex = id;
      console.log(id);
    },
    showMore() {
      this.isShowMore = !this.isShowMore;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./school.scss";
</style>