<template>
  <div class="nav-menu">
    <img class="logoImg" src="../assets/homelogo.png" />
    <!-- home -->
    <div
      @click="changeMenuEvent(7)"
      :class="index == 7 ? 'menuItemSel' : 'menuItem'"
    >
      <img
        style="width: 15px; height: 16px"
        :src="
          index == 7
            ? require('../assets/navBar/ic-home-sel.png')
            : require('../assets/navBar/ic-home-nor.png')
        "
      />
      <p :class="index == 7 ? 'menu-text-sel' : 'menu-text-nor'">
        {{ $t("nav.Home") }}
      </p>
    </div>
    <!--  gallery -->
    <div
      @click="changeMenuEvent(9)"
      :class="index == 9 ? 'menuItemSel' : 'menuItem'"
    >
      <img
        style="width: 15px; height: 16px"
        :src="
          index == 9
            ? require('../assets/navBar/ic-home-sel.png')
            : require('../assets/navBar/ic-home-nor.png')
        "
      />
      <p :class="index == 9 ? 'menu-text-sel' : 'menu-text-nor'">
        {{ $t("nav.Gallery") }}
      </p>
    </div>
    <!--  -->
    <div
      @click="changeMenuEvent(3)"
      :class="index == 3 ? 'menuItemSel' : 'menuItem'"
    >
      <img
        style="width: 15px; height: 16px"
        :src="
          index == 3
            ? require('../assets/navBar/ic-map-sel.png')
            : require('../assets/navBar/ic-map-nor.png')
        "
      />
      <p :class="index == 3 ? 'menu-text-sel' : 'menu-text-nor'">
        {{ $t("nav.Map") }}
      </p>
    </div>
    <div
      @click="changeMenuEvent(0)"
      :class="index == 0 ? 'menuItemSel' : 'menuItem'"
    >
      <img
        style="width: 15px; height: 16px"
        :src="
          index == 0
            ? require('../assets/navBar/ic-properties-sel.png')
            : require('../assets/navBar/ic-properties-nor.png')
        "
      />
      <p :class="index == 0 ? 'menu-text-sel' : 'menu-text-nor'">
        {{ $t("nav.Properties") }}
      </p>
    </div>
    <div
      v-if="env === 'development'"
      @click="changeMenuEvent(4)"
      :class="index == 4 ? 'menuItemSel' : 'menuItem'"
    >
      <img
        style="width: 15px; height: 16px"
        :src="
          index == 4
            ? require('../assets/navBar/ic-analyse-sel.png')
            : require('../assets/navBar/ic-analyse-nor.png')
        "
      />
      <p :class="index == 4 ? 'menu-text-sel' : 'menu-text-nor'">
        {{ $t("nav.Analyse") }}
      </p>
    </div>
    <div
      v-if="env === 'development'"
      @click="changeMenuEvent(5)"
      :class="index == 5 ? 'menuItemSel' : 'menuItem'"
    >
      <img
        style="width: 15px; height: 16px"
        :src="
          index == 5
            ? require('../assets/navBar/ic-propguide-sel.png')
            : require('../assets/navBar/ic-propguide-nor.png')
        "
      />
      <p :class="index == 5 ? 'menu-text-sel' : 'menu-text-nor'">
        {{ $t("nav.PropGuide") }}
      </p>
      <div class="beta-icon">
        <span>Beta</span>
      </div>
    </div>
    <div
      v-if="env === 'development'"
      @click="changeMenuEvent(6)"
      :class="index == 6 ? 'menuItemSel' : 'menuItem'"
    >
      <img
        style="width: 18px; height: 18px"
        :src="
          index == 6
            ? require('../assets/navBar/ic-utilities-sel.png')
            : require('../assets/navBar/ic-utilities-nor.png')
        "
      />
      <p :class="index == 6 ? 'menu-text-sel' : 'menu-text-nor'">
        {{ $t("nav.Utilities") }}
      </p>
    </div>
    <div
      @click="changeMenuEvent(1)"
      :class="index == 1 ? 'menuItemSel' : 'menuItem'"
    >
      <img
        style="width: 18px; height: 18px"
        :src="
          index == 1
            ? require('../assets/navBar/ic-shortlist-sel.png')
            : require('../assets/navBar/ic-shortlist-nor.png')
        "
      />
      <p :class="index == 1 ? 'menu-text-sel' : 'menu-text-nor'">
        {{ $t("nav.Shortlist") }}
      </p>
    </div>
    <div
      v-if="env === 'development'"
      @click="changeMenuEvent(2)"
      :class="index == 2 ? 'menuItemSel' : 'menuItem'"
    >
      <img
        style="width: 18px; height: 18px"
        :src="
          index == 2
            ? require('../assets/navBar/ic-aboutus-sel.png')
            : require('../assets/navBar/ic-aboutus-nor.png')
        "
      />
      <p :class="index == 2 ? 'menu-text-sel' : 'menu-text-nor'">
        {{ $t("nav.AboutUs") }}
      </p>
    </div>
    <div
      style="
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        margin-left: auto;
      "
    >
      <div v-if="isLogin == false" @click="goToSign" class="login-btn">
        Sign Up
      </div>
      <el-dropdown v-if="isLogin" trigger="click">
        <div
          v-if="isLogin"
          style="
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
          "
        >
          <img src="../assets/down_icon.png" class="right_img" />
          <div class="name">{{ name }}</div>
        </div>
        <el-dropdown-menu v-if="isLogin" slot="dropdown">
          <el-dropdown-item @click.native="clickLogout"
            >退出登录</el-dropdown-item
          >
        </el-dropdown-menu>
      </el-dropdown>
      <el-dropdown trigger="click">
        <img class="langIcon" src="../assets/navBar/language.png" />
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            :class="lan == 'en' ? 'langSel' : ''"
            @click.native="changeType('en')"
            >English</el-dropdown-item
          >
<!--          <el-dropdown-item
            :class="lan == 'mi' ? 'langSel' : ''"
            @click.native="changeType('mi')"
            >Te Reo Māori</el-dropdown-item
          > -->
          <el-dropdown-item
            :class="lan == 'zh' ? 'langSel' : ''"
            @click.native="changeType('zh')"
            >中文</el-dropdown-item
          >
<!--          <el-dropdown-item
            :class="lan == 'hi' ? 'langSel' : ''"
            @click.native="changeType('hi')"
            >हिंदी</el-dropdown-item
          >
          <el-dropdown-item
            :class="lan == 'jp' ? 'langSel' : ''"
            @click.native="changeType('jp')"
            >日本語</el-dropdown-item
          >
          <el-dropdown-item
            :class="lan == 'kr' ? 'langSel' : ''"
            @click.native="changeType('kr')"
            >한국어</el-dropdown-item
          > -->
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      env: process.env.NODE_ENV,
      index: 0,
      name: "",
      lan: "",
    };
  },
  computed: {
    ...mapGetters("user", {
      email: "email",
      isLogin: "isLogin",
    }),
  },
  watch: {
    email: {
      handler: function () {
        if (this.email != "" && this.email != null && this.email != undefined) {
          this.refreshName();
          this.menuUpdate();
        }
      },
      deep: true,
    },
  },
  created() {
    this.lan = this.$i18n.locale;
  },
  mounted() {
    this.refreshName();
    this.menuUpdate();
  },
  methods: {
    changeType(type) {
      localStorage.setItem("locale", type);
      this.$i18n.locale = type;
      this.lan = this.$i18n.locale;
      window.location.reload();
    },
    menuUpdate() {
      if (this.$router.currentRoute.path == "/") {
        this.index = 7;
      } else if (this.$router.currentRoute.path == "/porpertyMap") {
        this.index = 3;
      } else if (this.$router.currentRoute.path == "/shortlist") {
        this.index = 1;
      } else if (this.$router.currentRoute.path == "/about") {
        this.index = 2;
      } else if (this.$router.currentRoute.path == "/propertView") {
        this.index = 0;
      } else if (this.$router.currentRoute.path == "/analyse") {
        this.index = 4;
      } else if (this.$router.currentRoute.path == "/propGuide") {
        this.index = 5;
      } else if (this.$router.currentRoute.path == "/utilities") {
        this.index = 6;
      }
      this.$forceUpdate();
    },
    refreshName() {
      if (this.email != "" && this.email != null && this.email != undefined) {
        this.name = this.email.substring(0, this.email.indexOf("@"));
      }
    },
    changeMenuEvent(val) {
      if (val == 0) {
        this.$router.push({ path: "/propertView" });
      } else if (val == 2) {
        this.$router.push({ path: "/about" });
      } else if (val == 6) {
        this.$router.push({ path: "/utilities" });
      } else if (val == 1) {
        if (this.isLogin) {
          this.$router.push({ path: "/shortlist" });
        } else {
          this.$router.push({ path: "/signUp" });
        }
      } else if (val == 7) {
        this.$router.push({ path: "/" });
      } else if (val == 3) {
        this.$router.push({ path: "/porpertyMap" });
      } else if (val == 4) {
        if (this.isLogin) {
          this.$router.push({ path: "/analyse" });
        } else {
          this.$router.push({ path: "/signUp" });
        }
      } else if (val == 5) {
        if (this.isLogin) {
          this.$router.push({ path: "/propGuide" });
        } else {
          this.$router.push({ path: "/signUp" });
        }
      } else if (val === 9) {
        this.$router.push({ path: "/gallery" });
      }
      this.index = val;
    },
    goToSign() {
      this.$router.push({ path: "/signUp" });
    },
    clickLogout() {
      this.$store.dispatch("user/logout");
      this.index = 0;
      this.$router.replace("/");
    },
  },
};
</script>

<style scoped lang="scss">
.nav-menu {
  height: 56px;
  background: #ffffff;
  box-shadow: 0px 2px 2px rgba(94, 93, 93, 0.25);
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 999;
  width: 100%;
  margin: 0px auto;
}
.logoImg {
  height: 36px;
  width: 170px;
  margin-left: 30px;
  margin-right: 70px;
}
.menuItem {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: 30px;
  cursor: pointer;
  padding: 5px;
  border-radius: 2px;
}
.menuItemSel {
  position: relative;
  background: #02958014;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: 30px;
  cursor: pointer;
  padding: 5px;
  border-radius: 2px;
}
.beta-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -9px;
  right: -18px;
  width: 32px;
  height: 15px;
  background: rgb(254, 221, 42);
  border-radius: 4px;
  color: rgb(128, 82, 51);
  font-size: 10px;
  z-index: 1;
}
.menu-text-nor {
  color: #637381;
  font-size: 14px;
  margin-left: 8px;
  font-weight: 500;
}
.menu-text-sel {
  color: #029580;
  font-size: 14px;
  font-weight: 500;
  margin-left: 8px;
}
.login-btn {
  text-align: center;
  border-radius: 8px;
  border: 1px solid rgba(2, 149, 128, 0.5);
  font-size: 14px;
  font-weight: 700;
  display: inline-flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  color: #029580;
  margin-right: 40px;
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
}
.name {
  color: #3f907f;
  font-size: 20px;
  font-weight: 700;
  margin-right: 10px;
}
.right_img {
  width: 18px;
  height: 10px;
  margin-right: 40px;
}
.langIcon {
  width: 36px;
  height: 36px;
  margin-right: 8px;
}
.langSel {
  color: #029580;
}
</style>