<template>
	<div>
		<el-dialog @close="cancel()" width="800px" :modal-append-to-body='false' :close-on-click-modal="true" :show-close="false" title="Privacy Policy" center :visible.sync="showDialog">
		<div style="height: 450px;text-align: left;font-size: 14px;color: #313131;overflow: auto;white-space: pre-line;">
			<h1>
				1.What this policy is and what it covers
			</h1>
			<p>
				NZ Data Service Limited (together, we, us) takes the protection of your privacy seriously.  This policy (Privacy Policy) sets out how we deal with your personal information.
				By using [insert website here] and all associated pages, including social media pages and platforms (collectively, the Website) and/or by providing any personal information to us, you consent to the collection, use, storage, and disclosure of your personal information as set out in this Privacy Policy. 
				If you're providing us with another person's personal information, please ask them to read this Privacy Policy. By giving us personal information about another person you are confirming that they have given you permission to provide their personal information to us and that they understand how their personal information will be used. 
			</p>
			<h1>
				2.What is personal information?
			</h1>
			<p>
				Personal information is any information about you that identifies you, or by which your identity can reasonably be ascertained.
			</p>
			<h1>
				3.Personal information we collect and how we collect it
			</h1>
			<p>
				From time to time, in the course of providing you with our services, or by you using or accessing our Website, we may collect or request personal information.  We may request this either from you directly; collect this from publicly available sources; or collect this from third party suppliers. The information we collect comes from a variety of sources, including:
				<br/><br/>·Data and information generated and/or collected by our systems or third-party systems we use (including LINZ, local councils, and real estate agencies).
				<br/><br/>·From you directly, depending on how you're engaging with us or using our Website.  This should usually be clear to you at the time we collect it, e.g., if you subscribe or opt-in to receiving emails, we'll ask for an email address. 
				<br/><br/>·Cookies and other technology or platforms to collect information about your online browsing behaviour and any devices you have used to access our Website.	
				You do not have to provide us with any personal information, however, if you do not do so, we may not be able to provide you with the services or benefits you have requested.
			</p>
			<h1>
				4.Why we collect, use and disclose personal information
			</h1>
			<p>
				We collect, use and disclose your personal information to enable us to allow you to interact with us and the Website, to provide you with the services you have requested, and in accordance with our business purpose, which is to provide a centralised information platform about properties in New Zealand.  For example:
				<br/><br/>·Emails: when you register to receive our emails, we'll use your email address to send those to you.
				<br/><br/>.For agents: we might collect information about you (either via public sources or from you directly), including your name, photograph, which agency you work with, and your contact details, in connection with properties you are advertising for sale, in order to provide prospective purchasers with details about where they might go for more information.
				<br/><br/>.For homeowners: we might collect and display information about you (either via public sources or from you directly), including your name and details about r ownership of property. In addition, we might collect and display information about your property mortgage and property insurance. This information is collected from LINZ in accordance with the terms of the Creative Commons Attribution 3.0 New Zealand Licence (CC BY 3.0 NZ), and LINZ’s Licence for Personal Data, available here. We collect and display this information so that  we can provide a complete picture of information related to a property to interested parties.
				<br/><br/>.Internal reporting, insight and analysis: we may use your personal information to generate aggregated data for internal reporting, insight and analysis and for our training purposes. This data enables us to improve and personalise our Website, the services we offer and search results for our Website. This exercise may be carried out on our behalf by someone else. We might also keep a record of any information you acquire from us. 
				<br/><br/>.Contacting you: we may use your personal information to respond to any communications that you have directed at us, invite you to take part in polls and other market research activities carried out by us and on our behalf and to, where necessary, contact you about products or services we consider might be of interest to you. 
				<br/><br/>.Property Data and Information: we collect and display information in order to provide property data and information on our Website. This data could include property sales information (historical and current); addresses; and building data. Our Website contains data sourced from LINZ Data Service licensed for reuse under CC BY 4.0.
				<br/><br/>.Age and identity verification: we may use your personal information to check that you are who you say you are.
				<br/><br/>.To improve our products and services: we may use your personal information to generate profiling information to help us understand what you might be interested in; and for market research. We are always working to make our products and services better and using your personal information in this way helps us to do this.
				<br/><br/>.Any other lawful purpose: under the Privacy Act 2020, we may use and process your personal information where we have a lawful purpose to do so, for example for a lawful purpose connected with a function or an activity of ours.
				<br/><br/>.Other: for any other purpose that you authorise.
			</p>
			<h1>
				5.Storage and retention of personal information
			</h1>
			<p>
				Keeping your personal information secure is very important to us.  We take steps to ensure your information is protected by such security safeguards that are reasonable in the circumstances to protect against loss, unauthorised use, and misuse.
				<br/><br/>From time to time and for operational reasons the personal information we collect from you may be transferred to and stored in a country outside of New Zealand. Your information may also be processed by some of our service providers which operate outside New Zealand. When we appoint our service providers, we take care to ensure that we've put in place contractual protections, and that our service providers have appropriate security measures in place to enable them to comply with New Zealand’s privacy laws.
				<br/><br/>We will keep information about you on our systems for the period necessary to fulfil the purposes outlined in this Privacy Policy or as required by law (including for tax or regulatory purposes).
			</p>
			<h1>
				6.Sharing your personal information
			</h1>
			<p>
				In some circumstances, we may need to share your personal information with certain third parties, such as our technical partners and suppliers.  We may also share your personal information:
				<br/><br/>.In accordance with the purpose for which it was collected.
				<br/><br/>.If required or permitted to do so by law.
				<br/><br/>.If required to do so by any court, any applicable regulatory, compliance, governmental or law enforcement agency.
				<br/><br/>.If necessary in connection with legal proceedings or potential legal proceedings.
				<br/><br/>.If ownership of all or part of our business or Website is transferred.
			</p>
			<h1>
				7.Changes to this Privacy Policy
			</h1>
			<p>
				We may, from time to time, make changes to this Privacy Policy, for example to reflect changes in applicable laws, to ensure we keep up with best practice or to reflect Website enhancements. We will let you know what those changes are by posting them to this webpage. 
				<br/><br/>It's your responsibility as a user to make sure that you are aware of these changes posted on this webpage, by checking for any changes on a regular basis. Changes posted on this page will apply as soon as they are posted.
			</p>
			<h1>
				8.Your rights
			</h1>
			<p>
				You have the right to request access to and correction of the personal information that we hold about you.
				<br/><br/>If you would like a copy of the information we hold about you, or there are any errors in the personal information we hold about you please contact us. Any access or correction requests made by you will be dealt with in accordance with the Privacy Act 2020.
			</p>
			<h1>
				9.How to contact us
			</h1>
			<p>
				If you have any queries or complaints relating to the use of your personal information, please contact us at Info@propcente.co.nz.  
			</p>
		</div>
		<div style="flex-direction: row;display: flex;padding-top: 30px;padding-bottom: 10px;justify-content: center;">
			<el-button size="medium" @click="cancel" style="width:150px;">OK</el-button>
		</div>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		props: {
			isShow: Boolean
		},
		computed: {
			showDialog: {
				get() {
					return this.isShow
				},
				set(val) {
					this.$emit('dissmiss',val)
				}
			}
		},
		methods: {
			cancel () {
				this.$emit('dissmiss',false)
			}
		}
	}
</script>

<style>
</style>