const kr = {
  nav: {
    Map: "지도",
    Properties: "속성",
    Analyse: "분석",
    PropGuide: "속성 가이드",
    Shortlist: "단축 목록",
    AboutUs: "회사 소개",
    Home: "Home",
    Gallery: "갤러리",
  },
  map: {
    Map: "평면 지도",
    Satellite: "위성 지도",
    ForSale: "판매용",
    ShowStormPipe: "폭풍 배수관 보기",
    ShowWastePipe: "폐기물 배수관 보기",
    Shortlist: "단축 목록",
    Shortlisted: "단축 목록에 포함된",
    Maybe: "아마도",
    Nope: "아니요",
  },
  detail: {
    Listed: "등록됨",
    Size: "크기",
    Bedrooms: "침실",
    Bathrooms: "욕실",
    Garages: "차고",
    Location: "위치",
    OpenHome: "오픈 하우스",
    BasicInformation: "기본 정보",
    Details: "상세 정보",
    CapitalValuation: "자산 가치",
    PropertyInformation: "부동산 정보",
    Timeline: "시간표",
    SchoolZoning: "학군",
    ContactTheAgent: "중개인에게 연락",
    Suburb: "교외 지역",
    NearbyProperties: "인근 부동산",
    RecentlyViewedListings: "최근 본 목록",
    All: "모두",
    Primary: "초등",
    Intermediate: "중등",
    Secondary: "고등",
    ShowMore: "더 보기",
    ShowLess: "덜 보기",
    City: "도시",
    Suburbs: "교외 지역",
    AboutPropCentreCoNz: "PropCentre.co.nz 소개",
    PropertyType: "부동산 종류",
    Price: "가격",
    TypeAddress: "주소 입력",
  },
  analyse: {
    PropertiesDataCentre: "부동산 데이터 센터",
    Size: "크기",
    Bedrooms: "침실",
    Bathrooms: "욕실",
    Garages: "차고",
    BasicInformation: "기본 정보",
    CapitalValuation: "자산 가치 ",
    PropertyInformation: "부동산 정보",
    Timeline: "시간표",
    SchoolZoning: "학군",
    Parking: "차고 및 주차",
  },
  shortlist: {
    ShortlistedProperties: "단축 목록된 부동산",
    All: "모두",
    Shortlisted: "단축 목록",
    Maybe: "아마도",
    Nope: "아니요",
    Bedrooms: "침실",
    Bathrooms: "욕실",
    Garages: "차고",
  },
  aboutus: {
    CompanyIntroduction: "회사 소개",
    cTextLineF:
      "PropCentre에 오신 것을 환영합니다. 우리는 인공 지능과 빅데이터 분석 기술의 힘을 활용하여 부동산 업계를 혁신하려는 첨단 온라인 플랫폼입니다.",
    cTextLineS:
      "PropCentre에서는 사람들이 부동산을 구매하고 판매하는 방식을 재정립하는 것에 헌신하고 있습니다. 완벽한 부동산을 찾는 집 구매자이거나 작업 흐름을 최적화하려는 부동산 전문가이든, 우리의 플랫폼은 여러분의 요구를 충족시키도록 설계되었습니다.",
    Functions: "기능",
    fTextLineTF: "1. 지능형 부동산 검색",
    fTextLineTFT:
      "우리 플랫폼은 집 구매자들에게 가능한 부동산을 손쉽게 검색할 수 있는 능력을 부여하여 각 목록에 대한 종합 정보에 액세스할 수 있게 합니다. 필요한 모든 필수 정보를 갖추고 꿈의 집을 쉽게 찾으세요.",
    fTextLineTS: "2. 광범위한 부동산 통찰력",
    fTextLineTST:
      "바닥 계획부터 편의 시설, 동네 통계에 이르기까지 모든 부동산에 대한 심층적인 통찰력을 발견하세요. 손끝에서 다양한 정보에 액세스하여 정보를 활용한 결정을 내릴 수 있습니다.",
    fTextLineTT: "3. 부동산 전문가를 위한 전문 도구",
    fTextLineTTT:
      "부동산 전문가들은 부동산 관련 도구 스위트를 사용하여 생산성을 높일 수 있습니다. 시장 분석부터 클라이언트 관리까지, 우리 플랫폼은 업계에서 뛰어난 리소스로 당신을 장착시킵니다.",
    gnfxzj:
      "우리의 플랫폼은 최신 AI 및 빅 데이터 분석의 최신 기술을 활용하여 가장 정확하고 최신 정보를 제공하도록 보장합니다. 우리는 기술과 부동산 전문 지식을 결합하여 구매 및 판매 프로세스를 단순화하고 모든 관련 이해 당사자에게 더 효율적이고 투명하게 만들 수 있다고 믿습니다.",
    szyxfw: "디지털 마케팅 서비스",
    szyxfw1: "부동산 목록 홍보",
    szyxfw1T:
      "저희의 온라인 부동산 목록 홍보 서비스를 통해 경쟁이 치열한 부동산 시장에서 부동산의 가시성과 매력을 높여보세요. 주택 소유주이든 부동산 전문가이든, 우리의 플랫폼은 여러분의 목록을 효과적으로 전시하기 위한 포괄적인 솔루션을 제공합니다.",
    szyxfw2: "디스플레이 배치 광고",
    szyxfw2T:
      "저희의 디스플레이 배치 광고 서비스를 통해 디지털 광고 캠페인에서 정확성과 영향력의 잠재력을 끌어내세요. 특히 브랜드 확장을 원하는 기업이나 고도로 특정한 노출을 원하는 마케터에게 저희 플랫폼을 활용하여 브랜드 프레젠스를 강화하는 포괄적인 솔루션을 활용할 수 있습니다.",
    MultiPlatformAd: "멀티 플랫폼 인플루언서 광고",
    NZAndOversea: "뉴질랜드 및 해외",
    NZContent:
      "멀티 플랫폼 인플루언서 광고로 마케팅 노력을 더욱 높여보세요. 이 동적인 전략은 다양한 소셜 미디어 플랫폼에서 인플루언서의 힘을 활용합니다. 이 서비스는 인플루언서의 영향력과 진정성을 활용하기 위한 포괄적인 솔루션을 제공합니다. 브랜드 인식을 향상시키거나 참여도를 높이거나 판매를 촉진하기 위해 사용할 수 있습니다.",
    TechnicalD: "기업을 위한 기술 개발 지원",
    TechnicalDC: "데이터 분석、개인 맞춤형 추천、대화 AI 및 챗봇",
    OurMission: "우리의 미션",
    OurMission1:
      "PropCentre에서는 우리의 미션은 부동산 시장을 자신 있게 탐색할 수 있는 도구와 지식을 개인과 전문가 모두에게 제공하는 것입니다. 우리는 부동산 업계를 새로운 높이로 끌어올리는 원활하고 사용자 친화적인 경험을 제공하기 위해 헌신하고 있습니다.",
    OurMission2: "이 놀라운 여정에 참여하여 혁신과 부동산이 만나는 곳에서 함께 부동산 거래의 미래를 형성합시다.",
    OurMission3: "지금 PropCentre를 탐험하고 부동산의 미래를 경험해 보세요.",
    ContactUs: "문의하기",
  },
};
export default kr;
