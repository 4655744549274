<template>
  <div class="intermediaryDetail">
    <div class="intermediaryDetail_headView">
      <div
        :style="{ backgroundColor: officeInfo.brandHexCode }"
        class="logoView"
      >
        <img :src="logoImg()" class="logoImg" />
      </div>
      <div class="nameText">{{ officeInfo.officeName }}</div>
      <div v-if="addressStr()" class="subView">
        <img src="../../assets/locationLogo.png" class="subLogo" />
        <div class="sub1Text">{{ addressStr() }}</div>
      </div>
      <div
        style="cursor: pointer"
        @click="goToUrl(officeInfo.websiteUrl)"
        class="subView"
      >
        <img src="../../assets/netLogo.png" class="subLogo" />
        <div class="sub2Text">View our website</div>
      </div>
    </div>
    <our-team
      :id="officeId"
      :imageServerDomain="officeInfo.imageServerDomain"
    ></our-team>
    <our-lists :id="officeId"></our-lists>
  </div>
</template>

<script>
import { getOffice } from "@/api/api";
import ourTeam from "./ourTeam.vue";
import ourLists from "./ourLists.vue";
export default {
  components: {
    ourTeam,
    ourLists,
  },
  data() {
    return {
      officeId: "",
      officeInfo: {},
    };
  },
  activated() {
    this.officeId = "";
    this.officeId = this.$route.params.id;
    this.scrollToTop();
    this.getOfficeDetail();
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    getOfficeDetail() {
      getOffice(this.officeId).then((res) => {
        if (res.status == 200) {
          this.officeInfo = res.data;
        }
      });
    },
    goToUrl(url) {
      window.open(url, "_blank");
    },
    logoImg() {
      if (this.officeInfo.images) {
        if (this.officeInfo.images.length > 0) {
          var imgO = this.officeInfo.images[0];
          return (
            this.officeInfo.imageServerDomain +
            imgO["base-url"] +
            ".crop.400x110.jpg"
          );
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
    addressStr() {
      if (this.officeInfo.country) {
        return (
          this.officeInfo.country +
          " " +
          (this.officeInfo.city || "") +
          " " +
          (this.officeInfo.address1 || "")
        );
      } else {
        return "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./intermediaryDetail.scss";
</style>