<template>
  <div>
<!--    <left-menu
      style="padding-top: 30px"
      :isShoutList="true"
      :totalCount="total"
      @search="searchEvent"
      @clear="clearEvent"
    ></left-menu> -->
    <div class="right-view">
      <div class="filter-menu">
        <div style="margin-left: 52px; color: #158c79; font-size: 24px">
          {{ $t("shortlist.ShortlistedProperties") }}
        </div>
        <el-dropdown @command="handleTypesCommand" trigger="click">
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              :command="index"
              v-for="(item, index) in types"
              :key="index"
            >
              {{ item }}
            </el-dropdown-item>
          </el-dropdown-menu>
          <div style="display: flex; flex-direction: row; align-items: center">
            <p style="margin-left: 52px; color: #898989; font-size: 20px">
              {{ types[typeIndex] }}
            </p>
            <img
              style="margin-left: 15px; width: 18px; height: 10px"
              src="../assets/down_icon.png"
            />
          </div>
        </el-dropdown>
      </div>
      <div v-loading="loading" class="list-main">
        <div class="house-list">
          <div
            @click="goToDetail(item)"
            v-for="(item, index) in list"
            :key="index"
            class="house-cell"
          >
            <div
              style="display: flex; flex-direction: column; height: 100%"
              v-if="item.source == 'ON_SALE'"
            >
              <div class="img-content">
                <img
                  class="house-cell-img"
                  :src="
                    imgUrl(item) == ''
                      ? require('../assets/unsale_back.png')
                      : imgUrl(item)
                  "
                />
              </div>
              <p class="cell-title-label">{{ item.address.displayAddress }}</p>
              <p class="cell-info-label">{{ item.header }}</p>
              <div class="cell-price-bottom-view">
                <p style="color: #158c79; font-size: 14px; font-weight: 700">
                  {{ item.priceDisplay }}
                </p>
                <div class="cell-bottom-shuxing-view">
                  <div
                    style="
                      display: flex;
                      flex-direction: row;
                      align-items: center;
                    "
                    v-if="item.landArea > 0"
                  >
                    <img
                      class="cell_info_bottom_img"
                      src="../assets/mianji.png"
                    />
                    <p class="cell_info_bottom_label">
                      {{
                        item.landArea +
                        (item.landAreaUnit == "SQM" ? "㎡" : item.landAreaUnit || "㎡")
                      }}
                    </p>
                  </div>
                  <div
                    style="
                      display: flex;
                      flex-direction: row;
                      align-items: center;
                    "
                    v-if="item.bedroomCount > 0"
                  >
                    <img
                      class="cell_info_bottom_img"
                      src="../assets/woshi.png"
                    />
                    <p class="cell_info_bottom_label">
                      {{ item.bedroomCount }}
                    </p>
                  </div>
                  <div
                    style="
                      display: flex;
                      flex-direction: row;
                      align-items: center;
                    "
                    v-if="item.bathroomCount > 0"
                  >
                    <img
                      class="cell_info_bottom_img"
                      src="../assets/yushi.png"
                    />
                    <p class="cell_info_bottom_label">
                      {{ item.bathroomCount }}
                    </p>
                  </div>
                </div>
              </div>
              <div v-if="item.photos && item.photos.length > 0" class="photo-nums-view">
                <img
                  style="margin-left: 8px; height: 11px; width: 11px"
                  src="../assets/imgCount.png"
                />
                <p
                  style="
                    color: #fff;
                    font-size: 12px;
                    font-weight: 700;
                    margin-left: 5px;
                  "
                >
                  {{ item.photos.length }}
                </p>
              </div>
              <div v-if="item.show == true" class="agent-view">
                <div
                  style="
                    z-index: 2;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                  "
                  v-for="(agentItem, agentIndex) in item.agents"
                  :key="agentIndex"
                >
                  <img
                    :class="
                      agentIndex == 0 ? 'cell_head_img' : 'cell_head_img_second'
                    "
                    :src="agentItem.userHeadImageUrl"
                  />
                </div>
                <div
                  :style="{ backgroundColor: item.agentMap.officeBrandHexCode }"
                  class="cell_office_image_s"
                >
                  <img
                    mode="heightFix"
                    style="height: 14px; width: 85px"
                    :src="item.agentMap.officeImage"
                  />
                </div>
              </div>
              <div
                v-if="item.collectType == 'SHOUTLISTED'"
                class="bottom-cell-view"
              >
                <div
                  @click.stop="uncollectEvent(item, index)"
                  class="shortlist_btn"
                >
                  <div class="shortlist_img"></div>
                  <p class="shortlist_title">
                    {{ $t("shortlist.Shortlisted") }}
                  </p>
                </div>
              </div>
              <div v-if="item.collectType == 'MAYBE'" class="bottom-cell-view">
                <div
                  @click.stop="uncollectEvent(item, index)"
                  class="maybe-btn"
                >
                  <div
                    style="color: #212b36; font-size: 12px; font-weight: 700"
                  >
                    {{ $t("shortlist.Maybe") }}
                  </div>
                </div>
              </div>
              <div v-if="item.collectType == 'NOPE'" class="bottom-cell-view">
                <div @click.stop="uncollectEvent(item, index)" class="nope_btn">
                  <div class="nope_img"></div>
                  <p class="nope_title">{{ $t("shortlist.Nope") }}</p>
                </div>
              </div>
            </div>
            <div
              style="display: flex; flex-direction: column; height: 100%"
              v-if="item.source == 'NOT_SALE'"
            >
              <div class="img-content">
                <img
                  class="house-cell-img"
                  :src="
                    imgUrl(item) == ''
                      ? require('../assets/unsale_back.png')
                      : imgUrl(item)
                  "
                />
              </div>
              <p class="cell-title-label">{{ item.fullAddress }}</p>
              <div class="photo-nums-view">
                <img
                  style="margin-left: 8px; height: 11px; width: 11px"
                  src="../assets/imgCount.png"
                />
                <p
                  style="
                    color: #fff;
                    font-size: 12px;
                    font-weight: 700;
                    margin-left: 5px;
                  "
                >
                  No photo
                </p>
              </div>
              <div
                v-if="item.collectType == 'SHOUTLISTED'"
                class="bottom-cell-view"
              >
                <div
                  @click.stop="uncollectEvent(item, index)"
                  class="shortlist_btn"
                >
                  <div class="shortlist_img"></div>
                  <p class="shortlist_title">{{ $t("shortlist.Shortlist") }}</p>
                </div>
              </div>
              <div v-if="item.collectType == 'MAYBE'" class="bottom-cell-view">
                <div
                  @click.stop="uncollectEvent(item, index)"
                  class="maybe-btn"
                >
                  <div
                    style="color: #212b36; font-size: 12px; font-weight: 700"
                  >
                    {{ $t("shortlist.Maybe") }}
                  </div>
                </div>
              </div>
              <div v-if="item.collectType == 'NOPE'" class="bottom-cell-view">
                <div @click.stop="uncollectEvent(item, index)" class="nope_btn">
                  <div class="nope_img"></div>
                  <p class="nope_title">{{ $t("shortlist.Nope") }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCollectList, uncollect } from "../api/api.js";
// import leftMenu from "../components/leftMenu.vue";
import { compactObj, isEmpty } from "../utils/tools.js";
export default {
  data() {
    return {
      list: [],
      imageServerDomain: "",
      agentMap: {},
      loading: false,
      hasMore: false,
      isShortlist: true,
      typeIndex: 0,
      total: 0,
      form: {},
    };
  },
  computed: {
    types() {
      return [
        this.$t("shortlist.All"),
        this.$t("shortlist.Shortlisted"),
        this.$t("shortlist.Maybe"),
        this.$t("shortlist.Nope"),
      ];
    },
  },
  // components: {
  //   leftMenu,
  // },
  destroyed() {
    window.removeEventListener("scroll", this.scroll, true);
  },
  created() {
    window.addEventListener("scroll", this.scroll, true);
  },
  mounted() {
    this.requestMain(true);
  },
  methods: {
    searchEvent(form) {
      this.form = form;
      this.requestMain(true);
    },
    clearEvent(form) {
      this.form = form;
      this.requestMain(true);
    },
    goToDetail(item) {
      if (item.sale == true) {
        this.$router.push(`/property/${item.id}`);
      } else {
        this.$router.push(`/propertyNoListed/${item.addressId}`);
        //未售房源id = addressId
        console.log(item.addressId);
      }
    },
    uncollectEvent(item, index) {
      var form = {};
      form.hourseId = item.id;
      uncollect(form).then((res) => {
        if (res.status == 200) {
          this.list.splice(index, 1);
        }
      });
    },
    handleTypesCommand(command) {
      this.typeIndex = command;
      this.requestMain(true);
    },
    scroll() {
      var scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      var windowHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
      var scrollHeight = document.body.scrollHeight;
      if (scrollTop + windowHeight + 1 >= scrollHeight) {
        if (this.hasMore && this.loading == false) {
          this.requestMain(false);
        }
      }
    },
    imgUrl(item) {
      if (item.photos != null) {
        if (item.source === "OWNER") {
          return item.photos[0].baseUrl;
        } else {
          if (item.photos.length > 0) {
            if (item.photos[0].baseUrl.indexOf("http") != -1) {
              return item.photos[0].baseUrl;
            } else {
              return (
                this.imageServerDomain +
                item.photos[0].baseUrl +
                ".crop.1660x948.jpg"
              );
            }
          } else {
            return "";
          }
        }
      }
      return "";
    },
    requestMain(isFresh) {
      var form = {};
      if (isFresh == true) {
        form.pageIndex = 1;
        this.loading = true;
      } else {
        form.pageIndex = Math.ceil(this.list.length / 12.0);
      }
      form.pageSize = 12;
      if (this.typeIndex == 1) {
        form.collectType = "SHOUTLISTED";
      } else if (this.typeIndex == 2) {
        form.collectType = "MAYBE";
      } else if (this.typeIndex == 3) {
        form.collectType = "NOPE";
      }
      form = { ...form, ...this.form };
      compactObj(form, isEmpty);
      getCollectList(form).then((res) => {
        this.loading = false;
        if (res.status == 200) {
          this.imageServerDomain = res.data.imageServerDomain;
          this.agentMap = res.data.agentMap;
          if (isFresh == true) {
            this.list.length = 0;
          }
          var records = [];
          this.total = res.data.userCollectPage.total;
          res.data.userCollectPage.records.forEach((item) => {
            var newItem = res.data.hourseMap[item.hourseId];
            newItem.collectType = item.collectType;
            newItem.source = item.source;
            records.push(newItem);
          });
          records.forEach((item) => {
            var newItem = {};
            newItem = item;
            if (item.agents != null && item.agents.length > 0) {
              newItem.sale = true;
              newItem.agentMap = {};
              newItem.agentMap.officeBrandHexCode =
                this.agentMap[item.agents[0].id].officeBrandHexCode;
              if (this.agentMap[item.agents[0].id].image == null) {
                newItem.agentMap.userHeadImageUrl = require("../assets/headDefault.png");
              } else {
                if (
                  this.agentMap[item.agents[0].id].image["base-url"].indexOf(
                    "http"
                  ) != -1
                ) {
                  newItem.agentMap.userHeadImageUrl =
                    this.agentMap[item.agents[0].id].image["base-url"];
                } else {
                  newItem.agentMap.userHeadImageUrl =
                    this.imageServerDomain +
                    this.agentMap[item.agents[0].id].image["base-url"] +
                    ".crop.128x128.jpg";
                }
              }
              newItem.agentMap.name =
                this.agentMap[item.agents[0].id].firstName +
                " " +
                this.agentMap[item.agents[0].id].lastName;
              newItem.agentMap.officeImage =
                this.imageServerDomain +
                this.agentMap[item.agents[0].id].officeImage +
                ".crop.350x70.jpg";

              newItem.agents.forEach((subItem) => {
                if (this.agentMap[subItem.id].image == null) {
                  subItem.userHeadImageUrl = require("../assets/headDefault.png");
                } else {
                  if (
                    this.agentMap[subItem.id].image["base-url"].indexOf(
                      "http"
                    ) != -1
                  ) {
                    subItem.userHeadImageUrl =
                      this.agentMap[subItem.id].image["base-url"];
                  } else {
                    subItem.userHeadImageUrl =
                      this.imageServerDomain +
                      this.agentMap[subItem.id].image["base-url"] +
                      ".crop.200x200.jpg";
                  }
                }
                subItem.officeBrandHexCode =
                  this.agentMap[subItem.id].officeBrandHexCode;
                subItem.officeImage =
                  this.imageServerDomain +
                  this.agentMap[subItem.id].officeImage +
                  ".crop.350x70.jpg";
                subItem.phoneMobile = this.agentMap[subItem.id].phoneMobile;
                subItem.introduction = this.agentMap[subItem.id].introduction;
                subItem.name =
                  this.agentMap[subItem.id].firstName +
                  " " +
                  this.agentMap[subItem.id].lastName;
                subItem.email = this.agentMap[subItem.id].email;
              });
            } else {
              newItem.sale = false;
            }
            this.list.push(newItem);
          });
          if (res.data.userCollectPage.records.length < 10) {
            this.hasMore = false;
          } else {
            this.hasMore = true;
          }
        }
      });
    },
  },
};
</script>

<style scoped>
.right-view {
  width: 1360px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.filter-menu {
  margin-left: 4px;
  width: 1360px;
  height: 75px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: fixed;
  z-index: 998;
  background-color: #fff;
}
.list-main {
  margin-top: 75px;
  width: 100%;
  margin-left: 0px;
  min-width: 1360px;
}
.house-list {
  margin-top: 0rpx;
  margin-bottom: 25px;
  margin-left: 25px;
  min-width: 1010px;
  width: calc(100% - 50px);
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: space-between;
}
.house-cell {
  margin-top: 25px;
  height: 368px;
  width: calc(33% - 15px);
  border-radius: 16px;
  box-shadow: 0px 12px 24px -4px rgba(145, 158, 171, 0.12),
    0px 0px 2px 0px rgba(145, 158, 171, 0.2);
  position: relative;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}
.house-cell:hover {
  box-shadow: 0px 0px 5px 2px rgba(74, 74, 74, 0.25);
}
.bottom-cell-view {
  margin-top: auto;
  margin-bottom: 20px;
  height: 36x;
  margin-left: 20px;
  width: calc(100% - 40px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.house-cell-img {
  width: 100%;
  height: 204px;
  border-radius: 8px;
}
.cell-title-label {
  margin-top: 20px;
  color: #212b36;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  width: calc(100% - 40px);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  margin-left: 20px;
  text-align: left;
}
.cell-info-label {
  margin-top: 8px;
  font-size: 12px;
  color: #919eab;
  line-height: 15px;
  width: calc(100% - 40px);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  margin-left: 20px;
  text-align: left;
}
.cell-price-bottom-view {
  height: 20px;
  margin-top: 8px;
  width: calc(100% - 40px);
  margin-left: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.cell-bottom-shuxing-view {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
  margin-right: 0px;
}
.cell_info_bottom_label {
  font-size: 10px;
  line-height: 12px;
  color: #000000;
  font-weight: 700;
  margin-left: 5px;
}
.cell_info_bottom_img {
  width: 15px;
  height: 15px;
  margin-left: 15px;
}
.photo-nums-view {
  height: 22px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 8px;
  background: var(--grey-800, #212b36);
  position: absolute;
  z-index: 100;
  top: 174px;
  left: auto;
  right: 20px;
  padding-right: 8px;
}
.agent-view {
  position: absolute;
  left: 17px;
  top: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.cell_head_img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #eaeaea;
}
.cell_head_img_second {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #eaeaea;
  margin-left: -10px;
}
.cell_office_image_s {
  border-radius: 4px;
  height: 22px;
  margin-left: -10px;
  width: 85px;
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.nope_btn {
  border-radius: 8px;
  background: rgba(255, 171, 0, 0.08);
  width: 105px;
  height: 36px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.nope_btn:hover {
  background-color: #ff9933;
}
.nope_img {
  width: 12px;
  height: 12px;
  background: url("../assets/nopesel.png");
  background-size: cover;
}
.nope_btn:hover .nope_img {
  width: 12px;
  height: 12px;
  background: url("../assets/nope.png");
  background-size: cover;
}
.nope_title {
  color: #ff9933;
  font-size: 12px;
  font-weight: 700;
  margin-left: 10px;
}
.nope_btn:hover .nope_title {
  color: #ffffff;
  font-size: 12px;
  font-weight: 700;
  margin-left: 10px;
}

.shortlist_btn {
  border-radius: 8px;
  background: rgba(2, 149, 128, 0.08);
  width: 105px;
  height: 36px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.shortlist_btn:hover {
  background-color: #158c79;
}
.shortlist_img {
  width: 12px;
  height: 12px;
  background: url("../assets/gouxuansel.png");
  background-size: cover;
}
.shortlist_btn:hover .shortlist_img {
  width: 12px;
  height: 12px;
  background: url("../assets/gouxuan.png");
  background-size: cover;
}
.shortlist_title {
  color: #158c79;
  font-size: 12px;
  font-weight: 700;
  margin-left: 10px;
}
.shortlist_btn:hover .shortlist_title {
  color: #ffffff;
  font-size: 12px;
  font-weight: 700;
  margin-left: 10px;
}
.img-content {
  margin-top: 8px;
  margin-left: 8px;
  width: calc(100% - 16px);
  height: 196px;
  border-radius: 8px;
  overflow: hidden;
}
.house-cell:hover .house-cell-img {
  transform: scale(1.2, 1.2);
  -ms-transform: scale(1.2, 1.2); /* IE 9 */
  -moz-transform: scale(1.2, 1.2); /* Firefox */
  -o-transform: scale(1.2, 1.2); /* Opera */
  -webkit-transform: scale(1.2, 1.2); /* Safari 和 Chrome */
}
.maybe-btn {
  width: 105px;
  height: 36px;
  border-radius: 8px;
  background: rgba(145, 158, 171, 0.08);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
</style>