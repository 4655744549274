<template>
  <div class="property-detail">
    <div class="property-detail__content">
      <!--  -->
      <div class="property-breadcrumb">
        <span style="color: #212b36">{{ $t("nav.Properties") }}</span>
        <span style="color: #919eab; font-size: 12px; font-weight: 800">
          &nbsp;&nbsp;&nbsp;&nbsp;●&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
        <span style="color: #919eab">{{ house.address.displayAddress }}</span>
      </div>
      <!-- <property-carousel :imgList="house.photos" /> -->
      <!-- Banner -->
      <!-- <property-banner :imgs="house.photos" :serverDomain="imageServerDomain" /> -->
      <!-- Infomation -->
      <div class="property-detail__info">
        <!-- House Infomation -->
        <div class="property-detail__info--house">
          <!-- Carousel -->
          <div class="property-detail__row">
            <property-carousel
              :imgList="house.photos"
              :serverDomain="imageServerDomain"
              :vrInfo="house.vrInfo"
            />
          </div>

          <!-- Open Home -->
          <div
            v-if="house.openHomes && house.openHomes.length > 0"
            class="property-detail__row"
          >
            <div class="property-detail__title">
              <span>{{ $t("detail.OpenHome") }}</span>
            </div>
            <open-home
              v-if="house.openHomes.length > 0"
              :open-homes="house.openHomes"
            />
          </div>
          <!-- Basic information -->
          <!-- <div class="property-detail__row">
            <div class="property-detail__title">
              <span>Basic information</span>
            </div>
            <basic-information
              :ownerShip="house.properties.titlesOwners[0].owners"
              :legalDescription="house.properties.legalDescription"
              :landTitle="`${house.properties.titleType} ${house.properties.landArea}`"
              :zoning="house.properties.unitaryPlan"
              :certificateOfTitle="house.properties.titlesOwners[0].titleNo"
            />
          </div> -->
          <!-- Location -->

          <div v-if="house.address.fullAddress" class="property-detail__row">
            <div class="property-detail__title">
              <span>{{ $t("detail.Location") }}</span>
            </div>
            <property-location
              :address="house.address.fullAddress"
              :lat="house.address.latitude"
              :lng="house.address.longitude"
            />
          </div>
          <!-- Detail -->
          <div class="property-detail__row">
            <div class="property-detail__title">
              <span>{{ $t("detail.Details") }}</span>
            </div>
            <div class="property-detail__desc" v-html="house.description" />
          </div>
          <!-- Capital Valuation -->
          <div
            v-if="house.price && house.price?.capitalValue"
            class="property-detail__row"
          >
            <div class="property-detail__title">
              <span>{{ $t("detail.CapitalValuation") }}</span>
            </div>
            <div>
              <capital-value
                :total="house.priceDisplay"
                :capital="house.price"
              />
            </div>
          </div>
          <!-- Property Information -->
          <div v-show="false" class="property-detail__row">
            <div class="property-detail__title">
              <span>{{ $t("detail.PropertyInformation") }}</span>
            </div>
            <property-information />
          </div>
          <!-- Underground Services & Flood -->
          <div v-show="false" class="property-detail__row">
            <div class="property-detail__title">
              <span>Underground Services & Flood</span>
            </div>
          </div>
          <!-- Timeline -->
          <div v-if="times.length > 0" class="property-detail__row">
            <div class="property-detail__title">
              <span>{{ $t("detail.Timeline") }}</span>
            </div>
            <div>
              <time-line :times="times" />
            </div>
          </div>
          <!-- School -->
          <div v-if="house.schools?.length > 0" class="property-detail__row">
            <div class="property-detail__title">
              <span>{{ $t("detail.SchoolZoning") }}</span>
            </div>
            <div>
              <property-school :schools="house.schools" />
            </div>
          </div>
        </div>

        <div class="property-detail__info-agent-sticky">
          <!-- Title & Price -->
          <property-brief
            :name="house.address.displayAddress"
            :priceDisplay="house.priceDisplay"
            :subTitle="house.header"
            :publishedDate="house.publishedDate"
            :bathroomCount="house.bathroomCount"
            :bedroomCount="house.bedroomCount"
            :parkingCoveredCount="house.garageCount"
            :landArea="house.landArea"
            :floorArea="house.floorArea"
            :subType="house.listingSubType"
          />

          <div
            style="
              width: 100%;
              height: 1px;
              background-color: #f0f0f0;
              margin: 24px 0;
            "
          ></div>
          <!-- agents -->
          <div
            v-for="(agent, index) in agents"
            :key="agent.id"
            style="margin-bottom: 24px; width: 100%"
            @click="handleAgentClick(agent.id)"
          >
            <property-agent
              :id="agent.id"
              :index="index"
              :email="agent.email"
              :firstName="agent.firstName"
              :hourseNum="0"
              :introduction="''"
              :lastName="agent.lastName"
              :namte="agent.name"
              :officeBrandHexCode="''"
              :officeName="agent.officeName"
              :phoneMobile="agent.phoneMobile"
              :type="agent.type"
              :image="agent.image?.['base-url'] || ''"
              :imageServerDomain="imageServerDomain"
            />
          </div>
          <!-- ads -->
          <div class="ads-list">
            <div
              class="sticky-ads"
              :style="{
                top: adsScrollTop + 'px',
              }"
            >
              <div
                @click="goToAd(adItem.urlLink)"
                style="
                  border: 1px solid #ddddde;
                  border-radius: 12px;
                  margin-bottom: 10px;
                  width: 300px;
                  background-color: aqua;
                  height: 600px;
                "
                v-for="(adItem, adIndex) in adList"
                :key="adIndex"
              >
                <img
                  class="ads-img"
                  style="width: 100%; height: 100%; border-radius: 12px"
                  :src="adItem.url"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <!-- Contact the Agent -->
      <div v-if="agents.length > 0" class="property-detail__row">
        <div class="property-detail__title">
          <span>{{ $t("detail.ContactTheAgent") }}</span>
        </div>
        <div>
          <contact-the-agent
            v-for="agent in agents"
            :key="agent.id"
            :imageServerDomain="imageServerDomain"
            :agent="agent"
          ></contact-the-agent>
        </div>
      </div>
      <div v-if="env !== 'production'" class="property-detail__row">
        <div class="property-detail__title">
          <span>{{ $t("detail.Suburb") }}</span>
        </div>
        <div class="suburb-info">
          <div class="suburb-info-img">
            <img class="suburb-img" :src="require('@/assets/suburb.png')" />
          </div>
          <div class="suburb-button">
            <h2>{{ house.address.suburb }}</h2>
            <div class="suburb-learn-more" @click="handleToSuburb">
              <span>{{ $t("detail.ShowMore") }}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- Nearby Properties -->
      <!-- <div
        v-if="house.address.latitude && house.address.longitude"
        class="property-detail__row"
      >
        <div class="property-detail__title">
          <span>{{ $t("detail.NearbyProperties") }}</span>
        </div>
        <div>
          <nearby-properties
            :latitude="house.address.latitude"
            :longitude="house.address.longitude"
          ></nearby-properties>
        </div>
      </div> -->

      <!-- Recently Viewed Listings -->
      <!-- <div class="property-detail__row">
        <div class="property-detail__title">
          <span>{{ $t("detail.RecentlyViewedListings") }}</span>
        </div>
        <div>
          <recently-viewed-listings></recently-viewed-listings>
        </div>
      </div> -->
    </div>
    <div v-if="!house.collectType" class="property-detail__user-favorite">
      <div class="favorite-btn nope" @click="collect('NOPE')">
        <span>✖︎ {{ $t("shortlist.Nope") }}</span>
      </div>
      <div class="favorite-btn maybe" @click="collect('MAYBE')">
        <span>{{ $t("shortlist.Maybe") }}</span>
      </div>
      <div class="favorite-btn shoutlist" @click="collect('SHOUTLISTED')">
        <span>✔︎ {{ $t("shortlist.Shortlisted") }}</span>
      </div>
    </div>
    <div v-else class="property-detail__user-favorite">
      <div
        v-if="house.collectType === 'SHOUTLISTED'"
        class="favorite-btn-choosen shoutlist"
        @click="uncollect()"
      >
        <span>✔︎ {{ $t("shortlist.Shortlisted") }}</span>
      </div>
      <div
        v-if="house.collectType === 'MAYBE'"
        class="favorite-btn-choosen maybe"
        @click="uncollect()"
      >
        <span>{{ $t("shortlist.Maybe") }}</span>
      </div>
      <div
        v-if="house.collectType === 'NOPE'"
        class="favorite-btn-choosen nope"
        @click="uncollect()"
      >
        <span>✖︎ {{ $t("shortlist.Nope") }}</span>
      </div>
    </div>
    <detail-bottom />
  </div>
</template>
<script>
import propertyAgent from "./agent/propertyAgent";
import timeLine from "./timeline/timeline.vue";
// import propertyBanner from "./banner/propertyBanner.vue";
import propertyBrief from "./brief/propertyBrief.vue";
import propertyLocation from "./location/propertyLocation.vue";
import openHome from "./openHome/OpenHome.vue";
// import basicInformation from "./basicInformation/basicInformation";
import { collect, getAdsList, uncollect } from "@/api/api.js";
import { getHouseDetailPage } from "@/api/property";
import { API_SUCCESS_CODE } from "@/common/apiCode";
import contactTheAgent from "@/components/contactTheAgent.vue";
// import nearbyProperties from "@/components/nearbyProperties.vue";
// import recentlyViewedListings from "@/components/recentlyViewedListings.vue";
import { mapGetters } from "vuex";
import capitalValue from "./capitalValue/capitalValue.vue";
import detailBottom from "./detailBottom/detailBottom";
import PropertyCarousel from "./propertyCarousel/PropertyCarousel";
import propertyInformation from "./propertyInformation/propertyInformation.vue";
import propertySchool from "./school/school";

let currentPropertyId = "";

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export default {
  name: "PropertyDetailView",
  /**
   * @desc 自定义组件
   */
  components: {
    timeLine,
    capitalValue,
    propertyAgent,
    // propertyBanner,
    propertyBrief,
    propertyLocation,
    // basicInformation,
    openHome,
    propertyInformation,
    propertySchool,
    // nearbyProperties,
    detailBottom,
    // recentlyViewedListings,
    contactTheAgent,
    PropertyCarousel,
  },
  computed: {
    ...mapGetters("user", {
      isLogin: "isLogin",
    }),
  },
  /**
   * @desc 页面数据
   */
  data() {
    return {
      isStick: false,
      stickRight: (window.innerWidth - 1275) / 2,
      imageServerDomain: "",
      agents: [],
      times: [],
      env: process.env.NODE_ENV,
      house: {
        id: "",
        thirdId: "",
        name: null,
        source: "",
        photos: [],
        schools: [],
        videos: [],
        address: {},
        vrUrl: "",
        header: "",
        publishedDate: 0,
        description: "",
        propertyShortId: "",
        parkingCoveredCount: 0,
        landArea: 198,
        listingCategoryCode: "",
        parkingOtherCount: 0,
        isShowcased: true,
        bedroomCount: 0,
        listingSubType: "",
        listingNo: "",
        isCoastalWaterfront: false,
        bathroomEnsuiteCount: 0,
        isSuperFeatured: true,
        priceDisplay: "",
        storeyCount: 0,
        maxTenants: 0,
        floorArea: null,
        landAreaUnit: "",
        bathroomFullCount: 0,
        priceCode: 0,
        openHomes: [],
        listingStatus: "",
        showAgentPhoto: true,
        isMortgageeSale: false,
        hideAvm: false,
        bathroomCount: 3,
        createdDate: 0,
        createdTime: 0,
        modifiedTime: 0,
        offices: [],
        agents: [],
        price: null,
        top: 0,
        show: 0,
        topTime: 0,
        showTime: null,
        translateTime: 0,
        buyingSellingForm: null,
        outerPlatformShelfStatus: "",
        zhMap: {},
        claim: false,
        collect: false,
        collectType: null,
      },
      agentMap: {},
      officeMap: {},
      extendData: null,
      adList: [],
      scrollTop: 0,
      adsScrollTop: 0,
      adsScrollMax: 0,
    };
  },
  /**
   * @desc Mounted
   */
  async mounted() {
    currentPropertyId = this.$route.params.id;
    await this.getHouseDetail();
    await this.adRequest();
    window.addEventListener("scroll", () => {
      let adsScrollTop =
        window.scrollY - this.scrollTop > 0
          ? window.scrollY - this.scrollTop
          : 0;
      if (adsScrollTop > this.adsScrollMax) {
        adsScrollTop = this.adsScrollMax;
      }
      this.adsScrollTop = adsScrollTop;
    });
    this.computeTop();
  },
  /**
   * @desc
   */
  updated() {
    if (currentPropertyId !== this.$route.params.id) {
      this.getHouseDetail();
      currentPropertyId = this.$route.params.id;
    }
  },
  activated() {
    this.scrollToTop();
    if (currentPropertyId !== this.$route.params.id) {
      this.getHouseDetail();
    }
  },
  /**
   * @desc 页面方法
   */
  methods: {
    computeTop() {
      const scrollArea = document.querySelector(".ads-list");
      const scrollBlock = document.querySelector(".sticky-ads");
      this.scrollTop = scrollArea.offsetTop;
      this.adsScrollMax = scrollArea.offsetHeight - scrollBlock.offsetHeight;
    },

    scrollToTop() {
      window.scrollTo(0, 0);
    },
    goToAd(url) {
      window.location = url;
    },
    /**
     * @desc 广告
     */
    adRequest() {
      var params = {
        scope: "WEB_DETAIL_A,WEB_DETAIL_B",
        pageIndex: 1,
        pageSize: 2,
      };
      return getAdsList(params).then((res) => {
        if (res.status == 200) {
          this.adList = res.data.records;
        }
        console.log(res, "ads");
      });
    },
    /**
     * @desc 查询
     */
    async getHouseDetail() {
      try {
        const results = await getHouseDetailPage(this.$route.params.id);
        if (results.status === API_SUCCESS_CODE && results.data.hourse) {
          this.house = results.data.hourse;
          this.imageServerDomain = results.data.imageServerDomain;
          const agents = [];
          const times = results.data.extendData?.attributes?.["sales-history"];
          if (times) {
            let timeline = [];
            for (let time of times) {
              const d = new Date(time["sale-date"]);
              const month = d.getMonth();
              const date = d.getDate();
              const year = d.getFullYear();
              timeline.push({
                month: months[month] + " " + date,
                year: year,
                money: time["sale-price-display"],
                status: "Sales",
              });
            }
            this.times = timeline;
          }
          if (results.data.agentMap) {
            for (let key in results.data.agentMap) {
              var item = results.data.agentMap[key];
              console.log(results.data.officeMap);
              if (results.data.officeMap) {
                if (results.data.hourse.offices.length > 0) {
                  let office = results.data.hourse.offices[0];
                  if (results.data.officeMap[office.id]) {
                    item.officeMap = results.data.officeMap[office.id];
                  }
                }
              }
              if (!item.image && this.house.agents) {
                this.house.agents.forEach((agent) => {
                  if (item.id == agent.id) {
                    item.image = agent.image;
                  }
                });
              }
              agents.push(item);
            }
            this.agents = agents;
          }
        } else {
          // todo error
        }
      } catch (error) {
        // to exception
        console.log(error);
      }
    },
    async collect(collectType) {
      if (this.isLogin == false) {
        this.$router.push({ path: "/signUp" });
        return;
      }
      collect({
        hourseId: this.house.id,
        collectType,
      }).then((res) => {
        if (res.status == 200) {
          this.house.collectType = collectType;
        }
      });
    },
    async uncollect() {
      uncollect({ hourseId: this.house.id }).then((res) => {
        if (res.status == 200) {
          this.house.collectType = null;
        }
      });
    },
    handleAgentClick(id) {
      this.$router.push({ path: `/agent/${id}` });
    },
    handleToSuburb() {
      this.$router.push({
        path: `/suburb/${this.house.address.suburb}`,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./propertyDetailView.scss";
</style>