const mi = {
  nav: {
    Map: "Mahere",
    Properties: "Rawanga",
    Analyse: "Tātari",
    PropGuide: "Aratohu",
    Shortlist: "Rārangi Poto",
    AboutUs: "Mō mātou",
    Utilities: "Utilities",
    Home: "Home",
    Gallery: "Whakarārangi",
  },
  map: {
    Map: "Mahere",
    Satellite: "Amiorangi",
    ForSale: "Hei Hokona",
    ShowStormPipe: "Whakaatu ngongo ua",
    ShowWastePipe: "Whakaatu ngongo para",
    Shortlist: "Rārangi Poto",
    Shortlisted: "Kua Rārangitia",
    Maybe: "Tēnā pea",
    Nope: "Kāore",
  },
  detail: {
    Listed: "Kua Rārangitia",
    Size: "Rahi",
    Bedrooms: "Rūma moe",
    Bathrooms: "Rūma horoi",
    Garages: "Ngā Whare Waka",
    Location: "Wāhi",
    OpenHome: "Kāinga Tuwhera",
    BasicInformation: "Mōhiohio Taketake",
    Details: "Taipitopito",
    CapitalValuation: "Whakatau Whakapaipai",
    PropertyInformation: "Mōhiohio Rawanga",
    Timeline: "Wātaka",
    SchoolZoning: "Rohe Kura",
    ContactTheAgent: "Whakapā te Kaitakawaenga",
    Suburb: "Tāone iti",
    NearbyProperties: "Ngā Rawanga Takiwā",
    RecentlyViewedListings: "Ngā Rārangi Kua Tirohia Tātai nei",
    All: "Katoa",
    Primary: "Tuatahi",
    Intermediate: "Takawaenga",
    Secondary: "Tuarua",
    ShowMore: "Whakaatu atu anō",
    ShowLess: "Whakaatu iti iho",
    City: "Taone nui",
    Suburbs: "Tāone iti",
    AboutPropCentreCoNz: "Mō PropCentre.co.nz",
    PropertyType: "Momo Rawanga",
    Price: "Utu",
    TypeAddress: "Tāuruia he Wāhitau",
  },
  analyse: {
    PropertiesDataCentre: "Pokapū Raraunga Rawanga",
    Size: "Rahi",
    Bedrooms: "Rūma moe",
    Bathrooms: "Rūma horoi",
    Garages: "Ngā Whare Waka",
    BasicInformation: "Mōhiohio Taketake",
    CapitalValuation: "Whakatau Whakapaipai",
    PropertyInformation: "Mōhiohio Rawanga",
    Timeline: "Wātaka",
    SchoolZoning: "Rohe Kura",
    Parking: "Whare Waka me te Tūnga Waka",
  },
  shortlist: {
    ShortlistedProperties: "Ngā Rawanga Kua Rārangitia",
    All: "Katoa",
    Shortlisted: "Kua Rārangitia",
    Maybe: "Tēnā pea",
    Nope: "Kāore",
    Bedrooms: "Rūma moe",
    Bathrooms: "Rūma horoi",
    Garages: "Ngā Whare Waka",
  },
  aboutus: {
    CompanyIntroduction: "Whakataki Kamupene",
    cTextLineF:
      "Nau mai ki PropCentre, he pae tukutuku matatau e whakamahi ana i te mana o te AI me te tātari raraunga nui hei hurihanga i te ahumahi rawa pūmau.",
    cTextLineS:
      "I PropCentre, kei te ū mātou ki te huri i te āhua e hoko ai, e hoko atu ai te tangata i ngā rawa pūmau. Ahakoa he kaihoko whare e rapu ana i te rawa tino pai, he ngaio rawa pūmau e whai ana ki te whakamāmā i ō mahi, kua hoahoatia tō mātou pae tukutuku ki te whakatutuki i ō hiahia.",
    Functions: "Ngā Mahi",
    fTextLineTF: "1. Rapu Rawanga Atamai",
    fTextLineTFT:
      "Ka whakamana tō mātou pae tukutuku i ngā kaihoko whare ki te rapu rawanga wātea me te kore e uaua, ā, ka uru atu ki ngā mōhiohio whānui mō ia rārangi. Kimihia tō kāinga moemoeā me te ngāwari, me ngā taipitopito nui katoa e hiahiatia ana e koe.",
    fTextLineTS: "2. Mōhiohio Whānui mō te Rawanga",
    fTextLineTST:
      "Tūhura i ngā mōhiohio hōhonu mō ia rawanga, mai i ngā mahere papa me ngā rawa ki ngā tatauranga takiwā. Whakatau whakatau māramatanga mā te uru atu ki te taonga mōhiohio i ō maihao.",
    fTextLineTT: "3. Utauta Ngaiotanga mō ngā Tohunga Rawa Pūmau",
    fTextLineTTT:
      "Ka taea e ngā ngaio rawa pūmau te whakakaha i tō rātou hua mahi mā te whakamahi i tō mātou huinga utauta e pā ana ki te rawanga. Mai i te tātari mākete ki te whakahaere kiritaki, ka whakaritea koe e tō mātou pae tukutuku ki ngā rauemi hei eke panuku i te ahumahi.",
    gnfxzj:
      "Ka whakamahia e tō mātou pae tukutuku ngā ahunga whakamua hōu i roto i te AI me te tātari raraunga nui hei whakarite kia whiwhi koe i ngā mōhiohio tino tika me te whakahōu. E whakapono ana mātou mā te whakakotahi i te hangarau me te mātauranga rawa pūmau, ka taea e mātou te whakamāmā i te tukanga hoko me te hoko atu, kia pai ake, kia mārama ake hoki mō te katoa e whai wāhi ana.",
    szyxfw: "Ratonga Tauhokohoko Matihiko",
    szyxfw1: "Whakatairanga Rārangi Rawa Pūmau",
    szyxfw1T:
      "Whakanuia te kiteatanga me te hiahia ki tō rawanga i roto i te mākete rawa pūmau whakataetae me tō mātou ratonga Whakatairanga Rārangi Rawa Pūmau Tuihono. Ahakoa he kaipupuri whare koe, he ngaio rawa pūmau rānei, ka tuku tō mātou pae tukutuku i te otinga whānui hei whakaatu whai hua i ō rārangi.",
    szyxfw2: "Panui Whakanoho Whakaatu",
    szyxfw2T:
      "Wewete i te kaha o te tika me te pānga i ō pakanga whakatairanga matihiko me tō mātou ratonga Panui Whakanoho Whakaatu. He mea tino pai mō ngā Pakihi e whai ana ki te whakanui i tō rātou toronga, ngā kaihokohoko e whāia ana he whakakitenga tino whāia rānei, ka taea te whakamahi i tō mātou pae tukutuku mō te otinga whānui hei whakakaha i tō aroaro waitohu.",
    MultiPlatformAd: "Pānuitanga Kaiwhakawhana Maha-Whakahoahoanga",
    NZAndOversea: "NZ me ngā whenua ke",
    NZContent:
      "Whakanuia ō mahi tauhokohoko ki te taumata teitei ake me te Pānuitanga Kaiwhakawhana Maha-Whakahoahoanga, he rautaki kaha e whakamahi ana i te mana o ngā kaiwhakawhana puta noa i ngā pāpāho pāpori maha. Ka tuku tēnei ratonga he otinga whānui hei whakamahi i te toro atu me te pono o ngā kaiwhakawhana. Ka taea e koe te whakamahi hei whakanui ake i te mōhiotanga waitohu, hei whakakaha ake i te whakaurunga, hei whakapiki i ngā hokonga rānei.",
    TechnicalD: "Tautoko Whanaketanga Hangarau mō te Hinonga",
    TechnicalDC: "Tātaritanga Raraunga Tūtohutanga Whaiaro AI Kōrero me Ngā Kaiwhakawhitiwhiti Kōrerorero",
    OurMission: "Tā Mātou Mihana",
    OurMission1:
      "I PropCentre, ko tā mātou mihana he whakamana i ngā tāngata takitahi me ngā ngaio kia rite ki ngā taputapu me te mātauranga e tika ana hei arahi i te mākete rawa pūmau me te māia. Kei te ngākaunui mātou ki te whakarato i tētahi wheako māmā, ngāwari ki te kaiwhakamahi e whakapiki ana i te ahumahi rawa pūmau ki ngā taumata hou.",
    OurMission2:
      "Hono mai ki a mātou i tēnei haerenga whakahihiko, ka tūtaki te auahatanga ki te rawa pūmau, ā, tahi tātou e whakaahua ai te heke mai o ngā whakawhitinga rawa.",
    OurMission3: "Tūhuratia a PropCentre i tēnei rā, ā, wheako i te āhua o te rawa pūmau o āpōpō.",
    ContactUs: "Whakapā Mai",
  },
};
export default mi;
