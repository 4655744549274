<template>
  <div class="recommend_view">
    <!-- <div class="recommend_t_head">
      <div class="recommend_t">Recommend in</div>
      <el-cascader
        placeholder="All Region"
        size="mini"
        @change="regionChangeEvent"
        :props="optionProps"
        :options="options"
        :show-all-levels="false"
      ></el-cascader>
    </div> -->
    <div class="card_view" v-loading="loading" v-if="propertyId">
      <div @click="tapcard">
        <img class="house-cell-img" :src="coverUrl" />
        <p class="cell-title-label">
          {{ title }}
        </p>
        <p class="cell-info-label">{{ desc }}</p>
        <div class="cell-price-bottom-view">
          <p style="color: #158c79; font-size: 14px; font-weight: 700">
            {{ priceDisplay }}
          </p>
          <!-- <div class="cell-bottom-shuxing-view">
            <div
              style="display: flex; flex-direction: row; align-items: center"
              v-if="landArea > 0"
            >
              <img class="cell_info_bottom_img" src="../assets/mianji.png" />
              <p class="cell_info_bottom_label">
                {{ landArea + (landAreaUnit == "SQM" ? "㎡" : landAreaUnit) }}
              </p>
            </div>
            <div
              style="display: flex; flex-direction: row; align-items: center"
              v-if="bedroomCount > 0"
            >
              <img class="cell_info_bottom_img" src="../assets/woshi.png" />
              <p class="cell_info_bottom_label">
                {{ bedroomCount }}
              </p>
            </div>
            <div
              style="display: flex; flex-direction: row; align-items: center"
              v-if="bathroomCount > 0"
            >
              <img class="cell_info_bottom_img" src="../assets/yushi.png" />
              <p class="cell_info_bottom_label">
                {{ bathroomCount }}
              </p>
            </div>
        </div> -->
        </div>
        <div v-if="photoNum" class="photo-nums-view">
          <img
            style="margin-left: 8px; height: 11px; width: 11px"
            src="../assets/photo_icon.png"
          />
          <p
            style="
              color: #158c79;
              font-size: 12px;
              font-weight: 700;
              margin-left: 5px;
            "
          >
            {{ photoNum }}
          </p>
        </div>
        <div class="agent-view">
          <div
            style="
              z-index: 2;
              display: flex;
              flex-direction: row;
              align-items: center;
            "
            v-for="(agentItem, agentIndex) in agents"
            :key="agentIndex"
          >
            <img
              :class="
                agentIndex == 0 ? 'cell_head_img' : 'cell_head_img_second'
              "
              :src="agentItem.userHeadImageUrl"
            />
          </div>
        </div>
        <div class="bottom-cell-view">
          <div
            v-if="!collectType || collectType === 'NOPE'"
            @click.stop="collectType ? uncollectEvent() : collectEvent('NOPE')"
            style="background: #ffab0014; border-radius: 8px"
            class="bottom-btn-view"
          >
            <img
              style="width: 10px; height: 10px; margin-right: 6px"
              src="../assets/nope-orange.png"
            />
            <div style="color: #ffab00; font-weight: 800">&nbsp;Nope</div>
          </div>
          <div
            v-if="!collectType || collectType === 'MAYBE'"
            @click.stop="collectType ? uncollectEvent() : collectEvent('MAYBE')"
            style="border-radius: 8px; background-color: #919eab14"
            class="bottom-btn-view"
          >
            <div style="color: #212b36">Maybe</div>
          </div>
          <div
            v-if="!collectType || collectType === 'SHOUTLISTED'"
            @click.stop="
              collectType ? uncollectEvent() : collectEvent('SHOUTLISTED')
            "
            style="background-color: #02958014; border-radius: 8px"
            class="bottom-btn-view"
          >
            <img
              style="width: 10px; height: 10px; margin-right: 6px"
              src="../assets/right-green.png"
            />
            <div style="color: #029580; font-size: 12px; font-weight: 700">
              {{ collectType === "SHOUTLISTED" ? "Shortlisted" : "Shortlist" }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getArrayPropsDef,
  getBooleanPropsDef,
  getEmptyStringPropsDef,
  getNumberPropsDef,
  getObjectPropsDef,
} from "@/common/propsConfig.js";
import relationOptions from "@/common/relationData.js";
import { mapGetters } from "vuex";
export default {
  props: {
    loading: getBooleanPropsDef(),
    agentMap: getObjectPropsDef(),
    houseList: getArrayPropsDef(),
    imageServerDomain: getEmptyStringPropsDef(),
    // 展示信息
    propertyId: getEmptyStringPropsDef(),
    agents: getArrayPropsDef(),
    coverUrl: getEmptyStringPropsDef(),
    photoNum: getNumberPropsDef(),
    title: getEmptyStringPropsDef(),
    desc: getEmptyStringPropsDef(),
    size: getNumberPropsDef(),
    bedroomCount: getNumberPropsDef(),
    bathroomCount: getNumberPropsDef(),
    collectType: getEmptyStringPropsDef(),
    landArea: getNumberPropsDef(),
    landAreaUnit: getEmptyStringPropsDef(),
    priceDisplay: getEmptyStringPropsDef(),
  },
  watch: {
    collectType(newVal) {
      console.log("collect type change: " + newVal);
    },
  },
  data() {
    return {
      options: [],
      optionProps: {
        value: "value",
        label: "name",
        children: "submenu",
      },
      district: "", //区
      suburb: "", //街道
      form: { sale: 1 },
      list: [],
      selIndex: 0,
    };
  },
  computed: {
    ...mapGetters("user", {
      isLogin: "isLogin",
    }),
  },
  mounted() {
    // this.loading = true;
    relationOptions.forEach((item) => {
      this.options.push(item);
    });
  },
  methods: {
    tapcard() {
      this.$emit("tapcard", this.propertyId);
    },
    imgUrl(item) {
      if (item.photos != null) {
        if (item.source === "OWNER") {
          return item.photos[0].baseUrl;
        } else {
          if (item.photos.length > 0) {
            if (item.photos[0].baseUrl.indexOf("http") != -1) {
              return item.photos[0].baseUrl;
            } else {
              return (
                this.imageServerDomain +
                item.photos[0].baseUrl +
                ".crop.580x400.jpg"
              );
            }
          } else {
            return "";
          }
        }
      }
      return "";
    },
    calculateProcess() {
      if (this.list == 0) {
        return 0;
      }
      var process = 0;
      this.list.forEach((item) => {
        if (
          item.collectType != null &&
          item.collectType != undefined &&
          item.collectType != ""
        ) {
          process++;
        }
      });
      return (process / this.list.length).toFixed(2) * 100;
    },
    mutateSelindex() {
      setTimeout(() => {
        if (this.selIndex < this.list.length - 1) {
          this.selIndex++;
        } else {
          this.selIndex = 0;
        }
        this.$emit("switchIndex", this.selIndex);
      }, 1000);
    },
    collectEvent(collectType) {
      this.$emit("collect", { id: this.propertyId, collectType });
    },
    uncollectEvent() {
      this.$emit("uncollect", { id: this.propertyId });
    },
    regionChangeEvent(val) {
      console.log(val);
      var form = {};
      if (val[0] != "all") {
        if (val[1] != "all") {
          form.district = val[1];
        }
        if (val[2] != "all") {
          form.suburb = val[2];
        }
      }
      this.form = { ...this.form, ...form };
      this.requestMain();
      console.log(form);
    },
    collectSort() {
      var sortList = [];
      this.list.forEach((item) => {
        if (
          item.collectType == "" ||
          item.collectType == null ||
          item.collectType == undefined
        ) {
          sortList.push(item);
        }
      });
      this.list.forEach((item) => {
        if (
          item.collectType != "" &&
          item.collectType != null &&
          item.collectType != undefined
        ) {
          sortList.push(item);
        }
      });
      this.list = sortList;
    },
    makeData(houseList) {
      this.selIndex = 0;
      this.list.length = 0;
      houseList.forEach((item, i) => {
        var newItem = {};
        newItem = item;
        if (item.agents != null && item.agents.length > 0) {
          newItem.agentMap = {};
          let mapping = "";
          for (let key in this.agentMap) {
            mapping += `${key},`;
          }
          console.log(mapping, item.agents[0]?.id, i, "agent mapping");
          newItem.agentMap.officeBrandHexCode =
            this.agentMap[item.agents[0].id]?.officeBrandHexCode || "#000000";
          if (this.agentMap[item.agents[0].id]?.image == null) {
            newItem.agentMap.userHeadImageUrl = require("../assets/headDefault.png");
          } else {
            if (
              this.agentMap[item.agents[0].id].image["base-url"].indexOf(
                "http"
              ) != -1
            ) {
              newItem.agentMap.userHeadImageUrl =
                this.agentMap[item.agents[0].id].image["base-url"];
            } else {
              newItem.agentMap.userHeadImageUrl =
                this.imageServerDomain +
                this.agentMap[item.agents[0].id].image["base-url"] +
                ".crop.128x128.jpg";
            }
          }
          newItem.agentMap.name =
            this.agentMap[item.agents[0].id].firstName +
            " " +
            this.agentMap[item.agents[0].id].lastName;
          newItem.agentMap.officeImage =
            this.imageServerDomain +
            this.agentMap[item.agents[0].id].officeImage +
            ".crop.350x70.jpg";

          newItem.agents.forEach((subItem) => {
            if (this.agentMap[subItem.id].image == null) {
              subItem.userHeadImageUrl = require("../assets/headDefault.png");
            } else {
              if (
                this.agentMap[subItem.id].image["base-url"].indexOf("http") !=
                -1
              ) {
                subItem.userHeadImageUrl =
                  this.agentMap[subItem.id].image["base-url"];
              } else {
                subItem.userHeadImageUrl =
                  this.imageServerDomain +
                  this.agentMap[subItem.id].image["base-url"] +
                  ".crop.200x200.jpg";
              }
            }
            subItem.officeBrandHexCode =
              this.agentMap[subItem.id].officeBrandHexCode;
            subItem.officeImage =
              this.imageServerDomain +
              this.agentMap[subItem.id].officeImage +
              ".crop.350x70.jpg";
            subItem.phoneMobile = this.agentMap[subItem.id].phoneMobile;
            subItem.introduction = this.agentMap[subItem.id].introduction;
            subItem.name =
              this.agentMap[subItem.id].firstName +
              " " +
              this.agentMap[subItem.id].lastName;
            subItem.email = this.agentMap[subItem.id].email;
          });
        }
        this.list.push(newItem);
        this.collectSort();
      });
    },
  },
};
</script>

<style scoped lang="scss">
.recommend_view {
  // position: absolute;
  // top: 126px;
  // left: auto;
  // right: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 998;
  // height: 427px;
  background: #fff;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  padding: 8px;
}
.recommend_t_head {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 50px;
}
.recommend_t {
  margin-right: 5px;
  font-size: 16px;
  line-height: 19px;
  font-weight: 700;
  text-align: left;
  color: #000000;
}
.sale_num {
  margin-left: 17px;
  color: #636363;
  font-size: 12px;
  line-height: 15px;
  font-weight: 700;
  text-align: left;
}
.process_item {
  height: 10px;
  width: 224px;
  margin-left: 17px;
  margin-top: 15px;
}
.card_view {
  display: flex;
  width: 100%;
  flex-direction: column;
  position: relative;
}
.bottom-cell-view {
  margin-top: auto;
  margin-bottom: 0px;
  width: 100%;
  display: flex;
  flex-direction: row;
  font-family: Public Sans;
  align-items: center;
  justify-content: space-between;
  padding: 12px 8px;
  box-sizing: border-box;
  cursor: pointer;
}
.bottom-btn-view {
  flex: 1;
  margin: 0 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 40px;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 700;
  font-family: Public Sans;
}
.house-cell-img {
  width: 352px;
  height: 196px;
  border-radius: 8px;
  background-color: #f3f5f8;
  border: 0;
}
.cell-title-label {
  margin-top: 12px;
  color: #333333;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  width: calc(100% - 24px);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  margin-left: 12px;
  text-align: left;
}
.cell-info-label {
  margin-top: 8px;
  font-size: 12px;
  color: #919eab;
  line-height: 18px;
  width: calc(100% - 24px);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  margin-left: 12px;
  text-align: left;
}
.cell-price-bottom-view {
  height: 31px;
  margin-top: 5px;
  width: calc(100% - 24px);
  margin-left: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.cell-bottom-shuxing-view {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
  margin-right: 0px;
}
.cell_info_bottom_label {
  font-size: 10px;
  line-height: 12px;
  color: #000000;
  font-weight: 700;
  margin-left: 5px;
}
.cell_info_bottom_img {
  width: 15px;
  height: 15px;
  margin-left: 15px;
}
.photo-nums-view {
  height: 22px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #ffffff;
  border-radius: 4px;
  position: absolute;
  z-index: 100;
  top: 168px;
  left: auto;
  right: 13px;
  padding-right: 8px;
}
.agent-view {
  position: absolute;
  left: 17px;
  top: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.ad-agent-view {
  position: absolute;
  left: auto;
  right: 24px;
  top: 45px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.ad_head_img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #eaeaea;
  z-index: 2;
}
.cell_head_img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #eaeaea;
}
.cell_head_img_second {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #eaeaea;
  margin-left: -10px;
}
.cell_office_image_s {
  border-radius: 4px;
  height: 22px;
  margin-left: -10px;
  width: 85px;
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.cell_office_image_b {
  border-radius: 4px;
  height: 44px;
  margin-left: -20px;
  width: 164px;
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
</style>