var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contact-agent-view"},[_c('div',{staticClass:"agent-info-view"},[(_vm.agent.image)?_c('img',{staticClass:"agent-head",attrs:{"src":_vm.headUrl(_vm.agent.image['base-url'])}}):_vm._e(),_c('div',{staticClass:"agent-name-phone"},[_c('div',{staticClass:"agent-name"},[_vm._v(" "+_vm._s(_vm.agent.firstName + " " + _vm.agent.lastName)+" ")]),_c('div',{staticClass:"agent-phone-view"},[_c('img',{staticClass:"agent-icon",attrs:{"src":require("../assets/phone-black.png")}}),_c('div',{staticClass:"agent-phone"},[_vm._v(_vm._s(_vm.agent.phoneMobile))])])]),(_vm.agent.officeMap)?_c('div',{staticClass:"logo-view",style:({
        backgroundColor:
          _vm.agent.officeBrandHexCode || _vm.agent.officeMap?.brandHexCode,
      }),on:{"click":function($event){return _vm.goToOffice(_vm.agent.officeMap.id)}}},[(_vm.agent.officeMap && _vm.agent.officeMap.images && _vm.agent.officeMap.images.length > 0)?_c('img',{staticClass:"logo-img",attrs:{"src":_vm.officeUrl(_vm.agent.officeImage || _vm.agent.officeMap.images[0]['base-url'])}}):_vm._e()]):_vm._e()]),_c('div',{staticClass:"send-email-view"},[_c('div',{staticClass:"send-email-left-view"},[_vm._m(0),_c('el-input',{staticClass:"section-input",staticStyle:{"height":"50px","border-radius":"8px"},attrs:{"size":"large"},model:{value:(_vm.form.firstName),callback:function ($$v) {_vm.$set(_vm.form, "firstName", $$v)},expression:"form.firstName"}}),_vm._m(1),_c('el-input',{staticClass:"section-input",staticStyle:{"height":"50px","border-radius":"8px"},attrs:{"size":"large"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_vm._m(2),_c('el-input',{staticClass:"section-input",staticStyle:{"height":"50px","border-radius":"8px"},attrs:{"size":"large"},model:{value:(_vm.form.phoneNum),callback:function ($$v) {_vm.$set(_vm.form, "phoneNum", $$v)},expression:"form.phoneNum"}})],1),_c('div',{staticClass:"send-email-right-view"},[_vm._m(3),_c('el-input',{staticClass:"section-input",attrs:{"placeholder":"I'm interested in 15 Woven Place, Drury (ref. 850817) and I would like more information please.","autosize":{ minRows: 6, maxRows: 6 },"type":"textarea"},model:{value:(_vm.form.message),callback:function ($$v) {_vm.$set(_vm.form, "message", $$v)},expression:"form.message"}}),_c('div',{staticStyle:{"height":"16px"}}),_c('el-button',{style:({
          width: '100%',
          height: '50px',
          borderRadius: '8px',
          backgroundColor: '#212B36',
          color: '#FFF',
          fontWeight: 600,
        }),attrs:{"loading":_vm.sending,"size":"large","disabled":!(_vm.form.email && _vm.form.firstName)},on:{"click":_vm.sendEmail}},[_vm._v("Submit Now")])],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section-title"},[_c('div',{staticClass:"section-small-title"},[_vm._v(" First Name"),_c('span',{staticStyle:{"color":"#637381"}},[_vm._v("*")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section-title"},[_c('div',{staticClass:"section-small-title"},[_vm._v(" Email"),_c('span',{staticStyle:{"color":"#637381"}},[_vm._v("*")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section-title"},[_c('div',{staticClass:"section-small-title"},[_vm._v("Phone")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section-title"},[_c('div',{staticClass:"section-small-title"},[_vm._v("Message")])])
}]

export { render, staticRenderFns }