<template>
	<div class="home">
		<div class="home-top">
			<img class="home-top-img" src="../assets/home/home_1.png"/>
			<div style="z-index: 1;">
				<p class="home-top-txt1">Increase the visibility of your listing to attract more attention.</p>
				<p class="home-top-txt2">Tours, Agents, Homes</p>
				<p class="home-top-txt3">
				PropCentre leverages the cutting-edge capabilities of real estate marketing technology, digital marketing platforms, and immersive virtual reality solutions to offer<br/>
				unparalleled real estate marketing. Specializing in high-quality property photography, virtual reality tours, and AI-driven digital marketing strategies, PropCentre aims<br/>
				to revolutionize how properties are presented, promoted, and sold in today's competitive market. Our mission is to enhance the property buying experience with<br/>
				innovative visualizations and effective marketing, setting new industry standards for excellence and engagement.
				</p>
			</div>
		</div>
		<div class="home-middle-view">
			<p class="home-title-big">Make your space stand out with PropCentre</p>
			<p style="margin-top: 72px;" class="home-title-middle">
				Looking to make people more attention and look around？<br/>
				Sell your listings faster and at a higher price!
			</p>
			<p style="margin-top: 72px;" class="home-title-small">
				We are constantly innovating, promising agents new tools and proptech that will keep you at the forefront of real estate marketing.
			</p>
			<div @click="show = true" style="margin-top: 72px;" class="contact-us-btn">{{ $t('aboutus.ContactUs') }}</div>
			<img style="margin-top: 72px;width: 990px;height: 612px;" src="../assets/home/home_2.png"/>
		</div>
		<div style="padding-top: 92px;padding-bottom: 144px;background: linear-gradient(to bottom, #F7FCFB 0%, #E0F5F1 50%, #F7FCFB 100%);" class="home-middle-view">
			<p class="home-title-big">Present your properties in the best way</p>
			<p style="margin-top: 64px;" class="home-title-small">
				Captures exceptionally stunning photographs.
			</p>
			<img style="margin-top: 115px;width: 990px;height: 410px;" src="../assets/home/home_3.png"/>
		</div>
		<div style="background: linear-gradient(90deg, #F7FCFB 100%, #F7FCFB 100%);padding-top: 159px;padding-bottom: 164px;" class="home-middle-view">
			<p class="home-title-big">Digital Space solution</p>
			<div style="display: flex;flex-direction: row;width: 930px;margin-top: 53px;">
				<div class="home-middle-left-menu">
					<div v-for="(item,index) in digitalMenus" :key="index">
						<div @click="goToUrl(item.url)" v-if="index == activeIndex" class="menu-item-active">
							<div class="menu-item-title">{{ item.title }}</div>
							<div class="menu-item-desc">{{ item.desc }}</div>
							<div style="color: #029580;font-size: 12px;text-align: left;">Explore more ></div>
						</div>
						<div v-else class="menu-item-normal" @mouseenter="activateTab(index)">
							<div class="menu-item-title">{{ item.title }}</div>
						</div>
					</div>
				</div>
				<div class="content">
					<img style="width: 100%;height: 100%;" :src="digitalMenus[activeIndex].imgUrl"/>
				</div>
			</div>
		</div>
		<div style="padding-top: 86px;padding-bottom: 86px;" class="home-middle-view">
			<p class="home-title-big">Payless, Get More</p>
			<video controls style="margin-top: 32px;width: 957px;height: 588px;" src="../assets/home/home_decs_video.mp4"></video>
			<p style="margin-top: 32px;" class="home-title-small">
				At PropCentre, we believe in maximizing value for real estate agents. Our platform offers an all-encompassing 3D <br/>
				virtual tour solution that ensures you pay less while getting more. With our professional-grade capture devices, flexible <br/>
				hosting plans without monthly fees, and rich editing features, you can elevate your property listings effortlessly. Plus,<br/>
				our unique add-ons like Live 3D Call and AI Virtual Renovation distinguish your offerings, giving you a competitive edge <br/>
				in the market. Embrace efficiency and excellence with PropCentre, where your investment goes further.
			</p>
		</div>
		<div style="padding-top: 165px;padding-bottom: 165px;" class="home-middle-view">
			<p class="home-title-big">Enhance Your Digital Presence and Results</p>
			<p style="margin-top: 32px;margin-bottom: 150px;" class="home-title-small">
				PropCentre stands at the forefront of real estate marketing, harnessing the power of Western major social platforms <br/>
				like Google, Facebook, Instagram and Asia major social platforms This approach extends your reach to both passive and <br/>
				active buyers outside traditional listings, through a blend of targeted advertising and organic social content, powered <br/>
				by advanced automation. Propel your brand forward, expand your reach, and increase web traffic with PropCentre.
			</p>
		</div>
		<div style="padding-top: 156px;padding-bottom: 156px;" class="home-middle-view">
			<p class="home-title-big">Highlighted Advantages</p>
			<div style="margin-top: 56px;width: 1010px;display: flex;flex-direction: row;justify-content: space-between;">
				<div style="display: flex;flex-direction: row;">
					<img style="width: 50px;height: 50px;" src="../assets/home/homeBrand.png"/>
					<div style="display: flex;flex-direction: column;margin-left: 15px;">
						<div style="color: #000;font-size: 20px;text-align: left;">
							Brand Enhancement
						</div>
						<div style="color: #707171;font-size: 16px;text-align: left;">
							PropCentre's tailored marketing<br/>
							strategies ensure that your real<br/>
							estate brand stands out with a<br/>
							distinct and professional image.
						</div>
					</div>
				</div>
				<div style="display: flex;flex-direction: row;">
					<img style="width: 50px;height: 50px;" src="../assets/home/homeApproach.png"/>
					<div style="display: flex;flex-direction: column;margin-left: 15px;">
						<div style="color: #000;font-size: 20px;text-align: left;">
							User-Centric Approach
						</div>
						<div style="color: #707171;font-size: 16px;text-align: left;">
							Drive significant traffic directly to<br/>
							your listings with our targeted<br/>
							campaigns, emphasizing your<br/>
							unique offerings.
						</div>
					</div>
				</div>
				<div style="display: flex;flex-direction: row;">
					<img style="width: 50px;height: 50px;" src="../assets/home/homeBroadened.png"/>
					<div style="display: flex;flex-direction: column;margin-left: 15px;">
						<div style="color: #000;font-size: 20px;text-align: left;">
							Broadened Exposure
						</div>
						<div style="color: #707171;font-size: 16px;text-align: left;">
							Our innovative platforms extend<br/>
							your reach, capturing the attention<br/>
							of both passive and active property<br/>
							seekers.
						</div>
					</div>
				</div>
			</div>
			<div style="margin-top: 60px;width: 1010px;display: flex;flex-direction: row;justify-content: space-between;">
				<div style="display: flex;flex-direction: row;">
					<img style="width: 50px;height: 50px;" src="../assets/home/homeEfficiency.png"/>
					<div style="display: flex;flex-direction: column;margin-left: 15px;">
						<div style="color: #000;font-size: 20px;text-align: left;">
							Efficiency
						</div>
						<div style="color: #707171;font-size: 16px;text-align: left;">
							Experience a streamlined process<br/>
							with our integrated solutions,<br/>
							designed to save you time and<br/>
							amplify your marketing efforts.
						</div>
					</div>
				</div>
				<div style="display: flex;flex-direction: row;">
					<img style="width: 50px;height: 50px;" src="../assets/home/homeMeasured.png"/>
					<div style="display: flex;flex-direction: column;margin-left: 15px;">
						<div style="color: #000;font-size: 20px;text-align: left;">
							Measured Success
						</div>
						<div style="color: #707171;font-size: 16px;text-align: left;">
							Benefit from our data-driven<br/>
							insights that reflect a proven track<br/>
							record of success and positive<br/>
							industry feedback.
						</div>
					</div>
				</div>
				<div style="display: flex;flex-direction: row;">
					<img style="width: 50px;height: 50px;" src="../assets/home/homeDiverse.png"/>
					<div style="display: flex;flex-direction: column;margin-left: 15px;">
						<div style="color: #000;font-size: 20px;text-align: left;">
							Diverse Marketing Channels
						</div>
						<div style="color: #707171;font-size: 16px;text-align: left;">
							Take advantage of PropCentre's<br/>
							wide array of advertising options<br/>
							across multiple digital channels for<br/>
							comprehensive visibility.
						</div>
					</div>
				</div>
			</div>
		</div>
		<div style="padding-top: 186px;padding-bottom: 186px;" class="home-middle-view">
			<p class="home-title-big">Contact Us</p>
			<div style="margin-top: 84px;font-size: 18px;" class="home-title-small">Australia</div>
			<div style="margin-top: 30px;" class="home-title-small">Email:&ensp;&ensp;enquiryqld@propcentre.ai&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;TelePhone:&ensp;&ensp;0450 516 528</div>
			<div style="margin-top: 84px;font-size: 18px;" class="home-title-small">New Zealand</div>
			<div style="margin-top: 30px;" class="home-title-small">Email:&ensp;&ensp;info@propcentre.co.nz&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;TelePhone:&ensp;&ensp;09 600 6550</div>
		</div>
		<el-dialog
		:visible.sync="show"
		width="40%"
		center>
<!-- 		<span style="color: #333333;font-size: 20px;font-weight: 600;">&ensp;&ensp;&ensp;&ensp;Email:&ensp;&ensp;</span>
		<span style="color: #333333;font-size: 20px;">info@propcentre.co.nz</span>
		<br><br>
		<span style="color: #333333;font-size: 20px;font-weight: 600;">TelePhone:&ensp;&ensp;</span>
		<span style="color: #333333;font-size: 20px;">09 600 6550</span> -->
		<div style="display: flex;flex-direction: column;align-items: center;justify-content: center;width: 100%;padding-bottom: 30px;">
			<div style="font-size: 18px;" class="home-title-small">Australia</div>
			<div style="margin-top: 30px;" class="home-title-small">Email:&ensp;&ensp;enquiryqld@propcentre.ai&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;TelePhone:&ensp;&ensp;0450 516 528</div>
			<div style="margin-top: 30px;font-size: 18px;" class="home-title-small">New Zealand</div>
			<div style="margin-top: 30px;" class="home-title-small">Email:&ensp;&ensp;info@propcentre.co.nz&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;TelePhone:&ensp;&ensp;09 600 6550</div>
		</div>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				show: false,
				digitalMenus: [
					{
						title: 'Real estate',
						desc: 'Close deals faster than ever by creating and customizing your own home tours.',
						imgUrl: require("../assets/home/home_real.png"),
						url: 'https://new-vr.realsee.jp/cvr/KvmldMp4wuLWnjH2m4/kOymXk75SqhrXN76PkW25fZFgh4hkL4X'
					},
					{
						title: 'Construction',
						desc: 'Walk through your construction site remotely with immersive 3D virtual tours.',
						imgUrl: require("../assets/home/home_construction.png"),
						url: 'https://new-vr.realsee.jp/cvr/WnmRQkKK2FPAWviXDP/P4dOWlvgI4hp0JORwqBZ0i7F5h7hmdQx'
					},
					{
						title: 'Hospitality',
						desc: 'Bring your property to life for prospective guests with immersive virtual experiences.',
						imgUrl: require("../assets/home/home_hospital.png"),
						url: 'https://realsee.jp/N4eexPaz'
					},
					{
						title: 'Retail',
						desc: 'Walk through your construction site remotely with immersive 3D virtual tours.',
						imgUrl: require("../assets/home/home_retail.png"),
						url: 'https://new-vr.realsee.jp/cvr/ABD01N96KTyknEF1mN/eRLaqV7bCbhN2BJpMrKj4IpFmhQh6LPg'
					}
				],
				activeIndex: 0
			}
		},
		methods: {
			    // 激活Tab的方法
			activateTab(index) {
				this.activeIndex = index;
			},
			goToUrl(url) {
				window.location.href = url
			}
		}
	}
</script>

<style scoped lang="scss">
	.home {
		min-width: 1440px;
		display: flex;
		flex-direction: column;
		align-items: center;
		background-color: #FFFFFF;
		margin: 0px auto;
	}
	.home-top {
		height: 59vw;
		width: 100%;
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		.home-top-img {
			height: 100%;
			width: 100%;
			left: 0;
			top: 0;
			position: absolute;
			z-index: 0;
		}
		.home-top-txt1 {
			margin-top: 235px;
			text-align: center;
			color: #FFF;
			font-family: Urbanist;
			font-size: 26px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
		}
		.home-top-txt2 {
			text-align: center;
			margin-top: 71px;
			color: #FFF;
			font-family: Urbanist;
			font-size: 36px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
		}
		.home-top-txt3 {
			margin-top: 12vw;
			color: #FFF;
			text-align: center;
			font-family: Urbanist;
			font-size: 12px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
		}
	}
	.home-middle-view {
		width: 1440px;
		background: #F7FCFB;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 149px 100px;
		.home-title-big {
			color: #1C1D1F;
			font-family: Urbanist;
			font-size: 40px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
		}
		.home-title-middle {
			color: #6D6E72;
			text-align: center;
			font-family: Urbanist;
			font-size: 20px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
		}
		.home-title-small {
			color: #6D6E72;
			text-align: center;
			font-family: Urbanist;
			font-size: 16px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
		}
	}
	.contact-us-btn {
		background-color: #029580;
		text-align: center;
		color: #fff;
		font-size: 16px;
		padding: 10px 60px;
		border-radius: 8px;
	}
	.home-middle-left-menu {
		width: 250px;
		display: flex;
		flex-direction: column;
		margin-right: 10px;
		border-left: #E1E3EB 1px solid;
		justify-content: space-between;
		align-items: center;
		height: 456px;
		.menu-item-active {
			background: #fff;
			width: 100%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			padding: 15px;
			cursor: pointer;
		}
		.menu-item-normal {
			width: 100%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			min-height: 114px;
			cursor: pointer;
		}
		.menu-item-title {
			color: #000000;
			font-size: 14px;
			text-align: left;
		}
		.menu-item-desc {
			color: #ABAEBD;
			font-size: 14px;
			text-align: left;
			margin-top: 5px;
		}
		.content {
		  height: 675px;
		  width: 446px;
		  position: relative;
		}
		.content > img {
		  display: none;
		  position: absolute;
		  transition: opacity 0.5s; /* 添加动画效果 */
		}
		
		/* 当类为active时显示内容 */
		.content > img.active {
		  display: block;
		  opacity: 1;
		}
	}
</style>