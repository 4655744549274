<template>
  <div class="agent-container">
    <div class="agent-wrapper">
      <div class="agent-info">
        <div class="agent-avatar-wrapper">
          <img
            class="agent-avatar"
            :src="
              imageServerDomain +
              agentData.image['base-url'] +
              '.crop.128x128.jpg'
            "
          />
        </div>
        <div class="agent-text">
          <h2>{{ agentData.name }}</h2>
          <div>
            <span style="font-weight: 600">{{ agentData.officeName }}</span>
          </div>
          <p>Phone: {{ agentData.phoneMobile }}</p>
          <p>Email: {{ agentData.email }}</p>
        </div>
      </div>
      <div class="send-email">
        <h4>Contact the Agent</h4>
        <div class="mail-form">
          <div class="mail-left">
            <div>
              <p>First Name</p>
              <div>
                <input
                  class="mail-input"
                  type="text"
                  name="firstName"
                  v-model="mailFirstName"
                />
              </div>
            </div>
            <div>
              <p>Email</p>
              <div>
                <input
                  class="mail-input"
                  type="text"
                  name="email"
                  v-model="mailAddress"
                />
              </div>
            </div>
            <div>
              <p>Phone</p>
              <div>
                <input
                  class="mail-input"
                  type="text"
                  name="phone"
                  v-model="mailPhone"
                />
              </div>
            </div>
          </div>
          <div class="mail-right">
            <div>
              <p>Message</p>
              <div>
                <textarea
                  class="mail-text"
                  name="message"
                  v-model="mailContent"
                />
              </div>
            </div>
            <div>
              <button class="mail-button" type="submit">Send Email</button>
            </div>
          </div>
        </div>
      </div>
      <div class="active-listings">
        <h4>Active Listings</h4>
        <div class="agent-house-cards">
          <div
            v-for="house in houseList"
            :key="house.id"
            class="agent-house-card-wrapper"
            @click="handleClickHouse(house.id)"
          >
            <AgentHouseCard
              :price="house.priceDisplay || ''"
              :address="house.address.fullAddress"
              :area="house.floorArea"
              :bedroom="house.bedroomCount"
              :bathroom="house.bathroomCount"
              :image-url="
                imageServerDomain +
                house.photos[0]['baseUrl'] +
                house.photos[0]['large']
              "
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "./agent.scss";
import AgentHouseCard from "./AgentHouse.vue";
import { getAgentInfo, getAgentHouseInfo } from "@/api/api.js";

export default {
  components: {
    AgentHouseCard,
  },
  watch: {
    $route() {
      this.getAgent();
      this.getAgentHouses();
    },
  },
  data() {
    return {
      agentData: {
        assigned: false,
        createdTime: "",
        deleted: false,
        email: "",
        firstName: "",
        hourseNum: 0,
        id: "",
        image: {
          "base-url": "",
        },
        introduction: "",
        lastName: "",
        modifiedTime: "",
        name: "",
        officeBrandHexCode: "",
        officeImage: "",
        officeName: "",
        phoneMobile: "",
        slug: "",
        type: "",
      },
      imageServerDomain: "",
      pageIndex: 1,
      houseList: [],
      mailFirstName: "",
      mailContent: "",
      mailAddress: "",
      mailPhone: "",
    };
  },
  mounted() {
    this.getAgent();
    this.getAgentHouses();
  },
  methods: {
    async getAgent() {
      const results = await getAgentInfo(this.$route.params.id);
      this.agentData = results.data;
    },
    async getAgentHouses() {
      const results = await getAgentHouseInfo(
        this.$route.params.id,
        this.pageIndex
      );
      this.imageServerDomain = results.data.imageServerDomain;
      this.houseList = results.data.hoursePage.records;
      console.log(this.houseList, "house list");
    },
    handleClickHouse(id) {
      this.$router.push({
        path: `/property/${id}`,
      });
    },
  },
};
</script>