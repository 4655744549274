<template>
  <div class="capital-value">
    <div class="capital-value__data">
      <div class="capital-value__data-circle" id="pie" />
      <div class="capital-value__data-example">
        <div class="capital-value__data-row">
          <div
            class="capital-value__data-row__dot"
            style="background-color: #40927f; margin: 0 16px"
          ></div>
          <div>
            <span
              >Land Value: ${{ this.capital.landValue.replace("$", "") }}</span
            >
          </div>
        </div>
        <div class="capital-value__data-row">
          <div
            class="capital-value__data-row__dot"
            style="background-color: #76c8b4; margin: 0 16px"
          ></div>
          <div>
            <span
              >Improvement Value: ${{
                this.capital.improvementsValue.replace("$", "")
              }}</span
            >
          </div>
        </div>
        <div v-show="this.capital.propertyRate" class="capital-value__data-row">
          <div
            class="capital-value__data-row__dot"
            style="background-color: #76c8b4; margin: 0 16px"
          ></div>
          <div>
            <span>Property Rate: {{ this.capital.propertyRate }}</span>
          </div>
        </div>
        <div v-show="this.capital.rateYear" class="capital-value__data-row">
          <div
            class="capital-value__data-row__dot"
            style="background-color: #76c8b4; margin: 0 16px"
          ></div>
          <div>
            <span>Rate Year: {{ this.capital.rateYear }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="capital-desc">
      <p style="text-align: left">
        Capital Valuation is sourced from the relevant council. Please confirm
        directly with the council before making any decision based on this data.
      </p>
    </div>
  </div>
</template>
<script>
import {
  getEmptyStringPropsDef,
  getObjectPropsDef,
} from "@/common/propsConfig";
import * as echarts from "echarts";

export default {
  data() {
    return {};
  },
  props: {
    total: getEmptyStringPropsDef(),
    capital: getObjectPropsDef(),
  },
  watch: {
    capital() {
      setTimeout(() => {
        this.draw();
      }, 200);
    },
  },
  mounted() {
    setTimeout(() => {
      this.draw();
    }, 200);
  },
  methods: {
    draw() {
      if (!this.capital.landValue || !this.capital.improvementsValue) {
        return;
      }
      const pieChart = echarts.init(document.getElementById("pie"));
      const landValue = Number(
        this.capital.landValue.replaceAll(",", "").replace("$", "")
      );
      const improvementsValue = Number(
        this.capital.improvementsValue.replaceAll(",", "").replace("$", "")
      );
      const option = {
        title: {
          text: `Total`,
          subtext: "$" + (improvementsValue + landValue).toLocaleString(),
          left: "center",
          top: "35%",
          textStyle: {
            color: "#637381",
            fontSize: 12, // 设置第一行标题的字体大小
            lineHeight: 10, // 设置行高以创建更多的空间
            fontWeight: 400,
          },
          subtextStyle: {
            color: "#212B36",
            fontSize: 24, // 设置第二行标题的字体大小
            lineHeight: 24, // 设置行高以创建更多的空间
            fontWeight: 800,
          },
        },
        color: ["#40927F", "#76C8B4"],
        series: [
          {
            type: "pie",
            radius: ["70%", "80%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center",
            },
            labelLine: {
              show: false,
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "10",
                fontWeight: "bold",
              },
            },
            data: [
              { value: landValue, name: "" },
              { value: improvementsValue, name: "" },
            ],
          },
        ],
      };
      pieChart.setOption(option);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./capitalValue.scss";
</style>