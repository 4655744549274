<template>
  <div class="suburb-wrapper">
    <div class="suburb-map">
      <p-map
        v-if="lat"
        :initLat="lat"
        :initLng="lng"
        :zoom="13"
        :coordinates="coordinates"
        :locationList="locationList"
        @choose-property="onTapProperty"
      />
      <div class="suburb-house-detail">
        <map-recommend
          :loading="loadingDetail"
          :houseList="houseList"
          :imageServerDomain="imageServerDomain"
          :propertyId="selectedDisplayData.propertyId"
          :coverUrl="selectedDisplayData.coverUrl"
          :photoNum="selectedDisplayData.photoNum"
          :title="selectedDisplayData.title"
          :desc="selectedDisplayData.desc"
          :size="selectedDisplayData.size"
          :bedroomCount="selectedDisplayData.bedroomCount"
          :bathroomCount="selectedDisplayData.bathroomCount"
          :collectType="selectedDisplayData.collectType"
          :landArea="selectedDisplayData.landArea"
          :landAreaUnit="selectedDisplayData.landAreaUnit"
          @collect="onCollect"
          @uncollect="onUnCollect"
          @tapcard="toDetail"
        />
      </div>
    </div>
    <div style="height: 40px"></div>
    <div v-if="agents?.length > 0" class="suburb-agents">
      <h2>Agents</h2>
      <div class="suburb-agents-list">
        <div v-for="agent in agents" :key="agent.id" class="suburb-agent-item">
          <agent-card
            :agentName="`${agent.firstName} ${agent.lastName}`"
            :officeName="agent.officeName"
            :agentHead="
              imageServerDomain +
              agent.image?.['base-url'] +
              '.crop.128x128.jpg'
            "
            :officeImage="
              imageServerDomain + agent.officeImage + '.crop.284x84.jpg'
            "
            :officeBrandColor="agent.officeBrandHexCode"
            :id="agent.id"
            @show-phone-view="switchShowPhoneView(agent)"
            @show-email-view="switchShowSendEmail(agent)"
          />
        </div>
      </div>
      <div class="suburb-agents-page">
        <div
          class="suburb-agents-page-item"
          @click="
            handleClickAgentPage(pageIndexAgent > 1 ? pageIndexAgent - 1 : 1)
          "
        >
          &lt;
        </div>
        <div
          v-for="(num, index) in pageAgents"
          :key="num"
          class="suburb-agents-page-item"
          :style="{
            width: index > 9 ? '25px' : '',
            fontWeight: index + 1 === pageIndexAgent ? 'bold' : '',
            color: index + 1 === pageIndexAgent ? '#158c79' : '',
          }"
          @click="handleClickAgentPage(index + 1)"
        >
          {{ index + 1 }}
        </div>
        <div
          class="suburb-agents-page-item"
          @click="
            handleClickAgentPage(
              pageIndexAgent < pageAgents ? pageIndexAgent + 1 : pageAgents
            )
          "
        >
          &gt;
        </div>
      </div>
    </div>
    <div class="suburb-houses">
      <h2>Properties For Sales</h2>
      <div class="suburb-properties-list">
        <div
          v-for="house in houseList"
          :key="house.id"
          class="suburb-property-item"
          @click="toPropertyDetail(house.id)"
        >
          <AgentHouseCard
            :price="house.priceDisplay || ''"
            :address="house.address.fullAddress"
            :area="house.floorArea"
            :bedroom="house.bedroomCount"
            :bathroom="house.bathroomCount"
            :image-url="
              imageServerDomain +
              house.photos[0]['baseUrl'] +
              house.photos[0]['large']
            "
          />
        </div>
      </div>
      <div class="suburb-house-page">
        <div
          class="suburb-house-page-item"
          @click="
            handleClickHousePage(pageIndexHouse > 1 ? pageIndexHouse - 1 : 1)
          "
        >
          &lt;
        </div>
        <div
          v-for="(num, index) in pageHouses"
          :key="num"
          class="suburb-house-page-item"
          :style="{
            width: index > 9 ? '25px' : '',
            fontWeight: index + 1 === pageIndexHouse ? 'bold' : '',
            color: index + 1 === pageIndexHouse ? '#158c79' : '',
          }"
          @click="handleClickHousePage(index + 1)"
        >
          {{ index + 1 }}
        </div>
        <div
          class="suburb-house-page-item"
          @click="
            handleClickHousePage(
              pageIndexHouse < pageHouses ? pageIndexHouse + 1 : pageHouses
            )
          "
        >
          &gt;
        </div>
      </div>
    </div>
    <copy-phone-view
      :isShow="showCopyPhone"
      :agent="currnetAgent"
      :imageServerDomain="imageServerDomain"
      @dismiss="dissCopyPhoneView"
    ></copy-phone-view>
    <send-email-view
      :isShow="showSendEmail"
      :agent="currnetAgent"
      :imageServerDomain="imageServerDomain"
      @dismiss="dissSendEmailView"
    ></send-email-view>
    <detail-bottom />
  </div>
</template>

<script>
import pMap from "../map/widgets/map/map.vue";
import AgentCard from "@/components/agentCard.vue";
import AgentHouseCard from "../agent/AgentHouse.vue";
import {
  getSuburbHouseInfo,
  getAgentListBySuburb,
  getSuburbData,
} from "@/api/api.js";
import DetailBottom from "../propertyDetail/detailBottom/detailBottom.vue";
import copyPhoneView from "../intermediaryDetail/copyPhoneView.vue";
import sendEmailView from "../intermediaryDetail/sendEmailView.vue";
import { getHouseListByLatLng, getHouseDetail } from "@/api/property";
import MapRecommend from "../../components/mapRecommend.vue";
import bus from "@/utils/bus";
import { collect, uncollect } from "@/api/api.js";
import { mapGetters } from "vuex";

const initLat = 0,
  initLng = 0,
  defaultDistance = 1500;

export default {
  name: "suburbView",
  components: {
    pMap,
    AgentCard,
    AgentHouseCard,
    DetailBottom,
    copyPhoneView,
    sendEmailView,
    MapRecommend,
  },
  data() {
    return {
      showCopyPhone: false,
      showSendEmail: false,
      suburbName: "",
      imageServerDomain: "",
      lat: initLat,
      lng: initLng,
      distance: defaultDistance,
      agents: [],
      houseList: [],
      pageHouses: 1,
      pageAgents: 1,
      pageIndexHouse: 1,
      pageIndexAgent: 1,
      currnetAgent: {},
      coordinates: [],
      locationList: [],
      selectedProperty: {},
      selectedDisplayData: {},
      loadingDetail: false,
    };
  },
  mounted() {
    this.suburbName = this.$route.params.suburbName;
    this.getSuburbData();
    this.getSuburbHouses();
    this.getSuburbAgents();
  },
  computed: {
    ...mapGetters("user", {
      isLogin: "isLogin",
    }),
  },
  methods: {
    async getSuburbData() {
      const results = await getSuburbData(this.suburbName);
      if (!results) {
        return;
      }
      const suburb = results.data;
      this.lat = Number(suburb.basic.lat);
      this.lng = Number(suburb.basic.lng);
      this.coordinates = suburb.boundary.map((b) => {
        return [b.lng, b.lat];
      });
      this.initData(suburb.basic.lat, suburb.basic.lng, defaultDistance);
    },
    async getSuburbHouses() {
      const results = await getSuburbHouseInfo(
        this.suburbName,
        this.pageIndexHouse
      );
      this.imageServerDomain = results.data.imageServerDomain;
      this.houseList = results.data.hoursePage.records;
      this.pageHouses = results.data.hoursePage.pages;
    },
    handleClickAgentPage(index) {
      this.pageIndexAgent = index;
      this.getSuburbAgents();
    },
    async getSuburbAgents() {
      const results = await getAgentListBySuburb(
        this.suburbName,
        this.pageIndexAgent
      );
      this.agents = results.data.records;
      this.pageAgents = results.data.pages;
    },
    /**
     * @desc get houses by lat，lng，distance
     */
    async getHousesByLatLng(params) {
      const results = await getHouseListByLatLng(params);
      return (this.locationList = results.data);
    },
    async initData(latitude, longitude, distance) {
      await this.getHousesByLatLng({
        latitude,
        longitude,
        distance,
      });
    },
    handleClickHousePage(index) {
      this.pageIndexHouse = index;
      this.getSuburbHouses();
    },
    toPropertyDetail(id) {
      this.$router.push({ path: `/property/${id}` });
    },
    switchShowPhoneView(agent) {
      this.currnetAgent = agent;
      this.showCopyPhone = true;
    },
    switchShowSendEmail(agent) {
      this.currnetAgent = agent;
      this.showSendEmail = true;
    },
    dissCopyPhoneView() {
      this.currnetAgent = {};
      this.showCopyPhone = false;
    },
    dissSendEmailView() {
      this.currnetAgent = {};
      this.showSendEmail = false;
    },

    /**
     * @desc 用户点击收集房源
     */
    onCollect({ id, collectType }) {
      if (this.isLogin == false) {
        this.$router.push({ path: "/signUp" });
        return;
      }
      this.selectedDisplayData.collectType = collectType;
      bus.$emit("collect", { id, collectType });
      // this.houseDetailList.find((item) => item.id === id).collectType =
      //   collectType;
      collect({ hourseId: id, collectType })
        .then(() => {
          setTimeout(() => {
            const idx = this.locationList.findIndex(
              (location) => location.id === id
            );
            console.log(idx);
            const next = this.locationList.find((location, index) => {
              return index > idx && !location.collect;
            });
            if (next) {
              console.log(next);
              this.onTapProperty({ id: next.id });
              this.selectedPoint = next;
            }
          }, 500);
        })
        .catch((error) => {
          this.$emit("collect-error", {
            id: this.propertyId,
            collectType,
            error,
          });
        });
    },
    /**
     * @desc 用户点击取消收集
     */
    onUnCollect({ id }) {
      this.selectedDisplayData.collectType = null;
      bus.$emit("uncollect", { id });
      uncollect({ hourseId: id }).then((res) => {
        console.log(res);
      });
    },
    /**
     * @desc 用户点击卡片
     */
    toDetail(propertyId) {
      this.$router.push(`/property/${propertyId}`);
    },
    /**
     * @desc 获取点击的房产信息
     */
    onTapProperty({ id }) {
      this.getSelectedProperty(id);
    },
    /**
     * @desc 查询被选中的房屋
     */
    async getSelectedProperty(id) {
      this.loadingDetail = true;
      const results = await getHouseDetail(id);
      this.selectedProperty = results.data;
      const { imageServerDomain, hourse } = results.data;
      const selectedDisplayData = {
        coverUrl: hourse.photos[0]?.baseUrl
          ? imageServerDomain + hourse.photos[0]?.baseUrl + ".crop.580x400.jpg"
          : require("@/assets/unsale_back.png"),
        photoNum: hourse.photos[0]?.length || 0,
        title: hourse.address.displayAddress,
        desc: hourse.header,
        size: hourse.size,
        bedroomCount: hourse.bedroomCount,
        bathroomCount: hourse.bathroomCount,
        collectType: hourse.collectType,
        landArea: hourse.landArea,
        landAreaUnit: hourse.landAreaUnit,
        propertyId: hourse.id,
        priceDisplay: hourse.priceDisplay,
      };
      this.selectedDisplayData = selectedDisplayData;
      setTimeout(() => {
        this.loadingDetail = false;
      }, 300);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./suburb.scss";
</style>