import aiService from "../utils/aiRequest.js";
import service from "../utils/request";
// 房屋列表
export function getHouseList(data) {
  return service({
    url: `/api/v1/nz/hourse/new/list`,
    method: "get",
    params: data,
  });
}
// 登录
export function login(data) {
  return service({
    url: `/api/v1/nz/email/login`,
    method: "post",
    data,
  });
}

// 注册
export function register(data) {
  return service({
    url: `/api/v1/nz/email/register`,
    method: "post",
    data,
  });
}
//获取验证码
export function getCode(data) {
  return service({
    url: `/api/v1/nz/send/email-code?email=${data.email}`,
    method: "post",
  });
}

// 收藏
export function collect(data) {
  return service({
    url: `api/v1/nz/user/collect/with-collect-type`,
    method: "post",
    data,
  });
}

// 取消收藏
export function uncollect(data) {
  return service({
    url: `api/v1/nz/user/uncollect?hourseId=${data.hourseId}`,
    method: "post",
    data,
  });
}

//收藏列表
export function getCollectList(data) {
  return service({
    url: `/api/v1/nz/user/collect/list`,
    method: "get",
    params: data,
  });
}

//搜索关键词列表
export function getSearchkeysList(data) {
  return service({
    url: `/api/v1/nz/address/keywords/search`,
    method: "get",
    params: data,
  });
}

//广告列表
export function getAdsList(data) {
  return service({
    url: `/api/v1/nz/advertisement/list`,
    method: "get",
    params: data,
  });
}

export function getAgentInfo(id) {
  return service({
    url: `/api/v1/nz/agent/detail/${id}`,
    method: "get",
  });
}

export function getAgentHouseInfo(id, pageIndex) {
  return service({
    url: `/api/v1/nz/hourse/list/agent?agentId=${id}&pageIndex=${pageIndex}&pageSize=20`,
    method: "get",
  });
}

export function getAgentListBySuburb(suburb, pageIndex) {
  return service({
    url: `/api/v1/nz/agent/list?suburb=${suburb}&pageIndex=${pageIndex}&pageSize=8`,
    method: "get",
  });
}

export function getSuburbHouseInfo(suburb, pageIndex) {
  return service({
    url: `/api/v1/nz/hourse/list?suburb=${suburb}&pageIndex=${pageIndex}&pageSize=8`,
    method: "get",
  });
}

//联系代理
export function contactAgent(data) {
  return service({
    url: `/api/v1/nz/user/contact-agent/save`,
    method: "post",
    data,
  });
}
export function getAiResponse(data) {
  return aiService({
    url: `/api/v1/nz/ai-chat/new/send`,
    method: "post",
    data,
  });
}
export function getGptsAiResponse(data) {
  return aiService({
    url: `/api/v1/nz/ai-chat/openai/send`,
    method: "post",
    data,
  });
}
export function getOffice(id) {
  return service({
    url: `/api/v1/nz/office/detail/${id}`,
    method: "get",
  });
}
export function getAgentByOfficeId(data) {
  return service({
    url: `/api/v1/nz/agent/list`,
    method: "get",
    params: data,
  });
}
export function getHourseByOfficeId(data) {
  return service({
    url: `/api/v1/nz/hourse/list/office`,
    method: "get",
    params: data,
  });
}

export function getSuburbData(suburb) {
  return service({
    url: `/api/v1/nz/geo/suburb-detail`,
    method: "get",
    params: { suburb },
  });
}

export function getAiQuestions() {
  return service({
    url: `/api/v1/nz/ai-chat/questions`,
    method: "get",
  });
}

export function getDistrict() {
  return service({
    url: `/api/v1/nz/suburb-statistics/district`,
    method: "get",
  });
}

export function getSuburbs(district) {
  return service({
    url: `/api/v1/nz/suburb-statistics/suburb?district=${district}`,
    method: "get",
  });
}

export function getGalleryList(galleryType, pageIndex, pageSize) {
  return service({
    url: `/api/v1/nz/gallery/list?galleryType=${galleryType}&pageIndex=${pageIndex}&pageSize=${pageSize}`,
    method: "get",
  });
}

export function getGalleryDetail(id) {
  return service({
    url: `/api/v1/nz/gallery/detail/${id}`,
    method: "get",
  });
}
