<template>
  <div class="ourTeam">
    <div class="ourTeam_Title">Our Team</div>
    <div style="display: flex; flex-direction: column; width: 1300px">
      <div class="ourTeam_List">
        <div
          :class="
            index % 4 == 3 ? 'ourTeam_Cell' : 'ourTeam_Cell ourTeam_GapRight'
          "
          v-for="(item, index) in list"
          :key="index"
        >
          <div
            class="ourTeam_officeBack"
            :style="{ backgroundColor: item.officeBrandHexCode }"
          ></div>
          <img class="ourTeam_officeLogo" :src="officeImg(item)" />
          <img class="ourTeam_agentHead" :src="headImg(item)" />
          <div class="ourTeam_agentName">{{ item.name }}</div>
          <div class="ourTeam_officeName">{{ item.officeName }}</div>
          <img
            @click="goToAgent(item.id)"
            class="ourTeam_viewProfile"
            src="../../assets/viewprofile.png"
          />
          <div class="ourTeam_emailView">
            <img
              @click="sendEmail(item)"
              class="ourTeam_emailEnquiry"
              src="../../assets/emailEnquiry.png"
            />
            <div @click="copyPhone(item)" class="ourTeam_phoneBtn">
              <img src="../../assets/I_phone.png" class="ourTeam_phoneIcon" />
            </div>
          </div>
        </div>
      </div>
      <el-pagination
        class="ourTeam_pagination"
        layout="prev, pager, next"
        :total="total"
        :page-size="8"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
    <send-email-view
      :isShow="showSendEmail"
      :agent="currnetAgent"
      :imageServerDomain="imageServerDomain"
      @dismiss="dissSendEmailView"
    ></send-email-view>
    <copy-phone-view
      :isShow="showCopyPhone"
      :agent="currnetAgent"
      :imageServerDomain="imageServerDomain"
      @dismiss="dissCopyPhoneView"
    ></copy-phone-view>
  </div>
</template>

<script>
import { getAgentByOfficeId } from "../../api/api.js";
import sendEmailView from "./sendEmailView.vue";
import copyPhoneView from "./copyPhoneView.vue";
export default {
  data() {
    return {
      officeId: "",
      list: [],
      total: 0,
      currentPage: 1,
      showSendEmail: false,
      currnetAgent: {},
      showCopyPhone: false,
    };
  },
  components: {
    sendEmailView,
    copyPhoneView,
  },
  props: {
    id: String,
    imageServerDomain: String,
  },
  watch: {
    id(value) {
      this.officeId = value;
      this.getList();
    },
  },
  methods: {
    goToAgent(id) {
      this.$router.push({ path: `/agent/${id}` });
    },
    copyPhone(item) {
      this.currnetAgent = item;
      this.showCopyPhone = true;
    },
    sendEmail(item) {
      this.currnetAgent = item;
      this.showSendEmail = true;
    },
    dissSendEmailView() {
      this.currnetAgent = {};
      this.showSendEmail = false;
    },
    dissCopyPhoneView() {
      this.currnetAgent = {};
      this.showCopyPhone = false;
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getList();
    },
    getList() {
      var form = {};
      form.officeId = this.officeId;
      form.pageIndex = this.currentPage;
      form.pageSize = 8;
      getAgentByOfficeId(form).then((res) => {
        if (res.status == 200) {
          this.total = res.data.total;
          this.list.length = 0;
          this.list = res.data.records;
        }
      });
    },
    headImg(item) {
      if (item.image) {
        var imgO = item.image;
        return this.imageServerDomain + imgO["base-url"] + ".crop.200x200.jpg";
      } else {
        return "";
      }
    },
    officeImg(item) {
      return this.imageServerDomain + item.officeImage + ".crop.284x84.jpg";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./ourTeam.scss";
</style>